import type {
  accountClassification_enum,
  activeStatus_enum,
  journalEntry_bool_exp,
  party_enum,
} from '@finalytic/graphql';
import { type Maybe, day, hasValue } from '@finalytic/utils';

export type JournalEntryType =
  | 'deposit'
  | 'expense'
  | 'reservation'
  | 'recurringFee'
  | 'transfer';

export const whereJournalEntries = ({
  date,
  tenantId,
  type,
  party,
  search,
  listingId,
  accountId,
  accountClassification,
  isPublished,
  status,
}: {
  date?: Maybe<string>;
  tenantId: string;
  type?: Maybe<JournalEntryType>;
  search?: Maybe<string>;
  party?: Maybe<party_enum>;
  isPublished?: Maybe<boolean>;
  status?: Maybe<activeStatus_enum | 'all'>;
  listingId?: Maybe<string>;
  accountId?: Maybe<string>;
  accountClassification?: Maybe<accountClassification_enum>;
}): journalEntry_bool_exp => {
  const dates = date?.split('...');

  const start = dates?.[0]
    ? day(dates[0]).startOf('day').yyyymmdd()
    : undefined;
  const e = dates?.[1] ? day(dates[1]).add(1, 'day').yyyymmdd() : undefined;
  const end = e || day(start).add(1, 'day').yyyymmdd();

  const _and: journalEntry_bool_exp[] = [];

  if (type === 'deposit') {
    _and.push({
      transaction: {
        type: { _eq: 'deposit' },
      },
    });
  } else if (type === 'expense') {
    _and.push({
      transaction: {
        type: { _eq: 'expense' },
      },
    });
  } else if (type === 'reservation') {
    _and.push({
      reservationId: { _is_null: false },
      recurringFeeId: { _is_null: true },
      transactionId: { _is_null: true },
    });
  } else if (type === 'recurringFee') {
    _and.push({
      recurringFeeId: { _is_null: false },
    });
  } else if (type === 'transfer') {
    _and.push({
      transaction: {
        type: { _eq: 'transfer' },
      },
    });
  }

  if (search) {
    _and.push({
      _or: [
        { description: { _ilike: `%${search}%` } },
        { uniqueRef: { _ilike: `%${search}%` } },
        { transaction: { uniqueRef: { _ilike: `%${search}%` } } },
        /^\d+$/.test(search) ? { txnNum: { _eq: Number(search) } } : undefined,
        { reservation: { confirmationCode: { _ilike: `%${search}%` } } },
      ].filter(hasValue),
    });
  }

  if (listingId) {
    _and.push({
      _or: [
        {
          listingOwnershipPeriod: {
            listingId: { _eq: listingId },
          },
        },
        { listingId: { _eq: listingId } },
        { reservation: { listingId: { _eq: listingId } } },
      ],
    });
  }

  return {
    tenantId: { _eq: tenantId },
    status: status === 'all' ? undefined : { _eq: status || 'active' },
    accountId: accountId ? { _eq: accountId } : undefined,
    account: accountClassification
      ? { classification: { _eq: accountClassification } }
      : undefined,
    attachedToOwnerStatementId:
      typeof isPublished === 'boolean' ? { _is_null: !isPublished } : undefined,
    txnAt: date
      ? {
          _gte: start,
          _lt: end,
        }
      : undefined,
    party: party ? { _eq: party as party_enum } : undefined,
    _and: _and.length ? _and : undefined,
  };
};
