import { CalendarFilterDateType, Filter } from '@finalytic/components';
import { gqlV2, useInfiniteQuery } from '@finalytic/data';
import { action_bool_exp, schema_bool_exp } from '@finalytic/graphql';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { SelectItem } from '@finalytic/ui';
import { day, toTitleCase } from '@finalytic/utils';
import { Box, Group, Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { useCallback, useMemo, useState } from 'react';
import { AutomationActionStatusIcon } from '../../views/automations/components';

type Props = {
  connectionId: string;
  tenantId: string;
};

type FilterState = {
  search: string;
  type: string | null;
  date: CalendarFilterDateType | undefined;
};

export const ConnectionSyncedToTable = (props: Props) => {
  const initial: FilterState = {
    search: '',
    type: null,
    date: undefined,
  };
  const [filter, setFilter] = useSetState<FilterState>(initial);
  const resetFilter = useCallback(() => setFilter(initial), []);

  const columns = useMemo<
    MRT_ColumnDef<{
      id: string;
      status: string;
      uniqueRef: string;
      type: string;
      createdAt: string;
      message: string;
    }>[]
  >(
    () => [
      {
        accessorKey: 'uniqueRef',
        header: 'Title',
        maxSize: 100,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Group wrap="nowrap" maw="100%" w="100%">
              <AutomationActionStatusIcon status={data.status} />
              <Box
                maw="100%"
                w="100%"
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                <Text component="p" m={0}>
                  {data.uniqueRef}
                </Text>
                {data.type && (
                  <Text component="p" m={0} color={'gray'}>
                    {data.type}
                  </Text>
                )}
              </Box>
            </Group>
          );
        },
      },
      {
        accessorKey: 'message',
        header: 'Message',
      },
      {
        accessorKey: 'createdAt',
        header: 'Actions',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        maxSize: 140,
        Cell: ({ row }) =>
          row.original.createdAt
            ? day(row.original.createdAt).format('MMM D, YYYY HH:mm')
            : '',
      },
    ],
    []
  );

  const infiniteData = useInfiniteQuery(
    (q, { connectionId, search, type, date, tenantId }, { limit, offset }) => {
      if (!connectionId)
        return {
          aggregate: 0,
          list: [],
        };

      const getDateFilter = ():
        | gqlV2.timestamptz_comparison_exp
        | undefined => {
        const start = date?.[0] ? day(date?.[0]).yyyymmdd() : undefined;
        const end = date?.[1] ? day(date?.[1]).yyyymmdd() : undefined;
        if (!start && !end) return undefined;

        if (!end) {
          return {
            _gte: start,
            _lt: day(start).add(1, 'day').yyyymmdd(),
          };
        }

        return {
          _gte: start,
          _lt: end,
        };
      };

      const where: action_bool_exp = {
        tenantId: { _eq: tenantId },
        status: { _nin: ['failed', 'pending'] },
        jobPlan: {
          tenantId: { _eq: tenantId },
          _or: [
            {
              connectionId: { _eq: connectionId },
            },
            {
              automation: {
                leftConnectionId: { _eq: connectionId },
              },
            },
            {
              automation: {
                rightConnectionId: { _eq: connectionId },
              },
            },
          ],
        },
        createdAt: getDateFilter(),
        _or: search
          ? [
              { title: { _ilike: `%${search}%` } },
              {
                uniqueRef: {
                  _ilike: `%${search}%`,
                },
              },
            ]
          : undefined,
      };

      const aggregate =
        q
          .aggregateActions({ where, distinct_on: ['uniqueRef', 'schemaId'] })
          .aggregate?.count() || 0;

      const list = q
        .actions({
          where,
          limit,
          offset,
          order_by: [
            { uniqueRef: 'asc_nulls_last' },
            { schemaId: 'desc_nulls_last' },
          ],
          distinct_on: ['uniqueRef', 'schemaId'],
        })
        .map((action) => {
          return {
            id: action.id,
            createdAt: action.createdAt,
            message: action.title || toTitleCase(action?.status || '') || '',
            status: action.status || '',
            uniqueRef: action.uniqueRef || '',
            type: toTitleCase(action.schema?.uniqueRef || '') || '',
          };
        });

      if (type === 'success') {
        return {
          list,
          aggregate,
        };
      }

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: filter.search.trim(),
        type: filter.type,
        connectionId: props.connectionId,
        date: filter.date,
        tenantId: props.tenantId,
      },
      queryKey: 'actions',
    }
  );

  return (
    <InfiniteTable
      columns={columns}
      table={{
        // columns,
        hideHeader: true,
        emptyRowsFallback: 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      resetFilter={resetFilter}
    >
      <Group>
        <Filter.Search
          value={filter.search}
          setValue={(s) => setFilter({ search: s })}
        />
        <TypeFilter
          {...props}
          type={filter.type}
          setType={(v) => setFilter({ type: v })}
        />
        <Filter.Date
          value={filter.date}
          setValue={(newDate) =>
            setFilter({
              date: newDate,
            })
          }
        />
      </Group>
    </InfiniteTable>
  );
};

const TypeFilter = ({
  connectionId,
  setType,
  tenantId,
  type,
}: Props & { type: string | null; setType: (v: string | null) => void }) => {
  const [search, setSearch] = useState('');
  const queryData = useInfiniteQuery(
    (q, { connectionId, search }, { limit, offset }) => {
      if (!connectionId || !tenantId)
        return {
          aggregate: 0,
          list: [],
        };

      const where: schema_bool_exp = {
        uniqueRef: search ? { _ilike: `%${search}%` } : { _is_null: false },
        jobs: {
          status: { _neq: 'failed' },
          plan: {
            _or: [
              {
                connectionId: { _eq: connectionId },
              },
              {
                automation: {
                  leftConnectionId: { _eq: connectionId },
                },
              },
              {
                automation: {
                  rightConnectionId: { _eq: connectionId },
                },
              },
            ],
          },
        },
      };

      const aggregate =
        q
          .aggregateSchemata({ where, distinct_on: ['uniqueRef'] })
          .aggregate?.count() || 0;

      const list = q
        .schemata({
          where,
          limit,
          offset,
          order_by: [{ uniqueRef: 'asc_nulls_last' }],
          distinct_on: ['uniqueRef'],
        })
        .map<SelectItem>((schema) => {
          return {
            value: schema.uniqueRef!,
            label: toTitleCase(schema?.uniqueRef) || 'No name',
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: search.trim(),
        connectionId,
        tenantId,
      },
    }
  );

  const value = type ? { value: type, label: toTitleCase(type) } : null;

  return (
    <Filter.Select
      label="Type"
      type="single"
      value={value}
      setValue={(newValue) => {
        setType(newValue?.value || null);
      }}
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};
