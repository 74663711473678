import { Button } from '@finalytic/components';
import { useDisclosure } from '@mantine/hooks';
import { TaxRateModal } from './TaxRateModal';

export const AddTaxRateButton = () => {
  const [opened, handlers] = useDisclosure(false);
  return (
    <>
      <Button variant="primary" leftIcon={'PlusIcon'} onClick={handlers.open}>
        Add tax rate
      </Button>

      <TaxRateModal closeModal={handlers.close} opened={opened} />
    </>
  );
};
