import { InputSelect, InputWrapper } from '@finalytic/components';
import { type gqlV2, useMutation, useQuery, useTeamId } from '@finalytic/data';
import type { currency_enum } from '@finalytic/graphql';
import type { SelectItem } from '@finalytic/ui';
import { type Maybe, sortBy, toTitleCase } from '@finalytic/utils';
import { Tooltip } from '@mantine/core';
import { currencies } from '@vrplatform/ui-common';
import { memo, useMemo, useState } from 'react';

function useTeamCurrencyQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      const defaultCurrency = q.tenantById({
        id: args.teamId,
      })?.defaultCurrency;

      const hasJournalEntries = !!q
        .journalEntries({
          where: {
            tenantId: { _eq: args.teamId },
          },
          limit: 1,
          order_by: [{ createdAt: 'asc_nulls_last' }],
        })
        .map((entry) => entry.id)[0];

      return {
        defaultCurrency,
        hasJournalEntries,
      };
    },
    {
      queryKey: ['teams'],
      variables: {
        teamId,
      },
    }
  );
}

export const TeamCurrencySelect = memo(function Cell() {
  const { data: queryData, isLoading: loadingValue } = useTeamCurrencyQuery();
  const [teamId] = useTeamId();

  const [updatedCurrency, setUpdatedCurrency] =
    useState<Maybe<gqlV2.currency_enum>>();

  const initialCurrency = queryData?.defaultCurrency;
  const hasJournalEntries = queryData?.hasJournalEntries;

  const {
    mutate,
    loading: loadingMutation,
    error: errorMutation,
  } = useMutation(
    (
      q,
      args: {
        tenantId: string;
        currency: gqlV2.currency_enum | null;
      }
    ) => {
      return q.updateTenantById({
        pk_columns: {
          id: args.tenantId,
        },
        _set: {
          defaultCurrency: args.currency,
        },
      })?.defaultCurrency;
    }
  );

  const updateCurrency = async (currency: currency_enum | null) =>
    mutate({
      args: {
        tenantId: teamId,
        currency,
      },
    }).then(setUpdatedCurrency);

  const {
    data,
    isLoading: loadingQuery,
    error,
  } = useQuery(
    (q, args) => {
      const currencies = args.availableCurrencies;

      const c = q
        .currency({
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem<gqlV2.currency_enum>>((item) => ({
          label:
            currencies[
              (item.name?.toUpperCase() || '') as keyof typeof currencies
            ]?.currencyName ||
            toTitleCase(item.name) ||
            '',
          value: item.name as currency_enum,
        }));

      return sortBy(c, 'label', 'asc');
    },
    {
      variables: {
        availableCurrencies: currencies,
      },
    }
  );

  const { options, pinned } = useMemo(() => {
    const opts = data || [];

    const pinned = ['usd', 'eur', 'gbp'];

    return {
      options: opts.filter((o) => !pinned.includes(o.value)),
      pinned: opts.filter((o) => pinned.includes(o.value)),
    };
  }, [data]);

  const value =
    [...options, ...pinned]?.find((o) => {
      if (updatedCurrency !== undefined) return o.value === updatedCurrency;
      return o.value === initialCurrency;
    }) || null;

  return (
    <Tooltip
      label="You cannot change the currency once you have journal entries"
      disabled={!hasJournalEntries}
      withinPortal
      position="top-end"
    >
      <InputWrapper
        label="Default Team Currency:"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing.xs,
        })}
      >
        <InputSelect
          type="single"
          value={value}
          setValue={(v) => updateCurrency(v?.value || null)}
          data={{
            options,
            loading: loadingQuery,
            error,
            sort: null,
          }}
          inputProps={{
            placeholder: 'Automatic currency',
            loadingQuery: loadingValue || loadingQuery,
            loadingMutation,
            withClearButton: true,
            width: 200,
            error: errorMutation,
            disabled: hasJournalEntries,
          }}
          dropdownProps={{
            width: 'target',
          }}
          pinnedItems={pinned}
        />
      </InputWrapper>
    </Tooltip>
  );
});
