import { currency_enum } from '@finalytic/graphql';
import { useNavigate } from 'react-router-dom';

export type AddReservationExpenseState = {
  reservationId: string;
  currency: currency_enum;
};

export function useAddExpenseNavigate() {
  const goto = useNavigate();

  return (reservation?: AddReservationExpenseState) => {
    goto('/expenses/add', {
      state: { reservation },
    });
  };
}
