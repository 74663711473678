import { InputSelect, InputWrapper } from '@finalytic/components';
import { useMutation, useQuery, useTeamId } from '@finalytic/data';
import { account } from '@finalytic/graphql';
import { SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { rem } from '@mantine/core';
import { orderByAccount, whereAccounts } from '@vrplatform/ui-common';
import { useState } from 'react';

type Props = {
  accountId: string | null;
  contactId: string;
};

export const OwnerDefaultBankAccountInput = ({
  accountId: initial,
  contactId,
}: Props) => {
  const [teamId] = useTeamId();
  const [updatedAccountId, setUpdatedAccountId] = useState<Maybe<string>>();

  const accountId = updatedAccountId !== undefined ? updatedAccountId : initial;

  const {
    mutate,
    loading: loadingMutation,
    error: errorMutation,
  } = useMutation(
    (
      q,
      args: {
        contactId: string;
        accountId: string | null;
      }
    ) => {
      return (
        q.updateContact({
          pk_columns: {
            id: args.contactId,
          },
          _set: {
            defaultAccountId: args.accountId,
          },
        })?.defaultAccountId || null
      );
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  const updateAccount = async (accountId: string | null) =>
    mutate({
      args: {
        contactId,
        accountId,
      },
    }).then(setUpdatedAccountId);

  const {
    data,
    isLoading: loadingQuery,
    error,
  } = useQuery(
    (q, args) => {
      const getAccount = (acc: account): SelectItem => ({
        value: acc.id,
        label: acc.title || 'No name',
      });

      const accounts = q
        .accounts({
          where: whereAccounts({
            tenantId: args.teamId,
            type: 'bank',
          }),
          order_by: orderByAccount,
        })
        .map(getAccount);

      const defaultAccount =
        q
          .accounts({
            where: {
              tenantId: { _eq: args.teamId },
              assignments: {
                type: { _eq: 'transfer_ownerPayout' },
                tenantId: { _eq: args.teamId },
              },
            },
            order_by: orderByAccount,
          })
          .map(getAccount)[0] ?? null;

      return {
        accounts,
        defaultAccount,
      };
    },
    {
      queryKey: ['accounts'],
      variables: {
        teamId,
      },
    }
  );

  const value = data?.accounts.find((o) => o.value === accountId) || null;
  const defaultAccount = data?.defaultAccount;

  return (
    <InputWrapper
      label="Select an alternate payout account"
      description="You can optionally override the default payout account for this owner."
      inputWrapperOrder={['label', 'input', 'description']}
      mb="xs"
      styles={{
        description: {
          marginTop: rem(4),
        },
      }}
    >
      <InputSelect
        type="single"
        value={value}
        setValue={(v) => updateAccount(v?.value || null)}
        data={{
          options: data?.accounts ?? [],
          loading: loadingQuery,
          error,
          sort: null,
        }}
        inputProps={{
          placeholder: `Default: ${defaultAccount?.label ?? '-'}`,
          loadingQuery,
          loadingMutation,
          withClearButton: true,
          error: errorMutation,
        }}
        dropdownProps={{
          width: 'target',
        }}
      />
    </InputWrapper>
  );
};
