import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { Maybe, isUUID } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
} from '@vrplatform/ui-common';
import {
  ACTIVITY_START_DATE,
  ActivityDeletedItemBadge,
  ActivityDeltaJson,
  ActivityDrawer,
  ActivityOperation,
  ActivityTable,
  getActivityActor,
  whereActorUserId,
} from '../../../../drawers';
import { getOwnerActivityDetails, getOwnerActivityTables } from './_queries';

type FilterState = {
  search: string;
  actorUserId: Maybe<string>;
  op: Maybe<ActivityOperation>;
};

export function useOwnerActivityDrawer() {
  const [ownerId, setOwnerId] = useQueryParam('owner_activity', StringParam);

  return {
    ownerId,
    open: (id: string) => setOwnerId(id, 'push'),
    close: () => setOwnerId(null, 'push'),
  };
}

export const OwnerActivityDrawer = () => {
  const { close, ownerId } = useOwnerActivityDrawer();
  const { GL } = useEnabledFeatures();
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  const OWNER_ACTIVITY_TABLES = getOwnerActivityTables(GL);

  const { data } = useQuery(
    (q, args) => {
      if (!args.ownerId) return null;

      const owner = GL
        ? q.contact({ id: args.ownerId })
        : q.owner({ id: args.ownerId });

      return {
        id: owner?.id,
        title: formatOwnerName(owner),
        isCompany: !!owner?.companyType,
      };
    },
    {
      skip: !ownerId,
      variables: {
        ownerId,
        GL,
      },
    }
  );
  const title = data?.title;

  const [filter, setFilter] = useSetState<FilterState>({
    actorUserId: null,
    search: '',
    op: null,
  });

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      if (!args.teamId || !isUUID(args.ownerId || ''))
        return {
          aggregate: 0,
          list: [],
        };

      const where: gqlV2.audit_log_bool_exp = {
        createdAt: { _gte: args.ACTIVITY_START_DATE },
        tenantId: { _eq: args.teamId },
        tableName: { _in: args.OWNER_ACTIVITY_TABLES },
        op: args.op ? { _eq: args.op } : undefined,
        actorUserId: whereActorUserId(args.actorUserId),
        _or: [
          {
            ownerId: { _eq: args.ownerId },
          },
          {
            objectId: { _eq: args.ownerId },
          },
          {
            contactId: { _eq: args.ownerId },
          },
        ],
      };

      const aggregate = q.auditLogAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .auditLogs({
          where,
          order_by: [{ createdAt: 'desc' }],
          offset,
          limit,
        })
        .flatMap((log) => {
          const op = log.op as ActivityOperation;
          const tableName = log.tableName as ActivityTable;
          const actor = getActivityActor(log);

          // tables: owner, listing_owner
          const initial = {
            actor,
            date: log.createdAt,
            icon: op,
            op,
            logId: log.id,
            id: log.id,
            objectId: (args.GL ? log.contactId : log.ownerId)!,
          };

          const ownerName = args.GL
            ? formatOwnerName(log.contact)
            : formatOwnerName(log.owner);

          if (op === 'delete' || op === 'insert') {
            if (tableName === 'owner' || tableName === 'contact') {
              const actions: Record<ActivityOperation, string> = {
                insert: 'Created',
                delete: 'Deleted',
                update: 'Updated',
              };

              return [
                {
                  ...initial,
                  label: (
                    <Text>
                      <Text component="span" color="gray">
                        {actions[op]}
                      </Text>{' '}
                      {ownerName ? (
                        <Text fw={500} component="span">
                          {ownerName}
                        </Text>
                      ) : (
                        <ActivityDeletedItemBadge
                          objectId={initial.objectId!}
                          type="owner"
                        />
                      )}
                    </Text>
                  ),
                },
              ];
            }
          }

          if (
            op === 'update' ||
            (tableName !== 'owner' && tableName !== 'contact')
          ) {
            const listingName = getListingName(log.listing);
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            return getOwnerActivityDetails(
              {
                date: log.createdAt,
                delta,
                id: log.id,
                op,
                tableName,
                objectId: log.objectId!,
              },
              {
                listing: {
                  id: log.listingId!,
                  name: listingName,
                },
                owner: {
                  id: log.ownerId!,
                  name: formatOwnerName(log.owner),
                  isCompany: args.isCompany,
                },
                user: {
                  id: log.userId!,
                  name: formatUserName(log.user),
                },
              }
            )
              .map((update) => ({
                ...initial,
                label: update.label,
              }))
              .filter((x) => x.label);
          }

          // default
          return [];
        });

      return {
        aggregate,
        list,
        count: list.length,
      };
    },
    {
      queryKey: 'activity',
      skip: !ownerId || !data,
      variables: {
        teamId,
        search: filter.search?.trim(),
        dashboard,
        actorUserId: filter.actorUserId,
        op: filter.op,
        ownerId,
        isCompany: !!data?.isCompany,
        ACTIVITY_START_DATE,
        OWNER_ACTIVITY_TABLES,
        GL,
      },
    }
  );

  return (
    <ActivityDrawer
      opened={!!ownerId}
      close={close}
      title={title || 'Owner'}
      data={queryData}
      setFilter={setFilter}
      tables={OWNER_ACTIVITY_TABLES}
    />
  );
};
