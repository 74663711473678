import { useEnabledFeatures, useInfiniteQuery, useTeam } from '@finalytic/data';
import { reservation as Reservation } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum } from '@finalytic/utils';
import {
  getListingName,
  queryReservationFinancials,
  queryReservationPaymentLines,
} from '@vrplatform/ui-common';
import { getLedgerReservationFinancials } from '../../drawers/reservation-drawers/_utils';
import { useWhereReservations } from './useWhereReservations';

type Params = {
  sorting: MRT_SortingState;
  filterByAutomationId: string | undefined;
  hideCancellations: boolean;
};

export type ReservationRow = NonNullable<
  ReturnType<typeof useReservationTableQuery>['data']
>['pages'][number]['list'][number];

export const getReservation = (
  reservation: Reservation,
  opts: {
    partnerId: string;
    tenantId: string;
    GL: boolean;
  }
) => {
  const { partnerId, tenantId, GL } = opts;

  const financials = queryReservationFinancials(reservation, {
    partnerId,
    tenantId,
    GL,
  });

  const { centTotals } = getLedgerReservationFinancials(reservation, {
    partnerId,
    tenantId,
  });

  const included = financials.filter((x) => x.isInvoice === 'invoice');

  const paymentLines = queryReservationPaymentLines(reservation, {
    includeAllType2s: true,
  }).filter((x) => x.isResevationPayment);

  const total = GL ? centTotals.centTotal : sum(included, 'centTotal');
  const paid = GL ? centTotals.paidCentTotal : sum(paymentLines, 'centTotal');
  const unpaid = GL ? centTotals.unpaidCentTotal : total - paid;

  const listing = GL
    ? {
        id: reservation?.listing?.id,
        name: getListingName(reservation?.listing),
      }
    : {
        id: reservation?.listingConnection?.listing?.id,
        name: getListingName(reservation?.listingConnection?.listing),
      };

  return {
    id: reservation.id,
    connection: {
      id: reservation.connection?.id,
      name: reservation.connection?.name,
      app: {
        id: reservation?.connection?.app?.id,
        iconRound: reservation?.connection?.app?.iconRound,
      },
    },
    bookedAt: reservation?.bookedAt,
    cancelledAt: reservation?.cancelledAt,
    sourceId: reservation?.sourceId,
    checkIn: reservation?.checkIn,
    checkOut: reservation?.checkOut,
    nights: reservation?.nights,
    confirmationCode: reservation?.confirmationCode,
    guestName: reservation?.guestName,
    status: reservation?.status,
    currency: reservation?.currency,
    listing,
    tenant: {
      id: reservation?.tenant?.id,
      name: reservation?.tenant?.name,
      logo: reservation?.tenant?.logo,
      colorPrimary: reservation?.tenant?.colorPrimary,
    },
    paid,
    total,
    unpaid,
  };
};

export const useReservationTableQuery = ({
  sorting,
  filterByAutomationId,
  hideCancellations,
}: Params) => {
  const where = useWhereReservations({
    filterByAutomationId,
    hideCancellations,
  });
  const [team] = useTeam();
  const { GL } = useEnabledFeatures();

  return useInfiniteQuery(
    (q, { where, tenantId, partnerId, GL }, { limit, offset }) => {
      const aggregate =
        q.reservationAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const list = q
        .reservations({
          where,
          limit,
          offset,
          order_by,
        })
        .map((reservation) =>
          getReservation(reservation, {
            partnerId,
            tenantId,
            GL,
          })
        );

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'reservations',
      variables: {
        where,
        tenantId: team?.id,
        partnerId: team?.partnerId,
        sorting,
        GL,
      },
    }
  );
};
