import { Button, Collapse } from '@finalytic/components';
import { useInvalidateQueries } from '@finalytic/data';
import {
  AlertTriangleIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  RefreshCwIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable, MRT_ColumnDef } from '@finalytic/table';
import { IconButton, LoadingIndicator } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Box, Center, Stack, Text, useMantineTheme } from '@mantine/core';
import { getActionMessage } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { AutomationHistoryStatusBadge } from './_components';
import { useSyncedFromToTableColumns } from './_hooks';
import { useAutomationHistoryDrawerQuery } from './useAutomationHistoryDrawerQuery';
import {
  WorkflowSubscription,
  useAutomationHistoryDrawerSubscription,
} from './useAutomationHistoryDrawerSubscription';

export const MultiWorkflowOverview = ({
  workflowIds,
  closeDrawer,
  setWorkflowId,
}: {
  workflowIds: string[];
  closeDrawer: () => void;
  setWorkflowId: (id: string) => void;
}) => {
  const { data: queryData, isLoading: isQueryLoading } =
    useAutomationHistoryDrawerQuery(workflowIds);

  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useAutomationHistoryDrawerSubscription(workflowIds, {
      onlyErrors: true,
    });

  const isLoading = isQueryLoading || isSubscriptionLoading;

  const invalidate = useInvalidateQueries(['actions', 'tasks', 'jobPlans']);

  const errors = useMemo(() => {
    const rowData =
      subscriptionData?.flatMap((jobPlan) => jobPlan.errors.rowData) || [];
    const aggregate =
      subscriptionData?.reduce(
        (acc, current) => acc + current.errors.aggregate,
        0
      ) || 0;

    return {
      rowData,
      aggregate,
    };
  }, [subscriptionData]);

  const rowData = useMemo(() => {
    return (
      queryData?.workflows.map((workflow) => {
        const subscription = subscriptionData?.find(
          (sub) => sub.id === workflow.id
        );

        return {
          ...workflow,
          ...subscription,
        };
      }) ?? []
    );
  }, [queryData, subscriptionData]);

  const columns = useMemo<MRT_ColumnDef<(typeof rowData)[number]>[]>(
    () => [
      {
        header: 'Name',
        Cell: ({ row }) => {
          const data = row.original;
          const errors = data.errors?.aggregate ?? 0;
          const syncFrom = data.aggregateSyncedFrom ?? 0;
          const syncTo = data.aggregateSyncedTo ?? 0;

          const total = errors + syncFrom + syncTo;
          return (
            <Box>
              <Text>{data.automation.name}</Text>
              <Text c="gray" size="xs">
                Synced {total} entities
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Status',
        size: 50,
        Cell: ({ row }) => {
          const data = row.original;
          return <AutomationHistoryStatusBadge status={data.status} />;
        },
      },
      {
        header: 'arrow',
        size: 20,
        mantineTableBodyCellProps: { align: 'right' },
        Cell: () => {
          return <ArrowRightIcon size={20} />;
        },
      },
    ],
    []
  );

  return (
    <Box
      px="sm"
      pb="sm"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DrawerHeader
        closeDrawer={closeDrawer}
        title={'Multiple Syncs'}
        loading={isLoading}
        type="Sync History"
      >
        <Button
          onClick={() => invalidate()}
          variant="light"
          leftIcon={RefreshCwIcon}
        >
          Refresh data
        </Button>
      </DrawerHeader>
      {isLoading ? (
        <LoadingIndicator size="md" isFullPageLoading />
      ) : !workflowIds.length || !queryData?.workflows?.length ? (
        <Center mih="7vh">
          <h1>History not found</h1>
        </Center>
      ) : (
        <>
          <DrawerInfoCard
            rows={[
              {
                title: 'Triggered by',
                text: queryData.workflows[0]?.triggeredBy,
                icon: UserIcon,
              },
            ]}
          />
          <DrawerCollapsableTable
            columns={columns}
            rightSection={null}
            title="Syncs"
            onRowClick={{
              handler: (row) => setWorkflowId(row.original.workflowId!),
            }}
            rowData={rowData}
            emptyRowsFallback={'No syncs found'}
          />

          <OverviewErrorTable
            errors={errors}
            status={rowData?.[0].status}
            overwrites={queryData.messageOverwrites}
          />
        </>
      )}
    </Box>
  );
};

const OverviewErrorTable = ({
  errors,
  goToErrors,
  status,
  overwrites,
}: {
  errors: WorkflowSubscription['errors'];
  status: WorkflowSubscription['status'];
  goToErrors?: () => void;
  overwrites: Parameters<typeof getActionMessage>[1];
}) => {
  const { primaryColor } = useMantineTheme();

  const columns = useSyncedFromToTableColumns(overwrites);

  const isShowMoreErrors = errors.aggregate > errors.rowData.length;

  return (
    <Box mt="lg">
      <Collapse
        title={
          <Text component="span" size="sm">
            Errors
            <Text color="gray" ml="xs" component="span">
              {errors.aggregate}
            </Text>
          </Text>
        }
        rightSection={
          !!errors.aggregate &&
          goToErrors && (
            <IconButton onClick={goToErrors}>
              <ArrowRightIcon size={18} />
            </IconButton>
          )
        }
        minHeight={30}
        defaultOpened
      >
        {!errors.rowData.length ? (
          <Placeholder status={status} />
        ) : (
          <>
            <LazyTable
              table={{
                columns,
                hideHeader: true,
                hideTopBar: true,
              }}
              data={{
                rowCount: errors.aggregate,
                rows: errors.rowData,
                error: null,
                loading: false,
              }}
            />
            {isShowMoreErrors &&
              (!goToErrors ? (
                <Text size="xs" color="gray" ta="center" pt="md">
                  {errors.aggregate - errors.rowData.length} additional errors
                  <br />
                  View individual syncs for details
                </Text>
              ) : (
                <Button
                  onClick={goToErrors}
                  color={primaryColor}
                  variant="light"
                  sx={(theme) => ({
                    marginInline: 'auto',
                    display: 'block',
                    marginTop: theme.spacing.sm,
                  })}
                  rightIcon={ArrowRightIcon}
                >
                  Show more
                </Button>
              ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};

const OPTIONS: Record<string, string> = {
  completed: 'No errors, all parts are running smoothly.',
  failed: 'Something went wrong, please try rerunning.',
  started: 'Syncing data...',
  queued: 'Waiting for queue to finish...',
};

const Placeholder = ({
  status,
}: {
  status: Maybe<string>;
}) => {
  const { colors } = useMantineTheme();

  const message = OPTIONS[status || 'started'];

  return (
    <Stack mih={200} align="center" justify="center">
      {status === 'started' || status === 'queued' ? (
        <LoadingIndicator size="sm" />
      ) : status === 'completed' ? (
        <CheckCircleIcon color={colors.green[5]} size={30} />
      ) : (
        <AlertTriangleIcon color={colors.red[5]} size={30} />
      )}
      <Text c="neutral" ta="center">
        {message}
      </Text>
    </Stack>
  );
};
