import { Button } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { useAppName } from '@finalytic/ui';
import {
  Box,
  Center,
  Group,
  Tabs,
  Text,
  Title,
  Transition,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router';
import { useMissingAccountAssignmentsQuery } from '../../queries';
import { CopyChartOfAccountModal } from './_components';
import { AccountsTable } from './ledger-accounts/AccountsTable';
import { LineTypeMappingTable } from './line-type-mappings/LineTypeMappingTable';
import { useAccountsConfig } from './useAccountsConfig';

type View = 'overview' | 'mapping' | 'expense';

function useTabs() {
  const { tabValue } = useParams<{ tabValue: View }>();

  return tabValue || 'overview';
}

export const AccountsView = ({ hasAccounts }: { hasAccounts: boolean }) => {
  const navigate = useNavigate();
  const tabValue = useTabs();
  const { isMasterList } = useAccountsConfig();

  if (!isMasterList && !hasAccounts) {
    return <CopyAccountsPlaceholder />;
  }

  return (
    <>
      <MissingAccountAssignments />
      <Tabs
        value={tabValue}
        onChange={(value) => {
          if (value)
            navigate(
              isMasterList
                ? `/accounts/${value}`
                : `/settings/integrations/accounts/${value}`
            );
        }}
        keepMounted={false}
        styles={(theme) => ({
          panel: {
            marginTop: theme.spacing.md,
            maxWidth: '100%',
            width: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          },
          root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="overview">Accounts</Tabs.Tab>
          {isMasterList ? (
            <>
              <Tabs.Tab value="payment-lines">Payment Lines</Tabs.Tab>
              <Tabs.Tab value="reservation-lines">Reservation Lines</Tabs.Tab>
            </>
          ) : (
            <Tabs.Tab value="mapping">Mappings</Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="overview">
          <AccountsTable />
        </Tabs.Panel>

        {isMasterList ? (
          <>
            <Tabs.Panel value="reservation-lines">
              <LineTypeMappingTable type="reservation_line" />
            </Tabs.Panel>
            <Tabs.Panel value="payment-lines">
              <LineTypeMappingTable type="payment_line" />
            </Tabs.Panel>
          </>
        ) : (
          <Tabs.Panel value="mapping">
            <LineTypeMappingTable type="reservation_line" />
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  );
};

const MissingAccountAssignments = () => {
  const { data } = useMissingAccountAssignmentsQuery();
  const { appName } = useAppName();
  const { colors } = useMantineTheme();
  return (
    <Transition
      mounted={!!data?.length}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Box
          my="md"
          style={styles}
          sx={(theme) => ({
            backgroundColor: theme.colors.red[0],
            border: `1px solid ${theme.colors.red[3]}`,
            padding: theme.spacing.md,
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: theme.spacing.md,
            boxShadow: theme.shadows.sm,
            [`@media (max-width: ${theme.breakpoints.xs})`]: {
              flexDirection: 'column',
              alignItems: 'stretch',
              textAlign: 'center',
            },
          })}
        >
          <Center>
            <Icon
              icon="AlertCircleIcon"
              size={32}
              color={(theme) => theme.colors.red[8]}
            />
          </Center>
          <Box sx={{ flex: 1 }}>
            <Text size="lg" fw={500} c={colors.red[9]}>
              Missing Assignments
            </Text>
            <Text size="sm" c="neutral" mb="xs">
              We've found some accounts that are missing assignments. Please
              assign the following to continue using {appName}:
            </Text>

            <Group gap={rem(5)}>
              {data?.map((x) => (
                <Box
                  key={x.id}
                  component="span"
                  mr={5}
                  sx={(theme) => ({
                    border: '1px solid #EEEFF1',
                    borderRadius: 5,
                    padding: '2px 7px',
                    backgroundColor: '#fff',
                    fontSize: 13,
                    lineHeight: '1rem',
                    boxShadow: theme.shadows.sm,
                  })}
                >
                  {x.title}
                </Box>
              ))}
            </Group>
          </Box>
        </Box>
      )}
    </Transition>
  );
};

const CopyAccountsPlaceholder = () => {
  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Center
        h="100%"
        sx={{
          flexDirection: 'column',
          flex: 0.9,
        }}
      >
        <Icon
          icon="ListUnorderedIcon"
          color={(theme) => theme.colors.orange[8]}
          size={24}
          mb="xl"
        />
        <Title order={3} mb="md">
          Chart of Accounts
        </Title>
        <Text ta="center" component="p" maw={400} size="sm" mb="xl">
          You haven't configured any accounts yet. Add your chart of accounts to
          get started.
        </Text>
        <Button variant="filled" color="orange" onClick={handlers.open}>
          Add chart of accounts
        </Button>
      </Center>
      <CopyChartOfAccountModal closeModal={handlers.close} opened={opened} />
    </>
  );
};
