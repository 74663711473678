import { party_enum } from '@finalytic/graphql';
import { useMemo } from 'react';
import { useTaxRatesQuery } from '../../../queries';

type Props = {
  centTotal: number;
  markup: number;
  markupTaxId: string | null;
  party: party_enum;
  rates: NonNullable<ReturnType<typeof useTaxRatesQuery>['data']>;
};

export const getExpenseLineCentTotal = ({
  centTotal,
  markup: m,
  markupTaxId,
  party,
  rates,
}: Props) => {
  const rate = markupTaxId
    ? rates.find((x) => x.value === markupTaxId)
    : undefined;

  const appliedAmount = centTotal;

  const markup = party === 'owners' ? m || 0 : 0;
  const markupTax = rate ? rate.percentage * markup : 0;

  return {
    centTotal: appliedAmount + markup + markupTax,
    rate,
  };
};

export function useExpenseLineCentTotal(props: Omit<Props, 'rates'>) {
  const { data: rates, error, isLoading: loading } = useTaxRatesQuery();

  const data = useMemo(
    () => getExpenseLineCentTotal({ ...props, rates: rates ?? [] }),
    [props, rates]
  );

  return {
    data: loading ? undefined : data,
    loading,
    error,
  };
}
