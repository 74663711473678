import { useAuth, useClerk } from '@clerk/clerk-react';
import { Button, ConfirmModal } from '@finalytic/components';
import { useIntercom, useMe, useMutation, useTeamId } from '@finalytic/data';
import { useState } from 'react';
import { EmptyViewWrapper } from '../../components';

const EmptyTeamView = () => {
  const [, setTeamId] = useTeamId();
  const { signOut } = useClerk();
  const { show } = useIntercom();
  const { actor } = useAuth();
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <EmptyViewWrapper
      title="No Team found"
      description="We couldn't find a team for you. Was your team deleted or did you not receive your invitation?"
      actions={
        <>
          <Button
            variant="primary"
            leftIcon={'RefreshCwIcon'}
            onClick={() => setTeamId(null)}
          >
            Refresh
          </Button>

          {!show && (
            <Button onClick={show} leftIcon={'CommentQuestionIcon'}>
              Contact support
            </Button>
          )}

          {!actor && (
            <>
              <Button
                onClick={() => setDeleteModal(true)}
                leftIcon={'TrashIcon'}
              >
                Delete account
              </Button>

              <DeleteAccountModal
                closeModal={() => setDeleteModal(false)}
                opened={deleteModal}
              />
            </>
          )}

          <Button
            variant="light"
            onClick={() => signOut()}
            leftIcon={'LogOut2Icon'}
          >
            Sign out
          </Button>
        </>
      }
    />
  );
};

const DeleteAccountModal = ({
  closeModal,
  opened,
}: {
  closeModal: () => void;
  opened: boolean;
}) => {
  const { id: meId } = useMe();
  const { signOut } = useClerk();

  const { mutate, loading } = useMutation(
    (q, args: { userId: string }) => {
      return q.deleteUserById({ id: args.userId })?.id;
    },
    {
      invalidateQueryKeys: ['team', 'users'],
    }
  );

  const submitDelete = async () => {
    return await mutate({ args: { userId: meId } }).then(() => {
      closeModal();
      signOut();
    });
  };

  return (
    <ConfirmModal
      type="delete"
      opened={opened}
      closeModal={closeModal}
      onSubmit={submitDelete}
      loading={loading}
      title={'Are you sure to delete your account?'}
      subtitle={
        'This action is irreversible. You will not be able to access your account or recover any data.'
      }
    />
  );
};

export default EmptyTeamView;
