import { useQuery, useTeamId } from '@finalytic/data';

export const useDefaultRevenueRecognitionInputQuery = () => {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return (
        q.tenantById({
          id: args.teamId,
        })?.defaultRevenueRecognition || 'checkIn'
      );
    },
    {
      variables: {
        teamId,
      },
    }
  );
};
