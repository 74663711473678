import {
  Button,
  IconButton,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  captureSentryError,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { ArrowRightIcon } from '@finalytic/icons';
import { SelectItem, showWarnNotification } from '@finalytic/ui';
import { Maybe, sortBy, toTitleCase } from '@finalytic/utils';
import {
  Alert,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { orderByAccount, whereAccounts } from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useLineTypeMappingQuery } from './LineTypeMappingEditors';
import { LineTypeMappingRow } from './useLineTypeMappingTableQuery';

type Props = {
  closeModal: () => void;
  lineType: Maybe<LineTypeMappingRow>;
};

export const LineTypeMappingExceptionModal = ({
  closeModal,
  lineType: initial,
}: Props) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const lineType = debounced || initial;

  return (
    <Modal
      opened={!!initial}
      onClose={closeModal}
      size={650}
      centered
      title="Add exceptions"
      styles={{
        title: {
          fontWeight: 500,
        },
      }}
    >
      {!lineType ? (
        <Center my="xl">
          <h1>Missing line type</h1>
        </Center>
      ) : (
        <Content lineType={lineType} closeModal={closeModal} />
      )}
    </Modal>
  );
};

type FormInputs = {
  exceptions: {
    bookingChannel: string | undefined;
    accountId: string | undefined;
  }[];
};

const Content = ({
  closeModal,
  lineType,
}: { lineType: LineTypeMappingRow; closeModal: () => void }) => {
  const [teamId] = useTeamId();
  const theme = useMantineTheme();

  const { data: queryData, isLoading: loadingMapping } =
    useLineTypeMappingQuery(lineType.name!, { includeExceptions: true });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const initalExceptions = useMemo<FormInputs | undefined>(() => {
    if (queryData?.mapping.bookingChannelExceptions === undefined)
      return undefined;

    if (queryData.mapping.bookingChannelExceptions.length === 0)
      return {
        exceptions: [
          {
            bookingChannel: undefined,
            accountId: undefined,
          },
        ],
      };

    return {
      exceptions: (queryData?.mapping.bookingChannelExceptions || []).map(
        (exception) => ({
          bookingChannel: exception.bookingChannel!,
          accountId: exception.value,
        })
      ),
    };
  }, [JSON.stringify(queryData?.mapping?.bookingChannelExceptions || [])]);

  const methods = useForm<FormInputs>({
    values: initalExceptions,
    defaultValues: {
      exceptions: [
        {
          bookingChannel: undefined,
          accountId: undefined,
        },
      ],
    },
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'exceptions',
  });

  const { mutate } = useMutation(
    (
      q,
      args: {
        oldExceptions: LineTypeMappingRow['bookingChannelExceptions'];
        newExceptions: FormInputs['exceptions'];
        lineType: string;
        tenantId: string;
      }
    ) => {
      const oldBookingChannels = args.oldExceptions.map(
        (x) => x.bookingChannel
      );
      const unsusedBookingChannels = oldBookingChannels.filter(
        (x) => !args.newExceptions.some((y) => y.bookingChannel === x)
      );
      const existingBookingChannels = args.newExceptions.filter((x) =>
        oldBookingChannels.includes(x.bookingChannel || '')
      );

      const newBookingChannels = args.newExceptions.filter(
        (x) => !oldBookingChannels.includes(x.bookingChannel || '')
      );

      if (unsusedBookingChannels.length)
        q.deleteAccountReservationLineTypes({
          where: {
            lineType: { _eq: args.lineType },
            tenantId: { _eq: args.tenantId },
            bookingChannel: {
              _in: unsusedBookingChannels,
            },
          },
        }).affected_rows || 0;

      if (existingBookingChannels.length)
        q.updateManyAccountReservationLineTypes({
          updates: existingBookingChannels.map((t) => ({
            where: {
              lineType: { _eq: args.lineType },
              tenantId: { _eq: args.tenantId },
              bookingChannel: { _eq: t.bookingChannel },
              status: { _eq: 'active' },
            },
            _set: {
              accountId: t.accountId === 'exclude' ? null : t.accountId,
            },
          })),
        }).map((x) => x.affected_rows);

      if (newBookingChannels.length)
        q.insertAccountReservationLineTypes({
          objects: newBookingChannels.map((t) => ({
            tenantId: args.tenantId,
            lineType: args.lineType,
            accountId: t.accountId === 'exclude' ? null : t.accountId,
            bookingChannel: t.bookingChannel,
            status: 'active',
          })),
        }).affected_rows;
    },
    {
      invalidateQueryKeys: ['lineTypeMappings'],
    }
  );

  const submit = async (data: FormInputs) => {
    if (queryData?.isRecommendation)
      return showWarnNotification({
        message:
          'Please accept or reject the active mapping recommendation before saving further exceptions.',
      });

    if (!queryData?.mapping?.id || !queryData?.mapping?.value)
      return showWarnNotification({
        message:
          'Please add a default mapping before saving further exceptions.',
      });

    await mutate({
      args: {
        lineType: lineType.name!,
        tenantId: teamId,
        newExceptions: data.exceptions,
        oldExceptions:
          queryData.mapping.bookingChannelExceptions?.map((x) => ({
            accountId: x.value,
            bookingChannel: x.bookingChannel!,
            id: x.id,
            lineType: lineType.name!,
          })) || [],
      },
    })
      .then(closeModal)
      .catch((err) => {
        console.error(err);
        captureSentryError(err);
      });
  };

  useEffect(() => {
    if (initalExceptions) methods.reset(initalExceptions);
  }, [initalExceptions, methods.reset]);

  if (
    !loadingMapping &&
    queryData?.isRecommendation === false &&
    !queryData.mapping?.value
  )
    return (
      <>
        <Alert title="Missing default mapping" color="yellow" mb="md">
          Please add a default mapping before adding exceptions.
        </Alert>
        <Group justify="flex-end">
          <Button onClick={closeModal}>Close</Button>
        </Group>
      </>
    );

  return (
    <Box
      component="form"
      onSubmit={methods.handleSubmit(submit)}
      pos="relative"
    >
      <LoadingOverlay visible={loadingMapping} />
      <Stack mb="lg">
        {fields.map((field, index) => {
          return (
            <Box
              key={field.id}
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'nowrap',
                gap: theme.spacing.md,
                alignItems: 'flex-start',
              })}
            >
              <Center pt={34}>
                <Text span>{index + 1}.</Text>
              </Center>
              <Box sx={{ flex: 1 }}>
                <Controller
                  control={methods.control}
                  name={`exceptions.${index}.bookingChannel`}
                  rules={{
                    required: 'Booking channel is required',
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputWrapper
                      label="Booking Channel"
                      required
                      error={error?.message}
                    >
                      <BookingChannelSelect
                        value={field.value}
                        setValue={field.onChange}
                        error={!!error}
                        control={methods.control}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
              <ArrowRightIcon
                size={24}
                sx={{
                  marginTop: 30,
                }}
              />
              <Box sx={{ flex: 1 }}>
                <Controller
                  control={methods.control}
                  name={`exceptions.${index}.accountId`}
                  rules={{
                    required: 'An account is required',
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <InputWrapper
                        label="Account"
                        required
                        error={error?.message}
                        sx={{
                          flex: 1,
                        }}
                      >
                        <AccountSelect
                          value={field.value}
                          setValue={field.onChange}
                          error={!!error}
                        />
                      </InputWrapper>
                    );
                  }}
                />
              </Box>
              <Center mt={28}>
                <IconButton icon="TrashIcon" onClick={() => remove(index)} />
              </Center>
            </Box>
          );
        })}

        <Button
          sx={{
            alignSelf: 'flex-end',
          }}
          variant="light"
          color={theme.primaryColor}
          leftIcon={'PlusIcon'}
          onClick={() =>
            append({
              bookingChannel: undefined,
              accountId: undefined,
            })
          }
        >
          Add exception
        </Button>
      </Stack>

      <Group justify="right">
        <Button
          disabled={methods.formState.isSubmitting}
          onClick={methods.handleSubmit(async () => submit({ exceptions: [] }))}
        >
          Reset exceptions
        </Button>

        <Button
          type="reset"
          onClick={closeModal}
          disabled={methods.formState.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          loading={methods.formState.isSubmitting}
          sx={{
            width: 150,
          }}
        >
          Submit
        </Button>
      </Group>
    </Box>
  );
};

const BookingChannelSelect = ({
  value,
  setValue,
  error: formError,
  control,
}: {
  value: Maybe<string>;
  setValue: (value: string) => void;
  error: boolean;
  control: Control<FormInputs>;
}) => {
  const [teamId] = useTeamId();

  const exceptions = useWatch({
    control,
    name: 'exceptions',
  });

  const activeChannels =
    exceptions
      ?.filter((x) => x.bookingChannel !== value)
      .map((x) => x.bookingChannel) || [];

  const {
    data,
    isLoading: loadingQuery,
    error,
  } = useQuery(
    (q, args) => {
      if (!args.teamId)
        return {
          bookingChannels: [],
        };

      const bookingChannels = q
        .bookingChannels({
          order_by: [{ uniqueRef: 'asc_nulls_last' }],
          where: {
            uniqueRef: { _is_null: false },
            reservations: {
              tenantId: { _eq: args.teamId },
            },
          },
        })
        .map<SelectItem>((channel) => ({
          label: toTitleCase(channel.uniqueRef?.trim()),
          value: channel.uniqueRef!,
        }));

      return {
        bookingChannels: sortBy(bookingChannels, 'label'),
      };
    },
    {
      queryKey: 'paymentLineClassifications',
      variables: {
        teamId,
      },
    }
  );

  const bookingChannels = data?.bookingChannels.filter(
    (x) => !activeChannels.includes(x.value)
  );

  const selectValue = bookingChannels?.find((o) => o.value === value) || null;

  return (
    <InputSelect
      type="single"
      value={selectValue}
      data={{
        options: bookingChannels || [],
        loading: loadingQuery,
        error,
      }}
      setValue={(value) => {
        if (!value?.value) return;
        setValue(value.value);
      }}
      inputProps={{
        error: !!formError,
        loadingQuery,
        placeholder: 'Select booking channel',
      }}
      dropdownProps={{
        withinPortal: true,
      }}
    />
  );
};

const AccountSelect = ({
  value: accountId,
  setValue,
  error: formError,
}: {
  value: Maybe<string>;
  setValue: (value: string | undefined) => void;
  error: boolean;
}) => {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where = whereAccounts({
        search: args.search,
        tenantId: args.teamId,
      });

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      const list = q
        .accounts({
          where,
          order_by: orderByAccount,
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.classification || 'No classification'),
        }));

      return {
        aggregate,
        list,
      };
    },
    {
      queryKey: ['accounts'],
      variables: {
        search: search?.trim(),
        teamId,
      },
    }
  );

  const valueQuery = useQuery(
    (q, args) => {
      if (!args.accountId) return null;

      if (args.accountId === 'exclude')
        return {
          label: 'Excluded',
          value: 'exclude',
        };

      return (
        q
          .accounts({
            where: {
              id: { _eq: args.accountId },
              tenantId: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map<SelectItem>((x) => ({
            label: x.title!,
            value: x.id,
          }))[0] || null
      );
    },
    {
      queryKey: ['accounts'],
      variables: {
        accountId,
        teamId,
      },
    }
  );

  return (
    <InputSelect
      type="single"
      value={valueQuery.data || null}
      infiniteData={{ ...queryData, setSearch }}
      setValue={(value) => {
        setValue(value?.value);
      }}
      inputProps={{
        error: !!formError,
        loadingQuery: valueQuery.isLoading,
        placeholder: 'Select account',
      }}
      dropdownProps={{
        withinPortal: true,
      }}
      pinnedItems={[
        {
          label: 'Exclude mapping',
          value: 'exclude',
          description: 'Exclude this line type from the system.',
        },
      ]}
    />
  );
};
