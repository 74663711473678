import { EMAIL_ACTION_LINK_COMPONENT } from '../_extensions';
import {
  type ActionEmailData,
  ActionEmailTemplate,
  type ActionEmailTemplateProps,
} from '../_templates';

export const getDefaultInvitePropertyManagerEmailData = (
  mode: 'tag' | 'text',
  args: {
    appName: string;
    signInLink: string;
  }
): ActionEmailData => {
  const signInLink =
    mode === 'tag'
      ? EMAIL_ACTION_LINK_COMPONENT
      : `<a href='${args.signInLink}' target='_blank'>${args.signInLink}</a>`;

  return {
    bodyOne: `<p>You are invited to join us on ${args.appName}.</p><p>To start using ${args.appName}, accept the invitation and verify your email address.</p>`,
    bodyTwo: `<p>If you have any trouble with the button, you can use the following link below: ${signInLink}</p>`,
    buttonText: 'Accept Invitation',
    header: 'Welcome to the team!',
    shortDescription:
      'You have been invited to join the team. To start using VRPlatform, verify your email address at link below.',
    href: args.signInLink,
    subject: `You are invited to join your team on ${args.appName}`,
  };
};

export const InvitePmEmail = (props: ActionEmailTemplateProps) => {
  return (
    <ActionEmailTemplate
      {...props}
      tenant={{
        logo: undefined,
        name: props.tenant.name,
      }}
    />
  );
};

const Preview = () => (
  <ActionEmailTemplate
    mode="send"
    tenant={{
      name: 'Pineapple Mountain',
      logo: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/fbd8c31f-478f-4eb0-74ac-c8eeb47b5000/256x256',
    }}
    data={{
      bodyOne:
        'Hello, <br /> <br /> You have been invited to join Pineapple Mountain. <br /> <br />',
      bodyTwo:
        'Please click the button below to accept the invitation. <br /> <br />',
      buttonText: 'Accept Invitation',
      header: 'Welcome to Pineapple Mountain!',
      shortDescription: 'You have been invited to join Pineapple Mountain.',
      href: 'https://example.com',
      subject: 'You are invited to join Pineapple Mountain',
    }}
    user={{
      firstName: 'John',
    }}
    listing={undefined}
    month={undefined}
    themeColors={[
      // baseColor: vrp primary palette
      '#E6F1FF',
      '#B8D9FE',
      '#8BC0FE',
      '#5DA8FD',
      '#308FFD',
      '#0377FC',
      '#025FCA',
      '#024797',
      '#012F65',
      '#011832',
    ]}
  />
);

export default Preview;
