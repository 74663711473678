export const parseEmailHtml = (
  html: string,
  args: {
    href: string;
    userName: string;
    listingName: string;
    date: string;
  }
) => {
  // parse out any html scripts of type : <team-name-component></team-name-component> or <user-name-component></user-name-component>
  // and replace them with the appropriate component
  // return the parsed html

  return html
    .replaceAll(
      /<action\-link\-component><\/action\-link\-component>/g,
      `<a href="${args.href}" target="_blank">${args.href}</a>`
    )
    .replaceAll(
      /<user\-name\-component><\/user\-name\-component>/g,
      args.userName
    )
    .replaceAll(
      /<listing\-name\-component><\/listing\-name\-component>/g,
      args.listingName
    )
    .replaceAll(/<date\-component><\/date\-component>/g, args.date);
};
