import {
  gqlV2,
  useInvalidateQueries,
  useSubscriptionOrQuery,
  useTeamId,
} from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { hasValue, sortBy } from '@finalytic/utils';
import { getSyncStatus } from '@vrplatform/ui-common';
import { useEffect } from 'react';
import { getWorkflowActionRow, getWorkflowChangeRow } from './_utils';

const getSyncSubscription = (
  sync: gqlV2.sync,
  { onlyErrors }: { onlyErrors: boolean }
): WorkflowSubscription => {
  const errors = sync
    .changes({
      where: {
        status: { _eq: 'failed' },
      },
    })
    .map<WorkflowSubscription['errors']['rowData'][number]>((x) =>
      getWorkflowChangeRow(x, 'synced-from')
    );

  return {
    id: sync.id,
    status: getSyncStatus(sync),
    title: sync.message || '',
    errors: {
      aggregate: errors.length,
      rowData: errors,
    },
    aggregateSyncedTo: onlyErrors
      ? 0
      : sync
          .changes_aggregate({
            where: {
              syncType: { _eq: 'push' },
            },
          })
          .aggregate?.count() || 0,
    aggregateSyncedFrom: onlyErrors
      ? 0
      : sync
          .changes_aggregate({
            where: {
              syncType: { _eq: 'pull' },
            },
          })
          .aggregate?.count() || 0,
  };
};

const getJobPlanSubscriptionData = (
  jobPlan: gqlV2.jobPlan,
  {
    tenantId,
    onlyErrors,
  }: {
    tenantId: string;
    onlyErrors: boolean;
  }
) => {
  const whereErrors: gqlV2.action_bool_exp = {
    status: {
      _eq: 'failed',
    },
  };

  const actionErrors = jobPlan
    ?.actions({
      where: whereErrors,
      limit: 5,
      order_by: [
        {
          createdAt: 'asc_nulls_last',
        },
      ],
    })
    .map(getWorkflowActionRow);

  const jobErrors = jobPlan
    .jobs({
      where: {
        status: {
          _eq: 'failed',
        },
      },
    })
    .map(getLegacyJob);

  const errorCount =
    jobPlan?.actions_aggregate({ where: whereErrors }).aggregate?.count() || 0;

  const planError = getLegacyJob(jobPlan);

  const errors = {
    rowData: [...actionErrors, ...jobErrors, planError].filter(
      (x) => x.status === 'failed'
    ),
    aggregate: errorCount + jobErrors.length,
  };

  return {
    id: jobPlan.id,
    status: jobPlan.status,
    title: jobPlan.title,
    errors,
    aggregateSyncedTo: onlyErrors
      ? 0
      : (jobPlan
          .actions_aggregate({
            where: { status: { _neq: 'failed' }, tenantId: { _eq: tenantId } },
          })
          .aggregate?.count() ?? 0),
    aggregateSyncedFrom: onlyErrors
      ? 0
      : (jobPlan.ops_aggregate().aggregate?.count() ?? 0),
  };
};

export type WorkflowSubscription = ReturnType<
  typeof getJobPlanSubscriptionData
>;

export function useAutomationHistoryDrawerSubscription(
  workflowIds: string[],
  {
    onlyErrors,
  }: {
    onlyErrors: boolean;
  }
) {
  const { refreshKeys } = useRunDrawer();
  const invalidate = useInvalidateQueries();
  const { syncIds } = useRunDrawer();
  const [tenantId] = useTeamId();

  const queryData = useSubscriptionOrQuery(
    (q, args) => {
      if (!args.workflowIds.length) return null;

      const oldWorkflowIds = args.workflowIds.filter(
        (x) => !args.syncIds.includes(x)
      );
      const newSyncIds = args.workflowIds.filter((x) =>
        args.syncIds.includes(x)
      );

      const jobPlans = !oldWorkflowIds.length
        ? []
        : q
            .jobPlans({
              where: {
                workflowId: {
                  _in: oldWorkflowIds,
                },
              },
              order_by: [
                {
                  createdAt: 'desc',
                },
              ],
            })
            .map((jobPlan) =>
              getJobPlanSubscriptionData(jobPlan, {
                tenantId: args.tenantId,
                onlyErrors: args.onlyErrors,
              })
            );

      const syncs = !newSyncIds.length
        ? []
        : q
            .syncs({
              where: {
                id: { _in: newSyncIds },
              },
              order_by: [{ createdAt: 'desc' }],
            })
            .map((sync) =>
              getSyncSubscription(sync, { onlyErrors: args.onlyErrors })
            );

      return sortBy([...syncs, ...jobPlans], 'id', 'desc').filter((x) => x.id);
    },
    {
      queryKey: ['tasks', 'automations', 'jobPlans'],
      skip: !workflowIds.length,
      subscribe: true,
      variables: {
        workflowIds,
        syncIds: syncIds || [],
        tenantId,
        onlyErrors,
      },
    }
  );

  const statuses = queryData.data?.map((x) => x.status).filter(hasValue);

  const isCompleted =
    !!statuses?.length && statuses.every((x) => x === 'completed');

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const keys = refreshKeys?.filter(hasValue) || [];

    if (!!keys.length && isCompleted) {
      invalidate(keys as any[]);
    }
  }, [isCompleted, refreshKeys]);

  return {
    data: queryData.data,
    isLoading: queryData.isLoading,
    error: queryData.error,
  };
}

export const getLegacyJob = (x: gqlV2.job | gqlV2.jobPlan) =>
  getWorkflowActionRow({
    status: x.status,
    title: x.title,
    id: x.id,
    automationId: x.automationId,
    schema: {
      uniqueRef: 'automation',
    },
    sourceLinks() {
      return [];
    },
    inputJson() {
      return undefined;
    },
    outputJson() {
      return undefined;
    },
  } as any);
