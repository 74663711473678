import {
  EMAIL_ACTION_LINK_COMPONENT,
  EMAIL_DATE_COMPONENT,
  EMAIL_LISTING_COMPONENT,
  EMAIL_USER_NAME_COMPONENT,
} from '../_extensions';
import {
  ActionEmailData,
  ActionEmailTemplate,
  type ActionEmailTemplateProps,
} from '../_templates';

export const getDefaultOwnerStatementEmailData = (
  mode: 'tag' | 'text',
  args: {
    appName: string;
    ownerStatementHref: string;
    listingName: string;
    userName: string;
    month: string;
  }
): ActionEmailData => {
  const ownerStatementHref =
    mode === 'tag' ? EMAIL_ACTION_LINK_COMPONENT : args.ownerStatementHref;

  const listingName =
    mode === 'tag' ? EMAIL_LISTING_COMPONENT : args.listingName;
  const month = mode === 'tag' ? EMAIL_DATE_COMPONENT : args.month;
  const userName = mode === 'tag' ? EMAIL_USER_NAME_COMPONENT : args.userName;

  return {
    subject: 'Owner statement was published',
    shortDescription: `An owner statement is ready for you. Log in to ${args.appName} now to view it.`,
    header: 'Owner statement was published',
    bodyOne: `<p>Hi ${userName},<p>Your ${month} owner statement for property ${listingName} is ready for your review on ${args.appName}.</p><p>To access your statement, please click the button below:</p>`,
    buttonText: 'View Owner statement',
    bodyTwo: `<p>If you have any trouble with the button, you can copy and paste the link below into your browser: ${ownerStatementHref}</p>`,
    href: args.ownerStatementHref,
  };
};

export const OwnerStatementEmail = (props: ActionEmailTemplateProps) => {
  return <ActionEmailTemplate {...props} />;
};

const Preview = () => {
  const defaultListing = '1502 Amelia Way';
  const defaultMonth = `May ${new Date().getFullYear()}`;
  const defaultName = 'John';

  return (
    <ActionEmailTemplate
      mode="send"
      tenant={{
        name: 'Pineapple Mountain',
        logo: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/fbd8c31f-478f-4eb0-74ac-c8eeb47b5000/256x256',
      }}
      data={{
        banner:
          'This month there was a $1000 charge for the property. That charge was split between the two owners.',
        ...getDefaultOwnerStatementEmailData('text', {
          appName: 'VRPlatform',
          ownerStatementHref: 'https://example.com',
          listingName: defaultListing,
          month: defaultMonth,
          userName: defaultName,
        }),
      }}
      user={{
        firstName: 'John',
      }}
      listing={defaultListing}
      month={defaultMonth}
      themeColors={[
        // portal.brown
        '#9E9390',
        '#8D7C78',
        '#7C6964',
        '#6E5954',
        '#614B46',
        '#573F39',
        '#4E342E',
        '#3F2D29',
        '#332725',
        '#2A2220',
      ]}
    />
  );
};

export default Preview;
