import { Icon } from '@finalytic/icons';
import { Maybe } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Group,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { memo } from 'react';

type Props = {
  name: Maybe<string>;
  address: Maybe<string>;
  sourceStatus?: Maybe<string>;
  connections: Maybe<
    {
      id: Maybe<string>;
      iconRound: Maybe<string>;
      name: Maybe<string>;
    }[]
  >;
  variant?: 'default' | 'compact';
};

export const ListingNameCell = memo(function Cell({
  address,
  connections,
  name,
  variant = 'default',
  sourceStatus,
}: Props) {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const isDeletedInPms = sourceStatus === 'deleted';

  return (
    <Group wrap="nowrap">
      {((variant === 'compact' && !!connections?.length) ||
        variant === 'default') && (
        <Box miw={variant === 'compact' ? 25 : 50}>
          <ConnectionIcons connections={connections} />
        </Box>
      )}
      <Box>
        <Group wrap="nowrap" gap={rem(8)}>
          {isDeletedInPms && <SourceDeletedIcon />}
          <Text lineClamp={1} size="sm" sx={{ display: 'block' }}>
            {name}
          </Text>
        </Group>
        {address && (
          <Text
            c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
            size="xs"
            lineClamp={1}
            sx={{ display: 'block' }}
          >
            {address}
          </Text>
        )}
      </Box>
    </Group>
  );
});

const SourceDeletedIcon = () => (
  <Tooltip label="This listing was deleted in your PMS">
    <Icon
      icon="AlertTriangleIcon"
      color={(theme) => theme.colors.orange[7]}
      size={16}
    />
  </Tooltip>
);

export const ConnectionIcons = ({
  connections: value,
}: { connections: Props['connections'] }) => {
  const max = 2;
  const connections = (value || []).filter((x) => x.id);
  const isTooMuch = connections.length > max;
  const slicedConnections = connections.slice(0, max);
  const restConnections = connections.slice(max);
  return (
    <Avatar.Group sx={{ background: 'transparent' }}>
      {slicedConnections.map((i) => (
        <Tooltip
          label={i?.name}
          key={i?.id}
          withinPortal
          withArrow
          position="top"
        >
          <Avatar
            src={i?.iconRound}
            size="sm"
            sx={(theme) => ({
              border: `1px solid ${theme.colors.gray[2]}`,
            })}
          />
        </Tooltip>
      ))}
      {isTooMuch && (
        <Tooltip
          withArrow
          withinPortal
          radius="sm"
          label={restConnections.map((i) => (
            <Group gap="xs" key={i.id} wrap="nowrap" px="xs">
              <Avatar
                src={i?.iconRound}
                size={'xs'}
                sx={(theme) => ({
                  border: `1px solid ${theme.colors.gray[2]}`,
                })}
              />
              <Text>{i.name}</Text>
            </Group>
          ))}
        >
          <Avatar radius="xl" size="sm">
            +{connections.length - max}
          </Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  );
};
