import { useMutation, useQuery, useTeamId } from '@finalytic/data';
import { Maybe, ensure } from '@finalytic/utils';
import { Anchor, Collapse, Divider, Switch, Text } from '@mantine/core';
import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  // SettingsFormSubmitButtons,
  // SettingsFormTextInput,
  SettingsTitle,
  SettingsViewContainer,
} from './_components';

type FormValues = {
  name: Maybe<string>;
  email: Maybe<string>;
  taxId: Maybe<string>;
  phone: Maybe<string>;
  useBillingAddress: boolean;
};

export const SettingsFeaturesOwnerStatements = () => {
  return (
    <SettingsViewContainer>
      <SettingsTitle type="view-title">Owner statements</SettingsTitle>
      <Text
        component="p"
        size={'0.875rem'}
        mb="xl"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        By default we use your billing address to display on owner statements.
      </Text>
      <OwnerStatementsForm />

      <Divider my="xl" />
      <SettingsTitle type="heading">Owner Statement Templates</SettingsTitle>
      <Text component="p" color="gray" size="sm">
        You can manage your templates directly in owner statements section.
      </Text>
      <Anchor size="sm" component={Link} to="/statements/settings">
        Go to template settings
      </Anchor>
    </SettingsViewContainer>
  );
};

const OwnerStatementsForm = () => {
  const [teamId] = useTeamId();

  const methods = useForm<FormValues>();

  const { data: team, isLoading: loading } = useQuery(
    (q, args) => {
      const team = q.tenantById({ id: args.teamId });

      return ensure<FormValues>({
        name: team?.companyName || team?.name,
        taxId: team?.companyTaxCode,
        email: team?.supportEmail || team?.email,
        phone: team?.supportPhone,
        useBillingAddress: false,
      });
    },
    {
      variables: {
        teamId,
      },
    }
  );

  const { mutate } = useMutation(
    (
      q,
      args: { teamId: string; data: FormValues; addressId: string | undefined }
    ) => {
      const res = q.updateTenantById({
        pk_columns: { id: args.teamId },
        _set: {
          companyName: args.data.name,
          companyTaxCode: args.data.taxId,
          supportPhone: args.data.phone,
          supportEmail: args.data.email,
        },
      });

      return {
        companyName: res?.companyName,
        companyTaxCode: res?.companyTaxCode,
        supportPhone: res?.supportPhone,
        supportEmail: res?.supportEmail,
      };
    },
    {
      successMessage: {
        title: 'Owner statements settings saved',
        message: 'Your owner statement settings have been saved.',
      },
    }
  );

  const _handleFormSubmit = async (data: FormValues) => {
    const res = await mutate({ args: { teamId, addressId: undefined, data } });
    methods.reset(
      {
        email: res.supportEmail,
        name: res.companyName,
        phone: res.supportPhone,
        taxId: res.companyTaxCode,
      },
      { keepDirty: false }
    );
  };

  const handleReset = () => {
    methods.reset(team || undefined, { keepDirty: false });
  };

  useEffect(handleReset, [team]);

  return (
    <FormProvider {...methods}>
      <Controller
        control={methods.control}
        name="useBillingAddress"
        render={({ field }) => (
          <Switch
            mt="xl"
            label="Would you like to use a custom address for statements?"
            labelPosition="left"
            checked={field.value}
            onChange={field.onChange}
            styles={{
              body: { width: '100%' },
              labelWrapper: { flex: 1 },
            }}
          />
        )}
      />
      <FormInputs handleReset={handleReset} loading={loading} />
      {/* <SettingsFormSubmitButtons
        resetHandler={handleReset}
        submitHandler={handleFormSubmit}
        mt={20}
      /> */}
    </FormProvider>
  );
};

const FormInputs = ({
  loading: _,
  // handleReset,
}: {
  loading: boolean;
  handleReset: () => void;
}) => {
  const methods = useFormContext<FormValues>();

  const collapsed = methods.watch('useBillingAddress');

  return (
    <Collapse in={collapsed} transitionDuration={1500}>
      <Divider my="lg" />

      {/* <FormAddressInput /> */}

      <Divider mb="sm" mt="lg" mx="xl" />
      {/* <SettingsFormTextInput
        title="Company Email"
        fieldType="email"
        formKey="email"
        disabled={loading}
        loading={loading}
        required={false}
      />
      <SettingsFormTextInput
        title="Company Phone"
        fieldType="phone"
        formKey="phone"
        disabled={loading}
        loading={loading}
        required={false}
      />
      <SettingsFormTextInput
        title="Company Tax ID"
        fieldType="text"
        formKey="taxId"
        disabled={loading}
        loading={loading}
        required={false}
      /> */}
    </Collapse>
  );
};

// const FormAddressInput = () => {
//   const { control } = useFormContext<FormValues>();

//   return (
//     <Box mt="1em">
//       <Controller
//         control={control}
//         name="name"
//         render={({}) => {
//           return (
//             <StripeAddressWithProvider
//               address={{}}
//               onAddressChange={(event) => {
//                 console.log(event);
//               }}
//             />
//           );
//         }}
//       />
//     </Box>
//   );
// };
