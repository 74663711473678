import { Badge, Button, Filter, InputSelect } from '@finalytic/components';
import {
  gqlV2,
  useBrowserTracking,
  useInfiniteQuery,
  useInvalidateQueries,
  useMe,
  useMutation,
  useTeamId,
  useTeamRole,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { CopyIcon, EyeIcon, RefreshCwIcon } from '@finalytic/icons';
import { InfiniteTable } from '@finalytic/table';
import {
  DeleteModal,
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import { Box, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatUserName, getTenantUserStatus } from '@vrplatform/ui-common';
import { useState } from 'react';
import { OwnerUserStatusBadge } from '../../components';
import { InviteMemberModal, SettingsTitle } from './_components';

export const SettingsTeamMembersView = () => {
  const { isTeamAdmin } = useTeamRole();

  const [opened, handlers] = useDisclosure(false);

  const invalidate = useInvalidateQueries();

  return (
    <>
      <Group justify="space-between" mb="lg">
        <SettingsTitle type="view-title">Members</SettingsTitle>

        <Group>
          <Button
            variant="light"
            leftIcon="RefreshCwIcon"
            onClick={() => invalidate(['users'])}
          >
            Refresh data
          </Button>

          {isTeamAdmin && (
            <Button
              variant="primary"
              leftIcon={'PlusIcon'}
              onClick={handlers.open}
              data-testid="invite-member-button"
            >
              Invite Member
            </Button>
          )}
        </Group>
      </Group>

      <Table />

      <InviteMemberModal
        opened={opened}
        onClose={() => [handlers.close(), invalidate(['users'])]}
      />
    </>
  );
};

const Table = () => {
  const [teamId] = useTeamId();
  const { isTeamAdmin } = useTeamRole();
  const [tenantUserId, setTenantUserId] = useState<string | null>(null);
  const { id: meId } = useMe();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, args) => {
      const where: gqlV2.tenant_user_bool_exp = {
        role: { _in: ['admin', 'user'] },
        tenantId: { _eq: args.teamId },
        user: {
          _or: args.search
            ? [
                { firstName: { _ilike: `%${args.search}%` } },
                { lastName: { _ilike: `%${args.search}%` } },
                { email: { _ilike: `%${args.search}%` } },
              ]
            : undefined,
        },
      };

      const aggregate =
        q
          .tenantUserAggregate({
            where,
          })
          .aggregate?.count() || 0;

      const list = q
        .tenantUser({
          where,
          order_by: [
            {
              user: {
                email: 'asc_nulls_last',
              },
            },
          ],
        })
        .map((tenantUser) => {
          const inviteStatus = getTenantUserStatus(tenantUser);

          return {
            id: tenantUser.id,
            userId: tenantUser.userId,
            name: formatUserName(tenantUser.user, { showEmpty: true }) || '',
            email: tenantUser?.user?.email,
            secondaryEmails: tenantUser?.user?.secondaryEmails(),
            status: inviteStatus.status,
            lastInvitedAt: inviteStatus.lastInvitedAt,
            role: tenantUser?.role,
            partnerId: tenantUser.tenant.partnerId,
          };
        });

      return {
        aggregate,
        list,
      };
    },
    {
      variables: {
        teamId,
        search: search?.trim(),
      },
      queryKey: 'users',
    }
  );

  return (
    <>
      <InfiniteTable
        queryData={queryData}
        table={{}}
        columns={[
          {
            header: 'Email',
            accessorKey: 'email',
            Cell: ({ row }) => {
              const data = row.original;

              return (
                <Box
                  w="100%"
                  sx={{
                    '& , *': {
                      wordBreak: 'break-word',
                    },
                  }}
                >
                  <Text className="user_email" component="span">
                    {data.email}
                  </Text>
                  {!!data.secondaryEmails?.length && (
                    <Text color="gray" display="block">
                      {data.secondaryEmails.join(', ')}
                    </Text>
                  )}
                </Box>
              );
            },
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },

          {
            header: 'Status',
            accessorKey: 'status',
            Cell: ({ cell }) => (
              <OwnerUserStatusBadge status={cell.getValue<string>()} />
            ),
          },

          {
            header: 'Role',
            accessorKey: 'role',
            Cell: ({ row }) => {
              const role = row.original.role;

              if (isTeamAdmin && row.original.userId !== meId)
                return (
                  <RoleSelect role={role} tenantUserId={row.original.id} />
                );

              return <Badge>{toTitleCase(role) || 'user'}</Badge>;
            },
          },
        ]}
        resetFilter={() => setSearch('')}
        rowMenu={{
          hideMenu: ({ row }) => {
            const data = row.original;
            if (!data.email || !data.role) return true;

            if (!isTeamAdmin) return true;

            return false;
          },
          menuItems: ({ row }) => {
            const data = row.original;
            const { userId, partnerId, email, role } = data;

            const { mutate: impersonate, loading: loadingImpersonation } =
              useTrpcMutation('impersonateUser');

            const { mutate: inviteUser } = useTrpcMutation('inviteUser', {
              successMessage: {
                title: 'Success!',
                message: 'Invitation was sent successfully.',
              },
            });
            const invite = ({ email, role }: { email: string; role: string }) =>
              inviteUser({ email, role, teamId, partnerId });

            const { loading: loadingInvitationUrl, mutate: inviteUrl } =
              useTrpcMutation('copyInvitationUrl', {
                successMessage: {
                  message: 'Copied invitation to clipboard',
                },
              });

            const copyInvitation = async () => {
              const res = await inviteUrl({
                teamId,
                userId,
              });

              if (res?.signedInvitationUrl) {
                navigator.clipboard.writeText(res.signedInvitationUrl);
              }
            };

            if (!userId || !teamId) return null;

            return (
              <>
                {email && (
                  <EllipsisMenuItem
                    customIcon={<RefreshCwIcon size={16} />}
                    onClick={() => invite({ email, role: role || 'user' })}
                  >
                    Resend invitation
                  </EllipsisMenuItem>
                )}

                <EllipsisMenuItem
                  loading={loadingInvitationUrl}
                  onClick={copyInvitation}
                  customIcon={<CopyIcon size={16} />}
                >
                  Copy invitation URL
                </EllipsisMenuItem>

                <EllipsisMenuDangerItem
                  onClick={() => setTenantUserId(row.original.id)}
                >
                  Remove member
                </EllipsisMenuDangerItem>
                <HiddenFeatureIndicator permission="super-admin">
                  <EllipsisMenuItem
                    customIcon={<EyeIcon size={16} />}
                    loading={loadingImpersonation}
                    onClick={() =>
                      impersonate({
                        tenantId: teamId,
                        userId,
                      }).then((res) => window.open(res.url))
                    }
                  >
                    Impersonate user
                  </EllipsisMenuItem>
                </HiddenFeatureIndicator>
              </>
            );
          },
        }}
      >
        <Filter.Search value={search} setValue={setSearch} />
      </InfiniteTable>
      <DeleteMemberModal
        tenantUserId={tenantUserId}
        closeModal={() => setTenantUserId(null)}
      />
    </>
  );
};

const RoleSelect = ({
  role: initial,
  tenantUserId,
}: { role: Maybe<string>; tenantUserId: string }) => {
  const [newRole, setNewRole] = useState<string | null>(null);

  const role = newRole || initial;

  const userRoles = ['admin', 'user'];

  const value = role ? { value: role, label: toTitleCase(role) } : null;

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        tenantUserId: string;
        role: string;
      }
    ) => {
      return (
        q
          .updateTenantUser({
            where: {
              id: { _eq: args.tenantUserId },
            },
            _set: {
              role: args.role,
            },
          })
          ?.returning?.map((tenantUser) => ({
            role: tenantUser.role,
          }))[0]?.role || null
      );
    }
  );

  return (
    <InputSelect
      data={{
        options: userRoles.map((role) => ({
          value: role,
          label: toTitleCase(role),
        })),
      }}
      type="single"
      value={value}
      setValue={(newValue) => {
        if (!newValue?.value) return;
        if (newValue.value === role) return;

        mutate({
          args: {
            role: newValue.value,
            tenantUserId,
          },
        }).then((role) => setNewRole(role));
      }}
      inputProps={{
        width: 150,
        loadingMutation: loading,
      }}
      dropdownProps={{
        withinPortal: true,
        width: 200,
      }}
    />
  );
};

const DeleteMemberModal = ({
  closeModal,
  tenantUserId,
}: {
  tenantUserId: string | null;
  closeModal: () => void;
}) => {
  const { id: meId } = useMe();
  const { track } = useBrowserTracking();

  const { mutate } = useMutation(
    (q, args: { tenantUserId: string }) => {
      return (
        q
          .deleteTenantUser({
            where: {
              id: { _eq: args.tenantUserId },
            },
          })
          ?.returning.map((tu) => ({
            userId: tu.userId,
            teamId: tu.tenantId,
          }))[0] || null
      );
    },
    {
      invalidateQueryKeys: ['users'],
      successMessage: {
        title: 'Success!',
        message: 'The team member was successfully removed.',
      },
    }
  );

  const removeTenantUser = () =>
    tenantUserId &&
    mutate({
      args: {
        tenantUserId,
      },
    }).then((res) => {
      track('team_member_removed', {
        userId: meId,
        removedUserId: res.userId,
        tenantId: res.teamId,
      });
    });

  return (
    <DeleteModal
      size={400}
      opened={!!tenantUserId}
      onClose={closeModal}
      onSubmit={removeTenantUser}
      title="Are you sure you would like to remove this user?"
      subtitle="This user will be permanently removed from your team."
    />
  );
};
