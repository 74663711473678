import { InputWrapper } from '@finalytic/components';
import { useAccountingConnection, useTeam } from '@finalytic/data';
import { Box, Group, Text } from '@mantine/core';
import { AutomationSettingsEditor } from '../../components';
import { SettingsTitle, SettingsViewContainer } from './_components';

export const SettingsTeamAutomationsView = () => {
  return (
    <SettingsViewContainer>
      <SettingsTitle type="view-title">Automations</SettingsTitle>
      <Text
        component="p"
        size={'0.875rem'}
        mb="xl"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Define global automation settings for your team.
      </Text>
      <Form />
    </SettingsViewContainer>
  );
};

const Form = () => {
  const [{ finalyticConnectionId }] = useTeam();
  const { accounting } = useAccountingConnection();

  const leftConnectionId = finalyticConnectionId;
  const rightConnectionId = accounting?.id;

  if (!rightConnectionId || !leftConnectionId) return null;

  return (
    <>
      <InputWrapper label="Date target">
        <Group wrap="nowrap" align="flex-start" gap="lg">
          <Text mb="xs" color="gray" size="sm" component="p" m={0}>
            Select the date field to use for automations. The date field decides
            the date for ...
          </Text>
          <Box w={350}>
            <AutomationSettingsEditor
              automationId={null}
              leftConnectionId={leftConnectionId}
              rightConnectionId={rightConnectionId}
              settingKey="dateField"
              settings={{
                dateField: {
                  defaultValue: 'invoiceAutomation',
                  label: 'Txn Date',
                  optional: true,
                  options: [
                    'invoiceAutomation',
                    'checkIn',
                    'checkOut',
                    'bookedAt',
                  ],
                  type: 'select',
                },
              }}
            />
          </Box>
        </Group>
      </InputWrapper>
      <InputWrapper label="Start Date">
        <Group wrap="nowrap" align="flex-start" gap="lg">
          <Text mb="xs" color="gray" size="sm" component="p" m={0}>
            Select the date field to use for automations. The date field decides
            the date for ...
          </Text>
          <Box w={350}>
            <AutomationSettingsEditor
              automationId={null}
              leftConnectionId={leftConnectionId}
              rightConnectionId={rightConnectionId}
              settingKey="dateField"
              settings={{
                dateField: {
                  defaultValue: 'invoiceAutomation',
                  label: 'Txn Date',
                  optional: true,
                  options: [
                    'invoiceAutomation',
                    'checkIn',
                    'checkOut',
                    'bookedAt',
                  ],
                  type: 'select',
                },
              }}
            />
          </Box>
        </Group>
      </InputWrapper>
    </>
  );
};
