import { Button, InputCountry, InputSelect } from '@finalytic/components';
import { useApiMutation, useBrowserTracking, useTeamId } from '@finalytic/data';
import { currency_enum } from '@finalytic/graphql';
import {
  SelectItem,
  showErrorNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { InputWrapper, Stack, rem } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { InputCurrency } from '../../../components';
import { useIsLedgerTesting } from '../hooks';

type BillingDate = 'current' | 'next' | 'previous';

type FormInputs = {
  startDate: BillingDate;
  currency: currency_enum;
  country: string;
};

export const OnboardingSettingsRoute = () => {
  const goto = useNavigate();
  const methods = useForm<FormInputs>();
  const [teamId] = useTeamId();

  const { isManualTesting } = useIsLedgerTesting();

  const { mutateAsync } = useApiMutation('put', '/teams/{id}');

  const { track } = useBrowserTracking();

  const submit = async (data: FormInputs) => {
    if (!data.startDate && !isManualTesting)
      return showWarnNotification({
        title: 'Choose your start date',
        message: 'Please select a start date to continue your onboarding.',
      });

    const statementStartAts: Record<BillingDate, string> = {
      current: day().startOf('month').yyyymmdd(),
      next: day().add(1, 'month').startOf('month').yyyymmdd(),
      previous: day().subtract(1, 'month').startOf('month').yyyymmdd(),
    };

    await mutateAsync({
      params: {
        path: {
          id: teamId,
        },
      },
      body: {
        defaultCurrency: data.currency ?? 'usd',
        statementStartAt: statementStartAts[data.startDate || 'current'],
      },
    })
      .then(() => {
        track('onboarding_billing_settings_set', {});
        goto('/select-listing');
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.message ||
          "We couldn't update your settings. Please try again and contact support if the issue persists.";
        showErrorNotification({
          title: 'Failed to update settings',
          message,
        });
      });
  };

  return (
    <>
      <Stack w="100%" mb={rem(80)} gap="xl">
        <Controller
          control={methods.control}
          name="startDate"
          defaultValue="current"
          render={({ field, fieldState }) => {
            const now = day();

            const options: SelectItem<BillingDate>[] = [
              {
                label: `This month (${now.format('MMMM, YYYY')})`,
                value: 'current',
              },
              {
                label: `Next month (${now.add(1, 'month').format('MMMM, YYYY')})`,
                value: 'next',
              },
              {
                label: `Last month (${now.subtract(1, 'month').format('MMMM, YYYY')})`,
                value: 'previous',
              },
            ];

            const value =
              options.find((option) => option.value === field.value) || null;

            return (
              <InputWrapper
                label="When do you want to start?"
                error={fieldState.error?.message}
              >
                <InputSelect
                  value={value}
                  type="single"
                  data={{
                    options,
                  }}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  inputProps={{
                    error: !!fieldState.error,
                    disabled: field.disabled,
                  }}
                  dropdownProps={{
                    width: 'target',
                    hideSearch: true,
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          name="country"
          defaultValue={'us'}
          disabled={isManualTesting}
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                label="Which country do you operate in?"
                error={fieldState.error?.message}
              >
                <InputCountry
                  value={field.value || null}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  error={!!fieldState.error?.message}
                  placeholder="Select country"
                  // disabled={field.disabled}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          name="currency"
          defaultValue="usd"
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                label="Currency for accounting records"
                error={fieldState.error?.message}
              >
                <InputCurrency
                  value={(field.value as any) || null}
                  setValue={(value) => {
                    value?.value && field.onChange(value.value);
                  }}
                  error={!!fieldState.error}
                />
              </InputWrapper>
            );
          }}
        />
      </Stack>

      <Button
        variant="primary"
        sx={{
          width: '100%',
        }}
        data-testid="submit-settings"
        loading={methods.formState.isSubmitting}
        onClick={methods.handleSubmit(submit)}
      >
        Continue
      </Button>
    </>
  );
};
