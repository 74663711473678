import { Button } from '@finalytic/components';
import { useDisclosure } from '@mantine/hooks';
import { AddAccountModal } from './AddAccountModal';

export const AddAccountButton = () => {
  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Button variant="primary" leftIcon={'PlusIcon'} onClick={handlers.open}>
        Add account
      </Button>

      <AddAccountModal opened={opened} closeModal={handlers.close} />
    </>
  );
};
