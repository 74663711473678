import { Breadcrumbs } from '@finalytic/components';
import { useMe, useQuery, useTeam } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator, useAppName } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import {
  Box,
  Button,
  Center,
  LoadingOverlay,
  Text,
  Title,
} from '@mantine/core';
import {
  type ActionEmailData,
  getDefaultInviteOwnerEmailData,
  getDefaultOwnerStatementEmailData,
} from '@vrplatform/emails';
import { getListingName, orderByListing } from '@vrplatform/ui-common';
import { Route, Routes } from 'react-router';
import { ActionEmailForm } from './ActionEmailForm';
import { EmailTemplatesList } from './EmailTemplatesList';

export const SettingsFeaturesEmailsRoutes = () => {
  const [{ name: teamName, id: teamId, logo, colorPrimary }] = useTeam();
  const { firstName, lastName } = useMe();
  const { appName } = useAppName();

  const { data, isInitialLoading, error, refetch, isFetching } = useQuery(
    (q, args) => {
      const templates = q
        .emailTemplates({
          where: {
            tenantId: { _eq: args.teamId },
          },
          order_by: [{ type: 'asc_nulls_last' }],
        })
        .map((template) => ({
          id: template.id,
          type: template.type,
          updatedAt: template.updatedAt,
          dataJson: template.dataJson() as ActionEmailData,
        }));

      const listing: string = q
        .listings({
          where: {
            tenantId: { _eq: args.teamId },
          },
          limit: 1,
          order_by: [orderByListing],
        })
        .map((listing) => getListingName(listing, { allowEmpty: true }))[0];

      return {
        templates,
        listing,
      };
    },
    {
      queryKey: ['emailTemplates'],
      skip: !teamId,
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );

  if (isInitialLoading)
    return (
      <>
        <Breadcrumbs pageTitle="Emails" />
        <LoadingIndicator isFullPageLoading />
      </>
    );

  if (error || !data)
    return (
      <>
        <Breadcrumbs pageTitle="Emails" />
        <Center
          h={'70vh'}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Icon
            icon="AlertTriangleIcon"
            color={(theme) => theme.colors.yellow[8]}
            size={32}
          />
          <Title order={3} mt="xl" sx={{ textAlign: 'center' }}>
            Failed to fetch email templates
          </Title>
          <Text
            mt="xs"
            mb="xl"
            sx={{
              textAlign: 'center',
            }}
            c="gray"
          >
            There was an error fetching the team's email templates
          </Text>
          <Button
            onClick={() => refetch()}
            sx={{
              width: 200,
            }}
          >
            Try again
          </Button>
        </Center>
      </>
    );

  const defaultListingName = '1502 Amelia Way';
  const listing = data.listing || defaultListingName;

  const defaultSignInLink = window.location.origin;

  const defaultOwnerInvite = getDefaultInviteOwnerEmailData('tag', {
    appName,
    signInLink: defaultSignInLink,
  });
  const defaultPmInvite = getDefaultInviteOwnerEmailData('tag', {
    appName,
    signInLink: defaultSignInLink,
  });

  const month = day().format('MMMM YYYY');

  const defaulOwnerStatementEmail = getDefaultOwnerStatementEmailData('tag', {
    appName,
    ownerStatementHref: defaultSignInLink,
    listingName: listing,
    month: month,
    userName: firstName?.trim() || 'John',
  });

  const oInvite = data.templates.find((x) => x.type === 'invite_owner');
  const pInvite = data.templates.find(
    (x) => x.type === 'invite_propertyManager'
  );

  const ownerStatementOwner = data.templates.find(
    (x) => x.type === 'ownerStatement_owner'
  );

  const inviteOwnerTemplate = oInvite
    ? { ...oInvite.dataJson, id: oInvite.id }
    : defaultOwnerInvite;

  const invitePmTemplate = pInvite
    ? { ...pInvite.dataJson, id: pInvite.id }
    : defaultPmInvite;

  const ownerStatementTemplate = ownerStatementOwner
    ? { ...ownerStatementOwner.dataJson, id: ownerStatementOwner.id }
    : defaulOwnerStatementEmail;

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isFetching} />
      <Routes>
        <Route
          index
          element={
            <EmailTemplatesList
              invite_owner={{
                shortDescription: inviteOwnerTemplate.shortDescription,
                updatedAt: oInvite?.updatedAt,
              }}
              invite_propertyManager={{
                shortDescription: invitePmTemplate.shortDescription,
                updatedAt: pInvite?.updatedAt,
              }}
              ownerStatement_owner={{
                shortDescription: ownerStatementTemplate.shortDescription,
                updatedAt: ownerStatementOwner?.updatedAt,
              }}
            />
          }
        />
        <Route
          path="pm-invitation"
          element={
            <ActionEmailForm
              defaultValues={defaultPmInvite}
              type="invite_propertyManager"
              values={invitePmTemplate}
              data={{
                tenant: {
                  colorPrimary: undefined,
                  logo: undefined,
                  name: teamName,
                },
                user: {
                  firstName,
                  lastName,
                },
              }}
            />
          }
        />
        <Route
          path="owner-invitation"
          element={
            <ActionEmailForm
              type="invite_owner"
              defaultValues={defaultOwnerInvite}
              values={inviteOwnerTemplate}
              data={{
                tenant: {
                  colorPrimary,
                  logo,
                  name: teamName,
                },
                user: {
                  firstName,
                  lastName,
                },
              }}
            />
          }
        />
        <Route
          path="owner-statement"
          element={
            <ActionEmailForm
              type="ownerStatement_owner"
              defaultValues={defaultOwnerInvite}
              values={ownerStatementTemplate}
              data={{
                tenant: {
                  colorPrimary,
                  logo,
                  name: teamName,
                },
                user: {
                  firstName,
                  lastName,
                },
                listing,
                month,
              }}
            />
          }
        />
      </Routes>
    </Box>
  );
};
