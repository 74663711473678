import { Button as ReactEmailButton } from '@react-email/button';
import { ComponentProps } from 'react';
import { EmailBaseProps } from '../_types';

type Props = {
  mode: EmailBaseProps['mode'];
  href: string;
  themeColors: EmailBaseProps['themeColors'];
  label: string;
} & ComponentProps<typeof ReactEmailButton>;

export const Button = ({
  href,
  mode,
  label,
  themeColors,
  style,
  ...props
}: Props) => (
  <ReactEmailButton
    href={mode === 'send' ? href : undefined}
    style={{
      width: '100%',
      textAlign: 'center',
      display: 'block',
      padding: '13px 0',
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), ${
        themeColors[8]
      }`,
      color: '#fff',
      borderRadius: '8px',
      marginTop: '30px',
      marginBottom: '60px',
      ...style,
    }}
    {...props}
  >
    {label.trim()}
  </ReactEmailButton>
);
