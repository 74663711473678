import { Badge, Button, IconButton } from '@finalytic/components';
import { useDashboard, useQuery, useTeamId } from '@finalytic/data';
import { Icon, IconDefinition, LoaderIcon, TimeIcon } from '@finalytic/icons';
import {
  Drawer,
  StringParam,
  useQueryParam,
  useQueryParamSet,
} from '@finalytic/ui';
import { Maybe, day } from '@finalytic/utils';
import { Avatar, Box, Group, Tabs, Text, rem } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ConnectionEllipsisMenuItems } from '../../views/connections/connections-table/ConnectionEllipsisMenuItems';
import { ConnectionEllipsisMenuModals } from '../../views/connections/connections-table/ConnectionEllipsisMenuModals';
import { getConnection } from '../../views/connections/connections-table/useConnectionTableQuery';
import { DrawerHeader, DrawerInfoCard } from '../_components';
import { ConnectionSyncedFromTable } from './ConnectionSyncedFromTable';
import { ConnectionSyncedToTable } from './ConnectionSyncedToTable';
import { ConnectionSyncsTable } from './ConnectionSyncsTable';

type Connection = NonNullable<
  ReturnType<typeof useConnectionDetailQuery>['data']
>;

function useConnectionDetailQuery(connectionId: Maybe<string>) {
  return useQuery(
    (q, args) => {
      if (!args.connectionId) return null;

      return (
        q
          .connection({
            where: {
              id: { _eq: args.connectionId },
            },
          })
          .map(getConnection)[0] || null
      );
    },
    {
      skip: !connectionId,
      queryKey: 'connections',
      variables: {
        connectionId,
      },
    }
  );
}

export const ConnectionDetailDrawer = () => {
  const [dashboard] = useDashboard();

  const [, setTeamId] = useTeamId();
  const goto = useNavigate();

  const reconnect = useQueryParamSet('connectionId', StringParam);

  const [connectionId, setQuery] = useQueryParam(
    'connectionDetailId',
    StringParam
  );

  const [opened, setOpened] = useState<{
    connection: Connection;
    modal: 'rename' | 'delete' | 'update' | 'fetch';
  } | null>(null);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const {
    data: connection,
    refetch,
    isLoading: loading,
  } = useConnectionDetailQuery(connectionId);

  const isPartnerDashboard = dashboard === 'partner';

  const openModal = (
    c: Connection,
    modal: 'rename' | 'delete' | 'update' | 'fetch'
  ) => {
    setOpened({ connection: c, modal });
  };
  const closeModal = () => setOpened(null);

  return (
    <>
      <ConnectionEllipsisMenuModals
        data={opened?.connection}
        refresh={refetch}
        deleteModal={{
          opened: opened?.modal === 'delete' && !!opened?.connection,
          close: closeModal,
        }}
        updateModal={{
          opened: opened?.modal === 'update' && !!opened?.connection,
          close: closeModal,
        }}
        renameModal={{
          opened: opened?.modal === 'rename' && !!opened?.connection,
          close: closeModal,
        }}
      />

      <Drawer
        opened={!!connectionId}
        onClose={closeDrawer}
        size={800}
        zIndex={100}
      >
        <DrawerHeader
          title={
            connection && (
              <Group mt={rem(5)} wrap="nowrap" flex={1}>
                <Avatar
                  src={connection.app.iconRound}
                  sx={(theme) => ({
                    border: `1px solid ${theme.colors.gray[2]}`,
                  })}
                />
                <Box>
                  <Text component="p" m={0} color="gray" size="lg">
                    {connection.app.name} /{' '}
                    <Text c="black" fw={500} inherit component="span">
                      {connection.name}
                    </Text>
                  </Text>
                  {connection.uniqueRef && (
                    <Text component="p" m={0} size="sm" color="gray">
                      {connection.uniqueRef}
                    </Text>
                  )}
                </Box>
              </Group>
            )
          }
          loading={loading}
          menuItems={
            connection && (
              <ConnectionEllipsisMenuItems
                data={connection}
                openDeleteModal={() => openModal(connection, 'delete')}
                openRenameModal={() => openModal(connection, 'rename')}
                openUpdateModal={() => openModal(connection, 'update')}
              />
            )
          }
          closeDrawer={closeDrawer}
        >
          {['error', 'failed', 'outdated'].includes(connection?.status || '') &&
            connection && (
              <Button
                color="red"
                leftIcon={'LightningIcon'}
                onClick={() => reconnect(connection.id)}
              >
                Reconnect
              </Button>
            )}
          {isPartnerDashboard && connection?.tenant.id && (
            <IconButton
              onClick={() => {
                goto(
                  `/connections?search=${encodeURIComponent(
                    connection.name || ''
                  )}`
                );
                setTeamId(connection.tenant.id);
              }}
              variant="outline"
              icon="ArrowRightCircleIcon"
              tooltip="Go to team"
            />
          )}
        </DrawerHeader>

        {connection && <Content {...connection} />}
      </Drawer>
    </>
  );
};

const Content = ({
  id: connectionId,
  status,
  lastFetch,
  tenant,
}: Connection) => {
  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <StatusBadge status={status} />,
          },
          {
            icon: TimeIcon,
            title: 'Last sync',
            text: lastFetch?.createdAt
              ? day(lastFetch.createdAt).format('MMM DD, YYYY HH:mm')
              : null,
          },
        ]}
      />

      <Tabs
        keepMounted={false}
        defaultValue={'history'}
        styles={(theme) => ({
          root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          panel: {
            height: '100%',
            flex: 1,
            marginTop: theme.spacing.lg,
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          tab: {
            paddingBlock: theme.spacing.md,
            paddingInline: theme.spacing.sm,
            color: theme.colors.gray[6],
            "&[data-active='true']": {
              color: theme.black,
            },
          },
          tabsList: {
            marginInline: theme.spacing.xs,
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="history">History</Tabs.Tab>
          <Tabs.Tab value="synced-from">Synced from</Tabs.Tab>
          <Tabs.Tab value="synced-to">Synced to</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="history">
          <ConnectionSyncsTable
            connectionId={connectionId}
            tenantId={tenant.id}
          />
        </Tabs.Panel>
        <Tabs.Panel value="synced-from">
          <ConnectionSyncedFromTable
            connectionId={connectionId}
            tenantId={tenant.id}
          />
        </Tabs.Panel>
        <Tabs.Panel value="synced-to">
          <ConnectionSyncedToTable
            connectionId={connectionId}
            tenantId={tenant.id}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const StatusBadge = ({ status }: { status: Connection['status'] }) => {
  let color: 'green' | 'yellow' | 'red' | 'orange' = 'green';
  let label = 'Active';
  let icon: IconDefinition | undefined = undefined;

  if (status === 'failed' || status === 'error') {
    color = 'red';
    label = 'Error';
    icon = 'CrossIcon';
  } else if (status === 'pending' || status === 'started') {
    label = 'Pending';
    color = 'yellow';
  } else if (status === 'inactive' || status === 'archived') {
    label = 'Archived';
    color = 'yellow';
  } else if (status === 'outdated') {
    label = 'Version upgrade required';
    color = 'orange';
  }

  return (
    <Box>
      <Badge
        leftIcon={icon && <Icon icon={icon} color={color} size={12} />}
        color={color}
      >
        {label}
      </Badge>
    </Box>
  );
};
