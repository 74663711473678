import { CountBagde } from '@finalytic/components';
import { Query, useTeamId } from '@finalytic/data';
import { useSubscription } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { Stack, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router';
import { NavbarRoute } from '../layout/navbar';
import { getMissingAccountAssignments, useVrpRetoolQuery } from '../queries';
import { PartnerConnectionErrorAggregateBadge } from '../views/partner-connections/PartnerConnectionErrorAggregateBadge';

const iconSize = 18;

export function VrpNavigation() {
  const goto = useNavigate();

  const { data: vrpRetoolViews } = useVrpRetoolQuery();

  return (
    <>
      <NavbarRoute
        title="Overview"
        link={'/dashboard'}
        icon={<Icon icon="Activity2Icon" size={iconSize} />}
      />

      <Stack gap="xs" my="sm">
        <NavbarRoute
          title="Property Managers"
          link={'/property-manager'}
          icon={<Icon icon="HomeIcon" size={iconSize - 1} />}
        />
        <NavbarRoute
          title="Partners"
          link={'/partner'}
          icon={<Icon icon="OfficeIcon" size={iconSize} />}
        />
        <NavbarRoute
          title="Users"
          link={'/users'}
          icon={<Icon icon="UsersIcon" size={iconSize - 1} />}
        />
      </Stack>
      <Stack gap="xs" mb="sm">
        <NavbarRoute
          title="Statements"
          link={'/team-statements'}
          icon={<Icon icon="ClipboardTextIcon" size={iconSize} />}
        />
        <NavbarRoute
          title="Automations"
          link={'/team-automations'}
          icon={<Icon icon="RocketIcon" size={iconSize} />}
        />

        <NavbarRoute
          title="Connections"
          link={'/team-connections?status=active'}
          icon={<Icon icon="ShareIcon" size={iconSize} />}
          notification={
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                goto('/team-connections/errors?status=active');
              }}
              sx={{
                width: 'unset',
              }}
            >
              <PartnerConnectionErrorAggregateBadge loadingColor="white" />
            </IconButton>
          }
        />
      </Stack>

      <Stack gap="xs">
        <NavbarRoute
          title="VRP Billing"
          link={'/billing'}
          icon={<Icon icon="CircleDollarIcon" size={iconSize} />}
        />
        <NavbarRoute
          title="VRP Admins"
          link={'/members'}
          icon={<Icon icon="UsersIcon" size={iconSize} />}
        />
        <NavbarRoute
          title="VRP Chart of Accounts"
          link={'/accounts'}
          icon={<Icon icon="ListUnorderedIcon" size={iconSize} />}
          notification={<ChartOfAccountIssueBadge />}
        />
        <NavbarRoute
          title="VRP Statement Template"
          link={'/statements/templates'}
          icon={<Icon icon="FileInvoiceIcon" size={iconSize} />}
          notification={<ChartOfAccountIssueBadge />}
        />
      </Stack>

      {!!vrpRetoolViews?.length && (
        <Stack mt="md" gap="xs">
          {vrpRetoolViews.map((view) => (
            <NavbarRoute
              key={view.id}
              title={view.title}
              link={`/retool/${view.id}`}
              icon={<Icon icon="FileTextIcon" size={iconSize} />}
            />
          ))}
        </Stack>
      )}
    </>
  );
}

const ChartOfAccountIssueBadge = () => {
  const [teamId] = useTeamId();

  const { data, isLoading } = useSubscription(
    (q, args) => {
      const count = getMissingAccountAssignments(q as Query, {
        teamId: args.teamId,
      }).length;

      return count;
    },
    { teamId }
  );

  const count = data || 0;

  if (isLoading) return <CountBagde count={0} loading loadingColor="white" />;

  if (!count) return null;

  return (
    <Tooltip
      label={`${count} missing account assignments`}
      withArrow
      disabled={!count}
    >
      <CountBagde count={count} loading={isLoading} loadingColor="white" />
    </Tooltip>
  );
};
