import { useInfiniteQuery, useTeamId } from '@finalytic/data';
import { MRT_SortingState } from '@finalytic/table';
import { LineTypeType } from './LineTypeMappingFilter';
import { useWherePaymentLineClassificationMapping } from './useWhereLineTypeMapping';

type Params = {
  sorting: MRT_SortingState;
  type: LineTypeType;
};

export type LineTypeMappingRow = NonNullable<
  ReturnType<typeof useLineTypeMappingTableQuery>['data']
>['pages'][number]['list'][number];

export const useLineTypeMappingTableQuery = ({ sorting, type }: Params) => {
  const where = useWherePaymentLineClassificationMapping({ type });
  const [teamId] = useTeamId();

  return useInfiniteQuery(
    (q, { where, sorting, teamId }, { limit, offset }) => {
      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const bookingChannelExceptions = q
        .accountReservationLineTypes({
          where: {
            tenantId: { _eq: teamId },
            status: { _eq: 'active' },
            bookingChannel: {
              _is_null: false,
            },
          },
          order_by: [{ createdAt: 'asc_nulls_last' }],
        })
        .map((mapping) => ({
          lineType: mapping.lineType,
          id: mapping.id,
          accountId: (mapping.accountId || 'exclude') as
            | 'exclude'
            | (string & {}),
          bookingChannel: mapping.bookingChannel!,
        }));

      const aggregate =
        q.paymentLineClassificationAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .paymentLineClassifications({
          where,
          limit,
          offset,
          order_by,
        })
        .map((lineType) => {
          const type: 'payment_line' | 'reservation_line' =
            lineType.type === 'paymentLine'
              ? 'payment_line'
              : 'reservation_line';

          return {
            id: lineType.id,
            name: lineType.name,
            app: lineType.app?.name,
            type,
            bookingChannelExceptions: bookingChannelExceptions.filter(
              (x) => x.lineType === lineType.name
            ),
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'lineTypeMappings',
      variables: { where, sorting, teamId },
    }
  );
};
