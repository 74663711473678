import {
  Button,
  Input,
  InputCountry,
  InputPercentage,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  useApiMutation,
  useInfiniteQuery,
  useInvalidateQueries,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type {
  account_bool_exp,
  activeStatus_enum,
  taxBehavior_enum,
} from '@finalytic/graphql';
import {
  SelectItem,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import {
  bankersRound,
  bankersRoundToCents,
  toTitleCase,
} from '@finalytic/utils';
import { Box, Group, Modal } from '@mantine/core';
import { whereAccounts } from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTaxRateAccountAssignmentQuery } from './useTaxRateAccountAssignmentQuery';

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

type FormInputs = {
  id?: string;
  title: string;
  accountId: string | null;
  behavior: taxBehavior_enum | null;
  countryCode: string | null;
  rate: number | null;
};

type Props = {
  opened: boolean;
  closeModal: () => void;
  taxRate?: (FormInputs & { status: activeStatus_enum }) | null;
  zIndex?: number;
};

export const TaxRateModal = ({
  opened,
  closeModal: cl,
  taxRate,
  zIndex,
}: Props) => {
  const [search, setSearch] = useState('');

  const invalidate = useInvalidateQueries(['taxRates']);

  const { mutateAsync: insert } = useApiMutation('post', '/rates');
  const { mutateAsync: update } = useApiMutation('put', '/rates/{id}');

  const defaultAccountQueryData = useTaxRateAccountAssignmentQuery();

  const methods = useForm<FormInputs>({
    values: taxRate
      ? {
          accountId: taxRate.accountId,
          rate: bankersRoundToCents(taxRate.rate ?? 0),
          title: taxRate.title,
          behavior: taxRate.behavior,
          countryCode: taxRate.countryCode,
        }
      : {
          rate: null,
          accountId: null,
          title: '',
          behavior: 'included',
          countryCode: null,
        },
  });

  const submit = async (data: FormInputs) => {
    try {
      const percentage = bankersRound((data.rate ?? 0) / 100);
      const accountId = data.accountId ?? null;

      if (!taxRate?.id) {
        await insert({
          body: {
            name: data.title,
            percentage,
            accountId,
            type: 'tax',
            status: 'active',
            countryCode: data.countryCode,
          },
        });
      } else {
        await update({
          params: {
            path: {
              id: taxRate.id,
            },
          },
          body: {
            percentage,
            accountId,
            name: data.title,
            type: 'tax',
            status: taxRate.status,
            countryCode: data.countryCode,
          },
        });
      }

      showSuccessNotification({
        title: 'Success!',
        message: `Tax rate was ${taxRate ? 'updated' : 'added'} successfully`,
      });

      invalidate();
      closeModal();
    } catch (error: any) {
      const errorMessage =
        error?.message ||
        error.issues?.[0]?.message ||
        'Please try again later and if the problem persists, contact support.';

      showErrorNotification({
        message: errorMessage,
        title: 'Failed to save tax rate',
      });
    }
  };

  const closeModal = () => {
    cl();
    methods.reset({
      rate: null,
    });
  };

  const accountQueryData = useAccountQuery(search);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={`${taxRate ? 'Update' : 'Add'} Tax Rate`}
      styles={{
        title: { fontWeight: 500 },
      }}
      zIndex={zIndex}
      centered
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <Controller
          control={methods.control}
          rules={{
            required: 'Name is required',
            validate: trapSpacesForRequiredFields,
          }}
          name="title"
          render={({ field, fieldState }) => (
            <InputWrapper
              required
              label="Name"
              error={fieldState.error?.message}
            >
              <Input
                value={field.value}
                setValue={field.onChange}
                error={!!fieldState.error}
                placeholder="Sales tax (10%)"
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={methods.control}
          name={'accountId'}
          render={({ field, fieldState }) => {
            const { data, isLoading: loading } = useQuery(
              (q, args) => {
                if (!args.value) return null;

                return q
                  .accounts({
                    where: {
                      id: { _eq: args.value },
                    },
                  })
                  .map<SelectItem>((item) => ({
                    label: item.title || 'No name',
                    value: item.id!,
                  }))[0];
              },
              {
                keepPreviousData: true,
                variables: {
                  value: field.value,
                },
              }
            );

            const account = field.value ? data || null : null;

            return (
              <InputWrapper error={fieldState.error?.message} label="Account">
                <InputSelect
                  type="single"
                  value={account}
                  setValue={(v) => field.onChange(v?.value || null)}
                  infiniteData={{ ...accountQueryData, setSearch }}
                  inputProps={{
                    error: !!fieldState.error,
                    placeholder: defaultAccountQueryData.data?.title
                      ? `Default: ${defaultAccountQueryData.data.title}`
                      : accountQueryData.data?.pages?.[0]?.list?.[0]?.label ||
                        'Select Account',
                    loadingQuery: loading || defaultAccountQueryData.isLoading,
                    withClearButton: true,
                  }}
                  dropdownProps={{
                    width: 'target',
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          name="countryCode"
          render={({ field, fieldState }) => (
            <InputWrapper label="Country" error={fieldState.error?.message}>
              <InputCountry
                value={field.value}
                setValue={(v) => field.onChange(v?.value ?? null)}
                error={!!fieldState.error}
                placeholder="United States of America"
                clearable
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={methods.control}
          rules={{
            validate: (value) => {
              if (typeof value !== 'number') {
                return 'Please enter a number';
              }

              if (value <= 0) {
                return 'Please enter a positive rate';
              }
            },
          }}
          name="rate"
          render={({ field, fieldState }) => (
            <InputWrapper
              required
              label="Rate"
              error={fieldState.error?.message}
            >
              <InputPercentage
                value={field.value || undefined}
                onChange={field.onChange}
                error={!!fieldState.error}
                placeholder="10"
                min={0}
              />
            </InputWrapper>
          )}
        />

        {/* <Controller
          control={methods.control}
          name={'behavior'}
          render={({ field }) => {
            return (
              <Checkbox
                checked={field.value === 'included'}
                label="Include tax by default when applying to amounts"
                onChange={(event) =>
                  field.onChange(
                    event.currentTarget.checked ? 'included' : 'excluded'
                  )
                }
                size="xs"
                sx={(theme) => ({
                  flex: 1,
                  color: theme.colors.neutral[8],
                })}
              />
            );
          }}
        /> */}

        <Group justify="right" mt="md">
          <Button
            onClick={closeModal}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={methods.handleSubmit(submit, (err) => console.log(err))}
            loading={methods.formState.isSubmitting}
          >
            {taxRate ? 'Update' : 'Add tax rate'}
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

function useAccountQuery(search: string) {
  const [teamId] = useTeamId();

  return useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: account_bool_exp = {
        ...whereAccounts({
          tenantId: teamId,
          search,
        }),
        type: { _eq: 'ledger' },
      };

      const list = q
        .accounts({
          where,
          order_by: [
            {
              classification: 'asc',
            },
            { title: 'asc_nulls_last' },
          ],
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.classification || 'No classification'),
        }));

      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );
}
