import { Button } from '@finalytic/components';
import { useInvalidateQueries, useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { whereAccounts } from '@vrplatform/ui-common';
import { AccountsView } from '../../accounts/AccountsView';
import {
  AddAccountButton,
  CopyChartOfAccountModal,
} from '../../accounts/_components';
import { useAccountsConfig } from '../../accounts/useAccountsConfig';
import { SettingsTitle } from '../_components';

export const SettingsAccountsView = () => {
  const { isMasterList } = useAccountsConfig();
  const [teamId] = useTeamId();

  const {
    isLoading,
    data: hasAccounts,
    error,
    refetch,
  } = useQuery(
    (q, args) => {
      if (args.isMasterList) return true;

      return !!(
        q
          .accountAggregate({
            where: whereAccounts({
              tenantId: args.teamId,
              search: undefined,
            }),
          })
          .aggregate?.count() || 0
      );
    },
    {
      skip: isMasterList,
      variables: {
        teamId,
        isMasterList,
      },
    }
  );

  if (!isMasterList && isLoading) {
    return (
      <>
        <SettingsTitle type="view-title">Accounts</SettingsTitle>
        <LoadingIndicator isFullPageLoading />
      </>
    );
  }

  if (!isMasterList && error) {
    return (
      <>
        <SettingsTitle type="view-title">Accounts</SettingsTitle>
        <Stack gap={0} mih="60vh" justify="center" align="center">
          <Icon
            icon={'AlertTriangleIcon'}
            size={30}
            color={(theme) => theme.colors.orange[6]}
          />
          <Title ta="center" mt="xl" mb="xs" c="neutral" order={4} m={0}>
            Failed to load accounts
          </Title>
          <Text ta="center" component="p" c="gray" m={0} mb={'xl'}>
            We failed to load the accounts. Please try again and if the issue
            persists, contact support.
          </Text>
          <Button
            onClick={() => refetch()}
            variant="primary"
            leftIcon={'RefreshCwIcon'}
          >
            Try again
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Group justify="space-between">
        <SettingsTitle type="view-title">Accounts</SettingsTitle>
        {hasAccounts && (
          <Group>
            <RefreshButton />
            <ResetAccountsButton />
            <AddAccountButton />
          </Group>
        )}
      </Group>
      <AccountsView hasAccounts={!!hasAccounts} />
    </>
  );
};

const ResetAccountsButton = () => {
  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Button onClick={handlers.open} leftIcon={'AlertTriangleIcon'}>
        Reset accounts
      </Button>
      <CopyChartOfAccountModal closeModal={handlers.close} opened={opened} />
    </>
  );
};

const RefreshButton = () => {
  const invalidate = useInvalidateQueries([
    'accounts',
    'paymentLineClassifications',
    'settings',
    'lineTypeMappings',
  ]);

  return (
    <Button
      variant="transparent"
      leftIcon={'RefreshCwIcon'}
      onClick={() => invalidate()}
    >
      Refresh data
    </Button>
  );
};
