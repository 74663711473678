import { Button, Divider, Input, InputWrapper } from '@finalytic/components';
import { useQuery, useTeam, useTeamId, useTrpcMutation } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { showWarnNotification } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { Box, Group, LoadingOverlay, Text, Title } from '@mantine/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TeamDeleteButton } from './TeamDeleteButton';
import { SettingsTitle, SettingsViewContainer } from './_components';

function useTeamData() {
  const [teamId] = useTeamId();

  const {
    isLoading: loading,
    data: team,
    refetch,
    error,
  } = useQuery(
    (q, args) => {
      if (!args.teamId) return null;

      const team = q.tenantById({ id: args.teamId });

      return ensure<{ id: string; values: FormValues }>({
        id: team?.id,
        values: {
          teamName: team?.name || '',
          email: team?.supportEmail || '',
        },
      });
    },
    {
      skip: !teamId,
      variables: { teamId },
    }
  );

  return {
    loading,
    refetch,
    team,
    error,
  };
}

type FormValues = {
  teamName: string;
  email: string | undefined;
};

export const SettingsPartnerGeneralView = () => {
  const [{ status }] = useTeam();

  return (
    <SettingsViewContainer
      sx={{
        position: 'relative',
      }}
    >
      <SettingsTitle type="view-title">General</SettingsTitle>

      <TeamInputSection />

      <HiddenFeatureIndicator permission="vrp-admin" mt="xl" p="sm">
        <SettingsTitle type="heading" mb="md">
          VRP-admins only
        </SettingsTitle>
        <Text>Status: {status}</Text>
        <Divider mb="xl" mt="md" />
        <TeamDeleteButton />
      </HiddenFeatureIndicator>
    </SettingsViewContainer>
  );
};

const trapSpacesForRequiredFields = (value: string | undefined) =>
  !!value?.trim() || 'This field is required';

const TeamInputSection = () => {
  const { loading: loadingQuery, team: teamData, refetch } = useTeamData();

  const methods = useForm<FormValues>({
    values: teamData?.values,
  });

  const { mutate, loading } = useTrpcMutation('updateTenant', {
    successMessage: {
      title: 'Team updated',
      message: 'Team was updated successfully.',
    },
  });

  const submit = (values: FormValues) => {
    if (!teamData?.id) {
      refetch();
      return showWarnNotification({
        title: 'Team not found',
        message:
          'Trying to refetch team info, please try again after the loading screen disappears.',
      });
    }
    return mutate({
      tenantId: teamData.id,
      input: {
        email: values.email || null,
        name: values.teamName,
      },
    }).then(() => {
      methods.reset();
      refetch();
    });
  };

  return (
    <>
      <Box
        mt="lg"
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={() => methods.reset(teamData?.values)}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <FormProvider {...methods}>
          <Title order={4} mt="md">
            Team
          </Title>

          <Controller
            control={methods.control}
            name="teamName"
            rules={{
              required: 'Team name is required',
              validate: trapSpacesForRequiredFields,
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Team name" error={error?.message} required>
                <Input
                  {...field}
                  placeholder="Team name"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={methods.control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Email" error={error?.message}>
                <Input
                  {...field}
                  placeholder="Email"
                  type="email"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Group justify="right">
            <Button
              type="reset"
              disabled={loading || !methods.formState.isDirty}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!methods.formState.isDirty}
              loading={loading}
            >
              Save changes
            </Button>
          </Group>
        </FormProvider>
      </Box>
      <LoadingOverlay visible={loadingQuery} />
    </>
  );
};
