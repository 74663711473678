import { Breadcrumbs, Button, Divider, Input } from '@finalytic/components';
import { useMutation, useTeamId, useTrpcMutation } from '@finalytic/data';
import type { emailTemplateType_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import type { Maybe } from '@finalytic/utils';
import {
  Alert,
  Anchor,
  Box,
  Group,
  InputWrapper,
  Textarea,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { Text } from '@mantine/core';
import {
  InviteOwnerEmail,
  InvitePmEmail,
  OwnerStatementEmail,
  render,
} from '@vrplatform/emails';
import { getOwnerPortalColor } from '@vrplatform/ui-common';
import { type ComponentProps, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  type CustomRichTextExtension,
  EmailRichTextInput,
} from './EmailRichTextInput';

type FormInputs = ComponentProps<typeof InvitePmEmail>['data'] & {
  id?: string | undefined;
};

const useActionEmailForm = () => useFormContext<FormInputs>();

type BaseData = {
  tenant: {
    name: string;
    logo: Maybe<string>;
    colorPrimary: Maybe<string>;
  };
  user: {
    firstName: string | undefined;
    lastName: string | undefined;
  };
};

type EmailTemplate<T extends emailTemplateType_enum> = T;

interface InviteData {
  type: EmailTemplate<'invite_owner' | 'invite_propertyManager'>;
  data: BaseData;
}

interface OwnerStatementData {
  type: EmailTemplate<'ownerStatement_owner'>;
  data: BaseData & {
    listing: string;
    month: string;
  };
}

type Data = InviteData | OwnerStatementData;

type BaseProps = {
  values: FormInputs;
  defaultValues: Omit<FormInputs, 'id'>;
};

type Props = BaseProps & Data;

export const ActionEmailForm = (props: Props) => {
  const [teamId] = useTeamId();

  const { values: initialValues, defaultValues: DEFAULT_TEMPLATE } = props;

  const tenant = props.data.tenant;
  const user = props.data.user;

  const methods = useForm<FormInputs>({
    values: initialValues,
  });

  const theme = useMantineTheme();

  const { mutate } = useMutation(
    (
      q,
      {
        id,
        tenantId,
        type,
        ...newData
      }: FormInputs & { tenantId: string; type: emailTemplateType_enum }
    ) => {
      if (id) {
        return q.updateEmailTemplate({
          pk_columns: { id },
          _set: {
            dataJson: newData,
          },
        }).id;
      }

      return q.insertEmailTemplate({
        object: {
          dataJson: newData,
          tenantId,
          type,
        },
      }).id;
    },
    {
      successMessage: {
        title: 'Email template saved',
        message: 'The email template has been saved successfully',
      },
      invalidateQueryKeys: ['emailTemplates'],
    }
  );

  const { loading: loadingTestEmail, mutate: sendTest } = useTrpcMutation(
    'sendTestEmail',
    {
      successMessage: {
        title: 'Test email sent',
        message: 'The test email has been sent successfully',
      },
    }
  );

  const submit = async (data: FormInputs) => {
    return mutate({
      args: {
        ...data,
        tenantId: teamId,
        type: props.type,
      },
    });
  };

  const sendTestEmail = async () => {
    const data = methods.getValues();

    const jsx = (() => {
      const portalThemeColors =
        theme.colors[
          getOwnerPortalColor(tenant.colorPrimary) || theme.primaryColor
        ];

      if (props.type === 'ownerStatement_owner') {
        return (
          <OwnerStatementEmail
            mode="send"
            tenant={tenant}
            data={data}
            themeColors={portalThemeColors}
            user={user}
            listing={props.data.listing}
            month={props.data.month}
          />
        );
      }

      if (props.type === 'invite_owner') {
        return (
          <InviteOwnerEmail
            mode="send"
            tenant={tenant}
            data={data}
            themeColors={portalThemeColors}
            user={user}
            listing={undefined}
            month={undefined}
          />
        );
      }

      if (props.type === 'invite_propertyManager') {
        return (
          <InvitePmEmail
            mode="send"
            tenant={{
              logo: tenant.logo || undefined,
              name: tenant.name,
            }}
            data={data}
            themeColors={theme.colors[theme.primaryColor]}
            user={user}
            listing={undefined}
            month={undefined}
          />
        );
      }

      return <></>;
    })();

    await sendTest({
      tenantId: teamId,
      html: render(jsx),
      subject: data.subject,
      text: render(jsx, { plainText: true }),
    });
  };

  const pageTitle = useMemo(() => {
    const titles: Record<typeof props.type, string> = {
      invite_owner: 'Owner Invitation',
      invite_propertyManager: 'Property Manager Invitation',
      ownerStatement_owner: 'Owner Statement was published',
    };

    return titles[props.type] || 'Email Template';
  }, [props.type]);

  const rich_text_extensions = useMemo<CustomRichTextExtension[]>(() => {
    if (props.type === 'ownerStatement_owner') {
      return ['user', 'link', 'date', 'listing'];
    }
    return ['user', 'link'];
  }, [props.type]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        pos="relative"
      >
        <Group justify="space-between" mb="xl">
          <Breadcrumbs
            crumbs={[{ href: '/settings/features/emails', title: 'Emails' }]}
            pageTitle={pageTitle}
          />
          <Group>
            <Button
              leftIcon={'EmailIcon'}
              type="button"
              loading={loadingTestEmail}
              onClick={sendTestEmail}
            >
              Send me test
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!methods.formState.isDirty}
              leftIcon={'CheckCircleIcon'}
              loading={methods.formState.isSubmitting}
            >
              Save
            </Button>
          </Group>
        </Group>

        <Box
          sx={{
            containerType: 'inline-size',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: rem(3),
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing.lg,
              alignItems: 'stretch',
              flex: 1,
              maxHeight: '1000px',
              '@container (max-width: 800px)': {
                flexDirection: 'column',
                maxHeight: 'unset',
              },
            })}
          >
            {/* Preview */}
            <PreviewCard {...props} />

            {/* Inputs */}
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.lg,
                width: '100%',
                flex: 0.5,
              })}
            >
              <Controller
                control={methods.control}
                name="subject"
                render={({ field }) => (
                  <InputWrapper label="Subject">
                    <Input {...field} placeholder={DEFAULT_TEMPLATE.subject} />
                  </InputWrapper>
                )}
              />
              <Controller
                control={methods.control}
                name="shortDescription"
                render={({ field, fieldState }) => (
                  <InputWrapper label="Short preview text">
                    <Textarea
                      {...field}
                      autosize
                      minRows={3}
                      placeholder={DEFAULT_TEMPLATE.shortDescription}
                      styles={(theme) => ({
                        input: {
                          '&:focus': {
                            boxShadow: `0px 0px 0px 2px ${
                              theme.colors[
                                fieldState.error ? 'red' : theme.primaryColor
                              ][4]
                            }40`,
                          },
                        },
                      })}
                    />
                  </InputWrapper>
                )}
              />

              <Divider my="md" />

              <Alert
                color="blue"
                styles={(theme) => ({
                  icon: {
                    marginRight: theme.spacing.xs,
                    paddingBottom: 2,
                  },
                })}
                icon={<Icon icon="InfoIcon" size={18} />}
              >
                <Text c="neutral">
                  Set custom theme and logo in{' '}
                  <Anchor component={Link} to="/settings/features/owner-portal">
                    Owner portal settings
                  </Anchor>
                  .
                </Text>
              </Alert>

              <Controller
                control={methods.control}
                name="header"
                render={({ field }) => (
                  <InputWrapper label="Header">
                    <Input {...field} placeholder={DEFAULT_TEMPLATE.header} />
                  </InputWrapper>
                )}
              />

              <Controller
                control={methods.control}
                name="bodyOne"
                render={({ field, fieldState }) => (
                  <InputWrapper
                    label={
                      <Text fw={500}>
                        Text{' '}
                        <Text inline c="gray" component="span">
                          (First part)
                        </Text>
                      </Text>
                    }
                  >
                    <EmailRichTextInput
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      extensions={rich_text_extensions}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                control={methods.control}
                name="buttonText"
                render={({ field }) => (
                  <InputWrapper label="Button">
                    <Input
                      {...field}
                      placeholder={DEFAULT_TEMPLATE.buttonText}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                control={methods.control}
                name="bodyTwo"
                render={({ field, fieldState }) => (
                  <InputWrapper
                    label={
                      <Text fw={500}>
                        Text{' '}
                        <Text inline c="gray" component="span">
                          (Second part)
                        </Text>
                      </Text>
                    }
                  >
                    <EmailRichTextInput
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      extensions={rich_text_extensions}
                    />
                  </InputWrapper>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};

const PreviewCard = ({ type, data }: Props) => {
  const methods = useActionEmailForm();
  const formData = methods.watch();
  const tenant = data.tenant;
  const user = data.user;

  const theme = useMantineTheme();

  const palette = useMemo(() => {
    const c =
      type === 'invite_owner' || type === 'ownerStatement_owner'
        ? getOwnerPortalColor(tenant.colorPrimary)
        : undefined;

    return theme.colors[c || theme.primaryColor];
  }, [tenant.colorPrimary, theme.colors, theme.primaryColor, type]);

  return (
    <Box
      sx={(theme) => ({
        paddingBlock: rem(52),
        paddingInline: theme.spacing.xs,
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[2]}`,
        boxShadow: theme.shadows.md,
        flex: 0.55,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      })}
    >
      {type === 'ownerStatement_owner' && (
        <OwnerStatementEmail
          mode="edit"
          tenant={{
            logo: tenant.logo || undefined,
            name: tenant.name,
          }}
          data={formData}
          themeColors={palette}
          user={user}
          listing={data.listing}
          month={data.month}
        />
      )}

      {type === 'invite_propertyManager' && (
        <InvitePmEmail
          mode="edit"
          tenant={{
            logo: tenant.logo || undefined,
            name: tenant.name,
          }}
          data={formData}
          themeColors={palette}
          user={user}
          listing={undefined}
          month={undefined}
        />
      )}
      {type === 'invite_owner' && (
        <InviteOwnerEmail
          mode="edit"
          tenant={{
            logo: tenant.logo || undefined,
            name: tenant.name,
          }}
          data={formData}
          themeColors={palette}
          user={user}
          listing={undefined}
          month={undefined}
        />
      )}
    </Box>
  );
};
