import {
  Input,
  InputAmount,
  InputDay,
  InputSelect,
} from '@finalytic/components';
import {
  captureSentryError,
  gqlV2,
  useAccountingConnection,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { getNamespaceAndType } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator, SelectItem } from '@finalytic/ui';
import { ValueOf, day, ensure, isUUID, toTitleCase } from '@finalytic/utils';
import {
  Box,
  Checkbox,
  Group,
  SegmentedControl,
  SegmentedControlItem,
  Text,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import {
  InputFormSchema,
  getSourceDescription,
  whereAutomations,
  whereConnectionStatusDefault,
} from '@vrplatform/ui-common';
import { useEffect, useId, useState } from 'react';

type Props = {
  automationId: string | null;
  leftConnectionId: string;
  rightConnectionId: string;
  settings: InputFormSchema;
  settingKey: string;
};

export const AutomationSettingsEditor = ({
  automationId,
  settingKey,
  settings,
  leftConnectionId,
  rightConnectionId,
}: Props) => {
  const [teamId] = useTeamId();
  const uuid = useId();

  const setting = Object.entries(settings || {}).find(
    ([key]) => key === settingKey
  );

  const {
    mutate,
    loading: loadingMutation,
    error: mutationError,
  } = useMutation(
    (
      q,
      {
        setting: { value, settingId },
        params,
      }: {
        setting: { settingId: string | null; value: string | null };
        params: {
          teamId: string;
          automationId: string | null;
          settingKey: string;
          leftConnectionId: string;
          rightConnectionId: string;
        };
      }
    ) => {
      if (!value) {
        // remove setting
        if (settingId) {
          return q.delete_setting_by_pk({
            id: settingId,
          })?.value;
        }
      } else {
        // update or insert setting
        if (settingId) {
          return q.update_setting_by_pk({
            pk_columns: {
              id: settingId,
            },
            _set: {
              value: value,
              rightConnectionId: params.rightConnectionId,
              leftConnectionId: params.leftConnectionId,
            },
          })?.value;
        } else {
          return q.insert_setting_one({
            object: {
              automationId: params.automationId,
              key: params.settingKey,
              target: '*',
              value: value,
              group: 'automationSetting',
              leftType: 'automationSetting',
              tenant_id: params.teamId,
              leftConnectionId: params.leftConnectionId,
              rightConnectionId: params.rightConnectionId,
            },
          })?.value;
        }
      }
    },
    {
      invalidateQueryKeys: [uuid],
    }
  );

  const {
    data,
    isLoading: loadingQuery,
    error: queryError,
  } = useQuery(
    (q, args) => {
      return (
        q
          .setting({
            where: {
              tenant_id: { _eq: args.teamId },
              // Global setting or
              automationId: !args.automationId
                ? {
                    _is_null: true,
                  }
                : { _eq: args.automationId },
              target: { _eq: '*' },
              leftType: { _eq: 'automationSetting' },
              key: { _eq: args.settingKey },
              // If global setting, we need to filter by connection
              leftConnectionId: !args.automationId
                ? { _eq: args.leftConnectionId }
                : undefined,
              rightConnectionId: !args.automationId
                ? { _eq: args.rightConnectionId }
                : undefined,
            },
            order_by: [{ created_at: 'desc' }],
          })
          .map((setting) => ({
            id: setting.id,
            group: setting.group,
            key: setting.key,
            value: setting.value,
            target: setting.target,
          }))[0] || null
      );
    },
    {
      queryKey: [uuid, 'settings'],
      skip: !teamId || !settingKey,
      variables: {
        teamId,
        automationId,
        settingKey,
        leftConnectionId,
        rightConnectionId,
      },
    }
  );

  const value = data?.value || null;

  const loading = loadingQuery || loadingMutation;

  const setValue = async (newValue: string | null) => {
    if (newValue === value) return;

    await mutate({
      args: {
        setting: {
          settingId: data?.id || null,
          value: newValue,
        },
        params: {
          automationId,
          settingKey,
          teamId,
          leftConnectionId,
          rightConnectionId,
        },
      },
    });
  };

  if (!settingKey || !setting) return null;

  return (
    <Box>
      <ChooseEditor
        input={{
          loading,
          setValue,
          value,
        }}
        setting={setting}
        automationId={automationId}
        error={mutationError || queryError}
      />
      {queryError && (
        <Group gap={5} mt={2} wrap="nowrap">
          <Icon
            icon="AlertTriangleIcon"
            size={12}
            color={({ colors }) => colors.red[6]}
          />
          <Text color="red" size="xs">
            {queryError.message}
          </Text>
        </Group>
      )}
      {mutationError && (
        <Group gap={5} mt={2} wrap="nowrap">
          <Icon
            icon="AlertTriangleIcon"
            size={12}
            color={({ colors }) => colors.red[6]}
          />
          <Text color="red" size="xs">
            Failed to update: {mutationError.message}
          </Text>
        </Group>
      )}
    </Box>
  );
};

type ChooseEditorProps = {
  setting: [string, ValueOf<InputFormSchema>];
  input: {
    setValue: (v: string | null) => void;
    value: string | null;
    loading: boolean;
  };
  automationId: string | null;
  error: Error | null;
};

const ChooseEditor = (props: ChooseEditorProps) => {
  const [, setting] = props.setting;

  if (setting.type === 'day') {
    return <SelectDay {...props} />;
  }

  if (setting.type === 'select') {
    return <SelectOptions {...props} />;
  }

  if (setting.type === 'segmented-control') {
    return <InputSegmentedControl {...props} />;
  }

  if (setting.type === 'select-data') {
    return <SelectSource {...props} />;
  }

  if (setting.type === 'text') {
    return <InputText {...props} />;
  }

  if (setting.type === 'number') {
    return <InputNumber {...props} />;
  }

  if (setting.type === 'boolean') {
    return <InputBoolean {...props} />;
  }

  captureSentryError(new Error(`Missing setting editor type: ${setting.type}`));
  console.log(setting.type);

  return null;
};

const InputSegmentedControl = ({
  input: { setValue, value, loading },
  setting: [, setting],
  error,
}: ChooseEditorProps) => {
  const options = (
    ((setting as any)?.options || []) as string[]
  ).map<SegmentedControlItem>((option) => {
    return {
      label: toTitleCase(option)!,
      value: option,
    };
  });

  return (
    <Group w="100%">
      <SegmentedControl
        data={options}
        value={value || setting.defaultValue || undefined}
        onChange={(value) => {
          setValue(value || null);
        }}
        disabled={!!error || loading}
        fullWidth
        radius="md"
        styles={{
          root: {
            flex: 1,
          },
        }}
      />
      <LoadingIndicator
        size="xs"
        sx={{
          opacity: loading ? 1 : 0,
          transition: 'opacity 200ms ease',
        }}
      />
    </Group>
  );
};

const InputText = ({
  input,
  setting: [, setting],
  error,
}: ChooseEditorProps) => {
  const [value, setValue] = useDebouncedState<string | undefined>(
    undefined,
    500
  );

  useEffect(() => {
    if (input?.value) {
      setValue(input.value);
    } else {
      setValue(undefined);
    }
  }, [input?.value]);

  return (
    <Input
      value={value || ''}
      type="text"
      setValue={(newValue) => setValue(newValue)}
      placeholder={setting.defaultValue || setting.placeholder}
      loadingMutation={input.loading}
      disabled={setting.disabled || !!error}
      onBlur={(event) => {
        const newValue = event.target.value.trim();

        const oldSetting = (input?.value || '').trim();

        if (oldSetting !== newValue) {
          input.setValue(newValue);
        }
      }}
    />
  );
};

const InputBoolean = ({
  input,
  setting: [, setting],
  error,
}: ChooseEditorProps) => {
  const [value, setValue] = useDebouncedState<'true' | 'false' | undefined>(
    undefined,
    500
  );

  useEffect(() => {
    if (input?.value) {
      setValue(input.value as 'true' | 'false');
    } else {
      setValue(undefined);
    }
  }, [input?.value]);

  return (
    <Checkbox
      checked={value ? value === 'true' : setting.defaultValue === 'true'}
      onChange={(event) => {
        const newValue = event.target.checked.toString();
        input.setValue(newValue);
      }}
      disabled={setting.disabled || !!error || input.loading}
      label={setting.description || setting.label || ''}
      size="xs"
      sx={{ input: { borderColor: '#CED0D780' } }}
    />
  );
};

const InputNumber = ({
  input,
  setting: [, setting],
  error,
}: ChooseEditorProps) => {
  const initial = Number(input.value || '');

  const [value, setValue] = useDebouncedState<number | string>('', 500);

  useEffect(() => {
    if (typeof initial === 'number') {
      setValue(initial);
    } else {
      setValue('');
    }
  }, [initial]);

  return (
    <InputAmount
      value={value ?? ''}
      setValue={(newValue) => setValue(newValue)}
      placeholder={setting.defaultValue || setting.placeholder}
      loadingMutation={input.loading}
      disabled={setting.disabled || !!error || input.loading}
      onBlur={(event) => {
        const newValue = event.target.value.trim();
        const oldSetting = (input?.value || '').trim();

        if (oldSetting !== newValue) {
          input.setValue(newValue);
        }
      }}
    />
  );
};

const SelectDay = ({
  input: { loading, setValue, value },
  setting: [, setting],
  error,
}: ChooseEditorProps) => {
  const val = value ? day(value).toDate() : null;

  return (
    <InputDay
      clearable={setting.optional}
      placeholder={setting.placeholder || 'Select date'}
      value={val}
      onChange={(v) => {
        const newValue = v ? day(v).format('YYYY-MM-DD') : null;

        if (newValue === value) return;

        setValue(newValue);
      }}
      rightSection={loading && <LoadingIndicator size={'xs'} />}
      disabled={setting.disabled || !!error || loading}
      popoverProps={{
        withinPortal: true,
      }}
    />
  );
};

const SelectOptions = ({
  input: { loading, setValue, value },
  setting: [settingKey, setting],
  error,
}: ChooseEditorProps) => {
  const isCurrencyInput = settingKey === 'currency';

  const options = (
    ((setting as any)?.options || []) as string[]
  ).map<SelectItem>((option) => {
    return {
      label: isCurrencyInput ? option.toUpperCase() : toTitleCase(option)!,
      value: option,
    };
  });

  const formatted: SelectItem | null = value
    ? {
        label: isCurrencyInput ? value.toUpperCase() : toTitleCase(value)!,
        value: value,
      }
    : null;

  const placeholder = setting.placeholder || 'Select option';

  return (
    <InputSelect
      data={{
        options,
      }}
      value={formatted}
      setValue={(value) => {
        setValue(value?.value || null);
      }}
      type="single"
      dropdownProps={{
        width: 'target',
      }}
      inputProps={{
        placeholder: value
          ? placeholder
          : toTitleCase(setting.defaultValue) || placeholder,
        loadingMutation: loading,
        disabled: setting.disabled || !!error,
      }}
    />
  );
};

const SelectSource = ({
  input,
  setting: [, setting],
  automationId,
  error,
}: ChooseEditorProps) => {
  if (setting.type !== 'select-data') return null;

  const { value: sourceId, setValue } = input;

  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');
  const { accounting } = useAccountingConnection();
  const accountingConnectionId = accounting?.id;

  const isSelectAutomation = setting.sourceType === 'automation';

  const queryData = useInfiniteQuery(
    (
      q,
      {
        sourceType,
        teamId,
        search,
        connectionId,
        automationId,
        accountingConnectionId,
        isSelectAutomation,
      },
      { limit, offset }
    ) => {
      if (isSelectAutomation) {
        const where: gqlV2.automation_bool_exp = {
          ...whereAutomations({
            isPartnerAdmin: false,
            isSuperAdmin: false,
            isVrpAdmin: false,
            tenantId: teamId,
            search,
            accountingConnectionId,
          }),
          id:
            automationId && isUUID(automationId)
              ? { _neq: automationId }
              : undefined,
          ttemplate: {
            uniqueRef: {
              _eq: setting.params?.uniqueRef,
            },
          },
        };

        const list = q
          .automations({
            where,
            limit,
            offset,
            order_by: [
              {
                title: 'asc_nulls_last',
              },
            ],
          })
          .map<SelectItem>((automation) => ({
            value: automation.id,
            label: automation.title || automation.ttemplate?.label || '',
          }));

        const aggregate =
          q.automationAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const [namespace, name] = getNamespaceAndType(sourceType);

      const where: gqlV2.source_bool_exp = {
        type: { _eq: name },
        connectionId: connectionId ? { _eq: connectionId } : undefined,
        connection:
          namespace && !connectionId
            ? {
                appId: { _eq: namespace },
                status: whereConnectionStatusDefault,
              }
            : !connectionId
              ? { status: whereConnectionStatusDefault }
              : undefined,
        tenantId: { _eq: teamId },
        status: { _eq: 'active' },
        _or: search
          ? [
              {
                description: { _ilike: `%${search.trim()}%` },
              },
              {
                remoteId: { _ilike: `%${search.trim()}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .source({
          where,
          order_by: [{ description: 'asc' }],
          limit,
          offset,
        })
        .map<SelectItem>((source) => ({
          value: source.id,
          label: getSourceDescription(source),
        }));

      const aggregate =
        q
          .sourceAggregate({
            where,
          })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        sourceType: setting.sourceType,
        isSelectAutomation,
        teamId,
        connectionId: setting.connectionId,
        search,
        automationId,
        accountingConnectionId,
        automationTemplateUniqueRef: setting.params?.uniqueRef,
      },
      skip: !teamId || !setting.sourceType,
      queryKey: ['sources'],
    }
  );

  const {
    data: data2,
    isLoading: loadingValue,
    error: valueError,
  } = useQuery(
    (q, { sourceId, isSelectAutomation }) => {
      if (isSelectAutomation) {
        if (!sourceId) return null;
        const automation = q.automation({ id: sourceId });
        if (!automation) return null;
        return ensure<SelectItem>({
          value: automation?.id,
          label: automation?.title || automation?.ttemplate?.label || '',
        });
      }

      if (!sourceId) return null;

      const source = q.sourceById({
        id: sourceId,
      });

      if (!source) return null;

      return ensure<SelectItem>({
        value: source?.id,
        label: getSourceDescription(source),
      });
    },
    {
      variables: {
        sourceId,
        isSelectAutomation,
      },
      skip: !sourceId,
      queryKey: 'sources',
    }
  );

  const placeholder =
    setting.placeholder ||
    (isSelectAutomation ? 'Select automation' : 'Select option');

  return (
    <InputSelect
      infiniteData={{ ...queryData, setSearch }}
      value={data2 || null}
      setValue={(value) => {
        setValue(value?.value || null);
      }}
      type="single"
      dropdownProps={{
        width: 'target',
      }}
      inputProps={{
        placeholder: sourceId
          ? placeholder
          : toTitleCase(setting.defaultValue) || placeholder,
        loadingMutation: input.loading,
        withClearButton: true,
        loadingQuery: loadingValue,
        disabled:
          setting.disabled || !!error || !!valueError || !!queryData.error,
      }}
    />
  );
};
