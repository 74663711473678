import { useTeamId, useTrpcQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { Box, Center, Group, Text } from '@mantine/core';
import { SettingsTitle } from '../_components';
import {
  BillingCard,
  BillingPaymentMethodCard,
  BillingPlanCard,
} from './BillingCards';
import { InvoicesTable } from './InvoicesTable';

export const SettingsTeamBillingView = () => {
  return (
    <>
      <SettingsTitle type="view-title">Billing</SettingsTitle>
      <Billing />
    </>
  );
};

const Billing = () => {
  const [teamId] = useTeamId();
  const { data, isInitialLoading, loading, refetch } = useTrpcQuery(
    'getBillingInfo',
    {
      tenantId: teamId,
    },
    {
      skip: !teamId,
      queryKey: ['billing'],
    }
  );

  if (isInitialLoading) {
    return <LoadingIndicator isFullPageLoading />;
  }

  if (!data?.customer) {
    return (
      <Center
        sx={{
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Icon icon="BillingIcon" size={40} color="gray" strokeWidth={1} />
        <Text size="sm" c="gray" mt="lg" fw={500}>
          There was an error loading your billing information.
        </Text>
      </Center>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        containerType: 'inline-size',
      }}
    >
      <Group
        mt="sm"
        mb="md"
        sx={{
          '> *': { flex: 1, width: '100%' },
          alignItems: 'stretch',
          '@container (max-width: 700px)': {
            flexDirection: 'column',
          },
        }}
      >
        <BillingPlanCard
          subscription={data.subscription}
          customer={data.customer}
          loadingQuery={loading}
          refreshView={() => refetch()}
        />

        <BillingPaymentMethodCard
          paymentMethod={data.customer.current_payment_method}
          portalUrl={data.portal}
        />
      </Group>

      <Group
        sx={{
          alignItems: 'flex-start',
          '@container (max-width: 700px)': {
            flexDirection: 'column',
          },
        }}
      >
        {/* <BillingCard
          px={0}
          pb={0}
          sx={{
            flex: 1,
            height: '100%',
          }}
        >
          <SettingsTitle type="heading" size={'1.25rem'} mb="sm" px="sm">
            Billing address
          </SettingsTitle>
          <CustomerBillingInfoForm
            options={{
              token: data.componentToken,
              mode: data.isProduction ? 'production' : 'sandbox',
            }}

            style={{
              padding: '1rem',
            }}
          />
        </BillingCard> */}
        <BillingCard
          px={0}
          pb={0}
          sx={{
            flex: 1,
          }}
        >
          <SettingsTitle type="heading" size={'1.25rem'} mb="sm" px="sm">
            Billing history
          </SettingsTitle>
          <InvoicesTable />
        </BillingCard>
      </Group>
    </Box>
  );
};
