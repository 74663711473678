import { useApiClient, useInvalidateQueries } from '@finalytic/data';
import {
  DeleteModal,
  showErrorNotification,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useId } from 'react';
import { TaxRateModal } from './TaxRateModal';
import { TaxRateRow } from './useTaxRatesTableQuery';

export const TaxRateEllipsisMenuModals = ({
  data,
  deleteModal,
  editModal,
}: {
  data: Maybe<TaxRateRow>;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  editModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const notifyId = useId();
  const $api = useApiClient();
  const invalidate = useInvalidateQueries(['taxRates']);

  const id = data?.id;
  const taxRateTitle = data?.title;

  const handleDeletion = async () => {
    if (!id)
      return showErrorNotification({
        title: 'Missing tax rate',
        message: 'Tax Rate is missing. If the issue persists, contact support.',
      });

    try {
      showLoadingNotification({
        id: notifyId,
      });
      const res = await $api.DELETE('/rates/{id}', {
        params: {
          path: {
            id,
          },
        },
      });

      if (res.error) throw new Error(res.error.message);

      updateSuccessNotification({
        id: notifyId,
        title: 'Success!',
        message: `Tax rate was ${res.data?.status} successfully`,
      });
      invalidate();
    } catch (error: any) {
      const message =
        error?.message ||
        'Failed to delete tax rate. Please try again later and if the problem persists, contact support.';

      updateErrorNotification({
        title: 'Failed to delete tax rate',
        id: notifyId,
        message,
      });
    }
  };

  return (
    <>
      <DeleteModal
        opened={deleteModal.opened}
        onClose={deleteModal.closeModal}
        onSubmit={handleDeletion}
        title={`Are you sure you want to delete this tax rate: ${taxRateTitle}?`}
        subtitle="All connected data will be lost."
      />
      <TaxRateModal
        opened={editModal.opened}
        closeModal={editModal.closeModal}
        taxRate={data}
      />
    </>
  );
};
