import type {
  address_select_column,
  listing_owner_select_column,
  listing_select_column,
  owner_statement_select_column,
} from '@finalytic/graphql';
import { type Maybe, toCamelCase, toTitleCase, utc } from '@finalytic/utils';
import { Text } from '@mantine/core';
import type { ReactNode } from 'react';
import {
  ActivityDeletedItemBadge,
  ActivityDeltaComparison,
  type ActivityDeltaJson,
  type ActivityOperation,
  type ActivityTable,
} from '../../../drawers';

export const LISTING_ACTIVITY_TABLES: ActivityTable[] = [
  'listing',
  'address',
  // 'owner',
  'listing_owner',
];

export const getListingActivityDetails = (
  audit_log: {
    tableName: ActivityTable;
    id: string;
    op: ActivityOperation;
    date: string;
    delta: ActivityDeltaJson;
    objectId: string;
  },
  listing: {
    name: Maybe<string>;
    id: string;
  },
  listingCollection: {
    id: Maybe<string>;
    name: Maybe<string>;
  },
  owner: {
    id: Maybe<string>;
    name: Maybe<string>;
  },
  collections: { id: string; name: string }[]
): { label: ReactNode; date: string; updateKey?: string }[] => {
  const date = audit_log.date;

  const formatValue = (v: string | number | undefined, key: string) => {
    if (v === undefined || v === null) return undefined;
    if (key === 'collection_id') {
      if (!listingCollection.id) return null;

      return (
        collections.find((c) => c.id === (v as string))?.name || (
          <ActivityDeletedItemBadge
            objectId={listingCollection.id}
            type={'listing_collection'}
          />
        )
      );
    }
    if (typeof v === 'string') return toTitleCase(v);

    if (key === 'split') return `${v}%`;
    if (key === 'start_at') return utc(v).format('MMMM YYYY');

    return v.toString();
  };

  const action: Record<ActivityOperation, string> = {
    update: 'Updated ',
    delete: 'Deleted ',
    insert: 'Created ',
  };

  const ListingBadge = () =>
    listing.name ? (
      <Text component="span" color="black" fw={500}>
        {listing.name}
      </Text>
    ) : (
      <ActivityDeletedItemBadge objectId={listing.id} type={'listing'} />
    );

  const OwnerBadge = () =>
    owner.name ? (
      <Text component="span" color="black" fw={500}>
        {owner.name}
      </Text>
    ) : (
      owner.id && (
        <ActivityDeletedItemBadge objectId={owner.id} type={'owner'} />
      )
    );

  if (audit_log.op === 'delete' || audit_log.op === 'insert') {
    if (audit_log.tableName === 'address') {
      const label = (
        <Text c="gray">
          {action[audit_log.op]}{' '}
          <ActivityDeletedItemBadge
            type="address"
            objectId={audit_log.objectId}
          />
        </Text>
      );

      return [{ label, date }];
    }

    if (audit_log.tableName === 'owner') {
      const label = (
        <Text c="gray">
          {action[audit_log.op]} <ListingBadge />
        </Text>
      );

      return [{ label, date }];
    }

    if (audit_log.tableName === 'listing_owner') {
      if (audit_log.op === 'delete')
        return [
          {
            date,
            label: (
              <Text c="gray">
                Removed owner <OwnerBadge />
              </Text>
            ),
          },
        ];

      return [
        {
          date,
          label: (
            <Text c="gray">
              Added owner <OwnerBadge />
            </Text>
          ),
        },
      ];
    }

    return [];
  }

  const formatDelta = (update: ActivityDeltaJson[number]) => {
    let key: ReactNode | undefined = undefined;

    if (audit_log.tableName === 'listing') {
      const updateKey = toCamelCase(update.key) as listing_select_column;

      const keys: Partial<{
        [k in listing_select_column]: string;
      }> = {
        name: 'Name',
        title: 'Name',
        calculated_title: 'Name',
        status: 'Status',
        pmsStatus: 'PMS Status',
        collectionId: 'Listing Group',
        address: 'Address',
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'listing_owner') {
      const updateKey = toCamelCase(update.key) as listing_owner_select_column;

      const keys: Partial<{
        [k in listing_owner_select_column]: ReactNode;
      }> = {
        split: (
          <>
            <OwnerBadge /> split
          </>
        ),
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'owner_statement') {
      const updateKey = toCamelCase(
        update.key
      ) as owner_statement_select_column;

      const keys: Partial<{
        [k in owner_statement_select_column]: string;
      }> = {
        centBalanceStart: 'Starting Balance',
        centRentalRevenue: 'Net Income',
        centTotal: 'Current Balance',
        centPayedOut: 'Owner Payout',
        centBalanceEnd: 'Ending Balance',
        status: 'Status',
        currency: 'Currency',
        startAt: 'Month',
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'address') {
      if (update.key !== 'full') return null;

      const updateKey = toCamelCase(update.key) as address_select_column;

      const keys: Partial<{
        [k in address_select_column]: ReactNode;
      }> = {
        full: 'Address',
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (!key) return null;

    return (
      <ActivityDeltaComparison
        type={key}
        value={formatValue(update.value, update.key)!}
        oldValue={formatValue(update.oldValue, update.key)}
      />
    );
  };

  return audit_log.delta.map((update) => {
    return {
      date,
      label: formatDelta(update),
      updateKey: update.key,
    };
  });
};
