import { useQuery, useTeamId } from '@finalytic/data';

export function useTaxRateAccountAssignmentQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return (
        q
          .accounts({
            where: {
              tenantId: { _eq: args.teamId },
              status: { _eq: 'active' },
              assignments: {
                tenantId: { _eq: args.teamId },
                type: { _eq: 'expense_salesTax' },
              },
            },
          })
          .map((account) => ({
            id: account.id,
            title: account.title,
          }))[0] ?? null
      );
    },
    {
      variables: {
        teamId,
      },
      queryKey: ['taxRates'],
      keepPreviousData: true,
    }
  );
}
