import { Button } from '@finalytic/components';
import { useTeam, useTrpcQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { Box, Center, Text, Title } from '@mantine/core';
import { SettingsTitle } from './_components';

export const SettingsIntegrationWebhooksView = () => {
  const [{ id: tenantId, name: tenantName }] = useTeam();

  const { data, loading, refetch } = useTrpcQuery('svixDashboardUrl', {
    tenantId,
    tenantName,
  });

  if (loading) {
    return (
      <>
        <SettingsTitle type="view-title">Webhooks</SettingsTitle>
        <LoadingIndicator isFullPageLoading />
      </>
    );
  }

  if (!data?.url) {
    return (
      <>
        <SettingsTitle type="view-title">Webhooks</SettingsTitle>
        <Center
          h={'70vh'}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Icon
            icon="AlertTriangleIcon"
            color={(theme) => theme.colors.red[6]}
          />
          <Box my="xl">
            <Title order={3} sx={{ textAlign: 'center' }}>
              Error fetching webhooks
            </Title>
            <Text
              mt="sm"
              sx={{
                textAlign: 'center',
              }}
              color="gray"
            >
              There was an error fetching the team's webhooks
            </Text>
          </Box>
          <Button
            onClick={() => refetch()}
            sx={{
              width: 200,
            }}
          >
            Try again
          </Button>
        </Center>
      </>
    );
  }

  return (
    <>
      <SettingsTitle type="view-title">Webhooks</SettingsTitle>
      <iframe
        src={data.url}
        title="Webhooks"
        style={{
          border: 'none',
          width: '100%',
          height: 'calc(100vh - 100px)',
        }}
        allow="clipboard-write"
        loading="lazy"
      />
    </>
  );
};
