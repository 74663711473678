import { Icon } from '@finalytic/icons';
import { Tooltip, rem } from '@mantine/core';
import { Link, RichTextEditor, RichTextEditorControl } from '@mantine/tiptap';
import { Node, mergeAttributes } from '@tiptap/core';
import { useEditor } from '@tiptap/react';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  EMAIL_ACTION_LINK_COMPONENT,
  EMAIL_DATE_COMPONENT,
  EMAIL_LISTING_COMPONENT,
  EMAIL_USER_NAME_COMPONENT,
} from '@vrplatform/emails';

import '@mantine/tiptap/styles.css';

export type CustomRichTextExtension = 'link' | 'user' | 'listing' | 'date';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error: boolean;
  extensions: CustomRichTextExtension[];
};

export const EmailRichTextInput = ({
  value: content,
  onChange,
  error,
  extensions,
}: Props) => {
  const editor = useEditor({
    extensions: [StarterKit, Link, SignInLink, UserName, ListingName, Date],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  return (
    <RichTextEditor
      editor={editor}
      sx={(theme) => ({
        fontSize: theme.fontSizes.sm,
        borderRadius: `${theme.radius.md} !important`,
        '.mantine-RichTextEditor-toolbar': {
          paddingInline: rem(8),
          paddingBlock: rem(10),
          borderTopLeftRadius: theme.radius.md,
          borderTopRightRadius: theme.radius.md,
        },
        '.mantine-RichTextEditor-content': {
          // borderRadius: `0 0 ${theme.radius.md} ${theme.radius.md} !important`,
          borderBottomLeftRadius: theme.radius.md,
          borderBottomRightRadius: theme.radius.md,
        },
        ':focus, :focus-within': {
          border: `1px solid ${theme.colors[error ? 'red' : theme.primaryColor][4]}`,
          boxShadow: `0px 0px 0px 2px ${
            theme.colors[error ? 'red' : theme.primaryColor][4]
          }40`,
        },
      })}
    >
      <RichTextEditor.Toolbar>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          {extensions.includes('user') && (
            <Tooltip label="User">
              <RichTextEditorControl
                onClick={() => {
                  editor?.commands.insertContent(EMAIL_USER_NAME_COMPONENT);
                }}
              >
                <Icon icon="UserIcon" size={12} color="#000" />
              </RichTextEditorControl>
            </Tooltip>
          )}

          {extensions.includes('listing') && (
            <Tooltip label="Listing">
              <RichTextEditorControl
                onClick={() => {
                  editor?.commands.insertContent(EMAIL_LISTING_COMPONENT);
                }}
              >
                <Icon icon="HomeIcon" size={12} color="#000" />
              </RichTextEditorControl>
            </Tooltip>
          )}

          {extensions.includes('date') && (
            <Tooltip label="Date">
              <RichTextEditorControl
                onClick={() => {
                  editor?.commands.insertContent(EMAIL_DATE_COMPONENT);
                }}
              >
                <Icon icon="CalendarEventIcon" size={12} color="#000" />
              </RichTextEditorControl>
            </Tooltip>
          )}

          {extensions.includes('link') && (
            <Tooltip label="Link">
              <RichTextEditorControl
                onClick={() => {
                  editor?.commands.insertContent(EMAIL_ACTION_LINK_COMPONENT);
                }}
              >
                <Icon icon="Link2Icon" size={12} color="#000" />
              </RichTextEditorControl>
            </Tooltip>
          )}
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

const SignInLink = Node.create({
  name: 'signInLink',
  group: 'inline',
  inline: true,
  parseHTML() {
    return [
      {
        tag: 'action-link-component',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['action-link-component', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(SignInLinkComponent);
  },
});

const SignInLinkComponent = () => {
  return (
    <NodeViewWrapper as="span">
      <span
        style={{
          color: '#fff',
          display: 'inline',
          backgroundColor: '#025FCA',
          fontSize: '12px',
          borderRadius: '6px',
          padding: '2px 4px',
        }}
      >
        Link
      </span>
    </NodeViewWrapper>
  );
};

const UserName = Node.create({
  name: 'userName',
  group: 'inline',
  inline: true,
  parseHTML() {
    return [
      {
        tag: 'user-name-component',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['user-name-component', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(UserNameComponent);
  },
});

const UserNameComponent = () => {
  return (
    <NodeViewWrapper as="span">
      <span
        style={{
          color: '#fff',
          display: 'inline',
          backgroundColor: '#025FCA',
          fontSize: '12px',
          borderRadius: '6px',
          padding: '2px 4px',
        }}
      >
        User
      </span>
    </NodeViewWrapper>
  );
};

const ListingName = Node.create({
  name: 'listingName',
  group: 'inline',
  inline: true,
  parseHTML() {
    return [
      {
        tag: 'listing-name-component',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['listing-name-component', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ListingNameComponent);
  },
});

const ListingNameComponent = () => {
  return (
    <NodeViewWrapper as="span">
      <span
        style={{
          color: '#fff',
          display: 'inline',
          backgroundColor: '#025FCA',
          fontSize: '12px',
          borderRadius: '6px',
          padding: '2px 4px',
        }}
      >
        Listing
      </span>
    </NodeViewWrapper>
  );
};

const Date = Node.create({
  name: 'Date',
  group: 'inline',
  inline: true,
  parseHTML() {
    return [
      {
        tag: 'date-component',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['date-component', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(DateComponent);
  },
});

const DateComponent = () => {
  return (
    <NodeViewWrapper as="span">
      <span
        style={{
          color: '#fff',
          display: 'inline',
          backgroundColor: '#025FCA',
          fontSize: '12px',
          borderRadius: '6px',
          padding: '2px 4px',
        }}
      >
        Date
      </span>
    </NodeViewWrapper>
  );
};
