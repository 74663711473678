import { Button } from '@finalytic/components';
import { Group, Text, useMantineColorScheme } from '@mantine/core';
import { forwardRef } from 'react';
import { TablePagination } from './LazyTable.types';

export const LazyTablePagination = forwardRef<
  HTMLDivElement,
  {
    pagination: TablePagination;
    rowCount: number;
  }
>(({ pagination: data, rowCount }, ref) => {
  const { colorScheme } = useMantineColorScheme();
  const isDarkTheme = colorScheme === 'dark';

  const { pagination } = data;

  const currentPageStart = pagination.pageIndex * pagination.pageSize;
  const currentPageEnd = currentPageStart + pagination.pageSize;

  const pageEnd = currentPageEnd > rowCount ? rowCount : currentPageEnd;
  const hasNextPage = pageEnd < rowCount;
  const hasPreviousPage = pagination.pageIndex > 0;

  const handleNextPage = () => {
    if (hasNextPage && data.setPagination) {
      data?.setPagination({
        ...pagination,
        pageIndex: pagination.pageIndex + 1,
      });
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage && data.setPagination) {
      data?.setPagination({
        ...pagination,
        pageIndex: pagination.pageIndex - 1,
      });
    }
  };

  if (!data?.pagination) return null;

  return (
    <Group justify="space-between" mt="md" ref={ref}>
      <Text color={isDarkTheme ? undefined : 'gray'} size="xs">
        {(rowCount ? currentPageStart : -1) + 1} to {pageEnd} from {rowCount}{' '}
        results
      </Text>
      {!!data.setPagination && (
        <Group>
          <Button
            color={isDarkTheme ? undefined : 'neutral'}
            disabled={!hasPreviousPage}
            onClick={handlePreviousPage}
            variant="transparent"
          >
            Prev
          </Button>
          <Button
            color={isDarkTheme ? undefined : 'neutral'}
            variant="transparent"
            disabled={!hasNextPage}
            onClick={handleNextPage}
          >
            Next
          </Button>
        </Group>
      )}
    </Group>
  );
});
