import { Button } from '@finalytic/components';
import { CrossIcon, RefreshCwIcon, SlidersHorizIcon } from '@finalytic/icons';
import {
  EllipsisMenu,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  EllipsisMenuLabel,
  IconButton,
} from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import {
  Chip,
  Group,
  NativeSelect as MNativeSelect,
  Stack,
  Text,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  MRT_ColumnDef,
  MRT_DensityState,
  MRT_TableInstance,
} from 'mantine-react-table';
import { forwardRef } from 'react';
import {
  TableBasicRow,
  TablePagination,
  TableSortOptions,
} from './LazyTable.types';
import { TableKey, useSavedTableSettings } from './useSavedTableSettings';

export const LazyTablePagination = forwardRef<
  HTMLDivElement,
  {
    pagination: TablePagination;
    rowCount: number;
  }
>(({ pagination: data, rowCount }, ref) => {
  const { colorScheme } = useMantineColorScheme();
  const isDarkTheme = colorScheme === 'dark';

  const { pagination } = data;

  const currentPageStart = pagination.pageIndex * pagination.pageSize;
  const currentPageEnd = currentPageStart + pagination.pageSize;

  const pageEnd = currentPageEnd > rowCount ? rowCount : currentPageEnd;
  const hasNextPage = pageEnd < rowCount;
  const hasPreviousPage = pagination.pageIndex > 0;

  const handleNextPage = () => {
    if (hasNextPage && data.setPagination) {
      data?.setPagination({
        ...pagination,
        pageIndex: pagination.pageIndex + 1,
      });
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage && data.setPagination) {
      data?.setPagination({
        ...pagination,
        pageIndex: pagination.pageIndex - 1,
      });
    }
  };

  if (!data?.pagination) return null;

  return (
    <Group justify="space-between" mt="md" ref={ref}>
      <Text color={isDarkTheme ? undefined : 'gray'} size="xs">
        {(rowCount ? currentPageStart : -1) + 1} to {pageEnd} from {rowCount}{' '}
        results
      </Text>
      <Group>
        <Button
          color={isDarkTheme ? undefined : 'neutral'}
          disabled={!hasPreviousPage}
          onClick={handlePreviousPage}
          variant="transparent"
        >
          Prev
        </Button>
        <Button
          color={isDarkTheme ? undefined : 'neutral'}
          variant="transparent"
          disabled={!hasNextPage}
          onClick={handleNextPage}
        >
          Next
        </Button>
      </Group>
    </Group>
  );
});

export const LazyTableSetting: <TRow extends TableBasicRow>(props: {
  table: MRT_TableInstance<TRow>;
  columnDefs: MRT_ColumnDef<TRow>[];
  tableKey: TableKey | undefined;
  canGroup: boolean;
  resetFilter?: () => void;
  sortOptions?: TableSortOptions;
}) => React.ReactNode = (props) => {
  const { colors } = useMantineTheme();

  const tableState = props.table.getState();
  const { resetTableSettings, setTableSettings } = useSavedTableSettings();

  const columns = props.columnDefs;
  const sortableColumns = columns.filter((c) => c.enableSorting);
  const groupableColumns = columns.filter((c) => c.enableGrouping);

  // const isFullScreen = tableState.isFullScreen;
  const density = tableState.density;
  const groupBy = tableState.grouping[0];
  const sortedBy = tableState.sorting[0]?.id;
  const dir = tableState.sorting[0]?.desc ? 'desc' : 'asc';

  const canSort = sortableColumns.length > 1 || !!props.sortOptions?.length;
  const sortOptions =
    props.sortOptions ||
    sortableColumns.map((column) => ({
      label: column.header,
      value: column.id || column.accessorKey || column.header,
    }));

  // const toggleFullScreen = () => {
  //   const isFullScreen = props.table.getState().isFullScreen;
  //   props.table.setIsFullScreen(!isFullScreen);
  // };

  const resetSettings = () => {
    if (props.tableKey) resetTableSettings(props.tableKey);
    props.table.reset();
    props.table.resetGrouping();
    props.table.resetSorting();
  };

  return (
    <EllipsisMenu
      closeOnItemClick={false}
      width={250}
      target={
        <IconButton>
          <SlidersHorizIcon color={colors.neutral[5]} size={20} />
        </IconButton>
      }
    >
      <Stack mx={10} py={5} gap={10}>
        {canSort && (
          <>
            <NativeSelect
              label="Sort by"
              value={sortedBy}
              onChange={(value) =>
                props.table.setSorting([
                  { id: value as string, desc: dir === 'desc' },
                ])
              }
              data={sortOptions}
            />
            <NativeSelect
              label="Sorting"
              value={dir}
              onChange={(value) =>
                props.table.setSorting([
                  { id: sortedBy, desc: value === 'desc' },
                ])
              }
              data={[
                {
                  label: 'Ascending',
                  value: 'asc',
                },
                {
                  label: 'Descending',
                  value: 'desc',
                },
              ]}
            />
          </>
        )}
        {props.canGroup && (
          <NativeSelect
            label="Group by"
            value={groupBy}
            onChange={(value) => {
              if (value === '-') {
                props.table.setGrouping([]);
                if (props.tableKey) {
                  setTableSettings(props.tableKey, {
                    groupBy: [],
                  });
                }
              } else {
                props.table.setGrouping([value]);
                if (props.tableKey) {
                  setTableSettings(props.tableKey, {
                    groupBy: [value],
                  });
                }
              }
            }}
            data={[
              { value: '-', label: '-' },
              ...groupableColumns.map((column) => ({
                label: column.header,
                value: column.id || column.accessorKey || column.header,
              })),
            ]}
          />
        )}
        <NativeSelect
          value={density}
          label="Density"
          onChange={(value) =>
            props.table.setDensity(value as MRT_DensityState)
          }
          data={ensure<{ label: string; value: MRT_DensityState }[]>([
            {
              label: 'Compact',
              value: 'xs',
            },
            {
              label: 'Normal',
              value: 'md',
            },
            {
              label: 'Wide',
              value: 'xl',
            },
          ])}
        />
      </Stack>

      {/* <EllipsisMenuDivider />

      <EllipsisMenuItem
        onClick={toggleFullScreen}
        customIcon={
          isFullScreen ? (
            <MinimizeIcon size={16} color={colors.neutral[5]} />
          ) : (
            <Maximize3Icon size={16} color={colors.neutral[5]} />
          )
        }
      >
        Toggle full screen
      </EllipsisMenuItem> */}

      {props.resetFilter && (
        <>
          <EllipsisMenuDivider />
          <EllipsisMenuItem
            onClick={props.resetFilter}
            customIcon={<CrossIcon size={16} color={colors.neutral[5]} />}
          >
            Reset filtering
          </EllipsisMenuItem>
        </>
      )}

      <EllipsisMenuItem
        onClick={resetSettings}
        customIcon={<RefreshCwIcon size={16} color={colors.neutral[5]} />}
      >
        Reset settings
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <EllipsisMenuLabel
        sx={{
          textTransform: 'none',
          fontWeight: 400,
        }}
      >
        Columns
      </EllipsisMenuLabel>

      <Group gap={5} px={5} mb={5}>
        {columns.map((column) => (
          <ColumnVisibleToggle
            column={column}
            key={column.id || column.accessorKey || column.header}
            table={props.table}
            tableKey={props.tableKey}
          />
        ))}
      </Group>
    </EllipsisMenu>
  );
};

const getColumnKey = <TRow extends TableBasicRow>(
  column: MRT_ColumnDef<TRow>
) => column.id || column.accessorKey || column.header;

const ColumnVisibleToggle: <TRow extends TableBasicRow>(props: {
  table: MRT_TableInstance<TRow>;
  tableKey: TableKey | undefined;
  column: MRT_ColumnDef<TRow>;
}) => React.ReactNode = ({ column, table, tableKey }) => {
  const { value, setTableSettings } = useSavedTableSettings();
  const colKey = getColumnKey(column);
  const col = table.getColumn(colKey);

  return (
    <Chip
      key={column.id}
      checked={col.getIsVisible()}
      size="xs"
      variant="light"
      onClick={(event) => {
        const showColumn = event.currentTarget.checked;

        if (tableKey) {
          setTableSettings(tableKey, {
            visibility: {
              ...value[tableKey]?.visibility,
              [colKey]: showColumn,
            },
          });
        } else col.toggleVisibility(showColumn);
      }}
    >
      {column.header}
    </Chip>
  );
};

const NativeSelect = ({
  data,
  onChange,
  value,
  label,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  data: TableSortOptions;
}) => (
  <MNativeSelect
    value={value}
    label={label}
    size="xs"
    styles={(theme) => ({
      root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: rem(10),
      },
      label: {
        flexShrink: 0,
        fontWeight: 400,
      },
      wrapper: {
        width: '100%',
        maxWidth: 140,
      },
      input: {
        borderColor: `${theme.colors.neutral[2]}`,
      },
    })}
    data={data}
    onChange={(e) => onChange(e.currentTarget.value)}
  />
);
