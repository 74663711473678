import {
  captureSentryError,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useRunDrawer } from '@finalytic/data-ui';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  CopyIcon,
  ExportIcon,
  ExternalLinkIcon,
  HistoryIcon,
  ImportIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import {
  EllipsisMenu,
  EllipsisMenuItem,
  EllipsisMenuLabel,
  IconButton,
  StringParam,
  showSuccessNotification,
  showWarnNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  Box,
  Center,
  Group,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { getActionMessage } from '@vrplatform/ui-common';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { AutomationActionStatusIcon } from '../../../views/automations/components';
import { WorkflowAction } from '../_types';

export const useSyncedFromToTableColumns = (
  overwrites: Parameters<typeof getActionMessage>[1]
) => {
  return useMemo<MRT_ColumnDef<WorkflowAction>[]>(
    () => [
      {
        accessorKey: 'uniqueRef',
        header: 'Title',
        maxSize: 100,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Group wrap="nowrap">
              <AutomationActionStatusIcon status={data.status} />
              <Box>
                {data.title}
                {data.type && (
                  <Text component="p" m={0} color={'gray'}>
                    {data.type}
                  </Text>
                )}
              </Box>
            </Group>
          );
        },
      },
      {
        accessorKey: 'message',
        header: 'Message',
        Cell: ({ row }) => {
          const message = useMemo(
            () => getActionMessage(row.original.message || '', overwrites),
            [row.original.message, overwrites]
          );

          return (
            <Text
              sx={{
                display: 'block',
                maxWidth: '100%',
                textWrap: 'wrap',
                overflowWrap: 'anywhere',
                wordBreak: 'break-all',
              }}
            >
              {message}
            </Text>
          );
        },
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 30,
        minSize: 10,
        Cell: ({ row }) => <ActionIcons data={row.original} />,
      },
    ],
    [overwrites]
  );
};

function openJsonWindow(json: object) {
  const myjson = JSON.stringify(json, null, 2);
  const x = window.open();
  x?.document.open();
  x?.document.write(`<html><body><pre>${myjson}</pre></body></html>`);
  x?.document.close();
}

const ActionIcons = ({ data }: { data: WorkflowAction }) => {
  const { copy, copied } = useClipboard();
  const [teamId] = useTeamId();

  const setPayment = useQueryParamSet('payment', StringParam);
  const setReservation = useQueryParamSet('reservation', StringParam);
  const { setWorkflowIds } = useRunDrawer();

  const { mutate: mutateRetry, loading: loadingRetry } = useTrpcMutation(
    'retryAutomation',
    {
      successMessage: {
        title: 'Workflow cancelled',
        message: 'The workflow has been cancelled and will no longer run.',
      },
    }
  );

  const { colors } = useMantineTheme();
  const iconColor = colors.neutral[4];
  const iconSize = 20;

  const showOutput = data?.outputJson && Object.keys(data.outputJson).length;
  const showInput = data?.inputJson && Object.keys(data.inputJson).length;

  useEffect(() => {
    if (copied) {
      showSuccessNotification({
        message: `"${data.uniqueRef}" copied to clipboard`,
      });
    }
  }, [copied, data.uniqueRef]);

  return (
    <Tooltip.Group>
      <Group wrap="nowrap">
        {data.externalHref && (
          <Tooltip label="Open external url" withArrow withinPortal>
            <IconButton
              onClick={() =>
                data.externalHref && window.open(data.externalHref, '_blank')
              }
            >
              <ExternalLinkIcon color={iconColor} size={iconSize} />
            </IconButton>
          </Tooltip>
        )}

        <LinkedTargets
          links={data.links.paymentIds}
          openDrawer={setPayment}
          icon={<CircleDollarIcon color={iconColor} size={iconSize} />}
          tooltip="Open payment"
          ellipsisLabel="Payments"
        />
        <LinkedTargets
          links={data.links.reservationIds}
          openDrawer={setReservation}
          icon={<CalendarEventIcon color={iconColor} size={iconSize} />}
          tooltip="Open reservation"
          ellipsisLabel="Reservations"
        />
        <EllipsisMenu>
          <HiddenFeatureIndicator permission="super-admin">
            <EllipsisMenuItem
              customIcon={<HistoryIcon size={16} />}
              loading={loadingRetry}
              onClick={() => {
                const jobId = data.jobId;

                if (!jobId) {
                  captureSentryError('No jobId found for retry action');
                  return showWarnNotification({
                    message: 'No action found',
                    color: 'yellow',
                  });
                }

                mutateRetry({
                  jobPlanId: data.jobPlanId,
                  tenantId: teamId,
                  jobId: data.jobId,
                }).then((res) => {
                  setWorkflowIds([res.workflowId], res.syncId);
                });
              }}
            >
              Retry
            </EllipsisMenuItem>
          </HiddenFeatureIndicator>
          {data.uniqueRef ? (
            <EllipsisMenuItem
              onClick={() => copy(data.uniqueRef)}
              customIcon={<CopyIcon size={16} color={iconColor} />}
            >
              Copy unique ref
            </EllipsisMenuItem>
          ) : undefined}
          {showInput ? (
            <EllipsisMenuItem
              onClick={() => openJsonWindow(data.inputJson || {})}
              customIcon={<ImportIcon size={16} color={iconColor} />}
            >
              Download input
            </EllipsisMenuItem>
          ) : undefined}
          {showOutput ? (
            <EllipsisMenuItem
              onClick={() => openJsonWindow(data.outputJson || {})}
              customIcon={<ExportIcon size={16} color={iconColor} />}
            >
              Download output
            </EllipsisMenuItem>
          ) : undefined}
        </EllipsisMenu>
      </Group>
    </Tooltip.Group>
  );
};

const LinkedTargets = ({
  links,
  openDrawer,
  icon,
  tooltip,
  ellipsisLabel,
}: {
  links: WorkflowAction['links'][keyof WorkflowAction['links']];
  openDrawer: (id: string) => void;
  icon: React.ReactNode;
  tooltip: string;
  ellipsisLabel: string;
}) => {
  if (links.every((link) => !link?.id)) return null;

  return (
    <>
      {links?.length === 1 ? (
        <Center>
          <ActionIcon
            tooltip={tooltip}
            onClick={() => openDrawer(links[0]?.id)}
          >
            {icon}
          </ActionIcon>
        </Center>
      ) : (
        <EllipsisMenu
          target={
            <Center>
              <IconButton>{icon}</IconButton>
            </Center>
          }
        >
          <EllipsisMenuLabel>{ellipsisLabel}</EllipsisMenuLabel>
          {links?.map(({ id, title }) => {
            return (
              <EllipsisMenuItem
                key={id}
                onClick={() => openDrawer(id)}
                customIcon={icon || ''}
              >
                {title || id}
              </EllipsisMenuItem>
            );
          })}
        </EllipsisMenu>
      )}
    </>
  );
};

const ActionIcon = ({
  onClick,
  tooltip,
  children,
}: PropsWithChildren<{ onClick: () => void; tooltip: string }>) => (
  <Tooltip
    label={tooltip}
    withArrow
    withinPortal
    styles={(theme) => ({
      tooltip: {
        fontSize: theme.fontSizes.xs,
      },
    })}
  >
    <IconButton onClick={onClick}>{children}</IconButton>
  </Tooltip>
);
