import { useEnabledFeatures, useQuery, useTeam } from '@finalytic/data';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  ClipboardTextIcon,
  FileInvoiceIcon,
  FolderOpenIcon,
  HomeIcon,
} from '@finalytic/icons';
import { utc } from '@finalytic/utils';
import { getStatementYearlySummaryHref } from '@vrplatform/ui-common';
import { NavbarRoute } from '../layout/navbar';
import { getOwnerPortalSettings } from '../views/settings/SettingsFeaturesOwnerPortal';
import { NavigationSection } from './NavigationSection';

export const OwnerNavigation = () => {
  const [{ id: teamId, membershipStatus }] = useTeam();
  const { GL } = useEnabledFeatures();

  const { data } = useQuery(
    (q, args) => {
      const settings = getOwnerPortalSettings(q, args.teamId);

      const statements = q
        .ownerStatements({
          where: {
            tenantId: { _eq: args.teamId },
            status: { _in: ['posted', 'published'] },
          },
          limit: 1,
          order_by: [{ updatedAt: 'desc_nulls_last' }],
        })
        .map((statement) => ({
          id: statement.id,
          startAt: statement.startAt,
          owners: statement
            .owners({
              where: {
                newOwnerId: { _is_null: false },
              },
            })
            .map((x) => x.newOwnerId),
        }));

      return {
        showReservationSetting: !!settings.showReservationSettingId,
        statements,
      };
    },
    {
      variables: { teamId },
      skip: !teamId,
      queryKey: ['settings', 'ownerStatements'],
    }
  );

  const statements = data?.statements || [];
  const date = statements[0]?.startAt || utc();
  const ownerId = statements[0]?.owners[0];

  const isArchived = membershipStatus === 'archived';
  const showReservations = !!data?.showReservationSetting && !isArchived;

  const iconSize = 18;

  return (
    <>
      <NavigationSection>
        <NavbarRoute
          title="Statements"
          link="/statements"
          icon={<ClipboardTextIcon size={iconSize} />}
          end
        />

        {!GL && ownerId && (
          <NavbarRoute
            title="Summary"
            link={getStatementYearlySummaryHref({
              ownerId,
              date,
            })}
            icon={<FileInvoiceIcon size={iconSize} />}
            end
          />
        )}
      </NavigationSection>

      {!isArchived && (
        <NavigationSection>
          {(showReservations || window.location.host.includes('localhost')) && (
            <>
              <NavbarRoute
                title="Calendar"
                link="/calendar"
                icon={<CalendarDatesIcon size={iconSize} />}
              />
              <NavbarRoute
                title="Reservations"
                link="/reservations"
                icon={<CalendarEventIcon size={iconSize} />}
              />
            </>
          )}

          <NavbarRoute
            title="Listings"
            link="/listings"
            icon={<HomeIcon size={iconSize} />}
          />
        </NavigationSection>
      )}

      <NavigationSection>
        <NavbarRoute
          title="Files"
          link="/files"
          icon={<FolderOpenIcon size={iconSize} />}
        />
      </NavigationSection>
    </>
  );
};
