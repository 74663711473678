import { Button, InputWrapper } from '@finalytic/components';
import { useQuery, useTeam, useTeamId } from '@finalytic/data';
import { useSettingByIdMutation } from '@finalytic/data-ui';
import { showErrorNotification, showSuccessNotification } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Box, Group, LoadingOverlay, Switch, Text } from '@mantine/core';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  useForm,
} from 'react-hook-form';
import { SettingsTitle, SettingsViewContainer } from './_components';

export const SettingsTeamSecurityView = () => {
  return (
    <SettingsViewContainer>
      <SettingsTitle type="view-title">Security</SettingsTitle>
      <Text
        component="p"
        size={'0.875rem'}
        mb="xl"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Add stricter security to ensure a security standard across your team.
      </Text>
      <Form />
    </SettingsViewContainer>
  );
};

type FormValues = {
  forceTwoFactorAuth: boolean;
};

const useTeamData = () => {
  const [teamId] = useTeamId();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery(
    (q, args) => {
      const team = q.tenantById({ id: args.teamId });

      const forceTwoFactorAuthSetting = q.setting({
        where: {
          tenant_id: { _eq: args.teamId },
          key: { _eq: 'tenantSettings' },
          target: { _eq: 'forceTwoFactorAuth' },
        },
        limit: 1,
      })[0]?.id;

      return {
        logo: team?.logo,
        colorPrimary: team?.colorPrimary,
        forceTwoFactorAuthSetting,
      };
    },
    {
      variables: {
        teamId,
      },
    }
  );

  return {
    forceTwoFactorAuthSettingId: data?.forceTwoFactorAuthSetting,
    loading,
    refetch,
  };
};

const Form = () => {
  const [{ id: teamId }, refetchTeamContext] = useTeam();

  const { loading, refetch, forceTwoFactorAuthSettingId } = useTeamData();

  const methods = useForm<FormValues>({
    values: {
      forceTwoFactorAuth: !!forceTwoFactorAuthSettingId,
    },
  });

  const { mutate } = useSettingByIdMutation({
    successMessage: '',
  });

  const resetHandler = () => methods.reset();

  const submitHandler = async (data: FormValues) => {
    try {
      if (data.forceTwoFactorAuth !== !!forceTwoFactorAuthSettingId) {
        if (forceTwoFactorAuthSettingId) {
          await mutate({
            type: 'remove',
            settingId: forceTwoFactorAuthSettingId,
          });
        } else {
          await mutate({
            type: 'upsert',
            setting: {
              id: forceTwoFactorAuthSettingId,
              key: 'tenantSettings',
              leftType: 'pmDashboard',
              teamId,
              target: 'forceTwoFactorAuth',
            },
          });
        }
      }

      methods.reset(
        {
          forceTwoFactorAuth: data.forceTwoFactorAuth,
        },
        { keepDirty: false }
      );

      refetchTeamContext();
      refetch();
      showSuccessNotification({
        title: 'Success!',
        message: 'Your changes have been saved.',
      });
    } catch (error: any) {
      console.error(error);
      showErrorNotification({
        title: 'Failed to save changes.',
        message:
          error?.message ||
          error ||
          'We failed to save your changes. Please reload the window and try again.',
      });
    }
  };

  const errorHandler: SubmitErrorHandler<FormValues> = (errors) => {
    Object.entries(errors || {}).forEach(([key, value]) => {
      showErrorNotification({
        title: 'Form validation failed',
        message: `${toTitleCase(key)}: ${value?.message}`,
      });
    });
  };

  return (
    <Box
      sx={{ position: 'relative' }}
      component="form"
      onSubmit={methods.handleSubmit(submitHandler, errorHandler)}
    >
      <FormProvider {...methods}>
        <Controller
          control={methods.control}
          defaultValue={false}
          name="forceTwoFactorAuth"
          render={({ field, fieldState: { error } }) => {
            return (
              <InputWrapper label="2FA Authentication" error={error?.message}>
                <Group wrap="nowrap" align="flex-start" gap="lg">
                  <Text mb="xs" color="gray" size="sm" component="p" m={0}>
                    Enforce 2FA for all users on your team. This will require
                    the user to enable 2FA authentication to view any data.
                  </Text>
                  <Switch
                    checked={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                  />
                </Group>
              </InputWrapper>
            );
          }}
        />

        <Group mt={40} justify="right">
          <Button type="button" onClick={resetHandler}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={methods.formState.isSubmitting}
            disabled={!methods.formState.isDirty}
          >
            Save changes
          </Button>
        </Group>
      </FormProvider>
      <LoadingOverlay visible={loading} />
    </Box>
  );
};
