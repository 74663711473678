import { gqlV2, useQuery, useTeamId } from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { Maybe, sortBy } from '@finalytic/utils';
import {
  AutomationTemplateVisibility,
  formatUserName,
  getSyncStatus,
} from '@vrplatform/ui-common';

export const getSync = (sync: gqlV2.sync): Workflow => {
  const leftConnection = sync?.automation?.leftConnection;
  const rightConnection = sync?.automation?.rightConnection;
  const extractConnection = sync.connection;

  return {
    id: sync.id,
    status: getSyncStatus(sync),
    title: sync.message,
    triggerRef: sync.triggerRef,
    createdAt: sync.createdAt,
    workflowId: sync.id,
    hypervisorRef: 'trigger' as const,
    triggeredBy: formatUserName(sync.user || {}, {
      showEmpty: true,
    }),
    automation: {
      id: sync.automationId,
      name: sync.automation?.title || sync?.automation?.ttemplate?.title || '',
      templateType: sync?.automation?.ttemplate?.type,
      visibility: sync?.automation?.ttemplate
        ?.visibility as Maybe<AutomationTemplateVisibility>,
    },
    leftConnection: {
      id: leftConnection?.app?.id,
      icon: leftConnection?.app?.iconRound,
      name: leftConnection?.name || leftConnection?.app?.name,
    },
    rightConnection: {
      id: rightConnection?.app?.id,
      icon: rightConnection?.app?.iconRound,
      name: rightConnection?.name || rightConnection?.app?.name,
    },
    extractConnection: {
      id: sync.connectionId,
      icon: extractConnection?.app?.iconRound,
      name: extractConnection?.name || extractConnection?.app?.name,
      uniqueRef: extractConnection?.uniqueRef,
      appName: extractConnection?.app?.name,
    },
    isExtractWorkflow: !!extractConnection?.id && !sync?.automationId,
  };
};

const getJobPlan = (jobPlan: gqlV2.jobPlan) => {
  const leftConnection = jobPlan?.automation?.leftConnection;
  const rightConnection = jobPlan?.automation?.rightConnection;
  const extractConnection = jobPlan.connection;

  return {
    id: jobPlan.id,
    status: jobPlan.status,
    title: jobPlan.title,
    triggerRef: undefined as string | undefined,
    createdAt: jobPlan.createdAt,
    workflowId: jobPlan.workflowId,
    hypervisorRef: jobPlan.hypervisorRef,
    triggeredBy: formatUserName(jobPlan.user || {}, {
      showEmpty: true,
    }),
    automation: {
      id: jobPlan.automationId,
      templateType: jobPlan?.automation?.ttemplate?.type,
      visibility: jobPlan?.automation?.ttemplate
        ?.visibility as Maybe<AutomationTemplateVisibility>,
      name:
        jobPlan.automation?.title ||
        jobPlan?.automation?.ttemplate?.title ||
        '',
    },
    leftConnection: {
      id: leftConnection?.app?.id,
      icon: leftConnection?.app?.iconRound,
      name: leftConnection?.name || leftConnection?.app?.name,
    },
    rightConnection: {
      id: rightConnection?.app?.id,
      icon: rightConnection?.app?.iconRound,
      name: rightConnection?.name || rightConnection?.app?.name,
    },
    extractConnection: {
      id: jobPlan.connectionId,
      icon: extractConnection?.app?.iconRound,
      uniqueRef: extractConnection?.uniqueRef,
      name: extractConnection?.name || extractConnection?.app?.name,
      appName: extractConnection?.app?.name,
    },
    isExtractWorkflow: !!extractConnection?.id && !jobPlan?.automationId,
  };
};

export type Workflow = ReturnType<typeof getJobPlan>;

export function useAutomationHistoryDrawerQuery(workflowIds: string[]) {
  // const { refreshKeys } = useRunDrawer();
  // const invalidate = useInvalidateQueries();
  const { syncIds } = useRunDrawer();
  const [tenantId] = useTeamId();
  const {
    data: overwrites,
    isInitialLoading,
    error,
  } = useQuery(
    (q) =>
      q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        })),
    {
      keepPreviousData: true,
    }
  );

  const queryData = useQuery(
    (q, args) => {
      if (!args.workflowIds.length) return null;

      const oldWorkflowIds = args.workflowIds.filter(
        (x) => !args.syncIds.includes(x)
      );
      const newSyncIds = args.workflowIds.filter((x) =>
        args.syncIds.includes(x)
      );

      const messageOverwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      const jobPlans = !oldWorkflowIds.length
        ? []
        : q
            .jobPlans({
              where: {
                workflowId: {
                  _in: oldWorkflowIds,
                },
              },
              order_by: [
                {
                  createdAt: 'desc',
                },
              ],
            })
            .map(getJobPlan);

      const syncs = !newSyncIds.length
        ? []
        : q
            .syncs({
              where: {
                id: { _in: newSyncIds },
              },
              order_by: [{ createdAt: 'desc' }],
            })
            .map<Workflow>(getSync);

      return {
        workflows: sortBy([...syncs, ...jobPlans], 'createdAt', 'desc').filter(
          (x) => x.id
        ),
        messageOverwrites,
      };
    },
    {
      queryKey: ['tasks', 'automations', 'jobPlans'],
      skip: !workflowIds.length || isInitialLoading || overwrites === undefined,
      variables: {
        workflowIds,
        syncIds: syncIds || [],
        overwrites: overwrites || [],
        tenantId,
      },
    }
  );

  return {
    data: queryData.data,
    isLoading: queryData.isLoading || isInitialLoading,
    error: queryData.error || error,
  };
}
