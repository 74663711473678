import { EMAIL_ACTION_LINK_COMPONENT } from '../_extensions';
import {
  type ActionEmailData,
  ActionEmailTemplate,
  type ActionEmailTemplateProps,
} from '../_templates';

export const getDefaultInviteOwnerEmailData = (
  mode: 'tag' | 'text',
  args: {
    appName: string;
    signInLink: string;
  }
): ActionEmailData => {
  const signInLink =
    mode === 'tag' ? EMAIL_ACTION_LINK_COMPONENT : args.signInLink;

  return {
    bodyOne: `<p>You are invited to join us on ${args.appName}.</p><p>To start using ${args.appName}, accept the invitation and verify your email address.</p>`,
    bodyTwo: `<p>If you have any trouble with the button, you can copy and paste the link below into your browser: ${signInLink}</p>`,
    buttonText: 'Accept Invitation',
    header: 'Welcome to the team!',
    shortDescription:
      'You have been invited to join the team. To start using VRPlatform, verify your email address at link below.',
    href: args.signInLink,
    subject: `You are invited to join your team on ${args.appName}`,
  };
};

export const InviteOwnerEmail = (props: ActionEmailTemplateProps) => {
  return <ActionEmailTemplate {...props} />;
};

const Preview = () => (
  <ActionEmailTemplate
    mode="send"
    tenant={{
      name: 'Pineapple Mountain',
      logo: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/fbd8c31f-478f-4eb0-74ac-c8eeb47b5000/256x256',
    }}
    data={getDefaultInviteOwnerEmailData('text', {
      appName: 'VRPlatform',
      signInLink: 'https://example.com',
    })}
    user={{
      firstName: 'John',
    }}
    listing={undefined}
    month={undefined}
    themeColors={[
      // portal.brown
      '#9E9390',
      '#8D7C78',
      '#7C6964',
      '#6E5954',
      '#614B46',
      '#573F39',
      '#4E342E',
      '#3F2D29',
      '#332725',
      '#2A2220',
    ]}
  />
);

export default Preview;
