import { Badge, Filter } from '@finalytic/components';
import { InfiniteTable, type MRT_ColumnDef } from '@finalytic/table';
import { Group } from '@mantine/core';
import { Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { formatPercentage, getCountryByIsoCode } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { TaxRateEllipsisMenuItems } from './TaxRateEllipsisMenuItems';
import { TaxRateEllipsisMenuModals } from './TaxRateEllipsisMenuModals';
import { useTaxRateAccountAssignmentQuery } from './useTaxRateAccountAssignmentQuery';
import {
  TaxRateFilterInput,
  TaxRateRow,
  useTaxRatesTableQuery,
} from './useTaxRatesTableQuery';

export const TaxRatesTable = () => {
  const [filter, setFilter] = useSetState<TaxRateFilterInput>({
    search: '',
    status: undefined,
  });
  const queryData = useTaxRatesTableQuery(filter);
  const defaultAccountQueryData = useTaxRateAccountAssignmentQuery();
  const defaultAccountTitle = defaultAccountQueryData?.data?.title;

  const [openedModal, setOpenedModal] = useState<{
    modal: 'edit' | 'delete';
    data: TaxRateRow;
  } | null>(null);

  const columns = useMemo<MRT_ColumnDef<TaxRateRow>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Name',
        Cell: ({ row }) => row.original.title,
      },
      {
        header: 'Country',
        Cell: ({ row }) => {
          if (!row.original.countryCode) return null;

          const country = useMemo(
            () => getCountryByIsoCode(row.original.countryCode as any),
            [row.original.countryCode]
          );

          if (!country) return '-';

          return <Badge>{country.name}</Badge>;
        },
      },
      {
        accessorKey: 'accountTitle',
        header: 'Account',
        Cell: ({ row }) => {
          const title = row.original.accountTitle;

          if (!title) {
            return <Text c="gray">Default: {defaultAccountTitle}</Text>;
          }

          return title;
        },
      },
      {
        accessorKey: 'rate',
        header: 'Rate',
        mantineTableBodyCellProps: { align: 'right' },
        mantineTableHeadCellProps: { align: 'right' },
        Cell: ({ row }) => formatPercentage(row.original.rate),
      },
    ],
    [defaultAccountTitle]
  );

  return (
    <>
      <InfiniteTable
        columns={columns}
        queryData={queryData}
        resetFilter={() =>
          setFilter({
            search: '',
            status: undefined,
          })
        }
        table={{
          emptyRowsFallback: 'No tax rates found',
        }}
        rowMenu={{
          menuItems: ({ row }) => {
            const data = row.original;

            return (
              <TaxRateEllipsisMenuItems
                data={data}
                openDeleteModal={() =>
                  setOpenedModal({ data, modal: 'delete' })
                }
                openEditModal={() => setOpenedModal({ data, modal: 'edit' })}
              />
            );
          },
        }}
      >
        <Group>
          <Filter.Search
            setValue={(search) => setFilter({ search })}
            value={filter.search || ''}
          />
          <Filter.Status
            value={filter.status}
            setValue={(status) => setFilter({ status })}
          />
        </Group>
      </InfiniteTable>
      <TaxRateEllipsisMenuModals
        data={openedModal?.data}
        deleteModal={{
          opened: openedModal?.modal === 'delete' && !!openedModal?.data,
          closeModal: () => setOpenedModal(null),
        }}
        editModal={{
          opened: openedModal?.modal === 'edit' && !!openedModal?.data,
          closeModal: () => setOpenedModal(null),
        }}
      />
    </>
  );
};
