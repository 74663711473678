import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useInfiniteQuery,
  useQuery,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useRunDrawer } from '@finalytic/data-ui';
import { CopyIcon, Icon, IconDefinition } from '@finalytic/icons';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import {
  AutomationIcon,
  EllipsisMenuItem,
  SelectItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { Maybe, day, emptyUUID, sortBy } from '@finalytic/utils';
import { Avatar, Group, Text, useMantineColorScheme } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import {
  AutomationTemplateVisibility,
  getActionMessage,
  whereAutomationTemplateVisibility,
} from '@vrplatform/ui-common';
import { useCallback, useMemo } from 'react';
import { AutomationNameCell } from '../../views/automations/views/overview-automations/_components/AutomationNameCell';

type FilterState = {
  search: string;
  type: Maybe<string>; // automationId OR "extract"
};

type Props = { connectionId: string; tenantId: string };

export const ConnectionSyncsTable = ({ connectionId, tenantId }: Props) => {
  const { setWorkflowIds } = useRunDrawer();
  const initial: FilterState = { search: '', type: null };
  const [filter, setFilter] = useSetState<FilterState>(initial);
  const resetFilter = useCallback(() => () => setFilter(initial), []);
  const { colorScheme } = useMantineColorScheme();

  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();

  const queryData = useInfiniteQuery(
    (
      q,
      { connectionId, isPartnerAdmin, isSuperAdmin, isVrpAdmin, search, type },
      { limit, offset }
    ) => {
      if (!connectionId)
        return {
          list: [],
          aggregate: 0,
        };

      const connection = q.connectionById({ id: connectionId });

      const getWhere = (): gqlV2.jobPlan_bool_exp => {
        const extractWhere: gqlV2.jobPlan_bool_exp = {
          title: search ? { _ilike: `%${search}%` } : undefined,
          connectionId: { _eq: connectionId },
          automationId: { _is_null: true },
        };

        const automationWhere: gqlV2.jobPlan_bool_exp = {
          title: search ? { _ilike: `%${search}%` } : undefined,
          automation: {
            ttemplate: {
              visibility: whereAutomationTemplateVisibility({
                isPartnerAdmin,
                isSuperAdmin,
                isVrpAdmin,
              }),
            },
            _or: [
              {
                leftConnectionId: { _eq: connectionId },
              },
              {
                rightConnectionId: { _eq: connectionId },
              },
            ],
          },
        };

        if (type === 'extract') {
          return extractWhere;
        }

        if (type) {
          return { ...automationWhere, automationId: { _eq: type } };
        }

        return {
          _or: [extractWhere, automationWhere],
        };
      };

      const where = getWhere();

      const aggregate = q.aggregateJobPlans({ where }).aggregate?.count() || 0;

      const messageOverwrites =
        offset !== 0
          ? []
          : q
              .issueMessageOverwrites({
                order_by: [{ pattern: 'asc_nulls_last' }],
              })
              .map((o) => ({
                pattern: o.pattern || '',
                message: o.message || '',
              }));

      const list = q
        .jobPlans({
          where,
          limit,
          offset,
          order_by: [{ createdAt: 'desc_nulls_last' }],
        })
        .map((jobPlan) => {
          const jobPlanType = jobPlan.type;

          return {
            id: jobPlan?.id,
            status: jobPlan?.status,
            title: jobPlan?.title || '',
            createdAt: jobPlan?.createdAt,
            appName: connection?.app?.name,
            appIcon: connection?.app?.iconRound,
            workflowId: jobPlan?.workflowId,
            isSync: jobPlan.hypervisorRef === 'trigger',
            automationTitle:
              jobPlan?.automation?.title ||
              jobPlan?.automation?.ttemplate?.title ||
              '-',
            automationTemplateType: jobPlan?.automation?.ttemplate?.type,
            automationVisibility: jobPlan?.automation?.ttemplate
              ?.visibility as Maybe<AutomationTemplateVisibility>,

            type:
              jobPlan.jobs({
                where: {
                  kind: { _in: ['extract', 'extractLegacy'] },
                },
                limit: 1,
              }).length || jobPlanType === 'extract'
                ? 'extract'
                : 'automation',
          };
        });

      return {
        list,
        aggregate,
        messageOverwrites,
      };
    },
    {
      queryKey: 'connections',
      variables: {
        connectionId: connectionId || emptyUUID,
        search: filter.search?.trim(),
        type: filter.type,
        isVrpAdmin,
        isPartnerAdmin,
        isSuperAdmin,
      },
      skip: !connectionId,
    }
  );

  const messageOverwrites = useMemo(
    () => queryData.data?.pages?.[0]?.messageOverwrites || [],
    [queryData.data?.pages?.[0]?.messageOverwrites]
  );

  const columns = useMemo<MRT_ColumnDef<ConnectionExtractRow>[]>(
    () => [
      {
        accessorKey: 'title',
        enableSorting: true,
        header: 'Recent fetches',
        // maxSize: 120,
        Cell: ({ row }) => {
          const data = row.original;

          let color: 'green' | 'yellow' | 'red' = 'green';
          let icon: IconDefinition = 'CheckIcon';

          if (data.status === 'failed') {
            color = 'red';
            icon = 'CrossIcon';
          }

          if (data.status === 'queued' || data.status === 'started') {
            color = 'yellow';
            icon = 'RefreshCwIcon';
          }

          if (!data.status) return null;

          return (
            <Group wrap="nowrap">
              <Avatar size="sm" radius="xl" color={color}>
                <Icon
                  icon={icon}
                  color={(theme) => theme.colors[color][7]}
                  size={14}
                />
              </Avatar>
              <Text>{getActionMessage(data.title, messageOverwrites)}</Text>
            </Group>
          );
        },
      },

      {
        accessorKey: 'type',
        header: 'Date',
        Cell: ({ row }) => {
          const data = row.original;
          if (data.type === 'extract')
            return (
              <Group wrap="nowrap" gap={'xs'}>
                <Avatar src={data.appIcon} size={18} radius="lg" />
                <Text>{data.appName} Data Fetching</Text>
              </Group>
            );

          return (
            <AutomationNameCell
              name={data?.automationTitle}
              templateType={data.automationTemplateType}
              templateVisibility={data?.automationVisibility}
            />
          );
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Date',
        mantineTableBodyCellProps: { align: 'right' },
        mantineTableHeadCellProps: { align: 'right' },
        maxSize: 150,
        Cell: ({ row }) => {
          return (
            <Text sx={{ flexShrink: 0 }}>
              {day(row.original.createdAt).format('MMM DD, YYYY HH:mm')}
            </Text>
          );
        },
      },
    ],
    [messageOverwrites]
  );

  type ConnectionExtractRow = NonNullable<
    (typeof queryData)['data']
  >['pages'][number]['list'][number];

  return (
    <InfiniteTable
      columns={columns}
      table={{
        hideHeader: true,
        hideSettings: true,
        onRowClick: {
          handler: (row) => {
            const data = row.original;
            if (data.workflowId)
              setWorkflowIds(
                data.workflowId,
                data.isSync ? data.workflowId : undefined,
                { type: 'push' }
              );
          },
          disabled: (row) => !row.original.workflowId,
        },
      }}
      queryData={queryData}
      styles={{
        row: ({ row }, theme) => {
          const isHiddenAutomation =
            row.original.automationVisibility === 'admin' ||
            row.original.automationVisibility === 'partnerAdmin';

          if (!isHiddenAutomation) return {};

          return {
            backgroundColor:
              colorScheme === 'dark'
                ? theme.colors.dark[9]
                : theme.colors.gray[1],
          };
        },
      }}
      rowMenu={{
        hideMenu: () => isVrpAdmin === false,
        menuItems: ({ row }) => {
          const jobId = row.original.id;
          const workflowId = row.original.workflowId;
          const message = row.original.title;
          return (
            <HiddenFeatureIndicator permission="vrp-admin">
              <EllipsisMenuItem
                customIcon={<CopyIcon size={18} />}
                disabled={!message}
                onClick={() =>
                  navigator.clipboard.writeText(message).then(() =>
                    showSuccessNotification({
                      message: 'Message copied to clipboard',
                      icon: null,
                      autoClose: 1000,
                    })
                  )
                }
              >
                Copy message
              </EllipsisMenuItem>

              <EllipsisMenuItem
                customIcon={<CopyIcon size={18} />}
                disabled={!jobId}
                onClick={() =>
                  navigator.clipboard.writeText(jobId).then(() =>
                    showSuccessNotification({
                      message: 'Job ID copied to clipboard',
                      icon: null,
                      autoClose: 1000,
                    })
                  )
                }
              >
                Copy job ID
              </EllipsisMenuItem>
              <EllipsisMenuItem
                customIcon={<CopyIcon size={18} />}
                disabled={!workflowId}
                onClick={() =>
                  navigator.clipboard.writeText(workflowId!).then(() =>
                    showSuccessNotification({
                      message: 'Workflow ID copied to clipboard',
                      icon: null,
                      autoClose: 1000,
                    })
                  )
                }
              >
                Copy workflow ID
              </EllipsisMenuItem>
            </HiddenFeatureIndicator>
          );
        },
      }}
      resetFilter={resetFilter}
    >
      <Group>
        <Filter.Search
          value={filter.search}
          setValue={(s) => setFilter({ search: s })}
        />
        <TypeFilter
          connectionId={connectionId}
          tenantId={tenantId}
          type={filter.type}
          setType={(v) => setFilter({ type: v })}
        />
      </Group>
    </InfiniteTable>
  );
};

const TypeFilter = ({
  connectionId,
  setType,
  tenantId,
  type,
}: Props & { type: Maybe<string>; setType: (v: Maybe<string>) => void }) => {
  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();
  const queryData = useQuery(
    (q, { connectionId, isPartnerAdmin, isSuperAdmin, isVrpAdmin }) => {
      if (!connectionId || !tenantId)
        return {
          list: [],
        };

      const where: gqlV2.jobPlan_bool_exp = {
        automation: {
          ttemplate: {
            visibility: whereAutomationTemplateVisibility({
              isPartnerAdmin,
              isSuperAdmin,
              isVrpAdmin,
            }),
          },
          _or: [
            {
              leftConnectionId: { _eq: connectionId },
            },
            {
              rightConnectionId: { _eq: connectionId },
            },
          ],
        },
      };

      const connection = q.connectionById({ id: connectionId });

      const extracts: SelectItem[] = [
        {
          label: `${connection.app?.name} Data Fetching`,
          value: 'extract',
          icon: <Avatar size={14} src={connection.app?.iconRound} />,
          pinned: true,
        },
      ];

      const plans = sortBy(
        q
          .jobPlans({
            where,
            order_by: [{ automationId: 'desc_nulls_last' }],
            distinct_on: ['automationId'],
          })
          .map<SelectItem>((jobPlan) => {
            const automation = jobPlan?.automation;

            return {
              value: jobPlan?.automationId!,
              label:
                automation?.title || automation?.ttemplate?.title || 'No name',
              icon: (
                <AutomationIcon
                  templateType={automation?.ttemplate?.type}
                  size={16}
                />
              ),
            };
          }),
        'label'
      );

      return {
        list: [...extracts, ...plans],
      };
    },
    {
      variables: {
        connectionId: connectionId || emptyUUID,
        tenantId,
        isSuperAdmin,
        isVrpAdmin,
        isPartnerAdmin,
      },
    }
  );

  const value = type
    ? queryData.data?.list.find((x) => x.value === type) || null
    : null;

  return (
    <Filter.Select
      label="Type"
      type="single"
      value={value}
      setValue={(newValue) => {
        setType(newValue?.value || null);
      }}
      data={{
        options: queryData.data?.list || [],
        loading: queryData.isLoading,
      }}
    />
  );
};
