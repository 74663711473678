import { Badge } from '@finalytic/components';
import { useTeamId, useTrpcMutation, useTrpcQuery } from '@finalytic/data';
import { FileDownloadIcon, Icon } from '@finalytic/icons';
import { LazyTable, MRT_ColumnDef } from '@finalytic/table';
import { ExtendedCustomColors } from '@finalytic/ui';
import { formatCurrency, toTitleCase, utc } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { Center, Text } from '@mantine/core';
import { useMemo } from 'react';

export const InvoicesTable = () => {
  const [teamId] = useTeamId();
  const { loading, data } = useTrpcQuery(
    'getHyperlineInvoices',
    {
      tenantId: teamId,
    },
    {
      queryKey: ['billing'],
    }
  );

  const rowData = data?.data || [];

  type InvoiceRow = NonNullable<typeof data>['data'][number];

  const { mutate, loading: loadingPdf } = useTrpcMutation(
    'getHyperlineInvoicePdf'
  );

  const columnDefs = useMemo<MRT_ColumnDef<InvoiceRow>[]>(
    () => [
      {
        accessorKey: 'due_at',
        Cell: ({ row }) => utc(row.original.due_at).format('DD MMM YYYY'),
        header: 'Date',
      },
      {
        accessorKey: 'amount_due',
        header: 'Amount',
        mantineTableBodyCellProps: { align: 'right' },
        mantineTableHeadCellProps: { align: 'right' },
        maxSize: 0,
        minSize: 200,
        size: 200,
        Cell: ({ row }) => {
          const amount = row.original.total_amount;

          return formatCurrency(
            amount ? amount / 100 : amount,
            row.original.currency
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        maxSize: 0,
        minSize: 130,
        size: 130,
        mantineTableBodyCellProps: { align: 'right' },
        mantineTableHeadCellProps: { align: 'right' },
        Cell: ({ row }) => {
          const status = row.original.status;

          const OPTIONS = useMemo<
            Record<
              typeof status,
              {
                label: string;
                color: ExtendedCustomColors;
              }
            >
          >(
            () => ({
              archived: {
                color: 'gray',
                label: 'Archived',
              },
              draft: {
                color: 'yellow',
                label: 'Draft',
              },
              charged_on_parent: {
                color: 'green',
                label: 'Paid',
              },
              charged_on_child: {
                color: 'green',
                label: 'Paid',
              },
              closed: {
                color: 'gray',
                label: 'Closed',
              },
              error: {
                color: 'red',
                label: 'Error',
              },
              grace_period: {
                color: 'yellow',
                label: 'Grace Period',
              },
              paid: {
                color: 'green',
                label: 'Paid',
              },
              late: {
                color: 'red',
                label: 'Late',
              },
              missing_info: {
                color: 'red',
                label: 'Missing Info',
              },
              partially_paid: {
                color: 'yellow',
                label: 'Partially Paid',
              },
              open: {
                color: 'yellow',
                label: 'Open',
              },
              pending_parent_concat: {
                color: 'yellow',
                label: 'Pending',
              },
              to_pay: {
                color: 'yellow',
                label: 'To Pay',
              },
              voided: {
                color: 'gray',
                label: 'Voided',
              },
            }),
            []
          );

          if (!status) return null;

          const option = OPTIONS[status];

          if (!option) return toTitleCase(status);

          return <Badge color={option.color}>{option.label}</Badge>;
        },
      },
      {
        accessorKey: 'invoice_pdf',
        header: '',
        mantineTableBodyCellProps: { align: 'right' },
        mantineTableHeadCellProps: { align: 'right' },
        maxSize: 0,
        minSize: 50,
        size: 50,
        Cell: ({ row }) => {
          if (!row.original.id) return null;

          return <FileDownloadIcon size={18} />;
        },
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <LazyTable
        data={{
          error: null,
          loading: loading || loadingPdf,
          rowCount: rowData.length,
          rows: rowData,
        }}
        table={{
          onRowClick: {
            disabled: (row) => !row.original.id,
            handler: (row) => {
              mutate({
                invoiceId: row.original.id,
                tenantId: teamId,
              }).then(({ url }) => {
                function downloadPDF(pdf: string) {
                  const linkSource = `data:application/pdf;base64,${pdf}`;
                  const downloadLink = document.createElement('a');
                  const fileName = `Invoice ${row.original.number}.pdf`;
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                  downloadLink.remove();
                }

                if (url) downloadPDF(url);
              });
            },
          },
          emptyRowsFallback: () => (
            <Center
              sx={{
                flex: 1,
                flexDirection: 'column',
                minHeight: 300,
              }}
            >
              <Icon
                icon="FileInvoiceIcon"
                size={30}
                color={(theme) => theme.colors.gray[5]}
                strokeWidth={1}
              />
              <Text color="neutral" size={'md'} mt="lg" fw={500} mb={'xs'}>
                No invoices available yet
              </Text>
              <Text size="sm" color="gray">
                Invoices will appear here once generated
              </Text>
            </Center>
          ),
          columns: columnDefs,
          hideTopBar: true,
        }}
      />
    </Box>
  );
};
