import { useMe } from './team';

export const usePlaywright = () => {
  const me = useMe();

  const automatedTestUserEmails = [
    'lars+e2e@vrplatform.app',
    'vrp-testing@vrp.rocks',
  ];

  const isPlaywright =
    me?.firstName === 'Playwright' ||
    automatedTestUserEmails.includes(me?.email || '');

  return {
    isPlaywright,
  };
};
