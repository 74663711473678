import { sum } from '@finalytic/utils';
import { Text, useMantineColorScheme } from '@mantine/core';
import { MRT_ColumnDef, MRT_RowVirtualizer } from 'mantine-react-table';
import { useCallback, useEffect, useRef } from 'react';
import { LazyTable, LazyTableProps, TableFilterProps } from './LazyTable';

type BasicRow = Record<string, any>;

type InfiniteQueryProps<TRow extends BasicRow> = {
  queryData: {
    data:
      | {
          pages: {
            list: TRow[];
            aggregate: number;
            count?: number;
          }[];
        }
      | undefined;
    error: Error | null;
    isFetching: boolean;
    fetchNextPage: () => void;
    isLoading: boolean;
  };
  columns: MRT_ColumnDef<TRow>[];
  table?: Omit<LazyTableProps<TRow>['table'], 'columns'>;
};

export function InfiniteTable<TRow extends BasicRow>(
  props: Omit<
    LazyTableProps<TRow>,
    'data' | 'table' | 'pagination' | 'infinite'
  > &
    TableFilterProps &
    InfiniteQueryProps<TRow>
) {
  const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

  const { data, error, fetchNextPage, isFetching } = props.queryData;

  const flatData = data?.pages.flatMap((page) => page.list) ?? [];

  const totalRowCount = data?.pages?.[0]?.aggregate ?? 0;
  const totalFetched = data?.pages.some((x) => x.count !== undefined)
    ? sum(data.pages.map((x) => x.count || 0))
    : flatData.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < totalRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalRowCount]
  );
  //scroll to top of table when sorting or filters change
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [props.sorting, props.groupBy]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <>
      <LazyTable
        {...props}
        data={{
          rowCount: totalRowCount,
          loading: props.queryData.isFetching,
          error: error?.message,
          rows: flatData,
        }}
        table={{
          ...props.table,
          hidePagination: true,
          columns: props.columns,
        }}
        resetFilter={props.resetFilter!}
        virtualization={{
          enableRowVirtualization:
            !!props.virtualization?.enableRowVirtualization,
          overscan: props.virtualization?.overscan || 10,
        }}
        infinite={{
          fetchMoreOnBottomReached,
          rowVirtualizerInstanceRef,
          tableContainerRef,
        }}
      >
        {props.children}
      </LazyTable>
      {!props?.table?.hidePagination && (
        <TablePagination
          fetchedCount={totalFetched}
          totalCount={totalRowCount}
        />
      )}
    </>
  );
}

const TablePagination = ({
  fetchedCount,
  totalCount,
}: {
  fetchedCount: number;
  totalCount: number;
}) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Text c={colorScheme === 'dark' ? undefined : 'gray'} size="xs" py="md">
      {fetchedCount} of {totalCount} results
    </Text>
  );
};
