import { z } from 'zod';
import { getCountryByIsoCode, getStateByIsoCode } from '../iso-3166';
import { OWNER_COMPANY_TYPE_VALUES, OwnerCompanyTypeValue } from '../utils';

// Zod parsing
//First & last name
// If type !== individual check that only "name" is filled and not "firstName"
// If type individual then use name and firstName

// Company type
// only allow certain company types

// Phone
// Email: regex
// Tax ID: regex

// Address
// Check countryCode
// Check stateCode
// If line1, then check city, zip, state, country

const schema_tax_id = z
  .string()
  .regex(/^$|^[0-9]{9}$/, { message: 'Invalid tax ID' })
  .nullable();

const schema_email = z
  .union([
    z.literal(''),
    z.string().trim().email({ message: 'Invalid email address' }),
  ])
  .nullable();

const schema_basic = z.object({
  phone: z.coerce.string().nullish(),
  email: schema_email,
  taxId: schema_tax_id,
  line1: z.coerce.string().nullish(),
  line2: z.coerce.string().nullish(),
  city: z.string().nullish(),
  zip: z.coerce.string().nullish(),
  stateCode: z.coerce.string().nullish(),
  countryCode: z.coerce.string().nullish(),
});

const schema_individual = z
  .object({
    firstName: z
      .string()
      .trim()
      .min(1, { message: 'Individual requires a first name' }),
    name: z
      .string()
      .trim()
      .min(1, { message: 'Individual requires a last name' }),
    type: z.literal('individual'),
  })
  .merge(schema_basic);

const schema_c_corporation = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Company name is required for companies' }),
    type: z.literal<OwnerCompanyTypeValue>('c_corporation'),
  })
  .merge(schema_basic);

const schema_s_corporation = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Company name is required for companies' }),
    type: z.literal<OwnerCompanyTypeValue>('s_corporation'),
  })
  .merge(schema_basic);

const schema_partnership = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Company name is required for companies' }),
    type: z.literal<OwnerCompanyTypeValue>('partnership'),
  })
  .merge(schema_basic);

const schema_trust_estate = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Company name is required for companies' }),
    type: z.literal<OwnerCompanyTypeValue>('trust_estate'),
  })
  .merge(schema_basic);

const schema_limited_liability_company = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Company name is required for companies' }),
    type: z.literal<OwnerCompanyTypeValue>('limited_liability_company'),
  })
  .merge(schema_basic);

export const owner_csv_import_schema = z
  .discriminatedUnion(
    'type',
    [
      schema_individual,
      schema_c_corporation,
      schema_limited_liability_company,
      schema_partnership,
      schema_s_corporation,
      schema_trust_estate,
    ],
    {
      errorMap: (error, _data) => {
        if (error.path[0] === 'type') {
          const type = _data.data?.type;

          const available_types = `individual, ${OWNER_COMPANY_TYPE_VALUES.join(
            ', '
          )}`;

          if (!type) {
            return {
              message: `Missing type. Allowed types are: ${available_types}`,
            };
          }

          return {
            message: `Invalid type: ${type}. Allowed types are: ${available_types}`,
          };
        }
        return {
          message: error.message || 'Invalid owner',
        };
      },
    }
  )
  .superRefine((data, ctx) => {
    const line1 = data.line1?.trim();
    const line2 = data.line2?.trim();
    const city = data.city?.trim();
    const zip = data.zip?.trim();
    const stateCode = data.stateCode?.trim();
    const countryCode = data.countryCode?.trim();

    if (line1 || line2 || city || zip) {
      const isMissing = !line1 || !city || !zip || !countryCode || !stateCode;
      if (isMissing) {
        ctx.addIssue({
          code: 'custom',
          message: 'Missing address fields',
        });
      }

      const country = getCountryByIsoCode(countryCode || '');
      if (!country) {
        ctx.addIssue({
          code: 'custom',
          message: 'Invalid country code',
        });
      }
      if (!country) return;
      const state = getStateByIsoCode(stateCode || '', countryCode || '');
      if (!state) {
        ctx.addIssue({
          code: 'custom',
          message: 'Invalid state code',
        });
      }
    }
  });
