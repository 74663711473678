import { reservation as Reservation } from '@finalytic/graphql';
import { sortBy } from '@finalytic/utils';
import { queryReservationFinancials } from '@vrplatform/ui-common';

type Props = {
  partnerId: string;
  tenantId: string;
};

export const getLedgerReservationFinancials = (
  reservation: Reservation,
  { partnerId, tenantId }: Props
) => {
  const status = reservation.status;
  const paidStatus = reservation.paidStatus;

  const isCancelledAndPending = status === 'cancelled' && paidStatus !== 'paid';

  const initialFinancials = sortBy(
    queryReservationFinancials(reservation, {
      partnerId,
      tenantId,
      GL: true,
    }),
    (x) => (x.connectionId ? `a-${x.description}` : `b-${x.description}`)
  );

  const ownerFinancials = initialFinancials.filter(
    (x) => x.party === 'owners' || !x.party
  );

  const includedFinancials = ownerFinancials.filter(
    (x) => x.isInvoice === 'invoice'
  );

  const paidCentTotal = reservation.centPaid ?? 0;
  const centTotal = reservation.centTotal ?? 0;
  const unpaidCentTotal =
    (centTotal - paidCentTotal) * (isCancelledAndPending ? -1 : 1);

  type RecurringFee = {
    id: string;
    title: string;
    centTotal: number;
    currency: string;
    isAdjustment: boolean;
  };

  const recurringFeesJournalEntries = reservation
    .journalEntries({
      where: {
        recurringFee: {
          tenantId: { _eq: tenantId },
        },
        party: { _eq: 'manager' },
      },
      order_by: [
        {
          createdAt: 'asc_nulls_last',
        },
      ],
    })
    .map((journalEntry) => {
      return {
        id: journalEntry.id,
        recurringFeeId: journalEntry.recurringFeeId!,
        recurringFeeTitle: journalEntry.recurringFee?.title || 'No name',
        centTotal: journalEntry.centTotal ?? 0,
        currency: journalEntry.currency || 'usd',
        party: journalEntry.party,
      };
    })
    .reduce<RecurringFee[]>((acc, journalEntry) => {
      const index = acc.findIndex((x) => x.id === journalEntry.recurringFeeId);

      const journalEntryCentTotal = Math.abs(journalEntry.centTotal);

      if (index === -1)
        acc.push({
          centTotal: journalEntryCentTotal,
          currency: journalEntry.currency,
          id: journalEntry.recurringFeeId,
          title: journalEntry.recurringFeeTitle,
          isAdjustment: false,
        });
      else acc[index].centTotal += journalEntryCentTotal;
      return acc;
    }, []);

  const taxCentTotal = includedFinancials.reduce(
    (acc, curr) =>
      acc + (curr.description?.includes('tax') ? curr.centTotal : 0),
    0
  );

  const pmFinancials = initialFinancials
    .filter((x) => x.party === 'manager')
    .map<RecurringFee>((line) => {
      return {
        id: line.id,
        centTotal: line.centTotal,
        currency: line.currency ?? 'usd',
        title: line.description ?? '',
        isAdjustment: true,
      };
    });

  const recurringFees: RecurringFee[] = [
    ...recurringFeesJournalEntries,
    ...pmFinancials,
  ];

  const pmRevenueCentTotal = recurringFees.reduce(
    (acc, curr) => acc + curr.centTotal,
    0
  );

  const ownerRevenueCentTotal = centTotal - pmRevenueCentTotal - taxCentTotal;

  return {
    centTotals: {
      paidCentTotal,
      centTotal,
      unpaidCentTotal,
      taxCentTotal: isCancelledAndPending ? 0 : taxCentTotal,
      pmRevenueCentTotal,
      ownerRevenueCentTotal: isCancelledAndPending ? 0 : ownerRevenueCentTotal,
    },
    recurringFees,
    financials: ownerFinancials,
    currency: reservation.currency,
    isCancelledAndPending,
  };
};
