import { whereAccounts } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';
import { useAccountsFilter } from './AccountsFilter';

export function useWhereAccounts() {
  const { filter } = useAccountsFilter();
  const { teamId } = useAccountsConfig();

  const trimmedSearch = filter.search?.trim();

  return useMemo(
    () =>
      whereAccounts({
        search: trimmedSearch,
        classification: filter.classification as any,
        type: filter.type as any,
        status: filter.status as any,
        tenantId: teamId,
      }),
    [trimmedSearch, filter.classification, filter.type, teamId, filter.status]
  );
}
