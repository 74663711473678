import { Button, InputSelect, InputWrapper } from '@finalytic/components';
import {
  captureSentryError,
  useApiMutation,
  useInfiniteQuery,
  useQuery,
  useTeam,
} from '@finalytic/data';
import { tenant_bool_exp } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { showErrorNotification, useAppName } from '@finalytic/ui';
import { Box, Center, Group, Modal, Text, Title } from '@mantine/core';
import { whereTenants } from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export const CopyChartOfAccountModal = ({
  closeModal: cm,
  opened,
}: { opened: boolean; closeModal: () => void }) => {
  const [{ id: teamId, partnerId }, refetchTeam] = useTeam();

  const { appName } = useAppName();

  type FormInputs = {
    tenantId: string | null;
  };
  const methods = useForm<FormInputs>();

  const closeModal = () => {
    cm();
    methods.reset({
      tenantId: null,
    });
  };

  const { mutateAsync } = useApiMutation('patch', '/teams/{id}/init', {
    onSettled: refetchTeam,
  });

  const submit = async (data: FormInputs) => {
    try {
      await mutateAsync({
        params: {
          path: {
            id: teamId,
          },
        },
        body: {
          copyFromTeamId: data.tenantId || undefined,
        },
      });
    } catch (error: any) {
      const message =
        error?.message ||
        'We failed to copy the chart of accounts. Please try again.';

      captureSentryError(message);
      showErrorNotification({
        title: 'Chart of accounts',
        message,
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered
      radius="md"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.md,
          paddingTop: 0,
        },
      })}
    >
      <Center mb="xl">
        <Icon
          icon="ListUnorderedIcon"
          color={(theme) => theme.colors.orange[8]}
          size={24}
        />
      </Center>

      <Title ta="center" order={3} mb="md">
        Copy chart of accounts
      </Title>

      <Text component="p" c="gray" ta="center" mb="md">
        Get started now. Choose one of the following teams to set up your chart
        of accounts.
      </Text>

      <Box
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={closeModal}
      >
        <Controller
          control={methods.control}
          name="tenantId"
          render={({ field }) => {
            const [search, setSearch] = useState('');

            const queryData = useInfiniteQuery(
              (q, args) => {
                const where: tenant_bool_exp = {
                  ...whereTenants({
                    search: args.search,
                    dashboard: 'propertyManager',
                    partnerId: args.partnerId,
                  }),
                  id: { _neq: args.teamId },
                };

                const list = q
                  .tenant({
                    where,
                    order_by: [{ name: 'asc_nulls_last' }],
                  })
                  .map((tenant) => ({
                    value: tenant.id,
                    label: tenant.name || '',
                  }));

                const aggregate =
                  q
                    .tenantAggregate({
                      where,
                    })
                    .aggregate?.count() || 0;

                return {
                  list,
                  aggregate,
                };
              },
              {
                variables: {
                  teamId,
                  search: search.trim(),
                  partnerId,
                },
              }
            );

            const { error, isLoading, data } = useQuery(
              (q, args) => {
                if (!args.id) return null;

                return (
                  q
                    .tenant({
                      where: {
                        id: { _eq: args.id },
                      },
                    })
                    .map((tenant) => ({
                      value: tenant.id,
                      label: tenant.name || '',
                    }))[0] || undefined
                );
              },
              {
                skip: !field.value,
                variables: {
                  id: field.value,
                },
                keepPreviousData: true,
              }
            );

            const defaultLabel = `${appName} - Default chart of accounts`;
            const defaultValue = { value: null, label: defaultLabel };
            const value = field.value ? data || defaultValue : defaultValue;

            return (
              <InputWrapper label="Copy chart of accounts from" mb="xl">
                <InputSelect
                  type="single"
                  value={value.value ? value : null}
                  infiniteData={{ ...queryData, setSearch }}
                  setValue={(v) => field.onChange(v?.value || null)}
                  inputProps={{
                    loadingQuery: isLoading,
                    error: error?.message,
                  }}
                  customActionTop={{
                    label: defaultLabel,
                    onSubmit: () => field.onChange(null),
                  }}
                  dropdownProps={{
                    withinPortal: true,
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        <Group wrap="nowrap">
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="filled"
            color="orange"
            loading={methods.formState.isSubmitting}
            sx={{
              flex: 1,
            }}
          >
            Confirm selection
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
