import {
  Button,
  ConfirmModal,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  captureSentryError,
  useApiMutation,
  useInvalidateQueries,
  useQuery,
} from '@finalytic/data';
import { accountAssignmentType_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import {
  SelectItem,
  hideNotification,
  showErrorNotification,
  showLoadingNotification,
  showWarnNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe, hasValue, isUUID, toTitleCase } from '@finalytic/utils';
import { Box, Group, Modal, Text, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { whereAccounts } from '@vrplatform/ui-common';
import { useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAccountsConfig } from '../useAccountsConfig';
import { useAccountsAssignmentsQuery } from './useAccountsAssignmentsQuery';
import { type AccountRow } from './useAccountsTableQuery';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  account: Maybe<AccountRow>;
}

export const AccountEllipsisMenuModals = ({
  account,
  deleteModal,
  archiveModal,
  editModal,
}: {
  account: ModalProps['account'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  archiveModal: {
    opened: boolean;
    closeModal: () => void;
  };
  editModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  return (
    <>
      <Delete {...deleteModal} account={account} />
      <Edit {...editModal} account={account} />
      <Archive {...archiveModal} account={account} />
    </>
  );
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

const Edit = ({ account, opened, closeModal }: ModalProps) => {
  const { teamId } = useAccountsConfig();

  const invalidate = useInvalidateQueries(['accounts']);

  type FormInputs = {
    title: string;
    assignments: accountAssignmentType_enum[];
    offsetAccountId: string | null;
  };

  const methods = useForm<FormInputs>({
    values: {
      title: account?.title || '',
      assignments: account?.assignments.map((x) => x.id) || [],
      offsetAccountId: account?.offsetAccount?.id ?? null,
    },
  });

  const { mutateAsync } = useApiMutation('put', '/accounts/{id}', {
    onError: (error) => {
      const message =
        error.message ||
        'We failed to update account. Please reach out to support if the issue persists.';

      showErrorNotification({
        title: 'Failed to update account',
        message,
      });
    },
  });

  const submit = (data: FormInputs) => {
    if (!data.title.trim())
      return showWarnNotification({
        message: 'Please enter an account title.',
      });

    const assignments = data.assignments?.filter(hasValue) || [];

    return mutateAsync({
      params: {
        path: {
          id: account?.id!,
        },
      },
      body: {
        name: data.title?.trim(),
        offsetAccountId: data.offsetAccountId,
        assignments: assignments as any[],
      },
    }).then(() => {
      invalidate();
      closeModal();
    });
  };

  const {
    data: availableAssignmentTypes,
    isLoading: loadingAvailableAssignmentTypes,
    error: errorAvailableAssignmentTypes,
  } = useAccountsAssignmentsQuery({
    skip: !opened,
    currentAccountAssignments: account?.assignments.map((x) => x.id) || [],
  });

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title="Edit Account"
      onSubmit={methods.handleSubmit(submit)}
      onReset={closeModal}
      styles={{
        title: { fontWeight: 500 },
      }}
      centered
    >
      <Box component="form">
        <Controller
          control={methods.control}
          rules={{
            required: 'Account title is required',
            validate: trapSpacesForRequiredFields,
          }}
          name="title"
          render={({ field, fieldState }) => (
            <InputWrapper
              label="Title"
              error={fieldState.error?.message}
              mb="sm"
            >
              <Input
                value={field.value}
                setValue={field.onChange}
                error={!!fieldState.error}
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={methods.control}
          name="assignments"
          disabled={account?.type === 'recurringFee'}
          render={({ field, fieldState }) => {
            const value =
              availableAssignmentTypes?.filter((x) =>
                field.value?.includes(x.value)
              ) || [];

            return (
              <Tooltip
                label="Assignments are not available on accounts of type 'Recurring Fee'"
                withArrow
                disabled={!field.disabled}
                offset={-15}
              >
                <InputWrapper
                  label="Assignments"
                  error={fieldState.error?.message}
                  mb="sm"
                >
                  <InputSelect
                    type="multiple"
                    value={value}
                    setValue={(v) => field.onChange(v.map((x) => x.value))}
                    data={{
                      options: availableAssignmentTypes || [],
                      loading: loadingAvailableAssignmentTypes,
                      error: errorAvailableAssignmentTypes,
                    }}
                    inputProps={{
                      placeholder: field.disabled ? '-' : 'Select assignments',
                      disabled: field.disabled,
                    }}
                    dropdownProps={{
                      withinPortal: true,
                    }}
                  />
                </InputWrapper>
              </Tooltip>
            );
          }}
        />

        <Controller
          control={methods.control}
          name="offsetAccountId"
          disabled={account?.classification !== 'expense'}
          render={({ field, fieldState }) => {
            const {
              data: accounts = [],
              isLoading: loadingAccounts,
              error: errorAccounts,
            } = useQuery(
              (q, args) => {
                return q
                  .accounts({
                    where: {
                      ...whereAccounts({
                        tenantId: args.teamId,
                      }),
                      classification: { _in: ['expense', 'revenue'] },
                      id:
                        args.accountId && isUUID(args.accountId)
                          ? { _neq: args.accountId }
                          : undefined,
                    },
                    order_by: [
                      { classification: 'asc_nulls_last' },
                      { title: 'asc_nulls_last' },
                    ],
                  })
                  .map<SelectItem>((account) => {
                    const disabled =
                      !!account.offsetAccountId &&
                      args.accountId !== account.offsetAccountId;

                    return {
                      value: account.id,
                      label: account.title || 'No name',
                      group: toTitleCase(account.classification),
                      disabled,
                      description: disabled
                        ? 'This account is already assigned'
                        : null,
                    };
                  });
              },
              {
                variables: {
                  teamId,
                  accountId: account?.id,
                },
                keepPreviousData: true,
                queryKey: ['accounts'],
                skip: !account?.id || account?.classification !== 'expense',
              }
            );

            const value = accounts.find((x) => x.value === field.value) || null;

            return (
              <Tooltip
                label="Offset accounts are only available on accounts with classification 'Expense'"
                withArrow
                disabled={!field.disabled}
                offset={-15}
              >
                <InputWrapper
                  label={'Expense Reimbursement Offset Account'}
                  error={fieldState.error?.message}
                  mb="lg"
                >
                  <InputSelect
                    type="single"
                    value={value}
                    setValue={(v) => field.onChange(v?.value ?? null)}
                    data={{
                      options: accounts,
                      loading: loadingAccounts,
                      error: errorAccounts,
                    }}
                    inputProps={{
                      placeholder: field.disabled
                        ? '-'
                        : 'Select offset account',
                      disabled: field.disabled,
                      withClearButton: true,
                      loadingQuery: loadingAccounts,
                    }}
                    dropdownProps={{
                      withinPortal: true,
                      width: 'target',
                    }}
                  />
                </InputWrapper>
              </Tooltip>
            );
          }}
        />

        <Group justify="right" mt="md">
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={methods.formState.isSubmitting}
          >
            Submit
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

const Delete = ({ account: initial, opened, closeModal }: ModalProps) => {
  const notifyId = useId();
  const invalidate = useInvalidateQueries(['accounts']);

  const [confirmOpened, setConfirmOpened] = useState<
    ModalProps['account'] | null
  >(null);
  const closeConfirmModal = () => setConfirmOpened(null);

  const account = confirmOpened || initial;

  const { mutateAsync: deleteAccount, isPending: loading } = useApiMutation(
    'delete',
    '/accounts/{id}'
  );

  const handleDeletion = async ({
    forceArchive,
  }: { forceArchive: boolean }) => {
    showLoadingNotification({
      id: notifyId,
    });

    if (!account) {
      captureSentryError('Account to delete missing');
      hideNotification({ id: notifyId });

      return showWarnNotification({
        title: 'Missing account',
        message:
          'Account to delete missing. Please reach out to support if the issue persists.',
      });
    }

    return await deleteAccount({
      params: {
        path: {
          id: account.id,
        },
        query: {
          onLocked: forceArchive ? 'archive' : 'error',
        },
      },
    })
      .then((res) => {
        if (res.status === 'deleted') {
          updateSuccessNotification({
            id: notifyId,
            title: 'Account deleted',
            message: 'Account was deleted successfully.',
          });
        }

        if (res.status === 'archived') {
          updateSuccessNotification({
            id: notifyId,
            title: 'Account was archived',
            message: 'Account was archived successfully.',
          });
        }

        invalidate();
        closeConfirmModal();
      })
      .catch((err: any) => {
        if (err?.message.includes('pass onLocked=archive to archive')) {
          if (account.status === 'inactive') {
            updateErrorNotification({
              id: notifyId,
              title: 'Data associated with this account',
              message:
                'This account cannot be deleted because it has data associated with it.',
              icon: null,
              color: 'yellow',
            });
          } else {
            setConfirmOpened(account);
            hideNotification({
              id: notifyId,
            });
          }
        } else {
          updateErrorNotification({
            id: notifyId,
            title: 'Failed to delete account',
            message:
              err.body.message ||
              'We failed to delete account. Please reach out to support if the issue persists.',
          });
        }
      })
      .finally(() => {
        closeModal();
      });
  };

  return (
    <>
      <ConfirmModal
        type="delete"
        opened={opened}
        closeModal={closeModal}
        loading={loading}
        title="Delete account"
        subtitle="Are you sure you want to delete this account?"
        onSubmit={() => handleDeletion({ forceArchive: false })}
      />
      <Modal
        radius="md"
        opened={!!confirmOpened}
        onClose={closeConfirmModal}
        centered
        size={500}
        styles={(theme) => ({
          body: {
            paddingBottom: theme.spacing.xl,
            paddingInline: theme.spacing.xl,
          },
        })}
      >
        <Icon
          icon="AlertTriangleIcon"
          color={(theme) => theme.colors.orange[5]}
          size={32}
        />
        <Text ta="center" component="h4" size="lg" fw={500} my="lg">
          We have found data associated with this account
        </Text>
        <Text ta="center" component="p" c="neutral" mb="xl">
          We have found data associated with this account. Would you like to
          archive the account instead? This will keep the data associated with
          the account, but will remove the account from your dashboard.
        </Text>

        <Group wrap="nowrap">
          <Button type="button" onClick={closeConfirmModal} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            sx={{ flex: 1 }}
            onClick={() => handleDeletion({ forceArchive: true })}
            loading={loading}
          >
            Archive account
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const Archive = ({ account: initial, opened, closeModal }: ModalProps) => {
  const notifyId = useId();
  const invalidate = useInvalidateQueries(['accounts']);

  const { mutateAsync: update, isPending: loading } = useApiMutation(
    'put',
    '/accounts/{id}'
  );

  const [debounced] = useDebouncedValue(initial, 500);

  const account = debounced || initial;

  const isArchived = account?.status === 'inactive';
  const accountTitle = account?.title;

  const handle = async () => {
    showLoadingNotification({
      id: notifyId,
    });

    if (!account) {
      captureSentryError('Account to update missing');
      return showWarnNotification({
        title: 'Missing account',
        message:
          'Account to update missing. Please reach out to support if the issue persists.',
      });
    }

    return await update({
      params: {
        path: {
          id: account.id,
        },
      },
      body: {
        status: account.status === 'inactive' ? 'active' : 'inactive',
      },
    })
      .then((res) => {
        if (res.status === 'active') {
          updateSuccessNotification({
            id: notifyId,
            title: 'Account restored',
            message: 'Account was restored successfully.',
          });
        }

        if (res.status === 'inactive') {
          updateSuccessNotification({
            id: notifyId,
            title: 'Account was archived',
            message: 'Account was archived successfully.',
          });
        }

        invalidate();
      })
      .catch((err: any) => {
        updateErrorNotification({
          id: notifyId,
          title: 'Failed to update account',
          message:
            err.body.message ||
            'We failed to update account. Please reach out to support if the issue persists.',
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  return (
    <>
      <ConfirmModal
        type="confirm"
        opened={opened}
        closeModal={closeModal}
        loading={loading}
        onSubmit={() => handle()}
        title={isArchived ? 'Enable account' : 'Archive account'}
        subtitle={
          isArchived
            ? `Would you like to re-enable ${accountTitle || 'this account'}?`
            : `Are you sure you want to archive ${
                accountTitle || 'this account'
              }?`
        }
        submitTitle={isArchived ? 'Enable' : 'Archive'}
        icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'}
      />
    </>
  );
};
