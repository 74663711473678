import { useClerk } from '@clerk/clerk-react';
import { showErrorNotification } from '@finalytic/ui';
import type { ApiClientError, paths } from '@vrplatform/api';
import createFetchClient, { Middleware } from 'openapi-fetch';
import createClient from 'openapi-react-query';
import { createContext, useContext, useMemo } from 'react';
import { PLATFORM } from '../env';
import { useTeamId } from '../hooks';
// import type { paths } from './api.generated';

const BASE_URL = 'https://api.vrplatform.app';

const context = createContext<Client>({
  queryOptions: undefined,
  useMutation: undefined,
  useQuery: undefined,
  useSuspenseQuery: undefined,
} as any);

type Client = ReturnType<typeof createClient<paths>> & {
  $api: ReturnType<typeof createFetchClient<paths>>;
};

export const showApiErrorNotification = ({
  error,
  title,
  defaultMessage,
}: {
  error: any;
  title: string;
  defaultMessage: string;
}) => {
  const formatted = error as ApiClientError;

  const message =
    formatted.issues[0].message || formatted.message || defaultMessage;

  showErrorNotification({
    title,
    message,
  });
};

export const ApiClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const auth = useClerk();
  const [teamId] = useTeamId();

  const { $api, fetchClient } = useMemo(() => {
    const authMiddleware: Middleware = {
      async onRequest({ request }) {
        const accessToken = await auth.session?.getToken({
          template: 'Hasura',
        });

        if (!accessToken) {
          throw new Error('No API access');
        }

        const existingHeaderTenantId =
          request.headers.get('x-team-id') ||
          request.headers.get('x-tenant-id');
        const headerTenantId = existingHeaderTenantId || teamId;

        // add Authorization header to every request
        request.headers.set('Authorization', `Bearer ${accessToken}`);
        if (headerTenantId) {
          request.headers.set('x-tenant-id', headerTenantId);
          request.headers.set('x-team-id', headerTenantId);
        }
        request.headers.set(
          'x-transaction-id',
          Math.random().toString(36).substring(2, 9)
        );
        request.headers.set('platform', PLATFORM);

        return request;
      },
    };

    const fetchClient = createFetchClient<paths>({
      baseUrl: BASE_URL,
      async fetch(req) {
        return await fetch(req);
      },
    });

    fetchClient.use(authMiddleware);

    const $api = createClient(fetchClient);
    return { $api, fetchClient };
  }, [teamId, auth?.session?.getToken]);

  return (
    <context.Provider value={{ ...$api, $api: fetchClient }}>
      {children}
    </context.Provider>
  );
};

// TODO: not working
// export const useApiQuery: Client['useQuery'] = (
//   ...props: Parameters<Client['useQuery']>
// ) => useContext(context).useQuery(...props);
export const useApiMutation: Client['useMutation'] = (...props) =>
  useContext(context).useMutation(...props);
export const useApiClient = () => useContext(context).$api;
