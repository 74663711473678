import { ConfirmModal } from '@finalytic/components';
import {
  captureSentryError,
  useApiClient,
  useApiMutation,
  useInvalidateQueries,
} from '@finalytic/data';
import { showErrorNotification, showWarnNotification } from '@finalytic/ui';
import { useState } from 'react';

type Props = {
  reservationLine: {
    id: string;
    reservationId: string;
  } | null;
  closeModal: () => void;
};

export const ReservationLineDeleteModal = ({
  reservationLine,
  closeModal,
}: Props) => {
  const opened = !!reservationLine;

  const $api = useApiClient();
  const invalidate = useInvalidateQueries(['reservations']);
  const [loading, setLoading] = useState(false);

  const { mutateAsync } = useApiMutation('put', '/reservations/{id}', {
    onSuccess: () => {
      invalidate();
    },
  });

  const submit = async () => {
    if (!reservationLine) {
      captureSentryError('Missing line');
      return showWarnNotification({
        title: 'Missing line',
        message: 'Please reach out to support if the issue persists.',
      });
    }

    try {
      setLoading(true);

      const existing_lines = await $api
        .GET('/reservations/{id}', {
          params: {
            path: { id: reservationLine.reservationId },
          },
        })
        .then((x) => x.data?.lines || []);

      type ReservationLine = NonNullable<
        Parameters<typeof mutateAsync>[0]['body']['lines']
      >[number];

      const lines = existing_lines
        .filter((line) => line.id !== reservationLine.id)
        .map<ReservationLine>((line) => ({
          amount: line.amount,
          connectionId: line.connectionId ?? undefined,
          description: line.description ?? undefined,
          type: line.type,
        }));

      await mutateAsync({
        params: {
          path: {
            id: reservationLine.reservationId,
          },
        },
        body: {
          lines,
        },
      });

      closeModal();
    } catch (error: any) {
      const message =
        error?.message ||
        'We failed to update the reservation. Please try again later and if the problem persists, contact support.';

      showErrorNotification({
        title: 'Failed to update reservation',
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmModal
      type="delete"
      opened={opened}
      closeModal={closeModal}
      onSubmit={async () => await submit()}
      loading={loading}
      title={'Are you sure you want to delete the adjustment?'}
      subtitle="Please refresh the journal entries of this reservation after deletion."
    />
  );
};
