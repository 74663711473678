import { CheckInOutType, Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  ArrayParam,
  SelectItem,
  StringParam,
  UrlUpdateType,
  useQueryParams,
} from '@finalytic/ui';
import { day, ensure, hasValue, isDate, toTitleCase } from '@finalytic/utils';
import { Center, Group } from '@mantine/core';
import {
  getListingName,
  orderByListing,
  whereConnectionStatusDefault,
  whereListings,
  whereReservations,
} from '@vrplatform/ui-common';
import { useCallback, useMemo, useState } from 'react';
import { useGenericTableStore } from '../../stores';

export const useReservationFilter = () => {
  const [queryFilter, setQueryFilter] = useQueryParams({
    search: StringParam,
    status: StringParam,
    paidStatus: StringParam,
    listingIds: ArrayParam,
    listingCollectionId: StringParam,
    listingStatus: StringParam,
    connectionIds: ArrayParam,
    bookingChannels: ArrayParam,
    date: StringParam,
    dateType: StringParam,
  });

  const filterType = useGenericTableStore((s) => s.filterType);
  const stateFilter = useGenericTableStore((s) => s.filter);
  const setStateFilter = useGenericTableStore((s) => s.setFilter);

  const filter = useMemo(() => {
    if (filterType === 'query-params') {
      return queryFilter;
    }
    return stateFilter as typeof queryFilter;
  }, [filterType, stateFilter, queryFilter]);

  return {
    filter,
    setFilter: useCallback(
      (filter: Partial<typeof stateFilter>, updateType?: UrlUpdateType) => {
        if (filterType === 'query-params') {
          setQueryFilter(filter, updateType);
        } else {
          setStateFilter(filter);
        }
      },
      [filterType, setQueryFilter, setStateFilter]
    ),
    reset: () => {
      const initial: Record<keyof typeof queryFilter, undefined> = {
        search: undefined,
        status: undefined,
        paidStatus: undefined,
        listingIds: undefined,
        listingCollectionId: undefined,
        listingStatus: undefined,
        connectionIds: undefined,
        bookingChannels: undefined,
        date: undefined,
        dateType: undefined,
      };

      setStateFilter(initial);
      setQueryFilter(initial);
    },
  };
};

export const ReservationFilter = ({
  hide,
  showMonthSelect,
}: {
  hide?: ('connection' | 'booking-channel' | 'date' | 'status')[];
  showMonthSelect?: boolean;
}) => {
  const { filter, setFilter } = useReservationFilter();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  return (
    <Group>
      <Filter.Search
        value={filter.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      {!hide?.includes('date') && <DateFilter />}
      {showMonthSelect && <MonthSelectFilter />}
      {!hide?.includes('status') && <StatusFilter />}
      {GL && dashboard === 'propertyManager' && <PaidStatusFilter />}
      {!hide?.includes('connection') && <ConnectionFilter />}
      {!hide?.includes('booking-channel') && <BookingChannelFilter />}
      {dashboard === 'propertyManager' && <ListingGroupFilter />}
      <ListingFilter />
      {dashboard === 'propertyManager' && <ListingStatusFilter />}
    </Group>
  );
};

const MonthSelectFilter = () => {
  const { filter, setFilter } = useReservationFilter();

  type Month = Date | null | undefined;

  const date = useMemo<Month>(() => {
    const defaultDate = day().startOf('month').toDate();

    if (!filter.date) return defaultDate;

    const [v] = filter.date.split('...');

    if (!isDate(v)) return defaultDate;

    return day(v).startOf('month').toDate();
  }, [filter.date]);

  return (
    <Filter.Month
      value={date}
      setValue={(value) => {
        value &&
          setFilter({
            date: [
              day(value).startOf('month').yyyymmdd(),
              day(value).endOf('month').yyyymmdd(),
            ].join('...'),
          });
      }}
      type="default"
      label="Month"
    />
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useReservationFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
      dateType={(filter.dateType as CheckInOutType) || 'all'}
      setDateType={(v) => setFilter({ dateType: v })}
    />
  );
};

const StatusFilter = () => {
  const { filter, setFilter } = useReservationFilter();
  const [dashboard] = useDashboard();

  const isPmDashboard = dashboard === 'propertyManager';

  const options: SelectItem[] = useMemo<SelectItem[]>(() => {
    return ensure<(SelectItem | undefined)[]>([
      {
        label: 'Booked',
        value: 'booked',
        icon: (
          <Center
            sx={(theme) => ({
              width: 8,
              height: 8,
              backgroundColor: theme.colors.green[6],
              borderRadius: '50%',
              alignSelf: 'center',
            })}
          />
        ),
      },
      isPmDashboard
        ? {
            label: 'Inquiry',
            value: 'inquiry',
            icon: (
              <Center
                sx={(theme) => ({
                  width: 8,
                  height: 8,
                  backgroundColor: theme.colors.yellow[6],
                  borderRadius: '50%',
                  alignSelf: 'center',
                })}
              />
            ),
          }
        : undefined,
      {
        label: 'Cancelled',
        value: 'cancelled',
        icon: (
          <Center
            sx={(theme) => ({
              width: 8,
              height: 8,
              backgroundColor: theme.colors.red[6],
              borderRadius: '50%',
              alignSelf: 'center',
            })}
          />
        ),
      },
    ]).filter(hasValue);
  }, [isPmDashboard]);

  const value: SelectItem | null =
    options.find((o) => o.value === filter.status) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ status: v?.value })}
      label="Status"
      type="single"
      hideIcon
      data={{
        options,
      }}
    />
  );
};

const PaidStatusFilter = () => {
  const { filter, setFilter } = useReservationFilter();

  const options: SelectItem<
    NonNullable<Parameters<typeof whereReservations>[0]['paidStatus']>
  >[] = [
    // {
    //   label: 'Not paid',
    //   value: 'unpaid',
    //   icon: (
    //     <Center
    //       sx={(theme) => ({
    //         width: 8,
    //         height: 8,
    //         backgroundColor: theme.colors.orange[6],
    //         borderRadius: '50%',
    //         alignSelf: 'center',
    //       })}
    //     />
    //   ),
    // },
    {
      label: 'Unpaid',
      value: 'unpaid',
      icon: (
        <Center
          sx={(theme) => ({
            width: 8,
            height: 8,
            backgroundColor: theme.colors.red[6],
            borderRadius: '50%',
            alignSelf: 'center',
          })}
        />
      ),
    },
    {
      label: 'Paid fully',
      value: 'paid',
      icon: (
        <Center
          sx={(theme) => ({
            width: 8,
            height: 8,
            backgroundColor: theme.colors.green[6],
            borderRadius: '50%',
            alignSelf: 'center',
          })}
        />
      ),
    },
    // {
    //   label: 'Overpaid',
    //   value: 'overpaid',
    //   icon: (
    //     <Center
    //       sx={(theme) => ({
    //         width: 8,
    //         height: 8,
    //         backgroundColor: theme.colors.red[6],
    //         borderRadius: '50%',
    //         alignSelf: 'center',
    //       })}
    //     />
    //   ),
    // },
  ];

  const value: SelectItem | null =
    options.find((o) => o.value === filter.paidStatus) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ paidStatus: v?.value })}
      label="Payment Status"
      type="single"
      hideIcon
      data={{
        options,
      }}
    />
  );
};

const ListingStatusFilter = () => {
  const { filter, setFilter } = useReservationFilter();

  const options: SelectItem[] = [
    { label: 'Enabled', value: 'active' },
    { label: 'Disabled', value: 'inactive' },
  ];

  const value: SelectItem | null =
    options.find((o) => o.value === filter.listingStatus) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingStatus: v?.value })}
      label="Listing Status"
      type="single"
      data={{
        options,
      }}
    />
  );
};

const ConnectionFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useReservationFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.connection_bool_exp = {
        tenantId: { _eq: teamId },
        reservations: {},
        app: {
          id: { _neq: 'finalytic' },
        },
        status: whereConnectionStatusDefault,
        _or: search
          ? [
              {
                name: { _ilike: `%${search}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .connection({
          where,
          order_by: [{ name: 'asc' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.name || 'Missing connection name',
        }));

      const aggregate =
        q.connectionAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'connections'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { connectionIds }) => {
      const value = q
        .connection({ where: { id: { _in: connectionIds } } })
        .map<SelectItem>((item) => ({
          label: item.name || '',
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'connections'],
      keepPreviousData: true,
      variables: {
        connectionIds: (filter.connectionIds || []).filter(hasValue),
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ connectionIds: v.map((i) => i.value) })}
      label="Connection"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const ListingFilter = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = useReservationFilter();
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search, dashboard, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [orderByListing],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: (filter.listingIds || []).filter(hasValue),
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const ListingGroupFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useReservationFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.listing_collection_bool_exp = {
        tenantId: { _eq: teamId },
        name: search
          ? { _ilike: `%${search}%` }
          : { _is_null: false, _neq: '' },
      };

      const list = q
        .listingCollections({
          where,
          order_by: [{ name: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.name || 'No name',
        }));

      const aggregate =
        q.listingCollectionAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const values = queryData.data?.pages.flatMap((page) => page.list) || [];
  const value =
    values.find((v) => v.value === filter.listingCollectionId) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingCollectionId: v?.value })}
      label="Listing Group"
      type="single"
      withinPortal
      noOptionsText="No listing groups found"
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const BookingChannelFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useReservationFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.booking_channel_bool_exp = {
        reservations: {
          tenantId: { _eq: teamId },
        },
        _or: search
          ? [
              {
                uniqueRef: { _ilike: `%${search}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .bookingChannels({
          where,
          limit,
          offset,
          order_by: [{ uniqueRef: 'asc' }],
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: toTitleCase(res.uniqueRef) || 'Missing booking channel',
        }));

      const aggregate =
        q.bookingChannelAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'bookingChannels'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { bookingChannels }) => {
      const value = q
        .bookingChannels({
          where: { id: { _in: bookingChannels } },
        })
        .map<SelectItem>((item) => ({
          label: toTitleCase(item.uniqueRef) || '',
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'bookingChannels'],
      keepPreviousData: true,
      variables: {
        bookingChannels: filter.bookingChannels?.filter(hasValue) || [],
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ bookingChannels: v.map((i) => i.value) })}
      label="Booking Channel"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};
