import {
  activeStatus_enum,
  recurringFeeType_enum,
  recurringFee_bool_exp,
} from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';

export function whereRecurringFees({
  search,
  tenantId,
  status,
  listingIds,
  type,
}: {
  tenantId: string;
  search?: Maybe<string>;
  status?: Maybe<activeStatus_enum>;
  listingIds?: Maybe<string[]>;
  type?: Maybe<recurringFeeType_enum>;
}) {
  return ensure<recurringFee_bool_exp>({
    tenantId: { _eq: tenantId },
    title: search ? { _ilike: `%${search}%` } : undefined,
    status: status ? { _eq: status } : { _eq: 'active' },
    type: type ? { _eq: type } : undefined,
    listingSubscriptions: listingIds?.length
      ? { listingId: { _in: listingIds } }
      : undefined,
  });
}
