import { useTeam } from '@finalytic/data';
import { VRP_TENANT_ID } from '@vrplatform/ui-common';
import { useMemo } from 'react';

export function useAccountsConfig() {
  const [{ id: teamId, partnerId }] = useTeam();

  return useMemo(() => {
    const isMasterList = teamId === VRP_TENANT_ID;

    return {
      isMasterList,
      teamId,
      partnerId,
    };
  }, [teamId, partnerId, VRP_TENANT_ID]);
}
