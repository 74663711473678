import { recurringFeeType_enum } from '@finalytic/graphql';
import { FeeOverviewRoute } from './_types';

const MAPPING: Record<FeeOverviewRoute, recurringFeeType_enum> = {
  'booking-channel-fees': 'bookingChannelFee',
  'merchant-fees': 'merchantFee',
  other: 'additionalFee',
  commissions: 'managementFee',
  'cleaning-fees': 'cleaningFee',
};

export const getRecurringFeeEnumFromFeeTypeRoute = (
  feeType: FeeOverviewRoute
) => {
  return MAPPING[feeType];
};

export const getFeeTypeRouteFromRecurringFeeEnum = (
  recurringFeeEnum: recurringFeeType_enum
) => {
  return Object.keys(MAPPING).find(
    (key) => MAPPING[key as FeeOverviewRoute] === recurringFeeEnum
  )! as FeeOverviewRoute;
};
