import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  account_bool_exp,
  transaction_bool_exp,
  transaction_select_column,
} from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import {
  ArrayParam,
  BooleanParam,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue, isUUID, sortBy, toTitleCase } from '@finalytic/utils';
import { Avatar, Box, Group } from '@mantine/core';
import {
  getListingName,
  orderByAccount,
  whereAccounts,
  whereConnections,
  whereListings,
} from '@vrplatform/ui-common';
import { useCallback, useMemo, useState } from 'react';
import { useExpenseTableFilter } from '../../expenses/list/ExpenseTableFilter';

export const useDepositsTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingIds: ArrayParam,
    connectionId: StringParam,
    date: StringParam,
    accountId: StringParam,
    reconciled: BooleanParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        listingIds: undefined,
        date: undefined,
        accountId: undefined,
        connectionId: undefined,
      };

      setFilter(initial);
    },
  };
};

export const DepositsTableFilter = () => {
  return (
    <Group>
      <Search />
      <Date />
      <BankAccount />
      <Reconciled />
      <Connection />
      <Listing />
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = useDepositsTableFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Date = () => {
  const { filter, setFilter } = useDepositsTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const Listing = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = useDepositsTableFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard,
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: (filter.listingIds || []).filter(hasValue),
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const Connection = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useDepositsTableFilter();
  const [search, setSearch] = useState('');

  const [active, setActive] = useState<null | SelectItem | undefined>(
    undefined
  );

  const queryData = useQuery(
    (q, { teamId, search }) => {
      const where: transaction_bool_exp = {
        tenantId: { _eq: teamId },
        connection: whereConnections({
          teamId,
          search,
        }),
      };

      const distinct_on: transaction_select_column[] = ['connectionId'];

      const list = q
        .transactions({
          distinct_on,
          where,
          order_by: [
            {
              connectionId: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((res) => ({
          value: res.connectionId!,
          label: res.connection?.name ?? '-',
          icon: <Avatar size={16} src={res.connection?.app.iconRound} mt={3} />,
        }));

      return {
        list: sortBy(list, 'label'),
      };
    },
    {
      skip: !teamId,
      queryKey: ['connections'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const options = queryData.data?.list || [];

  const value =
    active !== undefined
      ? active
      : options.find((i) => i.value === filter.connectionId) || null;

  const setValue = useCallback(
    (v: SelectItem | null) => {
      setActive(v);
      setFilter({ connectionId: v?.value });
    },
    [setFilter]
  );

  const manualOption = useMemo<SelectItem>(
    () => ({
      label: 'Manual deposit',
      value: 'manual',
      icon: <Icon icon="UserIcon" size={16} />,
    }),
    []
  );

  return (
    <Filter.Select
      value={value}
      setValue={setValue}
      label="Connection"
      type="single"
      withinPortal
      hideIcon
      pinnedItems={[manualOption]}
      data={{
        options: options,
        error: queryData.error,
        loading: queryData.isLoading,
        onSearchChange: (v) => setSearch(v),
      }}
      popoverWidth={300}
    />
  );
};

const BankAccount = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useDepositsTableFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: account_bool_exp = whereAccounts({
        tenantId: teamId,
        search,
        type: 'bank',
      });

      const list = q
        .accounts({
          where,
          order_by: [
            { bankAccount: { category: 'desc_nulls_last' } },
            ...orderByAccount,
          ],
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.bankAccount?.category) || '-',
        }));

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, args) => {
      if (args.accountId === 'non-trust')
        return {
          label: 'Non-Trust Account',
          value: 'non-trust',
        };

      if (!args.accountId || !isUUID(args.accountId)) return null;

      return (
        q
          .accounts({
            where: {
              id: { _eq: args.accountId },
              tenantId: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map<SelectItem>((x) => ({
            label: x.title!,
            value: x.id,
          }))[0] || null
      );
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      keepPreviousData: true,
      variables: {
        accountId: filter.accountId,
        teamId,
      },
    }
  );

  return (
    <Filter.Select
      value={data || null}
      setValue={(v) => setFilter({ accountId: v?.value })}
      type="single"
      label="Bank Account"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const Reconciled = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  const options = useMemo<SelectItem[]>(() => {
    return [
      {
        label: 'Reconciled',
        value: 'true',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Unreconciled',
        value: 'false',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ];
  }, []);

  const value =
    options.find(
      (o) =>
        typeof filter.reconciled === 'boolean' &&
        o.value === (filter.reconciled ? 'true' : 'false')
    ) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) =>
        setFilter({
          reconciled:
            typeof v?.value === 'string' ? v.value === 'true' : undefined,
        })
      }
      type="single"
      label="Reconciliation"
      withinPortal
      data={{
        options,
      }}
      hideIcon
    />
  );
};
