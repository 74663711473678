import { useInfiniteQuery, useTeamId } from '@finalytic/data';
import { MRT_SortingState } from '@finalytic/table';
import { hasValue } from '@finalytic/utils';
import { formatAssignmentType } from '@vrplatform/ui-common';
import { useWhereAccounts } from './useWhereAccounts';

type Params = {
  sorting: MRT_SortingState;
};

export type AccountRow = NonNullable<
  ReturnType<typeof useAccountsTableQuery>['data']
>['pages'][number]['list'][number];

export const useAccountsTableQuery = ({ sorting }: Params) => {
  const where = useWhereAccounts();
  const [teamId] = useTeamId();

  return useInfiniteQuery(
    (q, { where, sorting, teamId }, { limit, offset }) => {
      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const list = q
        .accounts({
          where,
          limit,
          offset,
          order_by,
        })
        .map((account) => {
          const offsetAccount = {
            id: account.offsetAccountId,
            title: account.offsetAccount?.title,
          };

          return {
            id: account.id as string,
            title: account.title,
            type: account.type,
            status: account.status || 'inactive',
            uniqueRef: account.uniqueRef,
            classification: account.classification,
            offsetAccount: offsetAccount.id ? offsetAccount : null,
            assignments: account
              .assignments({
                where: {
                  tenantId: { _eq: teamId },
                },
              })
              .map((assignment) => ({
                id: assignment.type!,
                title: formatAssignmentType(assignment.type),
              }))
              .filter(hasValue),
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'accounts',
      variables: { where, sorting, teamId },
    }
  );
};
