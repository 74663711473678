import { ConfirmModal } from '@finalytic/components';
import { useApiMutation, useInvalidateQueries } from '@finalytic/data';
import {
  showLoadingNotification,
  showWarnNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useId } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DepositRow } from './useDepositsTableQuery';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  deposit: Maybe<DepositRow>;
}

export const DepositEllipsisMenuModals = ({
  deleteModal,
  deposit: initial,
}: {
  deposit: ModalProps['deposit'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const deposit = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} deposit={deposit} />
    </>
  );
};

const Delete = ({ opened, closeModal, deposit }: ModalProps) => {
  const depositId = deposit?.id;
  const notifyId = useId();

  const goto = useNavigate();
  const location = useLocation();

  const invalidate = useInvalidateQueries(['deposits', 'reservations']);

  const { mutateAsync: mutate, isPending: loading } = useApiMutation(
    'delete',
    '/transactions/{id}',
    {
      onSuccess: () => {
        invalidate();
      },
    }
  );

  const submit = async () => {
    if (!depositId) return showWarnNotification({ message: 'Missing deposit' });

    try {
      showLoadingNotification({ id: notifyId });
      const res = await mutate({
        params: {
          path: {
            id: depositId,
          },
          query: {
            onLocked: 'archive',
          },
        },
      });

      if (res.status === 'deleted' && location.pathname !== '/deposits') {
        goto('/deposits');
      }

      updateSuccessNotification({
        id: notifyId,
        title: 'Success!',
        message: `Deposit was ${res.status} successfully.`,
      });

      closeModal();
    } catch (error: any) {
      const message =
        error?.message ||
        'We failed to delete the deposit. Please try again later and if the problem persists, contact support.';

      updateErrorNotification({
        id: notifyId,
        title: 'Failed to delete deposit',
        message,
      });
    }
  };

  return (
    <ConfirmModal
      type="delete"
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      onSubmit={submit}
      title={'Delete this deposit?'}
      subtitle={
        'Are you sure you want to delete this deposit? This action cannot be undone.'
      }
    />
  );
};
