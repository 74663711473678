import { Button } from '@finalytic/components';
import {
  useBrowserTracking,
  useQuery,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { DeleteModal } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { deleteCheckTenant } from '@vrplatform/ui-common';
import { useNavigate } from 'react-router';

export const TeamDeleteButton = () => {
  const [opened, handlers] = useDisclosure(false);
  const [, setTeamId] = useTeamId();
  const [{ name: teamName, id: teamId }, _refetchTeam] = useTeam();
  const goto = useNavigate();
  const { track } = useBrowserTracking();

  const { mutate, loading } = useTrpcMutation('deleteTenant', {
    successMessage: {
      message: `You successfully deleted ${teamName}. If this was done in error, please get in touch with us.`,
    },
    invalidateQueryKeys: ['teams', 'scheduledEvents'],
  });

  const submit = async () =>
    mutate({
      tenantId: teamId,
      foreignObjectId: teamId,
    }).then(() => {
      track('team_deleted', {
        tenantId: teamId,
      });
      setTeamId(null);
      goto('/');
    });

  const { data, isLoading: loading2 } = useQuery(
    (q, { tenantId }: { tenantId: string }) => {
      return deleteCheckTenant(q, { tenantId });
    },
    {
      variables: { tenantId: teamId },
    }
  );

  const canDelete = !!data?.canDelete;
  const object = Object.entries(data || {}).filter(
    ([, value]) => typeof value === 'number' && value > 0
  ) as [string, number][];

  return (
    <>
      <Tooltip
        withArrow
        offset={15}
        label={`Please remove ${toTitleCase(
          object?.[0]?.[0]
        )?.toLowerCase()} first.`}
        disabled={canDelete}
      >
        <Box sx={{ display: 'inline' }}>
          <Button
            onClick={handlers.open}
            loading={loading || loading2}
            disabled={!canDelete}
            color="red"
            variant="filled"
          >
            Delete Team
          </Button>
        </Box>
      </Tooltip>
      <DeleteModal
        size={400}
        opened={opened}
        onClose={handlers.close}
        onSubmit={submit}
        title="Are you sure you would like to delete this team?"
        subtitle="The team will be deleted immediately including all its data. This action cannot be undone."
      />
    </>
  );
};
