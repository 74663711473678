import { IconButton } from '@finalytic/components';
import {
  useBilling,
  useDashboard,
  useEnabledFeatures,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { day } from '@finalytic/utils';
import {
  AppShell,
  Box,
  Group,
  ScrollArea,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { useLocation, useNavigate } from 'react-router';
import { useNavbarExpanded } from '../../hooks';
import {
  NavbarAccountingSwitch,
  NavbarFeaturesButton,
  NavbarIssueReportButton,
  NavbarNotificationsIconButton,
  NavbarSupportButton,
  NavbarTeamSwitch,
  UserSettingsPopover,
} from './_components';

export type NavigationComponent = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => JSX.Element;

export type NavbarBaseProps = {
  navigation: NavigationComponent;
};

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');

export const Navbar = ({ navigation: Navigation }: NavbarBaseProps) => {
  const { isDesktopExpanded: isExpanded } = useNavbarExpanded();
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();
  const [dashboard] = useDashboard();
  const [{ isOnboarding }] = useTeam();
  const { isVrpAdmin } = useTeamRole();

  const showFeaturesButton = dashboard !== 'owner' && !isOnboarding;

  return (
    <>
      <NavbarHeader />
      <AppShell.Section
        grow
        component={ScrollArea}
        px="sm"
        mt={isExpanded ? 'md' : undefined}
        sx={{
          width: isExpanded ? 280 : 80,
          height: '100%',
          paddingTop: !isExpanded ? 'sm' : undefined,
          gap: '1rem',
          '&, & > div, & > div > div': {
            height: '100%',
          },
        }}
      >
        <Stack
          h="100%"
          align={isExpanded ? undefined : 'center'}
          pt={!isExpanded ? 'sm' : undefined}
        >
          <CancellationCard />
          {dashboard === 'propertyManager' && <NavbarAccountingSwitch />}
          {Navigation && <Navigation navbarIsExpanded={!!isExpanded} />}
        </Stack>
      </AppShell.Section>

      {LIVEBLOCKS_NOTIFICATIONS ? (
        <Box
          mt="auto"
          mb="xs"
          sx={(theme) => ({
            display: 'flex',
            flexDirection: isExpanded ? 'row' : 'column',
            alignItems: isExpanded ? 'flex-start' : 'center',
            justifyContent: 'flex-start',
            paddingInline: 14,
            gap: isExpanded ? theme.spacing.xs : 0,
          })}
        >
          <CancellationCard />
          <NavbarExpandButton />
          <NavbarSupportButton />
          {isVrpAdmin && !isLocalhost && <NavbarIssueReportButton />}
          {showFeaturesButton && <NavbarFeaturesButton />}
        </Box>
      ) : (
        <>
          <NavbarSupportButton />
          {isVrpAdmin && !isLocalhost && <NavbarIssueReportButton />}
          {showFeaturesButton && <NavbarFeaturesButton />}
        </>
      )}

      <AppShell.Section
        px="xs"
        pb="xs"
        pt={4}
        sx={{
          borderTop: '1px solid #C6C6C630',
          width: isExpanded ? 280 : 80,
        }}
      >
        <UserSettingsPopover isOwnerDashboard={dashboard === 'owner'} />
      </AppShell.Section>
    </>
  );
};

const NavbarHeader = () => {
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();
  const { isDesktopExpanded: isExpanded } = useNavbarExpanded();

  const location = useLocation();

  const isSettingsView = location.pathname.startsWith('/settings');

  const goto = useNavigate();

  return (
    <AppShell.Section
      component={Group}
      wrap="nowrap"
      px="md"
      pt="md"
      gap={!isExpanded ? 0 : 'xs'}
      justify={isExpanded ? 'apart' : 'center'}
      sx={{
        width: isExpanded ? 280 : 80,
        height: '100%',
        flex: 0,
        paddingBottom: !isExpanded ? 10 : undefined,
        borderBottom: !isExpanded ? '1px solid #C6C6C630' : undefined,
        flexDirection: isExpanded ? undefined : 'column',
      }}
    >
      {isSettingsView ? (
        <Group gap={'xs'} flex={1}>
          <IconButton
            icon="ArrowLeftIcon"
            onClick={() => goto('/')}
            mb={!isExpanded ? 'xs' : undefined}
            color={(theme) => theme.colors.neutral[3]}
          />
          {isExpanded && (
            <Text component="span" size="lg" color="white">
              Settings
            </Text>
          )}
        </Group>
      ) : (
        <NavbarTeamSwitch />
      )}

      {LIVEBLOCKS_NOTIFICATIONS ? (
        !isSettingsView && <NavbarNotificationsIconButton />
      ) : (
        <NavbarExpandButton />
      )}
    </AppShell.Section>
  );
};

const NavbarExpandButton = () => {
  const { setIsDesktopExpanded, isMobile, canExpand } = useNavbarExpanded();
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();

  const toggle = () => setIsDesktopExpanded?.((e) => !e);

  if (isMobile || !canExpand) return null;

  return (
    <IconButton
      icon="Grid06Icon"
      onClick={toggle}
      size={LIVEBLOCKS_NOTIFICATIONS ? 18 : 16}
      color={
        LIVEBLOCKS_NOTIFICATIONS
          ? (theme) => theme.colors.neutral[3]
          : '#ffffff90'
      }
    />
  );
};

const CancellationCard = () => {
  const { billingStatus, subscriptionCancelledAt } = useBilling();
  const [dashboard] = useDashboard();

  const location = useLocation();
  const { isTeamAdmin } = useTeamRole();
  const goto = useNavigate();

  if (
    dashboard !== 'owner' &&
    billingStatus === 'active' &&
    subscriptionCancelledAt &&
    day(subscriptionCancelledAt).isAfter(day())
  ) {
    const showHover = isTeamAdmin && !location.pathname.startsWith('/settings');

    return (
      <Box
        p={rem(8)}
        title="Upcoming cancellation"
        onClick={() => {
          if (showHover) goto('/settings/team/billing');
        }}
        data-testid="upcoming-cancellation-card"
        sx={(theme) => ({
          border: `1px solid ${theme.colors.yellow[7]}30`,
          backgroundColor: theme.colors.yellow[7] + 20,
          borderRadius: theme.radius.md,
          ':hover': showHover
            ? {
                cursor: 'pointer',
                backgroundColor: theme.colors.yellow[7] + 40,
                border: `1px solid ${theme.colors.yellow[7]}50`,
              }
            : undefined,
        })}
      >
        <Group wrap="nowrap" gap="xs" mb={rem(5)}>
          <Icon
            icon="AlertTriangleIcon"
            color={(theme) => theme.colors.yellow[7]}
            size={18}
          />
          <Text size="sm" c="yellow">
            Upcoming cancellation
          </Text>
        </Group>
        <Text size="xs">
          Your subscription will cancel on{' '}
          {day(subscriptionCancelledAt).format('LL')}.
        </Text>
      </Box>
    );
  }

  return null;
};
