import { useQuery, useTeamId } from '@finalytic/data';
import { accountAssignmentType_enum } from '@finalytic/graphql';
import { SelectItem } from '@finalytic/ui';
import { formatAssignmentType } from '@vrplatform/ui-common';

export function useAccountsAssignmentsQuery({
  currentAccountAssignments,
  skip,
}: {
  skip: boolean;
  currentAccountAssignments: accountAssignmentType_enum[];
}) {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      const existingAssignments = q
        .accountAssignments({
          where: {
            tenantId: { _eq: args.teamId },
          },
          order_by: [{ type: 'asc' }],
        })
        .map((assignment) => assignment.type);

      return q
        .accountAssignmentTypes({
          order_by: [{ name: 'asc' }],
        })
        .map<SelectItem<accountAssignmentType_enum>>((x) => {
          const type = x.name! as accountAssignmentType_enum;

          const disabled =
            existingAssignments.includes(type) &&
            !args.currentAccountAssignments.includes(type);

          return {
            value: type,
            label: formatAssignmentType(type),
            disabled,
            description: disabled
              ? 'Already assigned to another account'
              : undefined,
          };
        });
    },
    {
      skip,
      queryKey: ['accounts', 'assignmentTypes'],
      variables: {
        teamId,
        currentAccountAssignments,
      },
    }
  );
}
