import { useQuery } from '@finalytic/data';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { IntegrationType } from '../_types';

function useAppByConnectionId(id: Maybe<string>) {
  const { data: connection, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.id) return null;

      const connection = q.connectionById({ id: args.id });

      return {
        id: connection?.id,
        app: {
          id: connection?.app?.id!,
          name: connection?.app?.name!,
          iconRound: connection?.app?.iconRound!,
          type: connection?.app?.type!,
          authentication: connection?.app?.authentication(),
        },
      };
    },
    {
      queryKey: 'connections',
      skip: !id,
      variables: {
        id,
      },
    }
  );

  const app: IntegrationType | undefined = (id && connection?.app) || undefined;

  return { app, loading };
}

export function useRedirectedApp() {
  const [connectionId] = useQueryParam('connectionId', StringParam);

  const { app } = useAppByConnectionId(connectionId);

  return { redirectedApp: app };
}
