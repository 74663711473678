import { gqlV2, useTeamId } from '@finalytic/data';
import { accountAssignmentType_enum } from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';
import { useMemo } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';
import {
  LineTypeType,
  useLineTypeMappingFilter,
} from './LineTypeMappingFilter';

export function useWherePaymentLineClassificationMapping({
  type,
}: { type: LineTypeType }) {
  const [teamId] = useTeamId();
  const { filter } = useLineTypeMappingFilter();
  const { isMasterList } = useAccountsConfig();

  const trimmedSearch = filter.search?.trim();

  return useMemo(
    () =>
      wherePaymentLineClassification({
        search: trimmedSearch,
        appId: isMasterList ? filter.appId || 'airbnb' : filter.appId,
        type,
        tenantId: isMasterList ? null : teamId,
        accountId: filter.accountId,
        assignment: filter.assignment,
      }),
    [
      teamId,
      trimmedSearch,
      filter.appId,
      isMasterList,
      type,
      filter.accountId,
      filter.assignment,
    ]
  );
}

function wherePaymentLineClassification({
  search,
  appId,
  type,
  tenantId,
  accountId,
  assignment,
}: {
  search: Maybe<string>;
  appId: Maybe<string>;
  type: LineTypeType;
  tenantId: string | null;
  accountId: Maybe<'exclude' | 'unmapped' | (string & {})>;
  assignment: Maybe<'exclude' | 'unmapped' | (string & {})>;
}) {
  return ensure<gqlV2.payment_line_classification_bool_exp>({
    name: search ? { _ilike: `%${search}%` } : undefined,
    type: { _eq: type === 'payment_line' ? 'paymentLine' : 'reservationLine' },
    lines: {
      // paymentId: type ? { _is_null: type === 'reservation_line' } : undefined,
      tenantId: tenantId ? { _eq: tenantId } : undefined,
      lineTypeAccounts:
        accountId && accountId !== 'unmapped'
          ? {
              accountId: {
                _eq: accountId === 'exclude' ? undefined : accountId,
                _is_null: accountId === 'exclude',
              },
            }
          : undefined,
      accountAssignment:
        assignment && assignment !== 'unmapped'
          ? {
              accountAssignmentType: {
                _eq:
                  assignment === 'exclude'
                    ? undefined
                    : (assignment as accountAssignmentType_enum),
                _is_null: assignment === 'exclude',
              },
            }
          : undefined,
      _not:
        accountId === 'unmapped' || assignment === 'unmapped'
          ? {
              lineTypeAccounts: accountId === 'unmapped' ? {} : undefined,
              accountAssignment: assignment === 'unmapped' ? {} : undefined,
            }
          : undefined,
    },
    appId: appId ? { _eq: appId } : undefined,
  });
}
