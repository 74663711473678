import { Button, Input, InputWrapper } from '@finalytic/components';
import { useMutation, useQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  IconButton,
  LoadingIndicator,
  Modal,
  showSuccessNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Box, Group, Stack, Text, useMantineTheme } from '@mantine/core';

type ApiKeyModalProps = {
  opened: boolean;
  onClose: () => void;
  id?: string;
  refetchTable: () => void;
};

function useApiTokenMutations() {
  const { mutate: deleteToken } = useMutation(
    (q, args: { id: string }) => {
      return q.deleteTokenById({ id: args.id })?.id;
    },
    {
      successMessage: {
        id: 'delete-api-key',
        message: 'Successfully removed API Token.',
      },
    }
  );

  return {
    deleteToken: (id: string) => deleteToken({ args: { id } }),
  };
}

function useApiTokenQuery(tokenId: Maybe<string>) {
  return useQuery(
    (q, args) => {
      if (!args.tokenId) return null;

      return (
        q
          .token({
            where: {
              id: {
                _eq: args.tokenId,
              },
            },
            limit: 1,
          })
          .map((token) => ({
            apiToken: token?.nanoId,
          }))[0] || null
      );
    },
    {
      skip: !tokenId,
      variables: {
        tokenId,
      },
    }
  );
}

export const ApiKeyModal = ({
  opened,
  onClose,
  id,
  refetchTable,
}: ApiKeyModalProps) => {
  const { colors: themeColors } = useMantineTheme();

  const { data, isLoading: loading } = useApiTokenQuery(id);
  const apiToken = data?.apiToken;

  const { deleteToken } = useApiTokenMutations();

  const copyApiKey = () => {
    if (apiToken) {
      navigator.clipboard.writeText(apiToken).then(() => {
        showSuccessNotification({
          message: 'Api key was copied was copied to your clipboard!',
          icon: null,
        });
      });
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} size="lg" title="API Token">
      <Box>
        <Stack mb={30}>
          <InputWrapper label="X-API-Token">
            <Input
              name="apiKey"
              placeholder=""
              readOnly
              type="text"
              value={apiToken || ''}
              rightSection={
                loading ? (
                  <LoadingIndicator size="xs" />
                ) : (
                  <IconButton onClick={copyApiKey}>
                    <Icon icon="CopyIcon" size={18} />
                  </IconButton>
                )
              }
              sx={{
                input: {
                  backgroundColor: loading
                    ? `${themeColors.neutral[1]}`
                    : undefined,
                },
                ".mantine-Input-section[data-position='right']": {
                  pointerEvents: 'initial',
                },
              }}
            />
          </InputWrapper>
        </Stack>
        <Group justify="space-between" mb="md">
          <Button
            variant="light"
            disabled={!id}
            onClick={() => {
              if (id) {
                deleteToken(id).then(() => {
                  refetchTable();
                  onClose();
                });
              }
            }}
            leftIcon={'RefreshCwIcon'}
          >
            Revoke
          </Button>

          <Group>
            <Button onClick={onClose}>Cancel</Button>

            <Button
              variant="primary"
              disabled={!apiToken}
              leftIcon={'CopyIcon'}
              onClick={copyApiKey}
            >
              <Text component="span" size="sm">
                Copy Token
              </Text>
            </Button>
          </Group>
        </Group>
      </Box>
    </Modal>
  );
};
