import {
  Badge,
  InputPercentage,
  InputSelect,
  Select,
  TaxId,
} from '@finalytic/components';
import {
  gqlV2,
  useBrowserTracking,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useMutation,
  useQuery,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { contactCompanyType_enum, contact_set_input } from '@finalytic/graphql';
import {
  BriefcaseIcon,
  CopyIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  HashtagIcon,
  Icon,
  LoaderIcon,
  PhoneIcon,
  PinIcon,
  PlusIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  IconButton,
  SelectItem,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  showWarnNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import { Maybe, ensure, hasValue, sortBy, utc } from '@finalytic/utils';
import { Box, Center, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useClipboard, useDebouncedState } from '@mantine/hooks';
import {
  formatAddress,
  formatPercentage,
  formatUserName,
  getCountryByIsoCode,
  getListingName,
  getStateByIsoCode,
  whereListings,
  whereOwnerUsers,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ListingOwnerBadge, VendorMappingSelect } from '../../components';
import { OwnerStatusBadge } from '../../views/owners/_components/OwnerStatusSelect';
import { StatementStatusBadge } from '../../views/statements/_components';
import { TaxStatementAmountCell } from '../../views/statements/statement-list/tax-statement-list-table/TaxStatementAmountCell';
import { TaxStatementStatusSelect } from '../../views/statements/statement-list/tax-statement-list-table/TaxStatementStatusSelect';
import { useTaxAccountCollectionQuery } from '../../views/statements/statement-list/tax-statement-list-table/useTaxAccountCollectionQuery';
import { getTaxStatement } from '../../views/statements/statement-list/tax-statement-list-table/useTaxStatementListTableQuery';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { OwnerEditForm } from './AddOwnerDrawer';
import { OwnerDefaultBankAccountInput } from './OwnerDefaultBankAccountInput';
import { OwnerEllipsisMenuItems } from './OwnerEllipsisMenuItems';
import { OwnerEllipsisMenuModals } from './OwnerEllipsisMenuModals';
import { useOwnerDetailDrawer } from './useOwnerDetailDrawer';
import {
  OwnerDetail,
  useOwnerDetailDrawerQuery,
} from './useOwnerDetailDrawerQuery';
import { useOwnerUserDetailDrawer } from './useOwnerUserDetailDrawer';

export const OwnerDetailDrawer = () => {
  const { GL } = useEnabledFeatures();
  const { opened, close, ownerId, view, setView } = useOwnerDetailDrawer();
  const { track } = useBrowserTracking();

  const [modalOpened, setModalOpened] = useState<{
    modal: 'delete' | 'archive' | 'migrate';
    owner: OwnerDetail;
  } | null>(null);

  const { isLoading, data: owner } = useOwnerDetailDrawerQuery(ownerId);

  const { mutate: mutateLegacyOwner } = useTrpcMutation('updateOwner', {
    invalidateQueryKeys: ['owners'],
  });

  const { mutate } = useMutation(
    (q, input: contact_set_input) => {
      if (input.id) {
        return q.updateContact({
          pk_columns: {
            id: input.id,
          },
          _set: input,
        }).id as string;
      }

      return q.insertContact({
        object: input,
      }).id as string;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  const updateContact = async (
    data: Parameters<typeof mutateLegacyOwner>[0]['input'] & {
      companyType: Maybe<contactCompanyType_enum | 'individual'>;
    }
  ) => {
    const hasAddress = !!data.address?.line1?.trim();

    if (
      hasAddress &&
      (!data.address?.stateCode || !data.address?.countryCode)
    ) {
      return showWarnNotification({
        title: 'Validation error',
        message: 'Please select a country and state.',
      });
    }

    const country =
      (data.address?.countryCode &&
        getCountryByIsoCode(data.address?.countryCode)?.name) ||
      '';
    const state =
      (data.address?.countryCode &&
        data.address?.stateCode &&
        getStateByIsoCode(data.address?.stateCode, data.address?.countryCode)
          ?.name) ||
      '';

    await mutate({
      args: {
        id: owner?.id,
        tenantId: owner?.tenantId,
        firstName: data.firstName?.trim(),
        name: data.lastName?.trim(),
        email: data.email?.toLowerCase().trim(),
        phone: data.phone?.trim(),
        taxId: data.taxId?.trim(),
        companyType:
          data.companyType === 'individual' ? null : data.companyType,
        type: 'owner',
        addressData: hasAddress
          ? {
              city: data.address!.city.trim(),
              line1: data.address!.line1.trim(),
              line2: data.address!.line2?.trim(),
              stateCode: data.address!.stateCode,
              state,
              countryCode: data.address!.countryCode,
              country,
              postcode: data.address!.postcode.trim(),
            }
          : null,
      },
    });
  };

  const closeModal = () => setModalOpened(null);

  return (
    <>
      {ownerId && (
        <OwnerEllipsisMenuModals
          owner={owner}
          deleteModal={{
            closeModal,
            opened: modalOpened?.modal === 'delete' && !!modalOpened?.owner,
          }}
          archiveModal={{
            closeModal,
            opened: modalOpened?.modal === 'archive' && !!modalOpened?.owner,
          }}
          migrateModal={{
            closeModal,
            opened: modalOpened?.modal === 'migrate' && !!modalOpened?.owner,
          }}
        />
      )}
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            <Group gap="xs" wrap="nowrap">
              <Icon
                icon={owner?.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
                size={22}
                color="gray"
              />
              <Text size="xl" fw={500} component="h3" m={0}>
                {owner?.name || 'Missing name'}
              </Text>
            </Group>
          }
          type="Owner"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            owner && (
              <OwnerEllipsisMenuItems
                openDeleteModal={() =>
                  setModalOpened({
                    modal: 'delete',
                    owner,
                  })
                }
                openArchiveModal={() =>
                  setModalOpened({
                    modal: 'archive',
                    owner,
                  })
                }
                openMigrationModal={() =>
                  setModalOpened({ modal: 'migrate', owner })
                }
                ownerId={owner.id}
                isArchived={owner.isArchived}
              />
            )
          }
          containerSx={
            view === 'edit'
              ? {
                  marginBottom: 0,
                }
              : undefined
          }
        />
        {!owner && !isLoading ? (
          'No owner found'
        ) : view === 'edit' && owner ? (
          <>
            <OwnerEditForm
              isOwnerAddModal={false}
              handleSubmit={async (values) => {
                if (!values.address.stateCode || !values.address.countryCode) {
                  return showErrorNotification({
                    message: 'Please select a country and state',
                    color: 'yellow',
                    icon: null,
                  });
                }

                const isIndividual = values.type === 'individual';

                const input: Parameters<typeof updateContact>[0] = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phone: values.phone,
                  taxId: values.taxId,
                  type: isIndividual ? 'individual' : 'company',
                  companyType: isIndividual ? null : values.type,
                  addressId: owner.addressId,
                  is1099PostalDelivery: values.is1099PostalDelivery,
                  address: !values.address.line1?.trim()
                    ? null
                    : {
                        line1: values.address.line1,
                        line2: values.address.line2 || '',
                        city: values.address.city,
                        postcode: values.address.postcode,
                        countryCode: values.address.countryCode,
                        stateCode: values.address.stateCode || '',
                        country:
                          getCountryByIsoCode(values.address.countryCode)
                            ?.name || '',
                        state:
                          getStateByIsoCode(
                            values.address.stateCode,
                            values.address.countryCode
                          )?.name || '',
                      },
                };

                if (GL) {
                  await updateContact(input);
                } else {
                  await mutateLegacyOwner({
                    ownerId: owner.id,
                    tenantId: owner.teamId,
                    input,
                  });
                }
                track('owner_updated', {
                  tenantId: owner.teamId,
                  ownerId: owner.id,
                });
                setView('overview');
              }}
              initialValues={{
                firstName: owner.firstName || '',
                lastName: owner.lastName || '',
                is1099PostalDelivery: owner.is1099PostalDelivery,
                companyName: '', // legacy
                email: owner.email!,
                defaultAccountId: owner.defaultAccountId ?? null,
                phone: owner.phone || '',
                taxId: owner.taxId || '',
                type:
                  owner.type === 'individual'
                    ? 'individual'
                    : owner.companyType?.value || 'individual',
                address: owner.address,
                addUserOption: false,
              }}
              onReset={() => setView('overview')}
              submitButtonLabel="Save changes"
              isEmailDisabled={false}
            />
          </>
        ) : (
          <Content owner={owner} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  owner,
  isLoading,
}: { owner: Maybe<OwnerDetail>; isLoading: boolean }) => {
  const { GL } = useEnabledFeatures();

  if (!owner) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: (owner.status === 'inactive' ||
              owner.pmsStatus === 'inactive') && (
              <OwnerStatusBadge {...owner} />
            ),
          },
          {
            icon: BriefcaseIcon,
            title: 'Type',
            text: owner.companyType?.label,
          },
          {
            icon: HashtagIcon,
            title: 'Tax ID',
            text: owner.taxId?.trim() ? <TaxId taxId={owner.taxId} /> : null,
          },
          {
            icon: EmailIcon,
            title: 'Email',
            text: owner.email,
          },
          {
            icon: PhoneIcon,
            title: 'Phone',
            text: owner.phone,
          },
          {
            icon: PinIcon,
            title: 'Address',
            text: formatAddress(owner.address),
          },
        ]}
      />

      {GL && (
        <OwnerDefaultBankAccountInput
          contactId={owner.id}
          accountId={owner.defaultAccountId || null}
        />
      )}

      {!GL && <VendorMappingSelect ownerId={owner.id} />}

      <UserAccesses userAccesses={owner.userAccesses} ownerId={owner.id} />

      {GL ? (
        <ListingPeriod memberships={owner.currentPeriodMemberships} />
      ) : (
        <ListingOwnerships ownerId={owner.id} ownerships={owner.ownerships} />
      )}
      <OwnerStatementsTable
        statements={owner.ownerStatements}
        ownerId={owner.id}
      />

      <TaxStatementsTable
        ownerId={owner.id}
        ownerStatements={owner.ownerStatements}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type Ownership = {
  id: string;
  listing: {
    id: string;
    name: string;
    address: string | undefined;
  };
  split: number | undefined;
};

const ListingPeriod = ({
  memberships: rows,
}: { memberships: OwnerDetail['currentPeriodMemberships'] }) => {
  const setListing = useQueryParamSet('listing', StringParam);

  return (
    <DrawerCollapsableTable
      title="Current Ownerships"
      rightSection={null}
      rowData={rows}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const member = row.original;

            return (
              <Badge
                key={member.id}
                color="gray"
                leftIcon={
                  <Center>
                    <Icon
                      icon={'HomeIcon'}
                      size={14}
                      color={(theme) => theme.colors.gray[6]}
                    />
                  </Center>
                }
              >
                {member.listingName}
              </Badge>
            );
          },
        },
        {
          header: 'Split',
          accessorKey: 'split',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const data = row.original;
            return formatPercentage((data.split || 0) / 100);
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No listing ownerships
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => setListing(row.original.listingId, 'push'),
      }}
    />
  );
};

const ListingOwnerships = ({
  ownerships,
  ownerId,
}: { ownerships: Ownership[]; ownerId: string }) => {
  const columns = useMemo<MRT_ColumnDef<OwnerDetail['ownerships'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'listing.name',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.listing.name}
            </Text>
            <Text component="span" display="block" size="xs" color="gray">
              {row.original.listing.address}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Split',
        accessorKey: 'split',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const split = row.original.split;
          const initialValue = typeof split === 'number' ? split : '';
          const [value, setValue] = useDebouncedState<number | string>(
            initialValue,
            500
          );
          const ownershipId = row.original.id;

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue, setValue]);
          const { track } = useBrowserTracking();

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string; split: number | null }) => {
              return q.updateListingOwner({
                pk_columns: {
                  id: args.ownershipId,
                },
                _set: {
                  split: args.split,
                },
              })?.id;
            }
          );

          return (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                gap: theme.spacing.xs,
              })}
            >
              <InputPercentage
                height={20}
                maw={90}
                value={value}
                onChange={setValue}
                loadingMutation={loading}
                placeholder="AUTO"
                decimalScale={2}
                hideControls
                onBlur={(event) => {
                  const newValue = event.target.value
                    ? parseFloat(event.target.value)
                    : '';

                  mutate({
                    args: {
                      ownershipId,
                      split: typeof newValue === 'number' ? newValue : null,
                    },
                  })
                    .then(() => {
                      setValue(newValue);
                    })
                    .then(() => {
                      track('ownership_updated', {
                        listingId: row.original.listing.id,
                        ownerId: ownerId,
                        ownershipId: ownershipId,
                      });
                    });
                }}
              />
            </Box>
          );
        },
      },
    ],
    [ownerId]
  );

  return (
    <DrawerCollapsableTable
      title="Listing ownerships"
      rightSection={<AddEntityToOwner ownerId={ownerId} type="listing" />}
      rowData={ownerships}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No listing ownerships
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();
          const { track } = useBrowserTracking();

          const ownershipId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string }) => {
              return q.deleteListingOwner({
                id: args.ownershipId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Ownership ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      ownershipId,
                    },
                  }).then(() => {
                    track('ownership_deleted', {
                      listingId: row.original.listing.id,
                      ownerId: ownerId,
                      ownershipId: ownershipId,
                    });
                  })
                }
              >
                Remove ownership
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(ownershipId)}
                >
                  Copy ownership ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const UserAccesses = ({
  userAccesses,
  ownerId,
}: { userAccesses: OwnerDetail['userAccesses']; ownerId: string }) => {
  const { open } = useOwnerUserDetailDrawer();

  const columns = useMemo<MRT_ColumnDef<OwnerDetail['userAccesses'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.name}
            </Text>
            <Text component="span" display="block" size="xs" color="gray">
              {row.original.email}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Role',
        accessorKey: 'role',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const [newRole, setNewRole] = useState<string>();

          const { mutate, loading } = useMutation(
            (
              q,
              args: {
                userAccessId: string;
                role: gqlV2.owner_user_access_role_enum;
              }
            ) => {
              return q.updateOwnerUserAccesses({
                pk_columns: {
                  id: args.userAccessId,
                },
                _set: {
                  role: args.role,
                },
              })?.role;
            }
          );

          const options = ensure<
            SelectItem<gqlV2.owner_user_access_role_enum>[]
          >([
            {
              label: 'Owner',
              value: 'admin',
              icon: <Edit3Icon size={16} />,
            },
            {
              label: 'Viewer',
              value: 'viewer',
              icon: <EyeIcon size={16} />,
            },
          ]);

          const value = newRole || row.original.role;
          const formatted = options.find((o) => o.value === value) || null;

          return (
            <Box w={130}>
              <InputSelect
                data={{
                  options,
                }}
                type="single"
                setValue={(value) => {
                  if (!value?.value) return;

                  mutate({
                    args: {
                      role: value.value as gqlV2.owner_user_access_role_enum,
                      userAccessId: row.original.id,
                    },
                  }).then((role) => setNewRole(role));
                }}
                value={formatted || null}
                inputProps={{
                  loadingMutation: loading,
                }}
                dropdownProps={{
                  withinPortal: true,
                  position: 'bottom-end',
                  width: 200,
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="User Access"
      rightSection={<AddEntityToOwner ownerId={ownerId} type="user" />}
      rowData={userAccesses}
      columns={columns}
      onRowClick={{
        handler: (row) => open(row.original.userId, 'overview', 'push'),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No users with access found
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();

          const accessId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { accessId: string }) => {
              return q.deleteOwnerUserAccess({
                id: args.accessId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Access ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      accessId,
                    },
                  })
                }
              >
                Remove user access
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(accessId)}
                >
                  Copy user access ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const OwnerStatementsTable = ({
  statements,
  ownerId,
  hideAddButton,
}: {
  statements: OwnerDetail['ownerStatements'];
  ownerId: string;
  hideAddButton?: boolean;
}) => {
  const goto = useNavigate();

  return (
    <DrawerCollapsableTable
      title="Owner statements"
      rightSection={
        !hideAddButton && (
          <AddEntityToOwner ownerId={ownerId} type="statement" />
        )
      }
      rowData={statements}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {utc(statement.startAt).format('MMM YYYY')}
                </Text>
                {statement.listing.name && (
                  <Text component="span" display="block" size="xs" color="gray">
                    {statement.listing.name}
                  </Text>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Group wrap="nowrap">
                <ListingOwnerBadge
                  role={statement.statementOwner.role}
                  name={statement.statementOwner.name}
                  variant="icon"
                />
                <StatementStatusBadge status={statement.status} />
              </Group>
            );
          },
        },
      ]}
      onRowClick={{
        handler: (row) =>
          goto(
            `/statement/${row.original.listing.id}?date=${utc(
              row.original.startAt
            ).yyyymmdd()}&statementOwner=${
              row.original.statementOwner.id
            }&owner=${ownerId}`
          ),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No owner statements
          </Text>
        </Center>
      )}
    />
  );
};

const TaxStatementsTable = ({
  ownerId,
  ownerStatements,
}: {
  ownerId: string;
  ownerStatements: OwnerDetail['ownerStatements'];
}) => {
  const [teamId] = useTeamId();

  const { isLoading: loadingYears, data: years } = useQuery(
    (q, args) => {
      const taxStatements = q
        .taxStatements({
          where: {
            ownerId: { _eq: args.ownerId },
            tenantId: { _eq: args.teamId },
          },
        })
        .map((x) => x.year);

      const years = Array.from(
        new Set([
          ...taxStatements.filter(hasValue),
          ...args.ownerStatementYears,
        ])
      );

      return sortBy(years, undefined, 'desc');
    },
    {
      skip: !ownerId || !teamId,
      variables: {
        ownerId,
        ownerStatementYears: ownerStatements
          .filter((x) => x.status === 'posted' || x.status === 'published')
          .map((statement) => utc(statement.startAt).year()),
        teamId,
      },
    }
  );

  const { collectionId, loading: loadingCollectionId } =
    useTaxAccountCollectionQuery();

  const { isLoading: loadingTaxStatements, data: taxStatements } = useQuery(
    (q, args) => {
      const taxAccountCollectionId = args.collectionId;

      if (!taxAccountCollectionId) return [];

      const owner = q.owner({
        id: args.ownerId,
      });

      const taxStatements = (args.years || []).map((year) => {
        return getTaxStatement({
          owner,
          year,
          teamId: args.teamId,
          taxAccountCollectionId,
        });
      });

      return taxStatements;
    },
    {
      skip: !ownerId || !teamId || !years?.length || !collectionId,
      variables: {
        ownerId,
        years,
        teamId,
        collectionId,
      },
    }
  );

  const loading = loadingCollectionId || loadingTaxStatements || loadingYears;

  return (
    <DrawerCollapsableTable
      title="Tax statements"
      rightSection={null}
      loading={loading}
      rowData={taxStatements || []}
      columns={[
        {
          header: 'Year',
          accessorKey: 'year',
          maxSize: 50,
        },
        {
          header: 'Status',
          accessorKey: 'status',
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return (
              <Group wrap="nowrap">
                <TaxStatementAmountCell {...row.original} />
                <TaxStatementStatusSelect
                  openFilingConfirmModal={() => {}}
                  statement={row.original}
                  disabled
                />
              </Group>
            );
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No tax statements
          </Text>
        </Center>
      )}
    />
  );
};

const AddEntityToOwner = ({
  ownerId,
  type,
}: { ownerId: string; type: 'listing' | 'statement' | 'user' }) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const [search, setSearch] = useState('');
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (
      q,
      { teamId, dashboard, search, ownerId, type, meId, GL },
      { limit, offset }
    ) => {
      if (type === 'user') {
        const where: gqlV2.user_bool_exp = {
          ...whereOwnerUsers({
            teamId,
            search,
          }),
          _not: {
            ownerAccesses: GL
              ? {
                  contactId: { _eq: ownerId },
                  contact: {
                    tenantId: { _eq: teamId },
                  },
                }
              : {
                  owner: {
                    id: {
                      _eq: ownerId,
                    },
                    tenantId: { _eq: teamId },
                  },
                },
          },
        };

        const list = q
          .user({
            where,
            limit,
            offset,
            order_by: [{ firstName: 'asc_nulls_last' }],
          })
          .map<SelectItem>((user) => ({
            label: formatUserName(user),
            value: user.id,
          }));

        const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      if (type === 'statement') {
        const where: gqlV2.owner_statement_bool_exp = {
          tenantId: { _eq: teamId },
          listing: {
            ownerships: {
              role: {
                _eq: 'spectator',
              },
              ownerId: { _eq: ownerId },
            },
            _or: search
              ? [
                  { title: { _ilike: `%${search}%` } },
                  { name: { _ilike: `%${search}%` } },
                  { address: { _ilike: `%${search}%` } },
                ]
              : undefined,
          },
          _not: {
            owners: {
              newOwnerId: {
                _eq: ownerId,
              },
            },
          },
        };

        const list = q
          .ownerStatements({
            where,
            limit,
            offset,
            order_by: [{ startAt: 'desc' }],
          })
          .map<SelectItem>((statement) => ({
            label: `${getListingName(statement.listing!)} - ${utc(
              statement.startAt
            ).format('MMM YYYY')}`,
            value: statement.id,
          }));

        const aggregate =
          q.ownerStatementAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where: gqlV2.listing_bool_exp = {
        ...whereListings({
          currentTeamId: teamId,
          dashboard,
          search,
          partnerTeamIds: [],
          meId,
          GL,
        }),
        _not: {
          ownerships: {
            newOwnerId: {
              _eq: ownerId,
            },
          },
        },
      };

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [
            {
              calculated_title: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
        }));
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        ownerId,
        type,
        meId,
        GL,
      },
    }
  );

  const { track } = useBrowserTracking();

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        entityId: string;
        ownerId: string;
        type: 'listing' | 'statement' | 'user';
        GL: boolean;
      }
    ) => {
      if (args.type === 'statement') {
        return q.insertOwnerStatementOwner({
          object: {
            statementId: args.entityId,
            newOwnerId: args.ownerId,
            role: 'spectator',
          },
        })?.id as string | undefined;
      }

      if (args.type === 'user') {
        return q.insertOwnerUserAccess({
          object: {
            userId: args.entityId,
            ownerId: GL ? undefined : args.ownerId,
            contactId: GL ? args.ownerId : undefined,
          },
        })?.id as string | undefined;
      }

      return q.insertListingOwner({
        object: {
          listingId: args.entityId,
          newOwnerId: args.ownerId,
          role: 'owner',
        },
      })?.id as string | undefined;
    },
    {
      invalidateQueryKeys: ['owners'],
      errorMessage: {
        message: (error) =>
          error?.message?.includes('owner_user_access_user_id_contact_id_key')
            ? 'This owner already has this user assigned. Please refresh the page.'
            : error?.message ||
              'Please reach out to support if the issue persists.',
      },
    }
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const entityId = value.value;
        mutate({ args: { entityId, ownerId, type, GL } }).then((res) => {
          if (type === 'listing') {
            track('ownership_created', {
              listingId: entityId,
              ownerId: ownerId,
              ownershipId: res,
            });
          }
        });
      }}
      dropdownProps={{
        position: 'bottom-end',
        noOptionsText:
          type === 'listing'
            ? 'No listings'
            : type === 'user'
              ? 'No users'
              : 'No owner statements available',
      }}
    >
      {() => {
        return (
          <IconButton loading={loading}>
            <PlusIcon size={18} />
          </IconButton>
        );
      }}
    </Select>
  );
};
