import { Body } from '@react-email/body';
import { Font } from '@react-email/font';
import { Head } from '@react-email/head';
import { Hr } from '@react-email/hr';
import { Html } from '@react-email/html';
import { Img } from '@react-email/img';
import { Preview } from '@react-email/preview';
import { Text } from '@react-email/text';
import { EmailBaseProps } from '../_types';

type Props = {
  children: React.ReactNode;
  title: string;
  tenant: Omit<EmailBaseProps['tenant'], 'name'>;
  mode: EmailBaseProps['mode'];
  shortDescription?: string;
};

export const EmailContainer = (props: Props) => {
  const { children, shortDescription, title } = props;

  const contentWrapperStyle = {
    paddingInline: '20px',
    paddingBlock: '10px',
    maxWidth: '500px',
    margin: '0 auto',
  };

  if (props.mode !== 'send') {
    return (
      <div style={contentWrapperStyle}>
        <Content {...props}>{children}</Content>
      </div>
    );
  }

  return (
    <Html lang="en">
      <Head>
        <title>{title}</title>
        <Font
          fontFamily="Inter"
          fallbackFontFamily="Verdana"
          webFont={{
            url: 'https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2',
            format: 'woff2',
          }}
          fontWeight={400}
          fontStyle="normal"
        />
        <Font
          fontFamily="Inter"
          fallbackFontFamily="Verdana"
          webFont={{
            url: 'https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2',
            format: 'woff2',
          }}
          fontWeight={600}
          fontStyle="normal"
        />
      </Head>
      {shortDescription && <Preview>{shortDescription}</Preview>}
      <Body style={contentWrapperStyle}>
        <Content {...props}>{children}</Content>
      </Body>
    </Html>
  );
};

const Content = ({ children, tenant: { logo: tenantLogo }, mode }: Props) => {
  return (
    <>
      {tenantLogo ? (
        <div
          style={{
            marginBottom: '60px',
            padding: '5px',
            // border: '1px solid rgb(233,236,239)',
            borderRadius: '5px',
            width: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Img src={tenantLogo} alt={'Logo'} width={70} />
        </div>
      ) : mode === 'send' ? (
        <Img
          src={'https://staging.portal.vrplatform.app/logo.png'}
          alt={'Logo'}
          width={70}
          style={{ marginBottom: '60px' }}
        />
      ) : (
        <div
          style={{
            width: '60px',
          }}
        >
          <VrpLogo />
        </div>
      )}

      {children}

      <Footer />
    </>
  );
};

const Footer = () => (
  <>
    <Hr
      style={{
        marginTop: '30px',
        marginBottom: '50px',
      }}
    />

    <Text
      style={{
        color: '#737373',
      }}
    >
      © {new Date().getFullYear()} VRPlatform Ventures Ltd. All rights reserved.
    </Text>
    <Text
      style={{
        color: '#737373',
      }}
    >
      VR Technologies, LLC, a company incorporated and registered in the United
      States of America with company number 20201965158
    </Text>
  </>
);

const VrpLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2549 8.25161C18.2549 5.89704 20.1636 3.98828 22.5182 3.98828C24.8728 3.98828 26.7815 5.89704 26.7815 8.25161C26.7815 10.6062 24.8728 12.5149 22.5182 12.5149C20.1636 12.5149 18.2549 10.6062 18.2549 8.25161Z"
      fill="#172554"
    />
    <path
      d="M7.02441 8.46593C7.02441 6.01867 9.20858 3.99805 11.6558 3.99805C14.1031 3.99805 15.0558 5.59785 16.5612 7.52728C17.1957 8.34054 20.7921 13.3413 20.7921 13.3413C20.7921 13.3413 21.566 14.6863 21.566 15.7894C21.566 18.5287 17.1993 22.8381 17.1993 22.8381L7.66841 10.6799C7.66841 10.6799 7.02441 10.0502 7.02441 8.46593Z"
      fill="#172554"
      fillOpacity="0.8"
    />
    <path
      d="M1.24707 8.42687C1.24707 5.97961 3.43123 3.95898 5.8785 3.95898C8.32576 3.95898 9.27845 5.55879 10.7839 7.48824C11.4184 8.30147 14.6041 12.4888 16.8247 15.5727C18.3585 17.703 18.474 17.705 18.474 20.3439C18.474 22.9827 15.1592 24.1379 14.1483 24.1914C12.0203 24.304 10.5418 22.4681 10.5418 22.4681L1.89106 10.6408C1.89106 10.6408 1.24707 10.0111 1.24707 8.42687Z"
      fill="#172554"
    />
  </svg>
);
