import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { TaxRateRow } from './useTaxRatesTableQuery';

export const TaxRateEllipsisMenuItems = ({
  data,
  openDeleteModal,
  openEditModal,
}: {
  data: TaxRateRow;
  openEditModal: () => void;
  openDeleteModal: () => void;
}) => {
  return (
    <>
      <EllipsisMenuItem
        customIcon={<Icon icon="Edit3Icon" size={16} />}
        onClick={openEditModal}
      >
        Edit
      </EllipsisMenuItem>
      <EllipsisMenuDangerItem onClick={openDeleteModal}>
        Delete
      </EllipsisMenuDangerItem>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuCopyItem value={data.id}>
          Copy tax rate ID
        </EllipsisMenuCopyItem>
      </HiddenFeatureIndicator>
    </>
  );
};
