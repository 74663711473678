import { ConfirmModal } from '@finalytic/components';
import { captureSentryError } from '@finalytic/data';
import { showWarnNotification } from '@finalytic/ui';
import { useReservationFeeMutation } from '../_hooks';

type Props = {
  recurringFee: {
    id: string;
  } | null;
  reservationId: string;
  closeModal: () => void;
};

export const ReservationFeeDeleteModal = ({
  recurringFee,
  closeModal,
  reservationId,
}: Props) => {
  const opened = !!recurringFee;

  const { submit, loading } = useReservationFeeMutation(
    reservationId,
    closeModal
  );

  return (
    <ConfirmModal
      type="delete"
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      title={'Are you sure you want to delete the adjustment?'}
      subtitle="This will delete any related journal entries."
      onSubmit={async () => {
        if (!recurringFee) {
          captureSentryError('Missing reservation adjustment to delete');
          showWarnNotification({
            title: 'Missing reservation adjustment',
            message: 'Please reach out to support if the issue persists.',
          });
          return;
        }

        return await submit({
          type: 'remove',
          data: {
            id: recurringFee.id,
          },
        });
      }}
    />
  );
};
