import { LoadingIndicator } from '@finalytic/ui';
import { InputProps, InputWrapperProps, Input as MInput } from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { ComponentPropsWithRef, forwardRef, useEffect, useState } from 'react';

type Props = {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  setValue?: (v: string) => void;
  debounce?: number;
} & Omit<InputProps, 'onChange'> &
  ComponentPropsWithRef<'input'>;

export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>(
  ({ styles, ...props }, ref) => (
    <MInput.Wrapper
      {...props}
      ref={ref}
      styles={(theme, u, o) => ({
        error: {
          paddingTop: 4,
        },
        description: {
          paddingTop: 4,
        },
        ...(typeof styles === 'function' ? styles(theme, u, o) : styles),
      })}
    >
      {props.children}
    </MInput.Wrapper>
  )
);
export const InputError = MInput.Error;

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      setValue,
      placeholder,
      loadingMutation,
      loadingQuery,
      debounce = 500,
      leftSection,
      value,
      sx,
      ...props
    },
    ref
  ) => {
    const hasOnchange = !!props.onChange;

    const [input, setInput] = useState(value);
    const [delayed] = useDebouncedValue(input, debounce);

    useDidUpdate(() => {
      if (typeof delayed === 'string' && setValue) {
        setValue(delayed);
      }
    }, [delayed]);

    useEffect(() => {
      if (!hasOnchange) setInput(value);
    }, [value, hasOnchange]);

    return (
      <MInput
        ref={ref}
        value={hasOnchange ? value : input}
        onChange={(event) => setInput(event.target.value)}
        sx={(theme, u) => ({
          height: '100%',
          width: props.width,
          input: {
            '&:focus': {
              boxShadow: `0px 0px 0px 2px ${
                theme.colors[props.error ? 'red' : theme.primaryColor][4]
              }40`,
              borderColor: props.error
                ? theme.colors.red[6]
                : theme.colors[theme.primaryColor][6],
            },
          },
          ".mantine-Input-section[data-position='right']": {
            pointerEvents: 'initial',
          },
          ...(typeof sx === 'function' ? (sx(theme, u) as any) : sx),
        })}
        radius="md"
        placeholder={placeholder}
        leftSection={
          loadingQuery ? <LoadingIndicator size="xs" /> : leftSection
        }
        rightSection={loadingMutation && <LoadingIndicator size="xs" />}
        {...props}
      />
    );
  }
);
