import { Filter } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { accountClassification_enum } from '@finalytic/graphql';
import { type SelectItem } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import { whereAccounts } from '@vrplatform/ui-common';

type Props = {
  value: Maybe<string>;
  setValue: (value: Maybe<accountClassification_enum>) => void;
};

export const JournalEntryClassificationFilter = ({
  setValue,
  value: initial,
}: Props) => {
  const [teamId] = useTeamId();

  const queryData = useQuery(
    (q, args) => {
      const where = whereAccounts({
        tenantId: args.teamId,
        search: undefined,
        classification: undefined,
        type: undefined,
      });

      const list = q
        .accounts({
          distinct_on: ['classification'],
          where,
          order_by: [{ classification: 'asc' }],
        })
        .map<SelectItem<accountClassification_enum>>((account) => ({
          label: toTitleCase(account.classification) || 'No classification',
          value: account.classification!,
        }));

      return {
        list,
      };
    },
    {
      variables: {
        teamId,
      },
    }
  );

  const options = queryData.data?.list || [];

  const value = initial
    ? {
        value: initial,
        label: toTitleCase(initial),
      }
    : null;

  return (
    <Filter.Select
      value={value}
      label="Classification"
      setValue={(v) => setValue(v?.value as any)}
      type="single"
      data={{
        options,
        loading: queryData.isLoading,
        error: queryData.error,
      }}
      hideSearch
    />
  );
};
