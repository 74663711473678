import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { useNavigate } from 'react-router';
import { useJournalEntriesModal } from '../../modals';
import { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';
import { useExpenseDetailDrawer } from './useExpenseDetailDrawer';

export const ExpenseEllipsisMenuItems = ({
  expense,
  openDeleteModal,
  openPaymentStatusModal,
  isDrawer,
}: {
  expense: ExpenseRow;
  openDeleteModal: () => void;
  openPaymentStatusModal: () => void;
  isDrawer?: boolean;
}) => {
  const goto = useNavigate();
  const { open: openQuickDetail } = useExpenseDetailDrawer();
  const { open: openJournalEntriesModal } = useJournalEntriesModal();

  return (
    <>
      {!isDrawer && (
        <EllipsisMenuItem
          onClick={() => openQuickDetail(expense.id)}
          customIcon={<Icon icon={'EyeIcon'} size={16} />}
        >
          Quick view
        </EllipsisMenuItem>
      )}

      <EllipsisMenuItem
        onClick={() => goto(`/expense/${expense.id}/edit`)}
        customIcon={<Edit3Icon size={16} />}
      >
        Edit
      </EllipsisMenuItem>

      <EllipsisMenuItem
        onClick={openPaymentStatusModal}
        customIcon={<Icon icon="CircleDollarIcon" size={16} />}
      >
        Mark as {expense.paidStatus === 'paid' ? 'unpaid' : 'paid'}
      </EllipsisMenuItem>

      <EllipsisMenuItem
        customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
        onClick={() =>
          openJournalEntriesModal({
            transactionId: {
              _eq: expense.id,
            },
          })
        }
      >
        Journal Entries
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={openDeleteModal}>
        Delete
      </EllipsisMenuDangerItem>
      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuCopyItem value={expense.id} />
      </HiddenFeatureIndicator>
    </>
  );
};
