import {
  useBilling,
  useDashboard,
  useEnabledFeatures,
  useMe,
  useQuery,
  useTeam,
  useTeamId,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  ApiTokensIcon,
  AutomationsIcon,
  BillingIcon,
  BracketsIcon,
  DashboardIcon,
  FileTextIcon,
  FingerprintIcon,
  Icon,
  MembersIcon,
  NotificationIcon,
  OwnerPortalIcon,
  OwnerStatementsIcon,
  PercentageCircleIcon,
  RouteIcon,
  UserIcon,
} from '@finalytic/icons';
import { Box, Center, Skeleton, Stack } from '@mantine/core';
import { NavbarRoute } from '../layout/navbar';
import { getOwnerPortalTaxStatementSetting } from '../queries';
import { NavigationSection } from './NavigationSection';

export const SettingsNavigation = () => {
  const [dashboard] = useDashboard();
  const { isTeamAdmin } = useTeamRole();
  const [{ membershipId }] = useTeam();
  const { showBilling } = useBilling();
  const { GL, LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();

  const isPmDashboard = dashboard === 'propertyManager';
  const isOwnerPortal = dashboard === 'owner';

  return (
    <Stack gap="xl">
      <NavigationSection title={isOwnerPortal ? '' : 'User Account'}>
        <NavbarRoute
          title="Profile"
          link="/settings/profile"
          icon={<UserIcon size={20} />}
        />
        <HiddenFeatureIndicator permission="super-admin">
          <NavbarRoute
            title="Developer"
            link="/settings/developer"
            icon={<Icon icon="GearIcon" size={18} />}
          />
        </HiddenFeatureIndicator>

        {isOwnerPortal && <OwnerSettingsNavigation />}
      </NavigationSection>

      {!isOwnerPortal && dashboard !== 'overview' && (
        <>
          <NavigationSection title="Team">
            <NavbarRoute
              title="General"
              link="/settings/team/general"
              icon={<DashboardIcon size={20} />}
            />

            {isTeamAdmin && (
              <NavbarRoute
                title="Security"
                link="/settings/team/security"
                icon={<FingerprintIcon size={20} />}
              />
            )}

            {isPmDashboard && (
              <NavbarRoute
                title="Members"
                link="/settings/team/members"
                icon={<MembersIcon />}
              />
            )}
            {showBilling && isTeamAdmin && (
              <NavbarRoute
                title="Billing"
                link={'/settings/team/billing'}
                icon={<BillingIcon />}
              />
            )}
            {isPmDashboard && (
              <HiddenFeatureIndicator permission="super-admin">
                <NavbarRoute
                  title="Automations"
                  link="/settings/team/automations"
                  icon={<AutomationsIcon size={20} />}
                />
              </HiddenFeatureIndicator>
            )}
            {LIVEBLOCKS_NOTIFICATIONS && membershipId && (
              <NavbarRoute
                title="Notifications"
                link="/settings/team/notifications"
                icon={<Icon icon="NotificationIcon" size={18} />}
              />
            )}
          </NavigationSection>

          <NavigationSection title="Integrations">
            <NavbarRoute
              title="Api tokens"
              link="/settings/integrations/api-tokens"
              icon={<ApiTokensIcon />}
            />
            {isPmDashboard && (
              <NavbarRoute
                title="Webhooks"
                link="/settings/integrations/webhooks"
                icon={<BracketsIcon size={18} />}
              />
            )}
            {GL && (
              <>
                <NavbarRoute
                  title="Accounts"
                  link="/settings/integrations/accounts"
                  icon={<RouteIcon size={18} />}
                />
                <NavbarRoute
                  title="Tax Rates"
                  link="/settings/integrations/tax-rates"
                  icon={<PercentageCircleIcon size={18} />}
                />
                <NavbarRoute
                  title="Contacts"
                  link="/settings/integrations/contacts"
                  icon={<Icon icon="UsersIcon" size={18} />}
                />
              </>
            )}
          </NavigationSection>

          {isPmDashboard && (
            <NavigationSection title="Features">
              <NavbarRoute
                title="Emails"
                link="/settings/features/emails"
                icon={<Icon icon="EmailIcon" size={18} />}
              />

              <NavbarRoute
                title="Owner Portal"
                link="/settings/features/owner-portal"
                icon={<OwnerPortalIcon />}
              />

              <HiddenFeatureIndicator permission="super-admin">
                <NavbarRoute
                  title="Owner Statements"
                  link="/settings/features/owner-statements"
                  icon={<OwnerStatementsIcon />}
                />
              </HiddenFeatureIndicator>
            </NavigationSection>
          )}
        </>
      )}
    </Stack>
  );
};

const OwnerSettingsNavigation = () => {
  const { id: meId } = useMe();
  const [teamId] = useTeamId();

  const { data, isInitialLoading } = useQuery(
    (q, args) => {
      const hasOwnerAccess = !!q
        .ownerUserAccesses({
          where: {
            userId: { _eq: args.meId },
            role: { _eq: 'admin' },
          },
          limit: 1,
        })
        .map((x) => x.id).length;

      const showTaxStatements = getOwnerPortalTaxStatementSetting(q, args);

      return {
        hasOwnerAccess,
        showTaxStatements,
      };
    },
    {
      keepPreviousData: true,
      variables: {
        meId,
        teamId,
      },
    }
  );

  return (
    <>
      <NavbarRoute
        title="Notifications"
        link="/settings/notifications"
        icon={
          <Center w={20}>
            <NotificationIcon size={16} />
          </Center>
        }
      />

      {!data || isInitialLoading ? (
        <Box px="xs" mt="xs">
          <Skeleton
            height={20}
            animate
            sx={{
              '&::before': {
                backgroundColor: 'unset',
              },
            }}
          />
        </Box>
      ) : (
        <>
          {data?.hasOwnerAccess && (
            <NavbarRoute
              title="Statement Details"
              link="/settings/details"
              icon={<FileTextIcon size={20} strokeWidth={1.2} />}
            />
          )}
        </>
      )}
    </>
  );
};
