import { Button } from '@finalytic/components';
import { useInvalidateQueries } from '@finalytic/data';
import { Group } from '@mantine/core';
import { SettingsTitle } from '../_components';
import { AddTaxRateButton } from './AddTaxRateButton';
import { TaxRatesTable } from './TaxRatesTable';

export const SettingsTaxRatesView = () => {
  return (
    <>
      <Group justify="space-between" mb="lg">
        <SettingsTitle type="view-title">Tax Rates</SettingsTitle>
        <Group>
          <RefreshButton />
          <AddTaxRateButton />
        </Group>
      </Group>
      <TaxRatesTable />
    </>
  );
};

const RefreshButton = () => {
  const invalidate = useInvalidateQueries(['taxRates']);

  return (
    <Button
      variant="transparent"
      leftIcon={'RefreshCwIcon'}
      onClick={() => invalidate()}
    >
      Refresh data
    </Button>
  );
};
