import { Icon, IconDefinition } from '@finalytic/icons';
import {
  ActionIcon,
  ActionIconProps,
  Indicator,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ComponentProps, ReactNode, forwardRef } from 'react';

type Props = {
  count?: number;
  size?: number;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: IconDefinition;
  strokeWidth?: number;
  color?: ComponentProps<typeof Icon>['color'];
  tooltip?: ReactNode;
  variant?: 'default' | 'outline';
  sx?: EmotionSx;
} & Omit<
  ActionIconProps,
  'onClick' | 'children' | 'color' | 'size' | 'variant' | 'sx'
>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      icon,
      variant = 'default',
      size: s,
      color,
      count,
      tooltip,
      strokeWidth,
      sx,
      ...props
    },
    ref
  ) => {
    const theme = useMantineTheme();

    const defaultSize = variant === 'outline' ? 16 : 18;

    const size = s ?? defaultSize;

    return (
      <Tooltip
        label={tooltip}
        withArrow
        disabled={!tooltip || (typeof tooltip === 'string' && !tooltip?.trim())}
      >
        <Indicator
          label={count}
          color={theme.colors.red[9]}
          position="top-end"
          styles={() => ({
            indicator: {
              height: 15,
              width: 16,
              fontSize: 11,
              borderRadius: '100%',
            },
          })}
          offset={0}
          disabled={!count}
        >
          <ActionIcon
            onClick={onClick}
            ref={ref}
            variant={variant === 'default' ? 'transparent' : 'outline'}
            sx={(theme, u) => ({
              borderRadius: theme.radius.sm,
              borderColor:
                variant === 'outline' ? theme.colors.gray[3] : undefined,
              padding: theme.spacing.xs,
              ':hover': {
                backgroundColor: theme.colors.gray[0] + 10,
              },
              ...((typeof sx === 'function' ? sx(theme, u) : sx || {}) as any),
            })}
            {...props}
          >
            <Icon
              icon={icon}
              color={color}
              size={size}
              strokeWidth={strokeWidth}
            />
          </ActionIcon>
        </Indicator>
      </Tooltip>
    );
  }
);
