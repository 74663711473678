import { type TimelineItem } from '@finalytic/components';
import { useDashboard, useQuery, useTeam } from '@finalytic/data';
import { type Maybe, hasValue, toTitleCase } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatUserName,
  getListingName,
  queryReservationFinancials,
  queryReservationPaymentLines,
} from '@vrplatform/ui-common';

export function useLegacyReservationDetailDrawerQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((reservation) => {
            const financials = queryReservationFinancials(reservation, {
              partnerId: args.partnerId,
              tenantId: args.teamId,
              GL: false,
            });

            const exluded = financials.filter((x) => x.isInvoice !== 'invoice');
            const included = financials.filter(
              (x) => x.isInvoice === 'invoice'
            );

            type TItem = TimelineItem & {
              v2: boolean;
              workflowId: string | undefined;
              syncId: string | undefined;
            };

            const actionTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    ?.actionLinks({
                      order_by: [{ createdAt: 'desc' }],
                    })
                    .map<TItem>((link) => {
                      const action = link.action;

                      const getIcon = () => {
                        const automation =
                          action?.automation || action?.jobPlan?.automation;
                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      const label = [
                        toTitleCase(action.schema?.uniqueRef),
                        action.title,
                      ]
                        .filter(hasValue)
                        .join(' - ');

                      return {
                        id: link.actionId,
                        label,
                        date: action.createdAt,
                        icon: getIcon(),
                        workflowId: action.jobPlan?.workflowId,
                        syncId: undefined,
                        v2: false,
                      };
                    });
            const changeTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    .changeSourceLinks({
                      order_by: [{ created_at: 'desc' }],
                    })
                    .map<TItem>((sourceChange) => {
                      const getIcon = () => {
                        const automation = sourceChange?.change.automation;

                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      return {
                        id: sourceChange.change.id,
                        syncId: sourceChange.change.syncId,
                        workflowId: sourceChange.change.syncId,
                        label: [
                          toTitleCase(
                            sourceChange.change.entityUniqueRef
                              ?.split('/')
                              .at(0)
                          ),
                          sourceChange.change.message,
                        ]
                          .filter(hasValue)
                          .join(' - '),
                        date: sourceChange.created_at,
                        v2: true,
                        icon: getIcon(),
                      };
                    });
            const timeline = [...actionTimeline, ...changeTimeline];

            const paymentLines = queryReservationPaymentLines(reservation, {
              includeAllType2s: true,
            });

            const reservationlistingConnectionListing = {
              id: reservation.listingConnection?.listing?.id,
              name: getListingName(reservation.listingConnection?.listing),
            };

            const reservationListing = {
              id: reservation.listingId,
              name: getListingName(reservation.listing),
            };

            return {
              id: reservation.id,
              bookedAt: reservation.bookedAt,
              connection: {
                id: reservation.connection?.id,
                app: {
                  id: reservation.connection?.app?.id,
                  iconRound: reservation.connection?.app?.iconRound,
                  name: reservation.connection?.app?.name,
                },
              },
              channel:
                reservation.channel?.uniqueRef || reservation.bookingPlatform,
              cancelledAt: reservation.cancelledAt,
              checkIn: reservation.checkIn,
              checkOut: reservation.checkOut,
              guestName: reservation.guestName,
              status: reservation.status,
              centTotal: reservation.centTotal,
              centPaid: reservation.centPaid ?? 0,
              paidStatus: reservation.paidStatus,
              userData: reservation.userdata(),
              confirmationCode: reservation.confirmationCode,
              pmsReferenceCode: reservation.pmsReferenceCode,
              hasListingOwnership: !!reservation.listing
                ?.ownershipPeriods({
                  limit: 1,
                })
                .map((x) => x.id)[0],
              listing: reservationListing?.id
                ? reservationListing
                : reservationlistingConnectionListing,
              currency: reservation.currency || 'usd',
              financials: {
                included,
                exluded,
              },
              timeline,
              paymentLines: paymentLines.filter((x) => x.isResevationPayment),
              resolutionLines: paymentLines.filter(
                (x) => !x.isResevationPayment
              ),
              files: reservation
                ?.files({
                  order_by: [{ startDate: 'desc_nulls_last' }],
                })
                .map((file) => ({
                  id: file.id,
                  type: file.type,
                  filename: file.filename,
                  ownerName: file.owner && formatUserName(file.owner),
                  startDate: file.startDate,
                  endDate: file.endDate,
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'reservations',
      variables: {
        id,
        teamId,
        partnerId,
        dashboard,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type LegacyReservation = NonNullable<
  ReturnType<typeof useLegacyReservationDetailDrawerQuery>['data']
>;
