import { useHasEnabledFeatures } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useVrpRetoolQuery } from '../../queries';

const FeaturesView = lazy(() => import('../../views/features'));
const SettingsView = lazy(() => import('../../views/settings'));
const AutomationsView = lazy(() => import('../../views/partner-automations'));
const BillingView = lazy(() => import('../../views/vrp-billing'));
const UsersView = lazy(() => import('../../views/vrp-users'));
const TeamsView = lazy(() => import('../../views/vrp-teams'));
const ConnectionsView = lazy(() => import('../../views/partner-connections'));
const MembersView = lazy(() => import('../../views/partner-members'));
const OwnerStatementsView = lazy(
  () => import('../../views/partner-statements')
);
const AccountsView = lazy(() => import('../../views/accounts'));
const LegacyReportsView = lazy(() => import('../../views/reports/ReportsView'));
const RetoolView = lazy(() => import('../../views/retool/RetoolView'));
const DashboardView = lazy(() => import('../../views/partner-dashboard'));

const StatementLayoutsView = lazy(
  () => import('../../views/statements/layouts')
);

export const VrpRoutes = () => {
  const hasEnabledFeatures = useHasEnabledFeatures();

  const { data: vrpRetoolViews } = useVrpRetoolQuery();

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route path="dashboard">
          <Route
            index
            element={<Navigate to="/dashboard/overview" replace />}
          />
          <Route path=":tabValue" element={<DashboardView />} />
        </Route>

        <Route
          path="property-manager"
          element={<TeamsView type="propertyManager" />}
        />
        <Route path="partner" element={<TeamsView type="partner" />} />
        <Route path="users" element={<UsersView />} />

        <Route path="billing">
          <Route index element={<Navigate to="/billing/teams" replace />} />
          <Route path=":tabValue" element={<BillingView />} />
        </Route>

        <Route path="accounts">
          <Route index element={<Navigate to="/accounts/overview" replace />} />
          <Route path=":tabValue" element={<AccountsView />} />
        </Route>

        <Route
          path="statements/templates"
          element={<Navigate to="/statements/templates/default" replace />}
        />
        <Route
          path="statements/templates/:tabValue"
          element={<StatementLayoutsView />}
        />

        {!!vrpRetoolViews?.length && (
          <Route path="retool">
            <Route
              index
              element={
                <Navigate to={`/retool/${vrpRetoolViews[0].id}`} replace />
              }
            />
            {vrpRetoolViews.map((view) => (
              <Route
                key={view.id}
                path={view.id}
                element={<RetoolView title={view.title} id={view.id} />}
              />
            ))}
          </Route>
        )}

        <Route path="members" element={<MembersView />} />

        <Route path="team-statements">
          <Route
            index
            element={
              <Navigate to="/team-statements/owner-statements" replace />
            }
          />
          <Route path=":tabValue" element={<OwnerStatementsView />} />
        </Route>

        <Route path="team-automations">
          <Route
            index
            element={<Navigate to="/team-automations/overview" replace />}
          />
          <Route path=":tabValue" element={<AutomationsView />} />
        </Route>

        <Route path="team-connections">
          <Route index element={<ConnectionsView />} />
          <Route path=":tabValue" element={<ConnectionsView />} />
        </Route>

        {hasEnabledFeatures && (
          <Route path="features">
            <Route path=":tabValue" element={<FeaturesView />} />
            <Route index element={<Navigate to="features" replace />} />
          </Route>
        )}

        <Route path="reports">
          <Route index element={<LegacyReportsView />} />
          <Route path=":id" element={<LegacyReportsView />} />
        </Route>

        {/* user settings */}
        <Route path="settings/*" element={<SettingsView />} />

        {/* catch all */}
        <Route path="*" element={<Navigate to="/property-manager" replace />} />
      </Routes>
    </Suspense>
  );
};
