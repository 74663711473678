import {
  Badge,
  Button,
  InputPercentage,
  InputSelect,
  InputSelectTarget,
} from '@finalytic/components';
import {
  gqlV2,
  useBrowserTracking,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { listing_owner_role_enum } from '@finalytic/graphql';
import {
  HomeIcon,
  Icon,
  OfficeIcon,
  TrashIcon,
  UserIcon,
} from '@finalytic/icons';
import { IconButton, SelectItem, showErrorNotification } from '@finalytic/ui';
import { Maybe, emptyUUID } from '@finalytic/utils';
import {
  Box,
  Center,
  Divider,
  Group,
  Popover,
  Stack,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
  whereOwners,
} from '@vrplatform/ui-common';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ListingOwnerBadge } from './ListingOwnerBadge';

type Table = 'listing' | 'owner';

type BaseProps = {
  ownerships: Ownership[];
  companyOwnerships: CompanyOwnership[];
  rowId: string;
  inputDisabled: boolean;
};
type ListingTable = BaseProps & {
  table: 'listing';
  ownerType?: undefined;
};

type OwnerTable = BaseProps & {
  table: 'owner';
  ownerType: 'individual' | 'company';
};

type CellProps = ListingTable | OwnerTable;

type CompanyOwnership = {
  ownershipId: string;
  listingName: Maybe<string>;
  companyName: Maybe<string>;
};

type Ownership = {
  ownershipId: Maybe<string>;
  rowId: string;
  role: gqlV2.listing_owner_role_enum | undefined;
  split: Maybe<number>;
  vendor: Maybe<{
    label: string;
    value: string;
    settingId: any;
  }>;
  listing: Maybe<{
    id: string;
    name: Maybe<string>;
  }>;
  owner: Maybe<{
    id: string;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    type: 'individual' | 'company';
  }>;
};

export const InputListingOwners = memo(function Cell({
  ownerships,
  rowId,
  table: target,
  companyOwnerships,
  ownerType,
  inputDisabled,
}: CellProps) {
  const [tenantId] = useTeamId();
  const [opened, handlers] = useDisclosure(false);
  const theme = useMantineTheme();

  const value = useMemo<SelectItem[]>(() => {
    const comps = companyOwnerships.map((ship) => ({
      value: ship.ownershipId!,
      label: ship.listingName || '',
      group: 'company',
    }));

    const owns = ownerships.map((ship) => ({
      value: ship.ownershipId!,
      label:
        target === 'listing'
          ? formatUserName(ship.owner!)
          : ship.listing?.name || '',
      group: ship.role,
    }));

    return [...comps, ...owns];
  }, [ownerships, companyOwnerships, target]);

  return (
    <Popover
      opened={inputDisabled ? false : opened}
      onClose={handlers.close}
      width={400}
      position="bottom-end"
      shadow="md"
      // closeOnClickOutside={false}
      trapFocus
      withinPortal
      disabled={inputDisabled}
    >
      <Popover.Target>
        <Box
          onClick={(e) => {
            if (inputDisabled) return;
            e.stopPropagation();
            handlers.toggle();
          }}
          w="100%"
        >
          <InputSelectTarget
            opened={opened}
            type="multiple"
            handlers={handlers}
            value={value}
            setValue={() => null}
            withBorder={!inputDisabled}
            placeholder={
              !inputDisabled
                ? target === 'owner'
                  ? 'Select listings'
                  : 'Select Owners'
                : undefined
            }
            // placeholder="Add ownerships"
            customMultiPill={(item) => {
              const ownership = ownerships.find(
                (o) => o.ownershipId === item.value
              );

              return (
                <Badge
                  color={
                    target === 'listing' && ownership?.owner?.type === 'company'
                      ? 'violet'
                      : theme.primaryColor
                  }
                  leftIcon={
                    <Center>
                      <Icon
                        icon={
                          target === 'owner'
                            ? 'HomeIcon'
                            : ownership?.owner?.type === 'company'
                              ? 'OfficeIcon'
                              : 'UserIcon'
                        }
                        size={14}
                        color={(theme) =>
                          target === 'listing' &&
                          ownership?.owner?.type === 'company'
                            ? theme.colors.violet[5]
                            : theme.colors[theme.primaryColor][5]
                        }
                      />
                    </Center>
                  }
                >
                  {item.label}
                </Badge>
              );
            }}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown onClick={(e) => e.stopPropagation()}>
        {/* stop propagation to fix accidental onRowClick */}
        <Dropdown
          ownerships={ownerships}
          rowId={rowId}
          closeDropdown={handlers.close}
          tenantId={tenantId}
          target={target}
          companyOwnerships={companyOwnerships}
          ownerType={ownerType}
        />
      </Popover.Dropdown>
    </Popover>
  );
});

const Dropdown = ({
  ownerships,
  rowId,
  tenantId,
  target,
  companyOwnerships,
  ownerType,
}: {
  ownerships: Ownership[];
  companyOwnerships: CompanyOwnership[];
  rowId: string;
  tenantId: string;
  closeDropdown: () => void;
  target: Table;
  ownerType?: 'individual' | 'company';
}) => {
  const { primaryColor, colors } = useMantineTheme();

  const [showAddRow, setShowAddRow] = useState(false);

  const show = ownerships.length < 1 || showAddRow;

  return (
    <>
      {!!companyOwnerships.length && (
        <>
          <Stack gap="sm" mt="xs">
            {companyOwnerships.map((ownership) => (
              <Group
                key={ownership.ownershipId}
                sx={{ justifyContent: 'stretch' }}
              >
                <Text
                  size="sm"
                  component="span"
                  sx={{ flex: 1, display: 'block', maxWidth: 220 }}
                >
                  {ownership.listingName}
                </Text>
                <ListingOwnerBadge
                  name={ownership.companyName}
                  role="company"
                />
              </Group>
            ))}
          </Stack>
          <Divider my="lg" color={colors.gray[4]} />
        </>
      )}

      <Stack gap="sm">
        {ownerships.map((ship) => (
          <OwnershipRow
            target={target}
            key={ship.ownershipId}
            {...ship}
            tenantId={tenantId}
            ownerType={ownerType}
          />
        ))}
        <Transition mounted={show} transition="fade">
          {(style) => (
            <div style={style}>
              <NewOwnershipRow
                target={target}
                rowId={rowId}
                tenantId={tenantId}
                hideAddRow={() => setShowAddRow(false)}
                ownerType={ownerType}
              />
            </div>
          )}
        </Transition>
      </Stack>

      <Transition mounted={!show} transition="fade" exitDuration={0}>
        {(style) => (
          <Box mt="sm" style={style}>
            <Button
              color={primaryColor}
              variant="transparent"
              leftIcon={'PlusIcon'}
              onClick={(event) => {
                event.stopPropagation();
                setShowAddRow(true);
              }}
              size="xs"
            >
              {target === 'owner' ? 'Add listing' : 'Add owner'}
            </Button>
          </Box>
        )}
      </Transition>
    </>
  );
};

type RowProps = Ownership & {
  setAddOwnership?: React.Dispatch<React.SetStateAction<Ownership>>;
  hideAddRow?: () => void;
  tenantId: string;
  target: Table;
  ownerType?: 'individual' | 'company';
};

const NewOwnershipRow = ({
  rowId,
  hideAddRow,
  tenantId,
  target,
  ownerType,
}: {
  rowId: string;
  tenantId: string;
  hideAddRow: () => void;
  target: Table;
  ownerType?: 'individual' | 'company';
}) => {
  const [ownership, setAddOwnership] = useState<Ownership>({
    rowId,
    owner: null,
    role: 'owner',
    ownershipId: null,
    vendor: null,
    split: undefined,
    listing: null,
  });

  return (
    <OwnershipRow
      {...ownership}
      setAddOwnership={(state) => {
        setAddOwnership(state);
      }}
      hideAddRow={hideAddRow}
      tenantId={tenantId}
      target={target}
      ownerType={ownerType}
    />
  );
};

const OwnershipRow = ({ ownerType, ...props }: RowProps) => {
  const { track } = useBrowserTracking();

  const { mutate, loading } = useMutation(
    (q, args: { ownershipId: string; vendorSettingId: Maybe<string> }) => {
      const ownership = q.deleteListingOwner({ id: args.ownershipId })?.id;

      let setting: string | undefined;
      if (args.vendorSettingId) {
        setting = q.delete_setting_by_pk({ id: args.vendorSettingId })?.id;
      }

      return {
        ownership,
        setting,
      };
    },
    {
      invalidateQueryKeys: ['listingOwners', 'listings', 'owners'],
    }
  );

  const removeRow = useCallback(async () => {
    const ownershipId = props.ownershipId;
    if (!ownershipId)
      return showErrorNotification({
        message: 'Missing ownership.',
        color: 'yellow',
      });

    await mutate({
      args: { ownershipId, vendorSettingId: props.vendor?.settingId },
    }).then(() =>
      track('ownership_deleted', {
        ownershipId: props.ownershipId,
        listingId: props.listing?.id,
        ownerId: props.owner?.id,
      })
    );
  }, [props.ownershipId, props.vendor?.settingId]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: theme.spacing.sm,
      })}
    >
      <Box sx={{ flex: 1 }}>
        <ListingOwner {...props} />
      </Box>
      <SplitInput {...props} />
      {props.ownershipId ? (
        <IconButton loading={loading} onClick={removeRow}>
          <TrashIcon size={18} />
        </IconButton>
      ) : (
        <Box w={28} />
      )}
    </Box>
  );
};

const ListingOwner = ({
  owner,
  ownershipId,
  rowId,
  role,
  split,
  tenantId,
  vendor,
  hideAddRow,
  listing,
  target,
}: RowProps) => {
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { tenantId, search, rowId, target }, { limit, offset }) => {
      if (target === 'listing') {
        // * Query new owners
        const where: gqlV2.owner_bool_exp = {
          ...whereOwners({
            teamId: tenantId,
            search,
          }),
          _not: {
            ownerships: {
              listingId: { _eq: rowId },
            },
          },
        };

        const list = q
          .owners({
            where,
            limit,
            offset,
            order_by: [
              {
                type: 'asc_nulls_last',
              },
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map<SelectItem>((owner) => ({
            label: formatOwnerName(owner, { lastNameFirst: true }) || '',
            value: owner.id,
            group: owner.type === 'company' ? 'Company' : 'Individual',
            icon:
              owner.type === 'company' ? (
                <OfficeIcon size={16} />
              ) : (
                <UserIcon size={14} />
              ),
          }));

        const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      // * Query listings
      const where: gqlV2.listing_bool_exp = {
        tenantId: { _eq: tenantId },
        calculatedStatus: { _eq: 'active' },
        _not: {
          ownerships: {
            newOwnerId: { _eq: rowId },
          },
        },
        _or: search
          ? [
              {
                title: { _ilike: `%${search}%` },
              },

              {
                name: { _ilike: `%${search}%` },
              },

              {
                address: { _ilike: `%${search}%` },
              },
            ]
          : undefined,
      };

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
        })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !tenantId,
      queryKey: ['owners', 'listings'],
      variables: {
        tenantId,
        rowId,
        search: search?.trim(),
        target,
      },
    }
  );

  const { data, isLoading: loadingValue } = useQuery(
    (q, { ownerId, target, listingId }) => {
      if (target === 'listing') {
        const value = q
          .owners({ where: { id: { _eq: ownerId || emptyUUID } }, limit: 1 })
          .map<SelectItem>((owner) => ({
            label: formatOwnerName(owner) || '',
            value: owner.id,
            icon: (
              <Icon
                icon={owner.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
                size={owner.type === 'company' ? 16 : 14}
                color={({ colors, primaryColor }) => colors[primaryColor][5]}
              />
            ),
          }))[0];
        return {
          value,
        };
      }

      const value = q
        .listings({ where: { id: { _eq: listingId || emptyUUID } }, limit: 1 })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
          icon: <HomeIcon size={16} />,
        }))[0];

      return {
        value,
      };
    },
    {
      skip: !tenantId,
      queryKey: ['owners', 'listings'],
      keepPreviousData: true,
      variables: {
        ownerId: owner?.id,
        listingId: listing?.id,
        target,
      },
    }
  );

  const { mutate, loading: loadingMutation } = useOwnershipMutation();

  const setOwner = (value: SelectItem) => {
    mutate({
      args: {
        ownershipId,
        listingId: target === 'owner' ? value.value : rowId,
        ownerId: target === 'listing' ? value.value : rowId,
        role: 'owner',
        split,
        vendorSettingId: vendor?.settingId,
      },
    }).then(hideAddRow);
  };

  return (
    <InputSelect
      type="single"
      value={data?.value || null}
      setValue={(value) => {
        if (!value?.value) return;
        setOwner(value);
      }}
      inputProps={{
        loadingQuery: loadingValue,
        loadingMutation: loadingMutation,
        placeholder:
          target === 'owner'
            ? 'Add new listing'
            : `Add new ${role || 'spectator'}`,
      }}
      infiniteData={{ ...queryData, setSearch }}
      dropdownProps={{
        // width: ,
        position: 'bottom-start',
      }}
    />
  );
};

// const VendorSelect = ({
//   // rowId,
//   role,
//   tenantId,
//   vendor,
//   ownershipId,
// }: RowProps) => {
//   const [search, setSearch] = useState('');

//   const queryData = useInfiniteQuery(
//     (q, { tenantId, search }, { limit, offset }) => {
//       const where: gqlV2.source_bool_exp = {
//         tenantId: { _eq: tenantId },
//         type: { _eq: 'vendor' },
//         _or: search ? [] : undefined,
//       };

//       const list = q
//         .source({
//           where,
//           order_by: [{ id: 'asc' }],
//           limit,
//           offset,
//         })
//         .map<SelectItem>((item) => ({
//           label: getSourceDescription(item) || item.remoteId || '',
//           value: item.id || '',
//         }));

//       const aggregate = q.sourceAggregate({ where }).aggregate?.count() || 0;

//       return {
//         list,
//         aggregate,
//       };
//     },
//     {
//       skip: !tenantId,
//       queryKey: 'sources',
//       variables: {
//         tenantId,
//         search: search?.trim(),
//       },
//     }
//   );

//   const { data, isLoading: loadingValue } = useQuery(
//     (q, { vendorSourceId }) => {
//       const value = q
//         .source({
//           where: { id: { _eq: vendorSourceId || emptyUUID } },
//           limit: 1,
//         })
//         .map<SelectItem>((source) => ({
//           label: getSourceDescription(source) || source.remoteId || '',
//           value: source.id,
//         }))[0];

//       return {
//         value,
//       };
//     },
//     {
//       skip: !vendor?.value,
//       queryKey: 'sources',
//       variables: {
//         vendorSourceId: vendor?.value,
//       },
//     }
//   );

//   const { mutate, loading: loadingMutation } = useVendorMutation(tenantId);

//   const setVendor = (value: SelectItem) => {
//     const oldSourceId = vendor?.value;
//     const newSourceId = value.value;
//     if (oldSourceId === newSourceId) return;
//     if (!ownershipId)
//       return showErrorNotification({
//         message: 'Missing ownership.',
//         color: 'yellow',
//       });

//     const settingId = vendor?.settingId;
//     mutate({
//       ownershipId,
//       settingId,
//       sourceId: newSourceId,
//     });
//   };

//   if (role === 'spectator' || !ownershipId) return <Box sx={{ flex: 1 }} />;

//   return (
//     <Box sx={{ flex: 1 }}>
//       <InputSelect
//         type="single"
//         value={data?.value || null}
//         setValue={(value) => {
//           if (!value?.value) return;
//           setVendor(value);
//         }}
//         inputProps={{
//           loadingQuery: loadingValue,
//           loadingMutation: loadingMutation,
//           placeholder: 'Select provider',
//         }}
//         infiniteData={{ ...queryData, setSearch }}
//         dropdownProps={{
//           width: 'target',
//           position: 'bottom-start',
//         }}
//       />
//     </Box>
//   );
// };

// const useVendorMutation = (tenantId: string) => {
//   const [{ automations }] = useTeam();

//   const mappingKey = 'vendor';

//   const statementAutomation = (automations || []).find(
//     (a) =>
//       Object.keys(a.mappings || {}).includes(mappingKey) &&
//       a.template.uniqueRef?.toLowerCase().endsWith('ownerstatements')
//   );

//   const leftType = statementAutomation?.mappings[mappingKey]?.left?.schema;
//   const rightType = statementAutomation?.mappings[mappingKey]?.right?.schema;
//   const leftConnectionId = statementAutomation?.leftConnectionId;
//   const rightConnectionId = statementAutomation?.rightConnectionId;
//   const automationId = statementAutomation?.automationId;

//   const { mutate, loading } = useMutation(
//     (
//       q,
//       args: {
//         sourceId: string;
//         settingId: Maybe<string>;
//         ownershipId: string;
//         options: {
//           leftType: string;
//           rightType: string;
//           mappingKey: string;
//           leftConnectionId: string;
//           rightConnectionId: string;
//           automationId: string;
//           tenantId: string;
//         };
//       }
//     ) => {
//       const {
//         automationId,
//         leftConnectionId,
//         leftType,
//         mappingKey,
//         rightConnectionId,
//         rightType,
//         tenantId,
//       } = args.options;

//       const formatVendorSetting = ({
//         ownershipId,
//         vendorId,
//         settingId,
//       }: {
//         ownershipId: string;
//         vendorId: string;
//         settingId: string | undefined;
//       }) => {
//         return ensure<gqlV2.setting_insert_input>({
//           id: settingId,
//           value: vendorId,
//           tenant_id: tenantId,
//           group: leftType,
//           key: mappingKey,
//           leftType,
//           rightType,
//           target: ownershipId,
//           leftConnectionId,
//           rightConnectionId,
//           automationId,
//         });
//       };

//       // Existing setting
//       if (args.settingId) {
//         return q.update_setting_by_pk({
//           pk_columns: { id: args.settingId },
//           _set: formatVendorSetting({
//             ownershipId: args.ownershipId,
//             settingId: args.settingId,
//             vendorId: args.sourceId,
//           }),
//         })?.id;
//       } else {
//         return q.insert_setting_one({
//           object: formatVendorSetting({
//             ownershipId: args.ownershipId,
//             settingId: undefined,
//             vendorId: args.sourceId,
//           }),
//         })?.id;
//       }
//     },
//     {
//       invalidateQueryKeys: ['listingOwners', 'listings', 'owners'],
//     }
//   );

//   return {
//     loading,
//     mutate: (args: Omit<Parameters<typeof mutate>[0]['args'], 'options'>) => {
//       if (!statementAutomation)
//         return showErrorNotification({
//           message: 'Missing owner statement automation.',
//           color: 'yellow',
//         });

//       if (!leftType || !rightType)
//         return showErrorNotification({
//           message: 'Missing left/right type.',
//           color: 'yellow',
//         });

//       return mutate({
//         args: {
//           ...args,
//           options: {
//             automationId,
//             leftConnectionId,
//             leftType,
//             mappingKey,
//             rightConnectionId,
//             rightType,
//             tenantId,
//           },
//         },
//       });
//     },
//   };
// };

const useOwnershipMutation = () => {
  const { track } = useBrowserTracking();

  const props = useMutation(
    (
      q,
      args: {
        ownerId: string;
        ownershipId: Maybe<string>;
        listingId: string;
        role: listing_owner_role_enum;
        split: number | undefined | null;
        vendorSettingId: Maybe<string>;
      }
    ) => {
      if (args.ownershipId) {
        const ownership = q.updateListingOwner({
          pk_columns: { id: args.ownershipId },
          _set: {
            listingId: args.listingId,
            ownerId: undefined,
            newOwnerId: args.ownerId,
            role: args.role,
            split: args.role === 'spectator' ? null : args.split,
          },
        })?.id;

        let deletedSetting: string | undefined;

        if (args.role === 'spectator' && args.vendorSettingId) {
          deletedSetting = q.delete_setting_by_pk({
            id: args.vendorSettingId,
          })?.id;
        }

        return {
          ownership,
          deletedSetting,
        };
      } else {
        const res = q.insertListingOwner({
          object: {
            listingId: args.listingId,
            ownerId: undefined,
            newOwnerId: args.ownerId,
            role: args.role,
            split: args.split,
          },
        });

        return {
          ownership: res?.id,
          deletedSetting: undefined,
        };
      }
    },
    {
      invalidateQueryKeys: ['listingOwners', 'listings', 'owners'],
    }
  );

  return {
    ...props,
    mutate: (p: Parameters<typeof props.mutate>[0]) => {
      return props.mutate(p).then((res) => {
        track(p.args.ownershipId ? 'ownership_updated' : 'ownership_created', {
          ownershipId: p.args.ownershipId || res.ownership,
          listingId: p.args.listingId,
          ownerId: p.args.ownerId,
        });

        return res;
      });
    },
  };
};

const SplitInput = ({
  split,
  ownershipId,
  role,
  owner,
  rowId,
  vendor,
  listing,
}: RowProps) => {
  const [value, setValue] = useState(split);

  const formatted = typeof value === 'number' ? value : '';

  const width = 90;

  useEffect(() => {
    setValue(split);
  }, [split]);

  const { mutate, loading } = useOwnershipMutation();

  console.log(owner);

  if (!ownershipId || !owner?.id || role === 'spectator')
    return <Box w={width} />;

  const setSplit = async (value: number | undefined) => {
    await mutate({
      args: {
        ownershipId,
        split: typeof value === 'number' ? value : null,
        listingId: listing?.id || rowId,
        ownerId: owner.id,
        role: role || 'spectator',
        vendorSettingId: vendor?.settingId,
      },
    });
  };

  return (
    <InputPercentage
      value={formatted}
      onChange={(newValue) =>
        setValue(typeof newValue === 'number' ? newValue : undefined)
      }
      onBlur={(event) => {
        const newValue = event.target.value;
        const parsed = Number(parseFloat(newValue).toFixed(2));
        if (!newValue && typeof split === 'number') setSplit(undefined);
        else if (newValue && parsed !== split) setSplit(parsed);
      }}
      w={width}
      hideControls
      decimalScale={2}
      placeholder="AUTO"
      loadingMutation={loading}
    />
  );
};
