import { useAuth } from '@clerk/clerk-react';
import { useIntercom } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';
import { ActionIcon, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router';
import { InviteMemberModal } from '../../settings/_components';
import {
  OnboardingTeamSwitch,
  useOnboardingTeamSwithQuery,
} from './OnboardingTeamSwitch';

export const OnboardingMenu = () => {
  const theme = useMantineTheme();
  const { signOut } = useAuth();
  const { show: showIntercom } = useIntercom();
  const goto = useNavigate();
  const iconColor = theme.colors.gray[6];
  const [openedInvite, handlersInvite] = useDisclosure(false);
  const [openedMenu, handlersMenu] = useDisclosure(false);

  const { queryData } = useOnboardingTeamSwithQuery({ search: '' });

  return (
    <>
      <Menu
        trigger="click"
        position="top"
        withArrow
        withinPortal
        width={240}
        closeOnItemClick={false}
        opened={openedMenu}
        onClose={handlersMenu.close}
      >
        <Menu.Target>
          <ActionIcon
            onClick={handlersMenu.toggle}
            variant="transparent"
            sx={() => ({
              borderRadius: '20%' as any,
              '&:hover': {
                backgroundColor: '#00000030',
              },
            })}
          >
            <Icon icon="MenuIcon" size={18} color="white" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            component="a"
            href="https://status.vrplatform.app"
            disabled
            leftSection={
              queryData.isLoading ? (
                <LoadingIndicator size="14px" />
              ) : (
                <Icon
                  icon="Activity2Icon"
                  size={16}
                  color={theme.colors.green[6]}
                />
              )
            }
            sx={(theme) => ({
              color: theme.black,
              opacity: 1,
            })}
          >
            All systems are online
          </Menu.Item>

          <Menu.Divider />

          <OnboardingTeamSwitch />

          <Menu.Item
            onClick={() => {
              handlersInvite.open();
              handlersMenu.close();
            }}
            leftSection={
              <Icon icon="UserPlusIcon" size={16} color={iconColor} />
            }
          >
            Invite member
          </Menu.Item>

          <Menu.Item
            onClick={() => goto('/settings')}
            leftSection={<Icon icon="GearIcon" size={16} color={iconColor} />}
          >
            Settings
          </Menu.Item>

          <Menu.Item
            onClick={() => {
              showIntercom?.();
              handlersMenu.close();
            }}
            leftSection={
              <Icon icon="CommentQuestionIcon" size={16} color={iconColor} />
            }
          >
            Help Center
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            onClick={() => signOut()}
            leftSection={<Icon icon="LogOutIcon" size={16} color={iconColor} />}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <InviteMemberModal opened={openedInvite} onClose={handlersInvite.close} />
    </>
  );
};
