import { Button } from '@finalytic/components';
import { useMe, useMutation } from '@finalytic/data';
import { Divider, Group, Switch, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { SettingsTitle, SettingsViewContainer } from './_components';

type FormValues = {
  'owner-statements': boolean;
};

function useUserMutation() {
  return useMutation(
    (
      q,
      {
        currentNotifications,
        inputs,
        userId,
      }: {
        userId: string;
        currentNotifications: string[];
        inputs: FormValues;
      }
    ) => {
      let notifications = [...currentNotifications];

      Object.entries(inputs).forEach(([k, value]) => {
        console.log(k, value);

        const key = keyToNotifyKey(k as keyof FormValues);

        if (value) {
          if (!notifications.includes(key)) {
            notifications.push(key);
          }
        } else {
          notifications = notifications.filter(
            (notification) => notification !== key
          );
        }
      });

      return q.updateUserById({
        pk_columns: { id: userId },
        _set: { notificationPreferences: notifications },
      })?.id;
    },
    {
      invalidateQueryKeys: ['users', 'team'],
      successMessage: {
        title: 'Success',
        message: 'Your notification preferences have been updated.',
      },
    }
  );
}

type NotifyKeys = 'owner-statements.status-change.email';

const keyToNotifyKey = (key: keyof FormValues) => {
  const map: Record<keyof FormValues, NotifyKeys> = {
    'owner-statements': 'owner-statements.status-change.email',
  };

  return map[key];
};

export const SettingsOwnerNotificationsView = () => {
  const { notificationPreferences, id: userId } = useMe();

  const methods = useForm<FormValues>({
    values: {
      'owner-statements': notificationPreferences.includes(
        'owner-statements.status-change.email'
      ),
    },
  });

  const { mutate, loading } = useUserMutation();

  const submit = async (inputs: FormValues) =>
    mutate({
      args: { userId, inputs, currentNotifications: notificationPreferences },
    });

  return (
    <SettingsViewContainer>
      <FormProvider {...methods}>
        <SettingsTitle type="view-title">Notifications</SettingsTitle>
        <BooleanSection
          title="Owner Statement Notifications"
          description="Enable email notifications to receive an email when a new statement gets published for you."
          formKey="owner-statements"
        />
        <Divider my="xl" />

        <Group mt={40} justify="right">
          <Button onClick={() => methods.reset()}>Cancel</Button>
          <Button
            variant="primary"
            onClick={methods.handleSubmit(submit)}
            loading={loading}
            disabled={!methods.formState.isDirty}
          >
            Save changes
          </Button>
        </Group>
      </FormProvider>
    </SettingsViewContainer>
  );
};

const BooleanSection = ({
  title,
  description,
  formKey,
}: {
  title: string;
  description: ReactNode;
  formKey: keyof Pick<FormValues, 'owner-statements'>;
}) => {
  const { control } = useFormContext<FormValues>();

  const theme = useMantineTheme();

  return (
    <>
      <SettingsTitle type="sub-heading">{title}</SettingsTitle>
      <Controller
        name={formKey}
        control={control}
        render={({ field: { value, onChange, name } }) => (
          <Switch
            labelPosition="left"
            label={description}
            name={name}
            checked={value}
            onChange={onChange}
            styles={{
              // body: { alignItems: 'center' },
              label: { color: theme.colors.gray[7] },
            }}
          />
        )}
      />
    </>
  );
};
