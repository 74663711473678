import { Query, useQuery, useTeamId } from '@finalytic/data';
import { getTenantAddress } from '@vrplatform/ui-common';

export const getOwnerPortalTaxStatementSetting = (
  q: Query,
  args: {
    teamId: string;
  }
) => {
  const isUSAAddress =
    getTenantAddress(q.tenantById({ id: args.teamId })).values.countryCode ===
    'US';

  const hideTaxStatementSettingId = q.setting({
    where: {
      tenant_id: { _eq: args.teamId },
      key: { _eq: 'tenantSettings' },
      target: { _eq: 'hideTaxStatements' },
    },
    limit: 1,
  })[0]?.id as string | undefined;

  return !hideTaxStatementSettingId && isUSAAddress;
};

export function useOwnerPortalTaxStatementSettingQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return getOwnerPortalTaxStatementSetting(q, args);
    },
    {
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );
}
