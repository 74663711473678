import {
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  type gqlV2,
  useApiMutation,
  useInfiniteQuery,
  useInvalidateQueries,
} from '@finalytic/data';
import type {
  accountAssignmentType_enum,
  accountClassification_enum,
} from '@finalytic/graphql';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { hasValue, toTitleCase } from '@finalytic/utils';
import { Box, Group, Modal } from '@mantine/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAccountsAssignmentsQuery } from '../ledger-accounts/useAccountsAssignmentsQuery';

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

type Props = {
  opened: boolean;
  closeModal: () => void;
  onAccountAdded?: (accountId: string) => void;
};

export const AddAccountModal = ({
  opened,
  closeModal: cl,
  onAccountAdded,
}: Props) => {
  type FormInputs = {
    code: string;
    title: string;
    classification: accountClassification_enum;
    assignments: accountAssignmentType_enum[];
  };

  const methods = useForm<FormInputs>();

  const invalidate = useInvalidateQueries([
    'accounts',
    'settings',
    'paymentLineClassifications',
  ]);

  const { mutateAsync: insert } = useApiMutation('post', '/accounts');

  const closeModal = () => {
    methods.reset();
    cl();
  };

  const submit = async (data: FormInputs) => {
    if (!data.title.trim())
      return showWarnNotification({
        message: 'Please enter an account title.',
      });

    const assignments = data.assignments.filter(hasValue);

    return await insert({
      body: {
        name: data.title,
        uniqueRef: data.code,
        classification: data.classification,
        type: 'ledger',
        status: 'active',
        assignments: assignments as any[],
      },
    })
      .then((res) => {
        invalidate();
        showSuccessNotification({
          title: 'Accounts',
          message: 'Account was added successfully',
        });
        closeModal();
        onAccountAdded?.(res.id);
      })
      .catch((err) => {
        showErrorNotification({
          title: 'Failed to add account',
          message:
            err.message || 'Please reach out to support if the issue persists',
        });
      });
  };

  const {
    data: availableAssignmentTypes,
    isLoading: loadingAvailableAssignmentTypes,
    error: errorAvailableAssignmentTypes,
  } = useAccountsAssignmentsQuery({
    skip: !opened,
    currentAccountAssignments: [],
  });

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title="Add Account"
      styles={{
        title: { fontWeight: 500 },
      }}
      centered
      withinPortal
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <Group align="flex-start">
          <Controller
            control={methods.control}
            rules={{
              required: 'Code is required',
              validate: trapSpacesForRequiredFields,
              pattern: {
                value: /^[0-9]+$/,
                message: 'Code must consist of numbers only',
              },
            }}
            name="code"
            render={({ field, fieldState }) => (
              <InputWrapper label="Code" error={fieldState.error?.message}>
                <Input
                  value={field.value}
                  setValue={field.onChange}
                  error={!!fieldState.error}
                  type="number"
                  placeholder="1010"
                  maw={100}
                />
              </InputWrapper>
            )}
          />
          <Controller
            control={methods.control}
            rules={{
              required: 'Title is required',
              validate: trapSpacesForRequiredFields,
            }}
            name="title"
            render={({ field, fieldState }) => (
              <InputWrapper
                label="Title"
                error={fieldState.error?.message}
                sx={{
                  flex: 1,
                }}
              >
                <Input
                  value={field.value}
                  setValue={field.onChange}
                  error={!!fieldState.error}
                  placeholder="Management fees"
                />
              </InputWrapper>
            )}
          />
        </Group>

        <Controller
          control={methods.control}
          rules={{
            required: 'Classification is required',
          }}
          name="classification"
          render={({ field, fieldState }) => {
            const [search, setSearch] = useState('');

            const queryData = useInfiniteQuery(
              (q, args, { limit, offset }) => {
                const where: gqlV2.accountClassification_bool_exp = {
                  name: args.search
                    ? { _ilike: `%${args.search}%` }
                    : undefined,
                };

                const aggregate =
                  q
                    .accountClassificationAggregate({
                      where,
                    })
                    .aggregate?.count() || 0;

                const list = q
                  .accountClassifications({
                    where,
                    limit,
                    offset,
                    order_by: [{ name: 'asc_nulls_last' }],
                  })
                  .map((clas) => ({
                    label: toTitleCase(clas.name)!,
                    value: clas.name!,
                  }));

                return {
                  aggregate,
                  list,
                };
              },
              {
                variables: {
                  search: search?.trim(),
                },
              }
            );

            return (
              <InputWrapper
                label="Classification"
                error={fieldState.error?.message}
              >
                <InputSelect
                  type="single"
                  infiniteData={{ ...queryData, setSearch }}
                  value={
                    field.value
                      ? {
                          value: field.value,
                          label: toTitleCase(field.value),
                        }
                      : null
                  }
                  setValue={(v) => field.onChange(v?.value)}
                  inputProps={{
                    error: !!fieldState.error,
                    placeholder: 'Revenue',
                  }}
                  dropdownProps={{
                    withinPortal: true,
                    width: 'target',
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          name="assignments"
          defaultValue={[]}
          rules={{
            required: false,
          }}
          render={({ field, fieldState }) => {
            const value =
              availableAssignmentTypes?.filter((x) =>
                field.value?.includes(x.value)
              ) || [];

            return (
              <InputWrapper
                label="Assignments"
                error={fieldState.error?.message}
                mb="sm"
              >
                <InputSelect
                  type="multiple"
                  value={value}
                  setValue={(v) => field.onChange(v.map((x) => x.value))}
                  data={{
                    options: availableAssignmentTypes || [],
                    loading: loadingAvailableAssignmentTypes,
                    error: errorAvailableAssignmentTypes,
                  }}
                  inputProps={{
                    placeholder: 'Select assignments',
                  }}
                  dropdownProps={{
                    withinPortal: true,
                  }}
                />
              </InputWrapper>
            );
          }}
        />

        <Group justify="right">
          <Button
            type="button"
            onClick={() => closeModal()}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={methods.handleSubmit(submit, (err) => console.log(err))}
            loading={methods.formState.isSubmitting}
          >
            Add account
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
