import { Filter } from '@finalytic/components';
import { gqlV2, useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { accountAssignmentType_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Group } from '@mantine/core';
import {
  formatAssignmentType,
  orderByAccount,
  whereAccounts,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';

export type LineTypeType = 'payment_line' | 'reservation_line';

export const useLineTypeMappingFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    appId: StringParam,
    accountId: StringParam,
    assignment: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () =>
      setFilter({
        search: undefined,
        appId: undefined,
        accountId: undefined,
        assignment: undefined,
      }),
  };
};

export const LineTypeMappingFilter = () => {
  const { isMasterList } = useAccountsConfig();

  return (
    <Group>
      <Search />
      {isMasterList && (
        <>
          <App />
          <Assignment />
        </>
      )}
      <Account />
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = useLineTypeMappingFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Assignment = () => {
  const { filter, setFilter } = useLineTypeMappingFilter();

  const { data: options = [], isLoading: loading } = useQuery(
    (q) => {
      return q
        .accountAssignmentTypes({
          order_by: [{ name: 'asc' }],
        })
        .map<SelectItem<accountAssignmentType_enum>>((x) => {
          const type = x.name! as accountAssignmentType_enum;

          return {
            value: type,
            label: formatAssignmentType(type),
          };
        });
    },
    {
      queryKey: ['assignmentTypes'],
    }
  );

  const value = useMemo<SelectItem | null>(() => {
    if (!filter.assignment) return null;

    if (filter.assignment === 'exclude')
      return {
        value: 'exclude',
        label: 'Excluded',
      };

    if (filter.assignment === 'unmapped')
      return {
        value: 'unmapped',
        label: 'Unmapped',
      };

    return options.find((i) => i.value === filter.assignment) ?? null;
  }, [options, filter.assignment]);

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ assignment: v?.value })}
      label="Assignment"
      type="single"
      withinPortal
      data={{
        options,
        loading,
      }}
      customBottomActions={[
        {
          label: 'Excluded',
          id: 'exclude',
          icon: <Icon icon="CrossCircleIcon" size={16} />,
          // description: 'Show excluded account mappings',
          onSubmit: () => setFilter({ assignment: 'exclude' }),
        },
        {
          label: 'Unmapped',
          id: 'unmapped',
          icon: <Icon icon="AlertCircleIcon" size={16} />,
          // description: 'Show excluded account mappings',
          onSubmit: () => setFilter({ assignment: 'unmapped' }),
        },
      ]}
    />
  );
};

const Account = () => {
  const { filter, setFilter } = useLineTypeMappingFilter();
  const [search, setSearch] = useState('');
  const [teamId] = useTeamId();

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.account_bool_exp = whereAccounts({
        tenantId: teamId,
        search,
      });

      const list = q
        .accounts({
          where,
          order_by: orderByAccount,
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.title || 'No name',
          group: toTitleCase(res.classification || '-'),
        }));

      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { accountId }) => {
      if (!accountId) return null;

      if (accountId === 'exclude')
        return {
          value: { value: 'exclude', label: 'Excluded' },
        };

      if (accountId === 'unmapped')
        return {
          value: { value: 'unmapped', label: 'Unmapped' },
        };

      const app = q.account({
        id: accountId,
      });

      return {
        value: {
          value: app.id!,
          label: app.title || '-',
        },
      };
    },
    {
      queryKey: ['accounts'],
      keepPreviousData: true,
      variables: {
        accountId: filter.accountId!,
      },
    }
  );

  const value = data?.value?.value ? data.value : null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ accountId: v?.value })}
      label="Account"
      type="single"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
      customBottomActions={[
        {
          label: 'Excluded',
          id: 'exclude',
          icon: <Icon icon="CrossCircleIcon" size={16} />,
          // description: 'Show excluded account mappings',
          onSubmit: () => setFilter({ accountId: 'exclude' }),
        },
        {
          label: 'Unmapped',
          id: 'unmapped',
          icon: <Icon icon="AlertCircleIcon" size={16} />,
          // description: 'Show excluded account mappings',
          onSubmit: () => setFilter({ accountId: 'unmapped' }),
        },
      ]}
    />
  );
};

const App = () => {
  const { filter, setFilter } = useLineTypeMappingFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { search }, { limit, offset }) => {
      const where: gqlV2.payment_line_classification_bool_exp = {
        app: search
          ? {
              name: { _ilike: `%${search}%` },
            }
          : undefined,
        appId: { _is_null: false, _neq: 'finalytic' },
      };

      const list = q
        .paymentLineClassifications({
          where,
          order_by: [{ appId: 'asc_nulls_last' }],
          limit,
          offset,
          distinct_on: ['appId'],
        })
        .map<SelectItem>((res) => ({
          value: res.appId!,
          label: res.app?.name || 'Missing app name',
        }));

      const aggregate =
        q
          .paymentLineClassificationAggregate({ where, distinct_on: ['appId'] })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { appId }) => {
      if (!appId)
        return {
          value: { value: 'airbnb', label: 'Airbnb' },
        };

      const app = q.appById({
        id: appId,
      });

      return {
        value: {
          value: app.id!,
          label: app.name || 'Missing app name',
        },
      };
    },
    {
      queryKey: ['reservations', 'connections'],
      keepPreviousData: true,
      variables: {
        appId: filter.appId!,
      },
    }
  );

  const value = data?.value?.value ? data.value : null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => v?.value && setFilter({ appId: v?.value })}
      label="App"
      type="single"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
      hideClearButton
    />
  );
};
