import {
  gqlV2,
  useEnabledFeatures,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { Maybe, day, ensure, sortBy } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatAddress,
  formatOwnerName,
  formatUserName,
  getListingName,
  getOwnerAddress,
  getOwnerCompanyType,
  whereListingPeriod,
} from '@vrplatform/ui-common';

type Ownership = {
  id: string;
  listing: {
    id: string;
    name: string;
    address: string | undefined;
  };
  split: number | undefined;
};

export function useOwnerDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      if (args.GL) {
        return (
          q
            .contacts({
              where: {
                id: { _eq: args.id },
                type: { _eq: 'owner' },
              },
            })
            .map((contact) => {
              const status = contact.status;
              const pmsStatus = contact.pmsStatus;

              const addressData = (contact.addressData() || null) as
                | Parameters<typeof formatAddress>[0]
                | null;

              const currentPeriodMemberships = contact
                .listingPeriodMemberships({
                  where: {
                    period: whereListingPeriod({
                      date: day().yyyymmdd(),
                    }),
                  },
                })
                .map((member) => ({
                  id: member.id,
                  listingName: getListingName(member.period.listing),
                  listingId: member.period.listingId,
                  split: member.split as Maybe<number>,
                }));

              const ownerships: Ownership[] = [];

              const getIsArchived = () => {
                if (status === 'inactive') {
                  return true;
                }

                if (!status && pmsStatus === 'inactive') {
                  return true;
                }

                return false;
              };

              return {
                id: contact.id,
                taxId: contact.taxId,
                status,
                currentPeriodMemberships,
                pmsStatus,
                addressId: undefined,
                isArchived: getIsArchived(),
                defaultAccountId: contact.defaultAccountId,
                tenantId: contact.tenantId,
                email: contact.email,
                phone: contact.phone,
                is1099PostalDelivery: contact.is1099PostalDelivery ?? true,
                type: contact.companyType ? 'company' : 'individual',
                companyType: getOwnerCompanyType(contact.companyType),
                name: formatOwnerName(contact),
                firstName: contact.firstName,
                lastName: contact.name,
                companyName: contact.name,
                teamId: contact.tenantId,
                address: addressData || {
                  line1: '',
                  line2: undefined,
                  city: '',
                  postcode: '',
                  state: undefined,
                  stateCode: undefined,
                  country: undefined,
                  countryCode: undefined,
                },
                ownerships,
                hasOwnerships: ownerships.length > 0,
                ownerStatements: [],
                userAccesses: sortBy(
                  contact
                    .userAccess({
                      order_by: [
                        {
                          user: {
                            firstName: 'asc_nulls_last',
                          },
                        },
                      ],
                      where: {
                        user: {
                          memberships: {
                            tenantId: { _eq: args.teamId },
                          },
                        },
                      },
                    })
                    .map((access) => {
                      return {
                        id: access.id,
                        userId: access.userId,
                        name: formatUserName(access.user),
                        email: access.user.email,
                        role: access.role,
                      };
                    }),
                  'name'
                ),
                // ownerStatements: contact
                //   .statementOwnerships({
                //     order_by: [
                //       {
                //         statement: {
                //           startAt: 'desc_nulls_last',
                //         },
                //       },
                //     ],
                //   })
                //   .map((statementOwner) => ({
                //     id: statementOwner.statementId,
                //     startAt: statementOwner.statement.startAt,
                //     status: statementOwner.statement.status,
                //     listing: {
                //       id: statementOwner.statement?.listing?.id,
                //       name: statementOwner.statement?.listing
                //         ? getListingName(statementOwner.statement?.listing)
                //         : '',
                //     },
                //     statementOwner: {
                //       id: statementOwner.id,
                //       role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                //         statementOwner.owner?.type === 'company'
                //           ? 'company'
                //           : statementOwner.role || 'owner'
                //       ),
                //       name: formatUserName(statementOwner.owner),
                //     },
                //   })),
              };
            })[0] || null
        );
      }

      return (
        q
          .owners({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((owner) => {
            const address = getOwnerAddress(owner).values;

            const currentPeriodMemberships = owner
              .ownershipPeriodMemberships({
                where: {
                  period: whereListingPeriod({
                    date: day().yyyymmdd(),
                  }),
                },
              })
              .map((member) => ({
                id: member.id,
                listingName: getListingName(member.period.listing),
                listingId: member.period.listingId,
                split: member.split as Maybe<number>,
              }));

            const ownerships = owner
              .ownerships({
                order_by: [
                  {
                    createdAt: 'asc_nulls_last',
                  },
                ],
              })
              .map<Ownership>((ownership) => ({
                id: ownership.id,
                listing: {
                  id: ownership.listing.id,
                  name: getListingName(ownership.listing),
                  address: ownership.listing.address,
                },
                split: ownership.split,
              }));

            const getIsArchived = () => {
              if (owner.status === 'inactive') {
                return true;
              }

              if (!owner.status && owner.pmsStatus === 'inactive') {
                return true;
              }

              return false;
            };

            return {
              id: owner.id,
              taxId: owner.taxId,
              status: owner.status,
              is1099PostalDelivery: owner.is1099PostalDelivery ?? true,
              currentPeriodMemberships,
              defaultAccountId: undefined,
              pmsStatus: owner.pmsStatus,
              isArchived: getIsArchived(),
              tenantId: owner.tenantId,
              addressId: owner.addressId,
              email: owner.email,
              phone: owner.phone,
              type: owner.type || 'individual',
              companyType: getOwnerCompanyType(owner.companyType),
              name: formatOwnerName(owner),
              firstName: owner.firstName,
              lastName: owner.name,
              companyName: owner.name,
              teamId: owner.tenantId,
              address,
              ownerships,
              hasOwnerships: ownerships.length > 0,
              userAccesses: sortBy(
                owner
                  .userAccesses({
                    order_by: [
                      {
                        user: {
                          firstName: 'asc_nulls_last',
                        },
                      },
                    ],
                    where: {
                      user: {
                        memberships: {
                          tenantId: { _eq: args.teamId },
                        },
                      },
                    },
                  })
                  .map((access) => {
                    return {
                      id: access.id,
                      userId: access.userId,
                      name: formatUserName(access.user),
                      email: access.user.email,
                      role: access.role,
                    };
                  }),
                'name'
              ),
              ownerStatements: owner
                .statementOwnerships({
                  order_by: [
                    {
                      statement: {
                        startAt: 'desc_nulls_last',
                      },
                    },
                  ],
                })
                .map((statementOwner) => ({
                  id: statementOwner.statementId,
                  startAt: statementOwner.statement.startAt,
                  status: statementOwner.statement.status,
                  listing: {
                    id: statementOwner.statement?.listing?.id,
                    name: statementOwner.statement?.listing
                      ? getListingName(statementOwner.statement?.listing)
                      : '',
                  },
                  statementOwner: {
                    id: statementOwner.id,
                    role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                      statementOwner.owner?.type === 'company'
                        ? 'company'
                        : statementOwner.role || 'owner'
                    ),
                    name: formatUserName(statementOwner.owner),
                  },
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: ['owners', id || ''],
      variables: {
        id,
        teamId,
        GL,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type OwnerDetail = NonNullable<
  ReturnType<typeof useOwnerDetailDrawerQuery>['data']
>;
