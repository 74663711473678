import { Badge } from '@finalytic/components';
import { emailTemplateType_enum } from '@finalytic/graphql';
import { Maybe, day } from '@finalytic/utils';
import { Card, Group, Text, Title, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import { SettingsTitle } from '../_components';

const PATHS: Record<emailTemplateType_enum, string> = {
  invite_owner: 'owner-invitation',
  invite_propertyManager: 'pm-invitation',
  ownerStatement_owner: 'owner-statement',
};

type Props = {
  [key in emailTemplateType_enum]: {
    shortDescription: string;
    updatedAt: Maybe<string>;
  };
};

export const EmailTemplatesList = ({
  invite_owner,
  // invite_propertyManager,
  ownerStatement_owner,
}: Props) => {
  return (
    <>
      <SettingsTitle type="view-title">Emails</SettingsTitle>

      <Text
        component="p"
        size={'0.875rem'}
        mb="xl"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Manage your email templates for your owners and team.
      </Text>

      <Title order={4} mb="md">
        Owner Portal
      </Title>

      <Group mb="xl">
        <TemplateCard
          title="Owner Invitation"
          description={
            invite_owner.shortDescription ||
            'You are invited to join the team. To start using VRPlatform, verify your email address at the following link:'
          }
          formKey="invite_owner"
          updatedAt={invite_owner?.updatedAt}
        />
        <TemplateCard
          title="Owner Statement was published"
          description={
            ownerStatement_owner.shortDescription ||
            'You are invited to join the team. To start using VRPlatform, verify your email address at the following link:'
          }
          formKey="ownerStatement_owner"
          updatedAt={ownerStatement_owner?.updatedAt}
        />
      </Group>

      {/* <Title order={4} mb="md">
        Property Manager
      </Title>

      <Group>
        <TemplateCard
          title="Property Manager Invitation"
          description={
            invite_propertyManager.shortDescription ||
            'You are invited to join the team. To start using VRPlatform, verify your email address at the following link:'
          }
          formKey="invite_propertyManager"
          updatedAt={invite_propertyManager?.updatedAt}
        />
      </Group> */}
    </>
  );
};

const TemplateCard = ({
  title,
  description,
  formKey,
  updatedAt,
}: {
  title: string;
  description: string;
  formKey: emailTemplateType_enum;
  updatedAt?: Maybe<string>;
}) => {
  return (
    <Card
      shadow="sm"
      withBorder
      mih={250}
      w={350}
      radius="md"
      component={Link}
      to={PATHS[formKey]}
      sx={(theme) => ({
        transition: 'box-shadow 100ms ease-out',
        cursor: 'pointer',
        ':hover': {
          boxShadow: theme.shadows.lg,
        },
      })}
    >
      <Title order={4} mb={rem(6)}>
        {title}
      </Title>

      <Text c="gray" size="xs" mb="md">
        {updatedAt
          ? `Last updated on ${day(updatedAt).format('MMM D, YYYY')}`
          : 'Not yet updated'}
      </Text>

      <Text c="neutral" size="xs">
        {description}
      </Text>

      <Badge
        sx={{
          marginTop: 'auto',
        }}
        size="md"
        color={updatedAt ? 'blue' : 'green'}
      >
        {updatedAt ? 'Edited' : 'Original'}
      </Badge>
    </Card>
  );
};
