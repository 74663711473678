import { useUser } from '@clerk/clerk-react';
import {
  useBrowserTracking,
  useDashboard,
  useMe,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { theme as defaultTheme } from '@finalytic/ui';
import {
  Center,
  MantineThemeProvider as MantineProvider,
  createTheme,
} from '@mantine/core';
import { VRP_TENANT_ID, getOwnerPortalColor } from '@vrplatform/ui-common';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  AddTeamDrawer,
  AutomationHistoryDrawer,
  BankRecordDetailDrawer,
  ConnectionDetailDrawer,
  DepositDetailDrawer,
  ExpenseDetailDrawer,
  InviteOwnerUserDrawer,
  OwnerDetailDrawer,
  OwnerUserDetailDrawer,
  PaymentDetailDrawer,
  ReservationDetailDrawer,
} from '../drawers';
import { AppLayout } from '../layout/AppLayout';
import {
  FinalyticMigrationModal,
  OwnerInviteLoggedInModal,
  RunModal,
  WelcomeUserModal,
} from '../modals';
import {
  OwnerNavigation,
  PartnerNavigation,
  PmNavigation,
  SettingsNavigation,
} from '../navigation';
import { VrpNavigation } from '../navigation/VrpNavigation';
import { ListingActivityDrawer } from '../views/listings/activity';
import { ListingDetailDrawer } from '../views/listings/drawer';
import { OnboardingRoutes } from '../views/onboarding';
import { useSignInToken } from '../views/onboarding/hooks';
import { OwnerActivityDrawer } from '../views/owners/owners/activity';
import EmptyTeamView from '../views/setup-migration/EmptyTeamView';
import { DashboardRoutes } from './dashboard';

export const AppRoutes = () => {
  const user = useMe();
  const [team] = useTeam();
  const { tokenIsActive } = useSignInToken();
  const location = useLocation();

  // If user hasn't loaded => show loading
  if (!user?.id) {
    return <Loading />;
  }
  if (!team?.id) {
    return <EmptyTeamView />;
  }

  // Show loading indicator while user preview token is being taken care off
  if (tokenIsActive) {
    return (
      <Center h="100vh">
        <LoadingIndicator isFullPageLoading size="md" />
      </Center>
    );
  }

  // New users are created with "onboarding" status and shown only onboarding screens
  if (team.isOnboarding && !location.pathname.startsWith('/settings')) {
    return <Onboarding />;
  }

  if (user.memberships.some((i) => i.status?.toLowerCase() === 'active')) {
    return <Dashboard />;
  }

  return <EmptyTeamView />;
};

const Onboarding = () => {
  const _ = usePageViewCapture();

  return (
    <>
      <OnboardingRoutes />
      <WelcomeUserModal />
      <FinalyticMigrationModal />
    </>
  );
};

const Dashboard = () => {
  const [dashboard] = useDashboard();

  if (dashboard === 'owner') {
    return <OwnerPortal />;
  }

  return <App />;
};

function OwnerPortal() {
  const [team] = useTeam();
  const primaryColor = getOwnerPortalColor(team.colorPrimary);

  const theme = useMemo(() => {
    if (!primaryColor) return undefined;

    return createTheme({
      ...defaultTheme,
      primaryColor,
    });
  }, [primaryColor]);

  if (!theme) return <App />;

  return (
    <MantineProvider theme={theme}>
      <App />
    </MantineProvider>
  );
}

function App() {
  const [dashboard] = useDashboard();
  const location = useLocation();
  const [teamId] = useTeamId();

  const _ = usePageViewCapture();

  const DashboardNavigation = useMemo(() => {
    if (dashboard === 'owner') return OwnerNavigation;
    if (dashboard === 'partner')
      return teamId === VRP_TENANT_ID ? VrpNavigation : PartnerNavigation;
    if (dashboard === 'overview') return PartnerNavigation;
    return PmNavigation;
  }, [dashboard, teamId]);

  const isSettingsView = location.pathname.startsWith('/settings');

  return (
    <>
      <AppLayout
        navigation={isSettingsView ? SettingsNavigation : DashboardNavigation}
        drawers={[
          AutomationHistoryDrawer,
          RunModal,
          ConnectionDetailDrawer,
          PaymentDetailDrawer,
          ReservationDetailDrawer,
          OwnerDetailDrawer,
          ListingDetailDrawer,
          InviteOwnerUserDrawer,
          OwnerUserDetailDrawer,
          AddTeamDrawer,
          OwnerActivityDrawer,
          ListingActivityDrawer,
          ExpenseDetailDrawer,
          DepositDetailDrawer,
          BankRecordDetailDrawer,
        ]}
      >
        <DashboardRoutes />
      </AppLayout>
      <WelcomeUserModal />
      {/*<OwnerTaxInfoModal />*/}
      <OwnerInviteLoggedInModal />
      <FinalyticMigrationModal />
    </>
  );
}

function usePageViewCapture() {
  const { page } = useBrowserTracking();
  const user = useMe();
  const location = useLocation();

  useEffect(() => {
    const category = location.pathname.split('/')[1];

    if (user.email)
      page(category ? `/${category}` : undefined, location.pathname);
  }, [location, user?.email, page]);
}

const Loading = () => {
  const [teamId] = useTeamId();
  const [, refreshTeam] = useTeam();
  const { user } = useUser();

  useEffect(() => {
    const interval = setInterval(async () => {
      await user?.reload(); // refetch on interval clerk public metadata with tenant_ids
      if (teamId || user?.publicMetadata?.user_id) refreshTeam();
    }, 3000);

    return () => clearInterval(interval);
  }, [teamId, refreshTeam, user]);

  return (
    <Center sx={{ height: '100vh', flexDirection: 'column' }}>
      <LoadingIndicator isFullPageLoading message="Loading Dashboard..." />
    </Center>
  );
};
