import { Button } from '@finalytic/components';
import {
  captureSentryError,
  useBrowserTracking,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Box, Group, Modal, Stack, Text } from '@mantine/core';

export const SubscriptionReinstateModal = ({
  opened,
  closeModal,
  subscriptionId,
  refetch,
}: {
  opened: boolean;
  closeModal: () => void;
  subscriptionId: Maybe<string>;
  refetch: () => void;
}) => {
  const [, refetchTeam] = useTeam();
  const { track } = useBrowserTracking();

  const { mutate, loading } = useTrpcMutation(
    'reinstateHyperlineSubscription',
    {
      invalidateQueryKeys: ['billing', 'teams', 'getBillingInfo'],
    }
  );
  const [teamId] = useTeamId();

  const submit = async () => {
    if (!subscriptionId) {
      captureSentryError('Missing subscriptionId during reinstate');
      return showWarnNotification({
        title: 'Reinstate subscription',
        message:
          'Missing subscription. Please reach out to support if the issue persists.',
      });
    }

    await mutate({
      subscriptionId,
      tenantId: teamId,
    })
      .then(() => {
        track('team_reinstated', {
          tenantId: teamId,
          subscriptionId,
        });

        showSuccessNotification({
          id: 'reinstate-subscription-success',
          title: 'Subscription reinstated',
          message: 'Your subscription was successfully reinstated.',
        });
        closeModal();
        refetchTeam();
      })
      .catch((error: any) => {
        console.error(error);
        const message =
          error?.message ||
          "We couldn't reinstate your subscription. Please contact support if the issue persists.";

        showErrorNotification({
          title: 'Subscription reinstate failed',
          message,
        });
      })
      .finally(() => {
        refetch();
      });
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={closeModal}
      onSubmit={async () => {}}
      size={500}
      styles={{
        header: {
          paddingBottom: 0,
          minHeight: 'auto',
        },
      }}
    >
      <Stack align="center" mb={20}>
        <Icon icon="AlertTriangleIcon" size={48} strokeWidth={1} />
        <Box mb={10} sx={{ textAlign: 'center' }}>
          <Text fw={500} mb={10}>
            Are you sure you want to reinstate the subscription?
          </Text>
          <Text size="sm" c="neutral">
            This will cancel the upcoming cancellation and reinstate the
            subscription. You will be charged for the next billing cycle.
          </Text>
        </Box>
      </Stack>
      <Group
        wrap="nowrap"
        sx={{
          '> button': {
            flex: 1,
          },
        }}
      >
        <Button
          sx={{
            maxWidth: 100,
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          data-testid="reinstate-subscription-submit-button"
          leftIcon={'RefreshCwIcon'}
          onClick={submit}
          loading={loading}
        >
          Reinstate subscription
        </Button>
      </Group>
    </Modal>
  );
};
