import { tenant_user as TenantUser } from '@finalytic/graphql';
import { Maybe, day } from '@finalytic/utils';

type TenantUserStatus = 'active' | 'inactive' | 'pending' | 'expired';

export const getTenantUserStatus = (tenantUser: TenantUser) => {
  const status = (tenantUser.status || 'inactive') as
    | 'active'
    | 'inactive'
    | 'unconfirmed';
  const lastInvitedAt = tenantUser.lastInvitedAt as Maybe<string>;

  const getStatus = (): TenantUserStatus => {
    const now = day();
    if (
      status === 'unconfirmed' &&
      lastInvitedAt &&
      now.diff(lastInvitedAt, 'days') > 7
    ) {
      return 'expired';
    }

    if (status === 'unconfirmed') {
      return 'pending';
    }

    return status;
  };

  return {
    status: getStatus(),
    lastInvitedAt,
  };
};
