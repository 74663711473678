import { Heading } from '@react-email/heading';
import { Text } from '@react-email/text';
import { Button, EmailContainer } from '../_components';
import type { EmailBaseProps } from '../_types';
import { parseEmailHtml } from '../_utils';

export type ActionEmailData = {
  subject: string;
  shortDescription: string;
  banner?: string;
  header: string;
  bodyOne: string;
  bodyTwo: string;
  href: string;
  buttonText: string;
};

export interface ActionEmailTemplateProps extends EmailBaseProps {
  data: ActionEmailData;
}

export const ActionEmailTemplate = ({
  data,
  tenant,
  themeColors,
  mode,
  user,
  listing,
  month,
}: ActionEmailTemplateProps) => {
  return (
    <EmailContainer
      title={data.subject}
      shortDescription={data.shortDescription}
      tenant={{
        logo: tenant.logo,
      }}
      mode={mode}
    >
      <Heading
        style={{
          fontSize: 28,
          fontWeight: 600,
          marginBottom: '50px',
        }}
      >
        {data.header?.trim() || `Welcome to ${tenant.name}!`}
      </Heading>

      {data.banner?.trim() && (
        <Text
          style={{
            padding: '24px',
            backgroundColor: '#f2f3f3',
            borderRadius: '4px',
            marginBottom: '40px',
          }}
        >
          {data.banner.trim()}
        </Text>
      )}

      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{
          __html: parseEmailHtml(data.bodyOne, {
            href: data.href,
            userName: user.firstName || '',
            date: month || '',
            listingName: listing || '',
          }),
        }}
      />

      <Button
        href={data.href}
        mode={mode}
        data-testid="accept-invite-button"
        label={data.buttonText?.trim() || 'Accept Invitation'}
        themeColors={themeColors}
      />

      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{
          __html: parseEmailHtml(data.bodyTwo, {
            href: data.href,
            userName: user.firstName || '',
            date: month || '',
            listingName: listing || '',
          }),
        }}
      />
    </EmailContainer>
  );
};
