import { Filter } from '@finalytic/components';
import { useInfiniteQuery, useTeam } from '@finalytic/data';
import { activeStatus_enum } from '@finalytic/graphql';
import { SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import { whereAccounts } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';

export const useAccountsFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    type: StringParam,
    classification: StringParam,
    status: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () =>
      setFilter({
        search: undefined,
        classification: undefined,
        status: undefined,
        type: undefined,
      }),
  };
};

export const AccountsFilter = () => {
  const { isMasterList } = useAccountsConfig();

  return (
    <Group>
      <Search />
      {!isMasterList && <StatusFilter />}
      <SelectFilter type="type" />
      <SelectFilter type="classification" />
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = useAccountsFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

type SelectFilterType = 'type' | 'classification';

const SelectFilter = ({ type }: { type: SelectFilterType }) => {
  const { filter, setFilter } = useAccountsFilter();
  const [search, setSearch] = useState('');

  const queryData = useAccountColumnQuery(type, search);

  const value = filter[type]
    ? { value: filter[type]!, label: toTitleCase(filter[type]) }
    : null;

  return (
    <Filter.Select
      type="single"
      label={toTitleCase(type)}
      value={value}
      infiniteData={{ ...queryData, setSearch }}
      setValue={(v) => setFilter({ [type]: v?.value })}
      hideSearch
    />
  );
};

const StatusFilter = () => {
  const { filter, setFilter } = useAccountsFilter();

  const status = filter.status || 'active';

  const options: SelectItem<activeStatus_enum>[] = useMemo(
    () => [
      {
        label: 'Active',
        value: 'active',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Archived',
        value: 'inactive',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ],
    []
  );

  const value = useMemo(
    () => options.find((o) => status === o.value) || null,
    [options, status]
  );

  return (
    <Filter.Select
      type="single"
      label={'Status'}
      value={value}
      data={{
        options,
      }}
      setValue={(v) => v?.value && setFilter({ status: v.value })}
      hideSearch
      hideIcon
      hideClearButton
    />
  );
};

export function useAccountColumnQuery(type: SelectFilterType, search: string) {
  const [{ id: teamId }] = useTeam();

  return useInfiniteQuery(
    (q, args) => {
      const where = whereAccounts({
        tenantId: args.teamId,
        search: undefined,
        classification: undefined,
        type: undefined,
      });

      const aggregate =
        q
          .accountAggregate({
            where,
            distinct_on: [args.type],
          })
          .aggregate?.count() || 0;

      const list = q
        .accounts({
          distinct_on: [args.type],
          where,
          order_by: [{ [args.type]: 'asc' }],
        })
        .map<SelectItem>((account) => ({
          label: toTitleCase(account[args.type]),
          value: account[args.type] || `No ${type}`,
        }));

      return {
        aggregate,
        list,
      };
    },
    {
      variables: {
        type,
        search: search?.trim(),
        teamId,
      },
    }
  );
}
