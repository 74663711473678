import {
  captureSentryError,
  useApiClient,
  useApiMutation,
  useInvalidateQueries,
} from '@finalytic/data';
import type { party_enum } from '@finalytic/graphql';
import { showErrorNotification, showWarnNotification } from '@finalytic/ui';
import { bankersRound } from '@finalytic/utils';
import { useCallback, useState } from 'react';

export type ReservationAdjustmentAddInputs = {
  description: string;
  lineType: string | null;
  centTotal?: number;
  party: party_enum;
};

export function useReservationAdjustmentMutation(
  reservationId: string | null,
  onSuccess: () => void
) {
  const [loading, setLoading] = useState(false);
  const invalidate = useInvalidateQueries(['reservations']);

  const { mutateAsync } = useApiMutation('put', '/reservations/{id}', {
    onSuccess: () => {
      invalidate();
    },
  });

  const $api = useApiClient();

  const submit = useCallback(
    async (
      args:
        | { data: ReservationAdjustmentAddInputs; type: 'add' }
        | {
            data: {
              id: string;
            };
            type: 'remove';
          }
    ) => {
      if (!reservationId) {
        captureSentryError('Missing reservationId');
        return showWarnNotification({
          title: 'Missing reservation',
          message: 'Please reach out to support if the issue persists.',
        });
      }

      try {
        setLoading(true);

        const response = await $api.GET('/reservations/{id}', {
          params: {
            path: { id: reservationId },
          },
        });

        if (response.error) {
          throw new Error(
            response.error.message ||
              response.error.issues?.[0]?.message ||
              'Failed to fetch reservation'
          );
        }

        type Adjustment = NonNullable<
          Parameters<typeof mutateAsync>[0]['body']['adjustments']
        >[number];

        const adjustments = ((): Adjustment[] => {
          // Map to PUT body
          let existing = [
            ...response.data.adjustments.map<Adjustment>((line) => ({
              amount: line.amount,
              connectionId: line.connectionId ?? undefined,
              description: line.description ?? undefined,
              type: line.type,
              accountId: line.account?.id,
              accountRef: line.account?.uniqueRef,
              id: line.id,
              metadata: line.metadata,
              uniqueRef: line.uniqueRef,
            })),
          ];

          // Add new line
          if (args.type === 'add') {
            existing.push({
              description: args.data.description.trim(),
              type: args.data.lineType!,
              amount: bankersRound(args.data.centTotal ?? 0),
              connectionId: null as any,
              party: args.data.party ?? 'owners',
            });
          }

          // OR remove line
          if (args.type === 'remove') {
            existing = existing.filter((x) => x.id !== args.data.id);
          }

          return existing;
        })();

        await mutateAsync({
          params: {
            path: {
              id: reservationId,
            },
          },
          body: {
            adjustments,
          },
        });

        onSuccess();
      } catch (error: any) {
        const message =
          error?.message ||
          'We failed to update the reservation. Please try again later and if the problem persists, contact support.';

        showErrorNotification({
          title: 'Failed to update reservation',
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    [reservationId, onSuccess, mutateAsync, $api]
  );

  return {
    loading,
    submit,
  };
}
