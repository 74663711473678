import { UserProfile } from '@clerk/clerk-react';
import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { SettingsTitle, SettingsViewContainer } from './_components';

export const SettingsClerkView = () => {
  const { colors, primaryColor, ...theme } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  // Clerk component coming from our @finalytic/auth package
  return (
    <SettingsViewContainer maxWidth={700}>
      <SettingsTitle type="view-title">Profile</SettingsTitle>
      <Text
        component="p"
        size={'0.875rem'}
        mb="md"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Manage your profile and security settings.
      </Text>
      <UserProfile
        appearance={{
          layout: {},
          elements: {
            rootBox: {
              width: '100%',
              backgroundColor: colorScheme === 'dark' ? '#1A1B1E' : undefined,
            },
            card: { width: '100%', boxShadow: 'none' },
            cardBox: {
              border: 'none',
              boxShadow: 'none',
              flexDirection: 'column',
              maxWidth: '100%',
            },
            navbar: {
              background: 'transparent',
              display: 'block',
              flex: 0,
              '& > div > div:first-of-type': {
                display: 'none',
              },
            },
            navbarButtons: {
              flexDirection: 'row',
            },
            pageScrollBox: {
              overflowX: 'visible',
            },
            scrollBox: {
              overflow: 'unset',
              borderRadius: 0,
            },
          },
          variables: {
            colorPrimary: colors[primaryColor][colorScheme === 'dark' ? 5 : 7],
            colorBackground: colorScheme === 'dark' ? 'transparent' : undefined,
            colorInputBackground:
              colorScheme === 'dark' ? colors.dark[5] : undefined,
            colorInputText: colorScheme === 'dark' ? theme.white : undefined,
            colorText: colorScheme === 'dark' ? theme.white : undefined,
          },
        }}
        routing="hash"
      />
    </SettingsViewContainer>
  );
};
