import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { CopyIcon } from '@finalytic/icons';
import { InfiniteTable } from '@finalytic/table';
import { EllipsisMenuItem, showSuccessNotification } from '@finalytic/ui';
import { useMantineColorScheme } from '@mantine/core';
import {
  GeneralLedgerDetailFilter,
  useGeneralLedgerDetailFilter,
} from './GeneralLedgerDetailFilter';
import { useGeneralLedgerDetailTableColumns } from './useGeneralLedgerDetailTableColumns';
import { useGeneralLedgerDetailTableQuery } from './useGeneralLedgerDetailTableQuery';

export const GeneralLedgerDetailTable = () => {
  const data = useGeneralLedgerDetailTableQuery();
  const { reset: resetFilter } = useGeneralLedgerDetailFilter();
  const columns = useGeneralLedgerDetailTableColumns();
  const { colorScheme } = useMantineColorScheme();

  return (
    <InfiniteTable
      resetFilter={resetFilter}
      columns={columns}
      table={{
        emptyRowsFallback: 'No journal entries found',
      }}
      styles={{
        row: ({ row }, theme) =>
          row.original.status === 'active'
            ? {}
            : {
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.dark[9]
                    : `${theme.colors.gray[1]}!important`,
              },
      }}
      rowMenu={{
        menuItems: ({ row }) => {
          const id = row.original.id;
          const txnId = row.original.uniqueRef;

          return (
            <>
              {txnId && (
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  disabled={!id}
                  onClick={() =>
                    navigator.clipboard.writeText(txnId).then(() =>
                      showSuccessNotification({
                        message: 'ID copied to clipboard',
                        icon: null,
                        autoClose: 1000,
                      })
                    )
                  }
                >
                  Copy Txn ID
                </EllipsisMenuItem>
              )}
              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  disabled={!id}
                  onClick={() =>
                    navigator.clipboard.writeText(id).then(() =>
                      showSuccessNotification({
                        message: 'ID copied to clipboard',
                        icon: null,
                        autoClose: 1000,
                      })
                    )
                  }
                >
                  Copy journal entry ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
      queryData={data}
    >
      <GeneralLedgerDetailFilter view="all" />
    </InfiniteTable>
  );
};
