import { ConfirmModal } from '@finalytic/components';
import {
  useApiMutation,
  useEnabledFeatures,
  useInvalidateQueries,
  useTrpcMutation,
} from '@finalytic/data';
import { showErrorNotification, showSuccessNotification } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { ListingMigrateModal } from './ListingMigrateModal';

export const ListingEllipsisMenuModals = ({
  deleteModal,
  listing: initial,
  migrationModal,
}: {
  listing: {
    id: string;
    name: Maybe<string>;
    tenantId: string;
  } | null;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
    onSuccess?: () => void;
  };
  migrationModal: {
    opened: boolean;
    closeModal: () => void;
    onSuccess?: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const listing = initial || debounced;
  const { GL } = useEnabledFeatures();

  const invalidate = useInvalidateQueries(['listings']);

  const { mutate, loading: loadingLegacy } = useTrpcMutation('deleteListing', {
    successMessage: { message: 'Successfully deleted listing.' },
    invalidateQueryKeys: ['listings'],
  });

  const { mutateAsync: deleteListing, isPending: loadingApi } = useApiMutation(
    'delete',
    '/listings/{id}',
    {
      onSettled: () => {
        invalidate();
      },
    }
  );

  if (!listing) return null;

  const removeListing = async () => {
    if (GL) {
      await deleteListing({
        params: {
          path: {
            id: listing.id,
          },
          query: {
            onLocked: 'error',
          },
        },
      })
        .then((d) => {
          if (d.status === 'archived') {
            showSuccessNotification({
              title: 'Listing archived',
              message:
                'Due to data related to this listing, it has been archived instead of deleted.',
            });
          } else {
            showSuccessNotification({
              title: 'Listing deleted',
              message: 'Listing has been deleted successfully.',
            });
          }

          deleteModal.closeModal();
          deleteModal.onSuccess?.();
        })
        .catch((error: any) => {
          const message =
            error?.message ||
            'Please reach out to support if the issue persists.';

          showErrorNotification({
            title: 'Failed to delete the listing',
            message,
          });
        });
    } else {
      const res = await mutate({
        foreignObjectId: listing.id,
        tenantId: listing.tenantId,
      });

      if (res?.ok) {
        deleteModal.closeModal();
        deleteModal.onSuccess?.();
      }
    }
  };

  const loading = loadingLegacy || loadingApi;

  return (
    <>
      <ConfirmModal
        type="delete"
        opened={deleteModal.opened}
        closeModal={deleteModal.closeModal}
        title={`Are you sure to delete ${
          listing.name ? listing.name : 'this listing'
        }?`}
        subtitle="Once deleted all data will be lost."
        onSubmit={() => removeListing()}
        loading={loading}
      />
      <ListingMigrateModal
        close={migrationModal.closeModal}
        opened={migrationModal.opened}
        listingId={listing.id}
        listingTeamId={listing.tenantId}
        listingName={listing.name}
        closeDrawer={() => migrationModal?.onSuccess?.()}
      />
    </>
  );
};
